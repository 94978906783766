import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneSendOtp from './phonesendotp'; // Import the Phonesendotp component
import Mainhomepage from '../home/mainhomepage'
import { Modal, Button } from 'react-bootstrap';
import CommonFuctions from '../commonfunction';

function Verifyphone() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const sessionTime = new CommonFuctions();
  const storedPhoneNumber = localStorage.getItem('phoneNumber');
  let location = useLocation();
  //const localphoneNumber = location.state?.phoneNumber || ''; // Access the phone number passed from the previous page
  // console.log('local phone number: ', storedPhoneNumber);
  // Destructure the required functions and states from Phonesendotp
  const [AlertMessage, setAlertMessage] = useState('');


  
  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;
  };




  const handleGetOTP = async () => {

    setLoading(true);
    toggleRefreshAndClose(false); // Prevent refresh and close actions
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };
    // const status = await sessionTime.checkInternetConnection(); // Call the function

    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

      return;
    }
    // if (status === 'Poor connection.') {
    //   setIsDialogOpen(true);
    //   setModalMessage('No/Poor Internet connection. Cannot access server.');
    //   setLoading(false);
    //   return;
    // }

   /// console.log('Call');

    const status = await sessionTime.checkInternetConnectionAvailable(); // Call the function
    if (status === 'Poor connection') {
      setIsDialogOpen(true);
      setModalMessage('Poor internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

      return;
    }

  ///  console.log('Call Function');

    ///  console.log(' phone number  1212: ', storedPhoneNumber);
    const phoneSendOtp = new PhoneSendOtp(storedPhoneNumber);

    try {
      const result = await phoneSendOtp.sendOTP(storedPhoneNumber);
     // console.log('Library call Function');

      if (result == '200') {

        toggleRefreshAndClose(true); // Prevent refresh and close actions
        /// navigate('/verifunumber', { state: { newPhone, newName, newAddress, newEmail } });
        /// console.log('Opt send successfully ');

        navigate('/verifyphoneotp', {
          state: {
            phoneNumber: storedPhoneNumber,
            message: 'Your OTP has been sent successfully.'
          }
        });
        
      }
      else if (result.status_code == '411') {
      //  console.log('Invalid Number ')
        setAlertMessage(`Invalid Number (${result.status_code})`)
        setLoading(false);
        toggleRefreshAndClose(true); // Prevent refresh and close actions

        return;
      }
      else if (result.status_code == '416') {
        setAlertMessage(`Sms service temporarily not available. (${result.status_code}) Please try after sometime`);
        setLoading(false);
        toggleRefreshAndClose(true); // Prevent refresh and close actions

        return;
      }
      else if (result == "Network Error") {
        setLoading(false);
        setIsDialogOpen(true);
        // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
        const errorMessage = `Network error. Please retry. (${result})`;
        setModalMessage(errorMessage);
        toggleRefreshAndClose(true); // Prevent refresh and close actions
        return;
      }
      else {
        setLoading(false);
        setIsDialogOpen(true);
        const errorMessage = `SMS service is temporarily unavailable. Please retry.(${result})`;
        setModalMessage(errorMessage);
        toggleRefreshAndClose(true); // Prevent refresh and close actions

      }

      // const result = await phoneSendOtp.sendOTP(storedPhoneNumber); // Assuming this function sends the OTP 
      // // Redirect to another page after sending OTP (change '/other-page' to your desired route)
      // console.log('otp Send ', result);

      // ///  navigate('/verifyphoneotp', { state: { phoneNumber: storedPhoneNumber } });
      // navigate('/verifyphoneotp', {
      //   state: {
      //     phoneNumber: storedPhoneNumber,
      //     message: 'Your OTP has been sent successfully.'
      //   }
      // });


    } catch (error) {
      console.log('Error ', error);
      setIsDialogOpen(true);
      const Errormessage = `Response not recieved  from server-S. Please check your internet connection .${error.message}`
      setModalMessage(Errormessage);
      setLoading(false);
      return;

    }
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => {
    setIsDialogOpen(false);
    // window.location.reload(); // This will reload the page
  };

  return (
    <>

      <div>
        <Mainhomepage />
      </div>

      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}

      <div className='containers'>
        <div className='formgroup'>
          {/* <div style={{}}>
            <h3 style={{ backgroundColor: '', color: 'black' }}
            >Verify Mobile Number</h3>
          </div> */}
          <div>
            <label htmlFor="phoneNumber">Phone Number</label>
            <div className="input-container1">
              <input
                type="text"
                className='form-control'
                // value = {localphoneNumber}
                defaultValue={storedPhoneNumber ? `+91${storedPhoneNumber}` : '+91'}
                readOnly
                // onChange={null} 
                maxLength={10}
                placeholder="Phone Number"
                style={{ paddingLeft: '30px' }}
              />
              <i className="fas fa-phone"></i>
            </div>

            <p>{AlertMessage}</p>

          </div>
          <div className="d-grid col-4">
            <button className='btn btn-primary' onClick={handleGetOTP}>Get OTP</button>
          </div>
        </div>
      </div>


      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p style={{ color: 'red' }}> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default Verifyphone;
