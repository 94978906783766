import React, { useState } from 'react';
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";


class PhoneSendOtp {

  constructor(phoneNumber) {
    this.phoneNumber = phoneNumber;
  }

  sendOTP = async (phoneNumber) => {
    const otpCode = Math.floor(100000 + Math.random() * 900000);

    console.log('Call function ');
    try {
      const apiKey = 'Ar2Wnv0UdDJbGb4bre87vb1P5DbEhhv7FipucwNvE5R1PmqIvPjd3d4R9GLF'; // Replace with your Fast2SMS API key
      const message = '142208';
      const apiUrl = `https://www.fast2sms.com/dev/bulkV2?authorization=${apiKey}&sender_id=MAXMIJ&message=${message}&variables_values=${otpCode}&route=dlt&numbers=${phoneNumber}`;
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('Network Error'));
        }, 5000 ); // 1 minute timeout  // 10000 For one minut 
      });
      // Use Promise.race() to race between the OTP sending request and the timeout promise
      const response = await Promise.race([axios.get(apiUrl), timeoutPromise]);

      console.log('Generated OTP:', otpCode); // Log the OTP in the console
      localStorage.setItem('otp', otpCode);

      if (response.status === 200) {
     
        return response.status;
      }
    } catch (error) {
       
     // console.log('Mesage ', error);

      if (error.message === "Network Error") {
       // console.log("network issues ")
      // console.log('Mesage ', error.message);
        return error.message;
      }
      return error.response ? error.response.data : error.message;
    }

    // try {
    // // console.log('Generated OTP:', otpCode); // Log the OTP in the console
    // const apiKey = 'Ar2Wnv0UdDJbGb4bre87vb1P5DbEhhv7FipucwNvE5R1PmqIvPjd3d4R9GLF'; // Replace with your Fast2SMS API key
    // const message = '142208';
    // const apiUrl = `https://www.fast2sms.com/dev/bulkV2?authorization=${apiKey}&sender_id=MAXMIJ&message=${message}&variables_values=${otpCode}&route=dlt&numbers=${phoneNumber}`;


    //   const response = await axios.get(apiUrl);
    //   console.log('Generated OTP:', otpCode); // Log the OTP in the console
    //    console.log(response.data);
    //   localStorage.setItem('otp', otpCode);
    //   return(response.data);
    //   // return true;
    // } catch (error) {
    //  console.error('Error sending OTP:', error.response.data);
    //   return error.response.data.status_code;
    // }

  };

  validatePhoneNumber = (phone) => {
    // Perform phone number validation logic here
    const phoneRegex = /^[0-9]{10}$/; // Regular expression for a 10-digit phone number
    return phoneRegex.test(phone);
  };

};


export default PhoneSendOtp;
