import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneSendOtp from '../adminLogin/phonesendotp';
import Send from '../adminLogin/sendmail'; // Import the Send component
import { auth } from '../adminLogin/firebase';
import Navbar from '../adminLogin/navbar';
import { Modal, Button } from 'react-bootstrap';
import CommonFuctions from '../commonfunction';
import Alert from '@mui/material/Alert';




function Phoneemail() {
  const navigate = useNavigate();
  const SessionTime = new CommonFuctions();
  const [user, setUser] = useState(null);
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState('');
  // const { enteredPhoneNumberModal } = location.state || {};
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { enteredPasswordModal } = location.state || {};
  // const initialEmail = location.state?.email || ''; // Get email from location state

  const initialEmail = localStorage.getItem('email');
  const enteredPhoneNumberModal = localStorage.getItem('TransferNumber');


  const { email, setEmail, errorMessage, handleButtonClick } = Send(); // Using your custom hook
  const [modalMessage, setModalMessage] = useState('');
  const [AlertPhoneMessage, setAlertPhoneMessage] = useState('');


  useEffect(() => {
    setEmail(initialEmail); // Set initial email from location state
  }, [setEmail, initialEmail]);




  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        const emailParts = authUser.email.split('@');
        if (emailParts.length === 2) {
          const numberPart = emailParts[0];
          // console.log("Number", numberPart);
          setPhoneNumber(numberPart);
          //SessionTime.updateSessionTimeActiveUser(numberPart);
          HandleUpdateSession(numberPart);

          setLoading(false);
        }
      } else {
        setUser(null);
        // Instead of redirecting, you can handle this case in your UI
        window.location.href = '/';
      }
    });

    return () => unsubscribe();
  }, []);

  const handleGetOTP = async () => {


    try {
      const phoneSendOtp = new PhoneSendOtp(enteredPhoneNumberModal);
      const result = await phoneSendOtp.sendOTP(enteredPhoneNumberModal);
      if (result === 411) {
        setAlertMessage(`Invalid number : (${result})`);
        setLoading(false);
      } else {

        navigate('/phoneemailotpverify', { state: { enteredPhoneNumberModal, enteredPasswordModal } });

        //  navigate('/verifunumber', { state: { newPhone, newName, newAddress, newEmail } });
      }
    } catch (otpError) {
    }
  };

  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;
  };

  const handleButtonClick1 = async (event) => {

    setLoading(true);
    toggleRefreshAndClose(false); // Prevent refresh and close actions
    setAlertMessage('');
    setAlertPhoneMessage('');
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };

    const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function

  
    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }



    if (status === 'Poor connection') {
      setIsDialogOpen(true);
      setModalMessage('Poor internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

      return;
    }

    // const status = await SessionTime.checkInternetConnection(); // Call the function
    // if (status === 'Poor connection.') {
    //   setIsDialogOpen(true);
    //   setModalMessage('Poor Internet. Cannot access server.');
    //   setLoading(false);
    //   toggleRefreshAndClose(true); // Prevent refresh and close actions
    //   return;
    // }


    const storeSessionId = localStorage.getItem('sessionId');
    try {
      const { sessionId } = await SessionTime.HandleValidatSessiontime(phoneNumber);
      if (storeSessionId === sessionId) {
        SessionUpdate();
        event.preventDefault();
        handleButtonClick(event);

        //  handleGetOTP();
        const phoneSendOtp = new PhoneSendOtp(enteredPhoneNumberModal);
        const result = await phoneSendOtp.sendOTP(enteredPhoneNumberModal);

        // console.log('opt ', result);

        if (result == '200') {

          toggleRefreshAndClose(true); // Prevent refresh and close actions
          ///  navigate('/phoneemailotpverify', { state: { enteredPhoneNumberModal, enteredPasswordModal } });
          navigate('/phoneemailotpverify', {
            state: {
              phoneNumber: enteredPhoneNumberModal,
              message: 'OTP has been sent successfully.'
            }
          });

        }
        else if (result.status_code == '411') {
          console.log('Invalid Number ')
          setAlertPhoneMessage(`Invalid Number (${result.status_code})`)
          setLoading(false);
          toggleRefreshAndClose(true); // Prevent refresh and close actions
          return;
        }
        else if (result.status_code == '416') {
          setAlertPhoneMessage(`Sms service temporarily not available. (${result.status_code}) Please try after sometime`);
          setLoading(false);
          toggleRefreshAndClose(true); // Prevent refresh and close actions
          return;
        }

        else if (result == "Network Error") {
          console.log('Network Erro ');
          setLoading(false);
          const errorMessage = `Network error. Please retry.`;
          setAlertPhoneMessage(errorMessage);
          toggleRefreshAndClose(true); // Prevent refresh and close actions
          return;
        }
        else {
          setLoading(false);
          const errorMessage = `Network error. Please retry.`;
          setAlertPhoneMessage(errorMessage);
          toggleRefreshAndClose(true); // Prevent refresh and close actions
        }

        // navigate('/phoneemailotpverify', { state: { enteredPhoneNumberModal, enteredPasswordModal } });
      } else {

        toggleRefreshAndClose(true); // Prevent refresh and close actions
        setLoading(false);
        setShowAlert(true)

      }

    } catch (error) {

      setIsDialogOpen(true);
      setModalMessage(`Response not recieved from server. Please retry.(${error})`);
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

    }


  };

  const history = useNavigate();
  const handleLogout = () => {
    auth.signOut().then(() => {
      // Redirect to login page after successful logout
      localStorage.clear();
      history('/'); // Change '/login' to your login page route
    }).catch((error) => {
      // Handle any errors during logout
      console.error('Error logging out:', error.message);
    })

  }
  const SessionUpdate = () => {
    SessionTime.updateSessionTimeActiveUser(phoneNumber);


  }

  const HandleUpdateSession = (numberPart) => {

    SessionTime.updateSessionTimeActiveUser(numberPart);

  }


  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => {

    setIsDialogOpen(false);
    window.onpopstate = null;

    // window.location.reload(); // This will reload the page
  };


  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

  const handleCloseAlert = () => {
    setShowAlert(false); // Close alert when clicked on the close button
    handleLogout();
  };






  return (

    <>

      <div>

        <Navbar />


        {loading ? (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999'
          }}>
            <div className="spinner-border text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}

      </div>
      <div className='containers'>
        <div className='formgroup'>


          <div>
            <label htmlFor="enteredPhoneNumberModal">Mobile Number</label>
            <div className="input-container1">
              <input
                type="text"
                id='enteredPhoneNumberModal'
                className='form-control'
                placeholder=" phone number"
                defaultValue={'+91' + enteredPhoneNumberModal}
                autoComplete="off"
                readOnly
              />
              {/* Display any response messages */}
              <i class="fas fa-phone"></i>

            </div>
            <p style={{ color: 'red' }}>{AlertPhoneMessage}</p>
          </div>

          <div>
            <label htmlFor="email">E-mail</label>
            <div className="input-container1">
              <input
                type="email"
                id='email'
                className='form-control'
                placeholder=" your email"
                defaultValue={email}
                autoComplete="off"
                readOnly
              />
              <i class="fa-solid fa-envelope"></i>
            </div>

            <div style={{ color: 'red' }} >{errorMessage}</div>
            {alertMessage && (
              <div className="alert-container">
                <p style={{ color: 'red' }}><i className="fas fa-exclamation-circle" style={{ color: 'red' }}></i> {alertMessage}</p>
              </div>
            )}

          </div>
          <div className='d-grid col-5'>
            <button className='btn btn-primary' onClick={handleButtonClick1}  >GET OTP</button>
          </div>
        </div>
      </div>


      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p style={{ color: 'red' }}> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      {showAlert && (
        <div className="alert-overlay"
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={handleCloseAlert}
            className='AlertDailog'
          >
            You have been logged out due to login from another device.!
          </Alert>
        </div>
      )}




    </>
  )
}

export default Phoneemail