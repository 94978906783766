import React, { useEffect, useState, useRef } from 'react';
import { auth } from '../adminLogin/firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './meterdetail.css';
import { database } from '../firebase';
import { Modal, Button } from 'react-bootstrap';
import { validateName } from '../validation/validation';
import CommonFuctions from '../commonfunction';
import { useNavigate } from 'react-router-dom';
import { ref, getDatabase, } from 'firebase/database';
import { validateEmailAddress } from '../validation/validation';
import Alert from '@mui/material/Alert';
import '../style.css'
import '../consucss.css'


import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function Groupdetails({ highlightedSerial, data, meterList, phoneNumber, onUpdateGroupData,
    highlightGroupName, setSelectedGroupHide, setHighlightGroupName }) {

    let numberPart = phoneNumber;
    const cfunction = new CommonFuctions();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const handleIconClick = () => {
        setShowDatePicker(!showDatePicker); // Toggle date picker visibility
    };

    const [selectedGroupData, setSelectedGroupData] = useState([]);
    const [searchExecuted, setSearchExecuted] = useState(false);
    const [user, setUser] = useState(null);
    const dateInputRef = useRef(null);
    const [selectedSerialData, setSelectedSerialData] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    let [inputValues, setInputValues] = useState({
        input1: '',
        input2: '', // Assuming default value for tariff rate is 0, update it accordingly
    });
    const [showInputs, setShowInputs] = useState(false);
    const [displayedInput2, setDisplayedInput2] = useState('');
    const [displayedInput1, setDisplayedInput1] = useState('');
    const [existingGroups, setExistingGroups] = useState([]);
    const [selectedGroupName, setSelectedGroupName] = useState(''); // State to store selected group name
    const [selectedExistingGroupName, setSelectedExistingGroupName] = useState()
    const [updatetoken, setUpdateToken] = useState({});
    const [groupNameError, setGroupNameError] = useState('');
    const [tariffRateError, setTariffRateError] = useState('');
    const [groupTariffRate, setGroupTariifRate] = useState('');
    const [loading, setLoading] = useState(false);
    let isGroupNameChanged = false;

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                //   console.log("Logged in user:", authUser.email);
                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                    const numberPart = emailParts[0];
                }
            } else {
                setUser(null);
                window.location.href = '/'; // Redirect to your login page
            }
        });
        return () => {

            unsubscribe();
        };
    }, []);

    const handleSearch = async (phoneNumber) => {
        const trimmedPhoneNumber = phoneNumber.trim();
        if (trimmedPhoneNumber !== '') {
            try {
                const dataRef = database.ref(`/adminRootReference/tenantDetails/${trimmedPhoneNumber}`);
                const snapshot = await dataRef.once('value');
                const newData = snapshot.val();
                setUpdateToken(newData);
                //console.log('group name related alll data ', newData);
                const modifiedDataWithoutTime = excludeTimeProperty(newData);
                // console.log('Modified Data without Time:', modifiedDataWithoutTime);
                setSelectedGroupData(modifiedDataWithoutTime);
                // setData(modifiedDataWithoutTime || {});
                if (newData) {
                    const options = Object.keys(newData).map(key => key.replace(/_/g, ' '));
                    // console.log("groupData", options);
                }
                setSearchExecuted(true);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const excludeTimeProperty = (data) => {
        const modifiedData = { ...data };
        delete modifiedData.time;
        return modifiedData;
    };
    const extractGroupNames = () => {
        if (data) {
            const groupNames = Object.keys(data).filter(key => key !== 'tariff');
            //   console.log("Grpuprealed tariff group names", groupNames);
            return groupNames;
        }
        return [];
    };

    /// const [meterList, setMeterList] = useState([]);
    const [serialOptions, setSerialOptions] = useState([]);
    const [mergedArray, setMergedArray] = useState([]);

    useEffect(() => {
        extractSerialNumbers();
    }, [updatetoken]);

    useEffect(() => {
        const merged = [...serialOptions, ...meterList.map(meterId => ({ serial: meterId }))];
        setMergedArray(merged);
    }, [serialOptions, meterList]);

    const extractSerialNumbers = () => {
        const extractedSerials = Object.values(data).reduce((acc, item) => {
            if (item && typeof item === 'object' && !Array.isArray(item)) {
                const keys = Object.keys(item);
                const filteredKeys = keys.filter((key) => !isNaN(Number(key)));
                acc.push(...filteredKeys.map(serial => ({ serial, name: item[serial].name })));
            }
            return acc;
        }, []);
        setSerialOptions(extractedSerials);
    };



    const [selectedDate, setSelectedDate] = useState(null);
    const handleCustomBoxClick = (groupName, serial, info) => {
        setSelectedGroupHide(null);
        setHighlightGroupName(null);
        setSelectedSerialData({
            serial,
            info: {
                ...info,
            },
            tariff: data[groupName].tariff, // Assuming data[groupName].tariff holds the tariff value

        });

        setSelectedGroupName(groupName);
        setGroupName(groupName);
        setSelectedDate(info.doo); // Set the initial selected date
        openModal();
    };


    useEffect(() => {
        //  console.log('Group Names:', extractGroupNames());
    }, [data]);

    useEffect(() => {
        if (selectedSerialData) {
            // console.log("Selected Serial Data:", selectedSerialData);
            // Do more actions with the selected data if needed
        }
    }, [selectedSerialData]);
    const [locationError, setLocationError] = useState('');
    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [consumetMobile, setConsumerMobile] = useState('');
    const [consumetAltMobile, setConsumerAltMobile] = useState('');


    const handleInputChange = (field, value) => {

        setErrorMessageLocation('');
        // Validate the address if the field is 'location'
        if (field === 'location') {
            // Regular expression to match any special characters
            const specialCharsRegex = /[^a-zA-Z0-9\s]/;

            // Check if the value contains any special characters
            if (specialCharsRegex.test(value)) {
                // Set the locationError state to display an error message
                setErrorMessageLocation('Special characters Not allowed');
            }
            else if (value.charAt(0) === ' ') {
                return ''; // Error message for name starting with a space
            } else {
                // Reset the locationError state if the value is valid
                setErrorMessageLocation('');
            }

            // Remove special characters from the input value
            value = value.replace(specialCharsRegex, '');
        }

        // let errorMessage = '';


        if (field === 'name') {
            setErrorMessageName('');
            const specialCharsRegex = /[^a-zA-Z0-9\s]/;
            // Check if the value contains any special characters
            if (specialCharsRegex.test(value)) {
                // Set the locationError state to display an error message
                setErrorMessageName('Special characters Not allowed');
            }
            else if (value.charAt(0) === ' ') {
                return ''; // Error message for name starting with a space
            } else {
                // Reset the locationError state if the value is valid
                setErrorMessageName('');
            }
            // Remove special characters from the input value
            value = value.replace(specialCharsRegex, '');
        }

        if (field === 'phone') {
            // Remove non-digit characters from the value
            setErrorMessagePhone('');
            const newValue = value.replace(/\D/g, '');
            if (value.charAt(0) === '0') {
                // Display error message
                setErrorMessagePhone("Can't start with Zero");
                console.log("Can't start with Zero");
                return;
            }
            value = newValue;

            setConsumerMobile(value);

        }

        if (field === 'altPhone') {
            // Remove non-digit characters from the value
            setErrorMessageAltPhone('');
            const newValue = value.replace(/\D/g, '');
            if (value.charAt(0) === '0') {
                // Display error message
                setErrorMessageAltPhone("Can't start with Zero");
                ///  console.log("Can't start with Zero");
                return;
            }
            value = newValue;

            setConsumerAltMobile(value);
        }

        if (field === 'email') {
            value = value.trim();
            setEmailError('');
            // }
            // Set the value after trimming spaces
            setSelectedSerialData(prevState => ({
                ...prevState,
                info: {
                    ...prevState.info,
                    email: value
                }
            }));
        }


        const initialDate = selectedSerialData.info.doo;



        setSelectedSerialData(prevData => ({
            ...prevData,
            info: {
                ...prevData.info,
                [field === 'date' ? 'doo' : field]: value,
            }
        }));
    };

    const handleInputChange1 = (e) => {

        const { name, value } = e.target;
        if (name === 'input1') {


            // Regular expression to match only alphanumeric characters and spaces
            const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;

            // Replace multiple spaces with a single space
            const trimmedValue = value.replace(/\s+/g, ' ');

            if (!alphanumericRegex.test(trimmedValue)) {
                setGroupNameError('Special characters not allowed.');
            } else if (trimmedValue.charAt(0) === ' ') {
                // Check if the trimmed value starts with a space
                setGroupNameError('Leading spaces are not allowed.');
            } else {
                setGroupNameError('');
                setInputValues({ ...inputValues, [name]: trimmedValue });
            }

        } else if (name === 'input2') {
            // Validation for input2 (Tariff Rate)
            const numericRegex = /^(?:[1-9]\d{0,1}(?:\.\d{0,2})?|99.99(?:\.00?)?)$/;
            if (numericRegex.test(value) || value === '') {
                setInputValues({ ...inputValues, [name]: value });
            } else {
                setTariffRateError('');
            }
        }
    };

    useEffect(() => {
        // Transform data into an array of group names (existing groups)
        const groups = Object.keys(data || {}).map(group => group.replace(/_/g, ' '));
        setExistingGroups(groups);
        //  console.log('Existing Groups:', groups);
    }, [data, setExistingGroups]);
    //console.log("Check all data for", data);

    const handleGroupClick = (group) => {
        // Access the data related to the clicked group and perform actions
        const groupData = data[group.replace(/ /g, '_')]; // Assuming your data structure contains group-related data
        // setSelectedGroupData(groupData);
        setSelectedGroupName(group);

        const groupNames = Object.keys(groupData || {}).filter(key => key !== 'tariff');

        setSelectedExistingGroupName(group);
        const groupName = displayedInput1 || selectedExistingGroupName; // Choose the appropriate value

        if (groupData && groupData.tariff) {
            setDisplayedInput2(groupData.tariff.toString()); // Assuming displayedInput2 is a state variable
        } else {
            setDisplayedInput2(''); // Clear the displayedInput2 if no tariff rate is available for the selected group
        }

        if (groupData && groupData.tariff !== undefined) {
            setDisplayedInput2(groupData.tariff.toString());

        }
        setTariffRateError('');
        setShowInputs(false); // Hide the input fields after selecting a group


    };

    const getInputValue = () => {
        if (selectedGroupName) {
            return selectedGroupName.split('_').join(' '); // Replace underscores with spaces
        } else if (inputValues.input1) {
            return inputValues.input1;
        } else {
            return 'Create/Selected Group';
        }
    };

    const calculateMetersCount = (groupData) => {
        let count = 0;
        if (groupData) {
            count = Object.keys(groupData).filter(key => key !== 'tariff').length;
        }
        return count;
    };

    const handleAddClick = async () => {



        // // Get the existing group names
        const checkGroupName = Object.keys(data);
        // // Process existing group names: Remove underscores and convert to uppercase
        const modifiedGroupNames = checkGroupName.map(name => name.replace(/_/g, "").toUpperCase());
        // console.log('Modified group names:', modifiedGroupNames);

        // // Process the new group name: Remove spaces and convert to uppercase




        setTariffRateError('');
        try {
            if (!inputValues.input1) {
                setGroupNameError("Enter group name. ");
                return;
            }
            const unput1 = inputValues.input1;

            const entergroupName = unput1.replace(/ /g, "").toUpperCase();

            if (modifiedGroupNames.includes(entergroupName)) {
                setGroupNameError("Already present this group name");
                //  console.log('Already presend this group name ');
                return;
            }

            if (!inputValues.input2) {
                // If input2 is empty, show an error message and return early
                setTariffRateError("Enter tariff rate.");
                return;
            }
            if (
                inputValues.input2.startsWith(".") ||
                inputValues.input2.startsWith("0.") ||
                inputValues.input2.startsWith("00.") ||
                inputValues.input2 === "0" ||
                inputValues.input2 === "00" ||
                inputValues.input2.endsWith(".")
            ) {
                // If any of the conditions are met, show an error message and return early
                setTariffRateError("Invalid tariff rate");
                return;
            }
            if (/^0(\.0{1,2})?$|^0\.99$/.test(inputValues.input2)) {
                // If input2 is in the range, show an error message and return early
                setTariffRateError("Invalid tariff.");
                return;
            }
            const input2Value = inputValues.input2;
            let updatedInput2Value = input2Value;

            if (!input2Value.includes('.')) {
                // If input2 does not have a decimal point, add '.00' to it
                updatedInput2Value = input2Value + '.00';
            } else if (input2Value.split('.')[1].length === 1) {
                // If input2 has one digit after the decimal point, add '0' to make it two digits
                updatedInput2Value = input2Value + '0';
            }

            const newOption = `${inputValues.input1} - ${inputValues.input2}`;
            setDisplayedInput1(inputValues.input1);
            setSelectedGroupName(inputValues.input1);
            setShowInputs(false);
            setDisplayedInput2(updatedInput2Value);
            setInputValues({
                input1: '',
                input2: ''
            });


            setShowInputs(false);
        } catch (error) {
            //  console.error('Error:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const handleResetInputss = () => {
        setInputValues({
            input1: '',
            input2: '',
        });
    };

    const handleCloseClick = () => {

        setGroupNameError('');
        setTariffRateError('');
        handleResetInputss(); // Reset input values when closing
        setShowInputs(false);

    };

    const handleSelectChange = (e) => {
        const value = e.target.value;
        setShowInputs(value === 'showInputs');
        //console.log("check values", value);
    };

    useEffect(() => {
        // Transform data into an array of group names (existing groups)
        const groups = Object.keys(data || {}).map(group => group.replace(/_/g, ' '));
        setExistingGroups(groups);


        //  console.log('Existing Groups:', groups);
    }, [data, setExistingGroups]);


    const [errorMessageLocation, setErrorMessageLocation] = useState('');
    const [errorMessageName, setErrorMessageName] = useState('');
    const [errorMessagePhone, setErrorMessagePhone] = useState('');
    const [errorMessageAltPhone, setErrorMessageAltPhone] = useState('');
    const [groupError, setGroupameError] = useState('');


    // let canRefreshOrClose = false; // Flag to control refresh and close actions
    let canRefreshOrClose = true; // Allow refresh and close actions by default
    // Function to handle beforeunload event
    const handleBeforeUnload = (event) => {
        if (!canRefreshOrClose) {
            const message = "Your Data  will be lost.";
            event.returnValue = message;
            return message; // Required for some older browsers   
        }
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Function to enable/disable refresh and close actions
    const toggleRefreshAndClose = (enabled) => {
        canRefreshOrClose = enabled;
        ///  console.log('Refresh and close actions enabled:', enabled);
    };

    const handleSavebutton = async () => {
        const { serial, info } = selectedSerialData;
        if (!info.location) {
            /// locationError = "Enter the consumer's meter location";
            setErrorMessageLocation("Enter the consumer's meter location")
            return;
        }
        if (!info.name) {
            //  console.log('enter name ');
            setErrorMessageName('Name is required');
            return;
        }
        if (!info.phone) {
            const phoneError = 'Phone is required!';
            setErrorMessagePhone(phoneError);
            return;
        } else if (info.phone.length < 10) {
            const phoneError = 'Invalid mobile number ';
            setErrorMessagePhone(phoneError);
            return;
        }

        const consumerMobileNumber = document.getElementById('mobileNumber').value;

        const consumerAltMobileNumber = document.getElementById('consumerAltMobileNumber').value;

        if (consumerAltMobileNumber.trim() !== '') {
            if (consumerAltMobileNumber.length !== 10) {
                setErrorMessageAltPhone('Invalid mobile number.');
                return;
            }

            // Trim the primary mobile number as well
            const trimmedConsumerMobileNumber = consumerMobileNumber.trim();

            // Check if the trimmed alternate mobile number and primary mobile number are the same
            if (consumerAltMobileNumber.trim() === trimmedConsumerMobileNumber) {
                setErrorMessageAltPhone('Do not enter same number.');
                return;
            }
        }
        const consumerEmail = document.getElementById('consumerEmail').value;

        if (consumerEmail.trim() !== '' && !validateEmailAddress(consumerEmail)) {
            setEmailError('Invalid Email address.');
            return;
        }

        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.href);
        };

        setLoading(true);
        toggleRefreshAndClose(false); // Prevent refresh and close actions

        // if (!navigator.onLine) {
        //     setIsDialogOpen(true);
        //     setmodalMessage('No/Poor Internet connection. Cannot access server.');
        //     setLoading(false);
        //     toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
        //     return;
        // }

        // const status = await cfunction.checkInternetConnection(); // Call the function
        // //  setShowChecker(status);
        // if (status === 'Poor connection.') {
        //     setIsDialogOpen(true);
        //     setmodalMessage('No/Poor Internet connection. Cannot access server.');
        //     setLoading(false);
        //     toggleRefreshAndClose(true); // Prevent refresh and close actions
        //     return;
        // }

        // try {
        //     const isLastActive = await cfunction.isLastActiveTime(numberPart);
        //     if (isLastActive) {
        //         setLoading(false);
        //         toggleRefreshAndClose(true); // Prevent refresh and close actions
        //         handleLogout();
        //         localStorage.setItem('isLoggedIn', true);
        //     }
        //     else {
        //     }
        // } catch (error) {
        //     setIsDialogOpen(true);
        //     setmodalMessage(`Response not recieved  from server-S. Please check your internet connection .(${error})`);
        //     setLoading(false);
        //     toggleRefreshAndClose(true); // Prevent refresh and close actions
        // }

        const storeSessionId = localStorage.getItem('sessionId');
        setErrorMessageName(nameError);
        setErrorMessagePhone(phoneError);
        if (locationError || nameError || phoneError) {
            return;
        }


        const formattedGroupName = selectedGroupName.split(' ').join('_');
        const newgroupName = groupName.replace(/ /g, '_');
        const newTariffRate = displayedInput2 || info.tariff;
        // console.log("Formated groupname : ", formattedGroupName);
        // console.log("new GRoup Name  : ", newgroupName);
        // console.log("enter all data : ", info.name);
        // console.log("enter all data : ", info.serial);
        // console.log("enter all data : ", info.location);
        //console.log("enter Phpne  : ", info.phone);
        // console.log("enter  phoneno 1: ", info.altPhone);
        // console.log("enter all data : ", info.tariff);
        // console.log("new Tariff rate : ", newTariffRate);
        // console.log('all data :', info);

        const dateOfOccupancy = document.getElementById('Dateofoccupent').value;
       // console.log('Dooood :', dateOfOccupancy);
        const updatedInfo = {
            ...info,
            tariff: displayedInput2 || info.tariff,
            doo: dateOfOccupancy // Assuming formatDate is a function that formats the date
        };

        const db = getDatabase(); // Assuming getDatabase is defined elsewhere
        const databasePath = `/adminRootReference/tenantDetails/${numberPart}/${formattedGroupName}/${serial}`;
        //  const deletePath = `/adminRootReference/tenantDetails/${numberPart}/${newgroupName}/${serial}`;
        const deletePath = ref(db, `/adminRootReference/tenantDetails/${numberPart}/${newgroupName}/${serial}`);
        const DeletedPath = deletePath.toString();
        const tariffReference = `/adminRootReference/tenantDetails/${numberPart}/${formattedGroupName}/tariff`;
        const findGroupChild = database.ref(`/adminRootReference/tenantDetails/${numberPart}/${newgroupName}`);
        const path = ref(db, `/adminRootReference/tenantDetails/${numberPart}/${newgroupName}`);
        const findGroupChild1 = path.toString();
        // Update through firebase Function 
        const adminRootReference = ref(db, `adminRootReference/tenantDetails/${numberPart}/${formattedGroupName}/${serial}`);
        const fullAdminProfilePath = adminRootReference.toString();

        const tariffRefe = firebase.database().ref(`/adminRootReference/tenantDetails/${numberPart}/${formattedGroupName}/tariff`);
        const saveTariffReference = tariffRefe.toString();

        const pathAdmin = firebase.database().ref(`/adminRootReference/adminDetails/${numberPart}/newMeterList/${serial}/groupName`);
        const updateGroupName = pathAdmin.toString();
        const path1 = firebase.database().ref(`/adminRootReference/adminDetails/${numberPart}/newMeterList/${serial}/tenantName`);
        const updatetenantName = path1.toString();
        const consumerPhone = firebase.database().ref(`/adminRootReference/meterDetails/${serial}/consumerPhone/`);
        const updateconsumerPhone = consumerPhone.toString();

        const consumerPhone2 = firebase.database().ref(`/adminRootReference/meterDetails/${serial}/consumerPhone2`);
        const updateconsumerPhone2 = consumerPhone2.toString();


        const dataToSend = {
            [updateGroupName]: formattedGroupName,
            [updateconsumerPhone]: info.phone,
            [updateconsumerPhone2]: info.altPhone,
            [updatetenantName]: info.name,
            [fullAdminProfilePath]: updatedInfo,
            [saveTariffReference]: newTariffRate
        };


        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setmodalMessage('No internet. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
            return;
        }


        const status = await cfunction.checkInternetConnectionAvailable(); // Call the function

        /// const status = await cfunction.checkInternetConnection(); // Call the function
        //  setShowChecker(status);

        if (status === 'Poor connection') {
            setIsDialogOpen(true);
            setmodalMessage('Poor internet. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }


        // (status === 'Poor connection.') {
        //     setIsDialogOpen(true);
        //     setmodalMessage('No/Poor Internet connection. Cannot access server.');
        //     setLoading(false);
        //     toggleRefreshAndClose(true); // Prevent refresh and close actions
        //     return;
        // }

        //   console.log('Session  Start ');

        try {
            const { sessionId } = await cfunction.HandleValidatSessiontime(numberPart);
            if (storeSessionId === sessionId) {



                //  《❎🔥 If Groupname and tariff  🔥 change then save on old Path  🔥❎ 》


                if (formattedGroupName !== groupName) {

                    //    console.log('change group name ');
                    isGroupNameChanged = true;
                    const dataToSend1 = {
                        [updateGroupName]: formattedGroupName,
                        [updateconsumerPhone]: info.phone,
                        [updateconsumerPhone2]: info.altPhone,
                        [updatetenantName]: info.name,
                        [fullAdminProfilePath]: updatedInfo,
                        [saveTariffReference]: newTariffRate,
                        [DeletedPath]: null,
                    };

                    //  《❎🔥 GroupName Null pass when only one Werial Number  🔥❎ 》
                    const Groupname = {
                        [findGroupChild1]: null
                    };

                    //  console.log(' Count data1 ');

                    try {

                        // console.log('only Newser Can run ', dataToSend1);


                        await cfunction.callWriteRtdbFunction(dataToSend1);

                        //  《❎🔥 Issues in Tariff Rate not update  🔥❎ 》
                        //  console.log('new Group ', formattedGroupName);


                        // Check if the group name already exists in data
                        if (data.hasOwnProperty(formattedGroupName)) {
                            // Check if the serial already exists in the group

                            data[formattedGroupName].tariff = newTariffRate;


                            if (data[formattedGroupName].hasOwnProperty(serial)) {
                                // Merge updatedInfo into existing serial data
                                data[formattedGroupName][serial] = {
                                    ...data[formattedGroupName][serial],
                                    ...updatedInfo,
                                };
                            } else {
                                // Add new serial entry under the existing group
                                data[formattedGroupName][serial] = updatedInfo;
                            }


                        } else {
                            // Create new group entry
                            data[formattedGroupName] = {
                                [serial]: updatedInfo,
                                tariff: newTariffRate
                            };
                        }


                        if (data[formattedGroupName]) {
                            // Delete the serial from the group
                            delete data[newgroupName][serial];

                            // If the group is now empty, you might want to delete the group itself
                            if (Object.keys(data[newgroupName]).length === 0) {
                                delete data[newgroupName];
                            }
                        }

                        // console.log(' Updaate data is rr ', data);

                        onUpdateGroupData(data);

                        // console.log("Groupname saved ", result);
                        setIsDialogOpenSavedata(true)
                        const errorMessage = `Data saved successfully!`;
                        setmodalSaveMessage(errorMessage);
                        setLoading(false);
                        toggleRefreshAndClose(true);//Prevent refresh and close actions
                        setLoading(false);

                    } catch (error) {

                        setisResponseModel(true);
                        const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                        setIsDialogOpenSavedata(errorMessage);
                        setLoading(false);
                        toggleRefreshAndClose(true);  //Prevent refresh and close actions
                    }


                    //  《❎🔥 If only 1 Serial number in group then groupName Also deleted   🔥❎ 》
                    // try {
                    //     const Count = await cfunction.isAnyMeterExists(findGroupChild1);

                    //     console.log(' Count data2 ');
                    //     if (Count == 1) {

                    //         try {

                    //             const [result1, result2] = await Promise.all([
                    //                 cfunction.callWriteRtdbFunction(dataToSend1),
                    //                 cfunction.callWriteRtdbFunction(Groupname)
                    //             ]);

                    //             setIsDialogOpenSavedata(true)
                    //             const errorMessage = `Data saved successfully!`;
                    //             setmodalSaveMessage(errorMessage);
                    //             toggleRefreshAndClose(true); // Prevent refresh and close actions
                    //             setLoading(false);
                    //         } catch (error) {
                    //             setIsDialogOpenSavedata(true);
                    //             const errorMessage = `Response not received from server. Please check through enquiry if transaction completed successfully. (${error}). `;
                    //             setmodalSaveMessage(errorMessage);
                    //             setLoading(false);
                    //             toggleRefreshAndClose(true); // Prevent refresh and close actions
                    //         }
                    //     }
                    //     else {
                    //         try {

                    //             ///  console.log('only Newser Can run ');
                    //             const result = await cfunction.callWriteRtdbFunction(dataToSend1)
                    //             /// console.log("Groupname saved ", result);
                    //             setIsDialogOpenSavedata(true)
                    //             const errorMessage = `Data saved successfully!`;
                    //             setmodalSaveMessage(errorMessage);
                    //             // setLoading(false);
                    //             toggleRefreshAndClose(true); // Prevent refresh and close actions
                    //             setLoading(false);

                    //         } catch (error) {

                    //             setisResponseModel(true);
                    //             // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                    //             const errorMessage = `Response not received from server. Please check through enquiry if transaction completed successfully. (${error}). `;
                    //             setIsDialogOpenSavedata(errorMessage);
                    //             setLoading(false);
                    //             toggleRefreshAndClose(true); // Prevent refresh and close actions
                    //         }
                    //     }


                    // } catch (error) {
                    //     //setIsDialogOpenSavedata(true);
                    //     setIsDialogOpen(true);
                    //     // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                    //     const errorMessage = `Response not received from server. Please retry. (${error})`;
                    //     setmodalMessage(errorMessage);
                    //     setLoading(false);
                    //     toggleRefreshAndClose(true); // Prevent refresh and close actions

                    // }



                    //  《❎🔥 Old Code  Igone this code   🔥❎ 》
                    // cfunction.isAnyMeterExist(findGroupChild)
                    //     .then((childCount) => {
                    //         if (childCount == 0) {
                    //             findGroupChild.remove().then(() => {
                    //                // console.log('Child Count ', childCount);
                    //                // console.log('Remove successfully');
                    //             }).catch(() => {
                    //                 // console.log('Error deleting Firebase reference');
                    //             });
                    //         } else {
                    //             // console.log('Child count is greater than 0');
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         //  console.error('Error in isAnyMeterExist:', error);
                    //     });

                    // cfunction.updateSessionTimeActiveUser(numberPart);
                    // /// console.log('Session id UPdate ', result);
                    // setIsDialogOpenSavedata(true)
                    // const errorMessage = `Data saved successfully!`;
                    // setmodalSaveMessage(errorMessage);
                    // // setLoading(false);
                    // toggleRefreshAndClose(true); // Prevent refresh and close actions
                    // setLoading(false);

                }


                //  《❎🔥 If Groupname and tariff 🔥 not change then save on old Path  🔥❎ 》
                else {
                    /// console.log('111111');
                    // Check if there's any change in the data
                    //  const currentData = await firebase.database().ref(databasePath).get();
                    // const currentInfo = currentData.val();
                    try {

                        const getData = await cfunction.callCloudFunction(fullAdminProfilePath);
                        const currentInfo = JSON.parse(getData);
                        delete currentInfo.time;

                        if (JSON.stringify(updatedInfo) === JSON.stringify(currentInfo)) {
                            // No change in the data
                            // alert('No changes in existing data.');
                            setIsDialogOpen(true);
                            const errorMessage = `No changes in existing data.`;
                            setmodalMessage(errorMessage);
                            setLoading(false);
                            toggleRefreshAndClose(true); // Prevent refresh and close action
                        }

                        else {
                            isGroupNameChanged = false;
                            try {

                                //  console.log("update data in same groupname with serial number  :", data);

                                await cfunction.callWriteRtdbFunction(dataToSend);
                                const updateLocalData = {
                                    [formattedGroupName]: {
                                        [serial]: {
                                            ...updatedInfo,
                                        },
                                        tariff: newTariffRate,
                                    },
                                };


                                // Check if the group name already exists in data
                                if (data.hasOwnProperty(formattedGroupName)) {
                                    // Check if the serial already exists in the group
                                    if (data[formattedGroupName].hasOwnProperty(serial)) {
                                        // Merge updatedInfo into existing serial data
                                        data[formattedGroupName][serial] = {
                                            ...data[formattedGroupName][serial],
                                            ...updatedInfo,
                                        };
                                    } else {
                                        // Add new serial entry under the existing group
                                        data[formattedGroupName][serial] = updatedInfo;
                                    }

                                } else {
                                    // Create new group entry
                                    data[formattedGroupName] = {
                                        [serial]: updatedInfo,
                                    };
                                }


                                // Log the updated data
                                ///  console.log('Updated data:', data);
                                onUpdateGroupData(data);

                                setIsDialogOpenSavedata(true)
                                const errorMessage = `Data saved successfully! `;
                                setmodalSaveMessage(errorMessage);
                                setLoading(false);
                                toggleRefreshAndClose(true); // Prevent refresh and close actions
                            } catch (error) {
                                setIsDialogOpenSavedata(true);
                                // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                                const errorMessage = ` Response not received from server. Please check through enquiry if transaction completed successfully. (${error}).`;
                                setmodalSaveMessage(errorMessage);
                                setLoading(false);
                                toggleRefreshAndClose(true); // Prevent refresh and close actions

                            }
                            //   const result2 = await cfunction.callWriteRtdbFunction(saveTariffRate);
                            //   console.log('Tariif rate in groupname22 :', result2);

                            //   await firebase.database().ref(databasePath).update(info);
                            //  const newTariffRate = displayedInput2 || info.tariff;
                            //  await firebase.database().ref(tariffReference).set(newTariffRate);
                            // setIsDialogOpenSavedata(true)
                            // const errorMessage = `Data saved successfully!`;
                            // setmodalSaveMessage(errorMessage);
                            // setLoading(false);
                            // alert('Data updated successfully!');
                            // window.location.reload(true);
                        }


                    } catch (error) {

                        //setIsDialogOpenSavedata(true);
                        setIsDialogOpen(true);
                        // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                        const errorMessage = `Response not received from server. Please retry. (${error})`;
                        setmodalMessage(errorMessage);
                        setLoading(false);
                        toggleRefreshAndClose(true); // Prevent refresh and close actions
                    }
                }

            } else {
                setLoading(false);
                setshowLogouttAlert(true);
                // alert("You have been logged-out due to log-in from another device.");
                // handleLogout();
            }
        }
        catch (error) {
            //setIsDialogOpenSavedata(true);
            setIsDialogOpen(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Response not received from server-S. Please retry. (${error})`;
            setmodalMessage(errorMessage);
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions

        }

    };


    const handleDelete = () => {

        setmodalMessage(`Are you sure you want to delete this data?`);
        setisConfirmed(true);
    }

    const deleteGroupname = async () => {
        // event.preventDefault();
        // Disable browser interactions
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.href);
        };

        setLoading(true);
        toggleRefreshAndClose(false); // Prevent refresh and close actions
        const status = await cfunction.checkInternetConnection(); // Call the function
        //  setShowChecker(status);
        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setmodalMessage('No internet. Cannot access server. ');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }



        if (status === 'Poor connection.') {
            setIsDialogOpen(true);
            setmodalMessage('No/Poor Internet connection. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }



        const storeSessionId = localStorage.getItem('sessionId');
        try {
            const { sessionId } = await cfunction.HandleValidatSessiontime(numberPart);
            if (storeSessionId === sessionId) {
                let selectedGroupNamed = selectedGroupName.replace(/ /g, "_");
                if (!selectedSerialData || !selectedGroupNamed) {
                    //   console.error('No selected serial data or group name to delete.');
                    return;
                }

                const { serial } = selectedSerialData;
                const databasePath = `/adminRootReference/tenantDetails/${numberPart}/${selectedGroupNamed}/${serial}`;
                const db = getDatabase(); // Assuming getDatabase is defined elsewhere
                const findGroupChild = database.ref(`/adminRootReference/tenantDetails/${numberPart}/${selectedGroupNamed}`);

                const path = firebase.database().ref(`/adminRootReference/tenantDetails/${numberPart}/${selectedGroupNamed}`);
                const FindgroupChild = path.toString();

                const tariffRef1 = firebase.database().ref(`/adminRootReference/tenantDetails/${numberPart}/${selectedGroupNamed}/`);
                const saveTariffReference1 = tariffRef1.toString();
                // const db = getDatabase(); // Assuming getDatabase is defined elsewhere
                const tariffRef = firebase.database().ref(`/adminRootReference/tenantDetails/${numberPart}/${selectedGroupNamed}/${serial}/`);
                const saveTariffReference = tariffRef.toString();

                const path2 = firebase.database().ref(`/adminRootReference/adminDetails/${numberPart}/newMeterList/${serial}/groupName/`);
                const updateGroupName = path2.toString();

                const path3 = firebase.database().ref(`/adminRootReference/adminDetails/${numberPart}/newMeterList/${serial}/tenantName/`);
                const updatetenantName = path3.toString();



                /// console.log('Selected Serial :, ', updateGroupName);
                //  console.log('Selected Serial :, ', serial);

                const consumerPhone = firebase.database().ref(`/adminRootReference/meterDetails/${serial}/consumerPhone/`);
                const updateconsumerPhone = consumerPhone.toString();

                const consumerPhone2 = firebase.database().ref(`/adminRootReference/meterDetails/${serial}/consumerPhone2`);
                const updateconsumerPhone2 = consumerPhone2.toString();

                // Create an object with the path to delete and set its value to null
                const dataToSend = {
                    [updateGroupName]: null,
                    [updateconsumerPhone]: null,
                    [updatetenantName]: null,
                    [updateconsumerPhone2]: null,
                    [saveTariffReference]: null
                };

                try {

                    ///  console.log('data is ', dataToSend);
                    await cfunction.callWriteRtdbFunction(dataToSend);
                    // console.log('Select serial to be Delete', serial);
                    // console.log('Selected Group Name to be Delete', selectedGroupNamed);
                    //  console.log('data is ', data);
                    //  console.log('MetetList is :', meterList);
                    let newData = { ...data };
                    if (newData[selectedGroupNamed]) {
                        // Delete the specific serial number from the group
                        delete newData[selectedGroupNamed][serial];
                        // Check if the group is now empty (excluding the tariff property)
                        if (Object.keys(newData[selectedGroupNamed]).length === 1 && 'tariff' in newData[selectedGroupNamed]) {
                            delete newData[selectedGroupNamed];
                        }
                    }


                    // Update the data using the provided callback
                    onUpdateGroupData(newData);
                    meterList.push(serial);
                    // console.log('Updated MeterList:', meterList);
                    setIsDialogOpenSavedata(true)
                    const errorMessage = `Remove data successfully!`;
                    setmodalSaveMessage(errorMessage);
                    setLoading(false);
                    toggleRefreshAndClose(true); // Prevent refresh and close actions

                } catch (error) {
                    setIsDialogOpenSavedata(true);
                    // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                    const errorMessage = `Response not received from server. Please check through enquiry if transaction completed successfully. (${error}). `;
                    setmodalSaveMessage(errorMessage);
                    setLoading(false);
                    toggleRefreshAndClose(true); // Prevent refresh and close actions
                }


                //  if in only 1 Serail when delete then also delete groupname

                // const childCount = await cfunction.isAnyMeterExists(FindgroupChild)
                // if (childCount == 1) {
                //     try {
                //         await cfunction.callWriteRtdbFunction(dataToSend1);
                //         setIsDialogOpenSavedata(true)
                //         const errorMessage = `Remove data successfully!`;
                //         setmodalSaveMessage(errorMessage);
                //         setLoading(false);
                //         toggleRefreshAndClose(true); // Prevent refresh and close actions
                //     } catch (error) {
                //         setIsDialogOpenSavedata(true);
                //         // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                //         const errorMessage = `Response not received from server. Please check through enquiry if transaction completed successfully. (${error}). `;
                //         setmodalSaveMessage(errorMessage);
                //         setLoading(false);
                //         toggleRefreshAndClose(true); // Prevent refresh and close actions
                //     }
                // } else {
                //     ///  console.log("Serial are Available ");
                //     try {
                //         await cfunction.callWriteRtdbFunction(dataToSend);
                //         setIsDialogOpenSavedata(true)
                //         const errorMessage = `Remove data successfully!`;
                //         setmodalSaveMessage(errorMessage);
                //         setLoading(false);
                //         toggleRefreshAndClose(true); // Prevent refresh and close actions
                //     } catch (error) {
                //         setIsDialogOpenSavedata(true);
                //         // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                //         const errorMessage = `Response not received from server. Please check through enquiry if transaction completed successfully. (${error}). `;
                //         setmodalSaveMessage(errorMessage);
                //         setLoading(false);
                //         toggleRefreshAndClose(true); // Prevent refresh and close actions
                //     }
                // }
                // } catch (error) {

                //     setisResponseModel(true);
                //     // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                //     const errorMessage = `Response not recieved  from server-A. Please check if transaction completed successfully, else retry.(${error}). `;
                //     setmodalMessageResponse(errorMessage);
                //     setLoading(false);

                // }
                //   firebase.database().ref(databasePath).remove()
                // .then(() => {

                // console.log('Data deleted successfully!');
                // Add any additional logic you need after deleting
                //  handleClose(); // Close the modal after successful deletion

                // setIsDialogOpenSavedata(true)
                // const errorMessage = `Remove data successfully!`;
                // setmodalSaveMessage(errorMessage);
                // setLoading(false);

                // window.location.reload();
                // })
                // .catch((error) => {
                //     //  console.error('Error deleting data:', error);
                //     // Handle errors here
                // });

                //   const cfunction = new CommonFuctions();
                // cfunction.isAnyMeterExist(findGroupChild)
                //     .then(childCount => {
                //         //  console.log('childCount1111111111111', childCount);

                //         if (childCount == 0) {
                //             //  console.log('Deleting Firebase reference because childCount is 0');
                //             //cfunction.deleteFirebaseReference(findGroupChild);
                //             findGroupChild.remove().then(() => {

                //                 //    console.log("remove successfully");
                //             })
                //                 .catch(() => {

                //                     // console.log("Error deleting Firebase reference");
                //                 });
                //         } else {
                //             console.log("Child count is greater than 0");
                //         }
                //     })
                //     .catch(error => {
                //         console.error('Error in isAnyMeterExist:', error);
                //     });

            } else {
                alert("You have been logged-out due to log-in from another device.");
                // console.log('you are logg out ');
                handleLogout();
            }
        } catch (error) {
            setIsDialogOpen(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Response not recieved  from server-S. Please check if transaction completed successfully, else retry. (${error}).`;
            setmodalMessage(errorMessage);
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions

        }
    }

    const handleResetInputs = () => {
        setInputValues({
            input1: '',
            input2: '',
        });
    };
    const handleClose = () => {
        setErrorMessageName('');
        setErrorMessagePhone('');

        handleResetInputs();
        // If inputs are shown, close the inputs
        if (showInputs) {
            setShowInputs(false);
        } else {
            // If inputs are not shown, reset data and close the modal
            handleResetInputs();
            setSelectedGroupName("");
            setDisplayedInput2("");
            setModalIsOpen(false);
            setShowDatePicker(false);

        }
    };


    const [modalIsOpendata, setModalIsOpenData] = useState(false); // State to manage the modal
    const handleClosed = () => {
        setSelectedGroupName(''); // Reset the selected group name
        setModalIsOpenData(false); // Close the modal
    };

    const history = useNavigate();
    const handleLogout = () => {
        auth.signOut().then(() => {

            history('/'); // Change '/login' to your login page route
        }).catch((error) => {
            // Handle any errors during logout
            //  console.error('Error logging out:', error.message);
        })
        //   history('/');
    }

    const openModal = () => {
        setModalIsOpen(true);
    };

    const handleGroupNameClick = (groupName) => {


        setSelectedGroupHide(null);
        setHighlightGroupName(null);
        setModalIsOpenData(true); // Open the modal
        setSelectedGroupName(groupName);
        setEditGroupName(groupName); // This ensures that editGroupName is updated when the selectedGroupName is updated
        const selectedGroupData = data[groupName];

        if (selectedGroupData) {
            setGroupName(groupName);
            setTariff(selectedGroupData.tariff || '');
            setGroupTariifRate(selectedGroupData.tariff);
        } else {
            setGroupName('');
            setTariff('');
        }

    };

    const [Errorgroup, setErroeGroup] = useState('');

    // Edit group name and related data
    const [tariff, setTariff] = useState('');
    const [groupName, setGroupName] = useState(selectedGroupName);
    const [editGroupName, setEditGroupName] = useState(selectedGroupName); // Initialize editGroupName with selectedGroupName

    const handleGroupNameChange = (event) => {
        // setEditGroupName(event.target.value);
        setEnterGroupnameError('');

        let groupname = event.target.value;
        groupname = groupname.replace(/\s+/g, ' ');
        // const groupname = event.target.value;


        const errorMessage = validateName(groupname);

        if (errorMessage) {
            // Display the error message to the user
            setErroeGroup(errorMessage);
        } else {
            // Validation passed, set the group name in the state
            setEditGroupName(groupname);
            setErroeGroup("");
        }
    };

    const handleTariffChange = (event) => {
        setEnterTariffError('');
        const inputValue = event.target.value;
        if (inputValue === '0') {
            setTariff('');
        } else {
            const isValid = /^(?:\d{1,2}(?:\.\d{0,2})?|99.99(?:\.00?)?)$/.test(inputValue);
            if (isValid || inputValue === '') {
                setTariff(inputValue);
            } else {
                // Handle invalid input, e.g., display an error message or prevent setting tariff
            }
        }
    };

    const [EnterGroupnameError, setEnterGroupnameError] = useState('');
    const [EnterTariffError, setEnterTariffError] = useState('');

    // const isAnychangeGroupname = (oldGroupname, newGroupname, oldTariff, newTariff) => {

    //     // // Get the existing group names
    //     const checkGroupName = Object.keys(data);
    //     // // Process existing group names: Remove underscores and convert to uppercase
    //     const modifiedGroupNames = checkGroupName.map(name => name.replace(/_/g, "").toUpperCase());
    //   //  console.log('Modified group names:', modifiedGroupNames);

    //     // // Process the new group name: Remove spaces and convert to uppercas
    //     const entergroupName = newGroupname.replace(/ /g, "").toUpperCase();

    //     if (modifiedGroupNames.includes(entergroupName)) {
    //       //  console.log('Group name already exists');
    //         return false; // Group name already exists
    //     } else {
    //         // console.log('New Group Name');
    //         return true; // New group name is unique
    //     }
    // }

    const isAnychangeGroupname = (oldGroupname, newGroupname, oldTariff, newTariff) => {
        // Get the existing group names
        const checkGroupName = Object.keys(data);
        // Process existing group names: Remove underscores and convert to uppercase
        const modifiedGroupNames = checkGroupName.map(name => name.replace(/_/g, "").toUpperCase());

        console.log('Enter GroupName ', newGroupname);

        console.log('Enter Tariffe', newTariff);

        console.log('old  Tariffe', oldTariff);

        // Process the new group name: Remove spaces and convert to uppercase
        const entergroupName = newGroupname.replace(/ /g, "").toUpperCase();

        // Check if the new group name already exists
        if (modifiedGroupNames.includes(entergroupName) && oldTariff === newTariff) {
            return false; // No changes in the data
        }

        return true; // Either the group name is new or the tariff has changed
    };
    const handleUpdate = async () => {

        const isAnychange = isAnychangeGroupname(selectedGroupName, editGroupName, groupTariffRate, tariff);

        if (isAnychange) {




            if (!editGroupName.trim()) {
                setEnterGroupnameError("Cannot be empty.")
                return;
            }
            if (!tariff.trim()) {
                setEnterTariffError('"Cannot be empty."')
                return;
            }

            if (tariff.endsWith('.')) { // Check if tariff ends with a dot
                setEnterTariffError("Invalid tariff.");
                return;
            }

            setLoading(true);
            // Disable browser interactions
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, null, window.location.href);
            };

            toggleRefreshAndClose(false); // Prevent refresh and close actions
            ///  const status = await cfunction.checkInternetConnection(); // Call the function
            //  setShowChecker(status);

            const status = await cfunction.checkInternetConnectionAvailable(); // Call the function

            if (!navigator.onLine) {
                setIsDialogOpen(true);
                setmodalMessage('No internet. Cannot access server.');
                setLoading(false);
                toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
                return;
            }


            if (status === 'Poor connection') {
                setIsDialogOpen(true);
                setmodalMessage('Poor internet. Cannot access server.');
                setLoading(false);
                toggleRefreshAndClose(true); // Prevent refresh and close actions
                return;
            }



            const storeSessionId = localStorage.getItem('sessionId');
            try {
                const { sessionId } = await cfunction.HandleValidatSessiontime(numberPart);
                if (storeSessionId === sessionId) {
                    // Add this line to format the tariff to two decimal places
                    const formattedTariff = parseFloat(tariff).toFixed(2);
                    let SelectedGroupName = selectedGroupName.replace(/ /g, "_");
                    const currentDataRef = database.ref(`/adminRootReference/tenantDetails/${numberPart}/${SelectedGroupName}/`);
                    const Deletepath = currentDataRef.toString();


                    let srData; // Declare the variable outside the if block
                    if (data.hasOwnProperty(SelectedGroupName)) {
                        srData = data[SelectedGroupName];
                    }

                    // else {
                    //     console.log(`Group name ${SelectedGroupName} not found in the data.`);
                    // }

                    //  《❎🔥 Updated Code 🔥❎ 》

                    const formattedGroupName = (editGroupName || selectedGroupName).split(' ').join('_');


                    const newDataRef = database.ref(`/adminRootReference/tenantDetails/${numberPart}/${formattedGroupName}/`);
                    const db = getDatabase(); // Assuming getDatabase is defined elsewhere
                    const adminRootReference = ref(db, `/adminRootReference/tenantDetails/${numberPart}/${formattedGroupName}/`);
                    const fullAdminProfilePath = adminRootReference.toString();

                    // Conditionally update the group name and tariff rate
                    if (editGroupName && formattedGroupName) {
                        // data.groupName = groupName;
                    }
                    for (const serialKey in srData) {
                        if (Object.hasOwnProperty.call(srData, serialKey)) {
                            const serialData = srData[serialKey];
                            if (serialData && typeof serialData === 'object' && serialData.serial) {
                                // Check if the serial has a tariff property and update it
                                if (serialData.tariff !== undefined && serialData.tariff !== formattedTariff) {
                                    serialData.tariff = formattedTariff;
                                }
                            }
                        }
                    }

                    // Update grouptariff if it's different
                    if (srData.grouptariff !== undefined && srData.grouptariff !== formattedTariff) {
                        srData.grouptariff = formattedTariff;
                    }

                    if (formattedTariff !== undefined && formattedTariff !== srData.tariff) {
                        srData.tariff = formattedTariff;
                    }

                    let dataToSend = {
                        [fullAdminProfilePath]: srData,

                    };

                    let updateGroupNames = []; // Declare an array to store updateGroupNames

                    Object.keys(data).map((key) => {

                        const serialNumber = data[key].serial;
                        if (serialNumber !== null && serialNumber !== undefined && serialNumber.length > 0) {
                            //  console.log("serialNumber", serialNumber);
                            const path = firebase.database().ref(`/adminRootReference/adminDetails/${numberPart}/newMeterList/${serialNumber}/groupName`);
                            const updAdminSerial = firebase.database().ref(path).toString(); // Correctly build Firebase path as string
                            updateGroupNames.push(updAdminSerial); // Store each path in the array
                            dataToSend[updAdminSerial] = formattedGroupName;
                        }
                    });

                    // If there is at least one serial number, add the paths to dataToSend
                    if (updateGroupNames.length > 0) {
                        updateGroupNames.forEach(updAdminSerial => {
                            dataToSend[updAdminSerial] = formattedGroupName;
                        });
                    }

                    //  this part is when groupname is change then old groupname and related data  deleted  and new groupname with data print  
                    if (editGroupName && editGroupName !== selectedGroupName) {
                        // Update dataToSend to include Deletepath
                        dataToSend = {
                            ...dataToSend,
                            [Deletepath]: null
                        };
                    }

                    try {


                        //   console.log(' All data  :', data);

                        //  console.log('selected data is :', srData);

                        //  console.log('Old groupname :', selectedGroupName)

                        //  console.log('Edited groupname :', formattedGroupName)


                        data[formattedGroupName] = srData;

                        //  console.log('Data after adding new groupname:', data);

                        if (editGroupName && editGroupName !== selectedGroupName) {

                            // Remove the old group name from the original data object
                            delete data[selectedGroupName];

                        }

                        onUpdateGroupData(data);
                        // console.log(' first data :', dataToSend);
                        await cfunction.callWriteRtdbFunction(dataToSend);
                        setIsDialogOpenSavedata(true)
                        toggleRefreshAndClose(true); // Prevent refresh and close actions
                        const errorMessage = `Data saved successfully!`;
                        setmodalSaveMessage(errorMessage);
                        setLoading(false);
                        ///  console.log('update Data groupdat and tariff  :', result);
                    } catch (error) {
                        setIsDialogOpenSavedata(true);
                        // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                        const errorMessage = `Response not received from server. Please check through enquiry if transaction completed successfully. (${error}).`;
                        setmodalSaveMessage(errorMessage);
                        setLoading(false);
                        toggleRefreshAndClose(true); // Prevent refresh and close actions
                    }
                } else {
                    alert("You have been logged-out due to log-in from another device.");
                    handleLogout();
                }
            }
            catch (error) {
                // setIsDialogOpenSavedata(true);
                setIsDialogOpen(true);
                const errorMessage = `Response not received from server-S. Please retry. (${error})`;
                setmodalMessage(errorMessage);
                setLoading(false);
                toggleRefreshAndClose(true); // Prevent refresh and close actions
            }


        } else {
            setIsDialogOpen(true);
            const errorMessage = `No changes in existing data.`;
            setmodalMessage(errorMessage);
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            // alert('No change in existing data. ');
        }
    }

    const handleDeleteGroupname = () => {
        setmodalMessage(`Are you sure you want to delete this data?`);
        setisConfirmedgroup(true);
    }

    const handleDeleteGroup = async () => {


        //  console.log('groupname ', editGroupName);
        // Disable browser interactions
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.href);
        };

        // setLoading(true);
        const formattedGroupName = (editGroupName || selectedGroupName).split(' ').join('_');
        const status = await cfunction.checkInternetConnection(); // Call the function
        //  setShowChecker(status);

        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setmodalMessage('No/Poor Internet connection. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }

        if (status === 'Poor connection.') {
            setIsDialogOpen(true);
            setmodalMessage('No/Poor Internet connection. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }


        // try {
        //     const isLastActive = await cfunction.isLastActiveTime(numberPart);
        //     if (isLastActive) {
        //         setLoading(false);
        //         toggleRefreshAndClose(true); // Prevent refresh and close actions
        //         handleLogout();
        //         localStorage.setItem('isLoggedIn', true);
        //     }
        //     else {
        //     }
        // } catch (error) {

        //     setIsDialogOpen(true);
        //     setmodalMessage(`Response not recieved  from server-S. Please check your internet connection .(${error})`);
        //     setLoading(false);
        //     toggleRefreshAndClose(true); // Prevent refresh and close actions

        // }


        const storeSessionId = localStorage.getItem('sessionId');
        try {
            const { sessionId } = await cfunction.HandleValidatSessiontime(numberPart);
            if (storeSessionId === sessionId) {

                const newDataRef = database.ref(`/adminRootReference/tenantDetails/${numberPart}/${formattedGroupName}/`);

                const db = getDatabase(); // Assuming getDatabase is defined elsewhere
                const adminRootReference = ref(db, `/adminRootReference/tenantDetails/${numberPart}/${formattedGroupName}/`);
                const fullAdminProfilePath = adminRootReference.toString();

                const tariffRef = firebase.database().ref(`/adminRootReference/tenantDetails/${numberPart}/${formattedGroupName}/`);
                const saveTariffReference = tariffRef.toString();

                const result = await cfunction.callCloudFunction(fullAdminProfilePath)
                const data = JSON.parse(result);
                delete data.time;

                if (data) {
                    const keys = Object.keys(data).filter(key => key !== 'time'); // Exclude 'time' key
                    if (keys.length === 0) {

                        const dataToSend = {
                            [saveTariffReference]: null
                        };
                        await cfunction.callWriteRtdbFunction(dataToSend);
                        ///  console.log('update Data groupdat and tariff  :', result);

                    } else {
                        //  console.log(`Group ${formattedGroupName} has keys other than 'time', checking for serial numbers.`);
                        const serialNumbers = keys.filter(key => key !== 'tariff'); // Exclude 'tariff' key if needed
                        if (serialNumbers.length === 0) {

                            // Create an object with the path to delete and set its value to null
                            const dataToSend = {
                                [saveTariffReference]: null
                            };

                            try {
                                const result = await cfunction.callWriteRtdbFunction(dataToSend);
                                ///  console.log('update Data groupdat and tariff  :', result);

                                setIsDialogOpenSavedata(true)
                                const errorMessage = `Group ${formattedGroupName} deleted successfully as it has no serial numbers.`;
                                setmodalSaveMessage(errorMessage);
                                setLoading(false);
                                toggleRefreshAndClose(true); // Prevent refresh and close actions

                            } catch (error) {
                                setisResponseModel(true);
                                // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
                                const errorMessage = `Response not recieved  from server-A. Please check if transaction completed successfully, else retry. (${error}).`;
                                setmodalMessageResponse(errorMessage);
                                setLoading(false);
                                toggleRefreshAndClose(true); // Prevent refresh and close actions

                            }

                        } else {
                            // alert(`Group ${formattedGroupName} has ${serialNumbers.length} serial numbers, not deleting.`);
                            //  alert(`Meter present in this group cannot delete..`);
                            setIsDialogOpen(true);
                            const errorMessage = `Meter present in this group cannot delete.`;
                            setmodalMessage(errorMessage);
                            setLoading(false);
                            toggleRefreshAndClose(true); // Prevent refresh and close actions
                        }
                    }
                } else {
                    /// console.log(`Group ${formattedGroupName} not found.`);
                }


            } else {

                alert("You have been logged-out due to log-in from another device.");
                handleLogout();
            }

        }
        catch (error) {
            // setIsDialogOpenSavedata(true);
            setIsDialogOpen(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})
            `;
            setmodalMessage(errorMessage);
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions


        }
    }

    const [modalMessage, setmodalMessage] = useState('');
    const [modalSaveMessage, setmodalSaveMessage] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const closeDialog = () => {
        setIsDialogOpen(false);
        //  window.location.reload(); // This will reload the page
    };

    const [modalMessageResponse, setmodalMessageResponse] = useState('');
    const [isResponseModel, setisResponseModel] = useState(false);

    const closeResponse = () => {
        setisResponseModel(false);
        ///  window.location.reload();
        window.onpopstate = null;
    };

    const [isDialogOpenSavedata, setIsDialogOpenSavedata] = useState(false);
    const closeDialogSavedata = () => {
        setIsDialogOpenSavedata(false);
        toggleRefreshAndClose(true); // Prevent refresh and close actions
        //  setIsFormOpen(false); // Close the form after submission
        //  window.location.reload();
        window.onpopstate = null;
        setModalIsOpenData(false); // Close the modal
        handleClose(false);

    };

    const [isConfirmed, setisConfirmed] = useState(false);
    const isConfirmedYes = () => {
        setisConfirmed(false);
        deleteGroupname();
        setLoading(true);
    }
    const closeisConfirmed = () => {
        setisConfirmed(false);
        window.onpopstate = null;

    };

    const [isConfirmedgroup, setisConfirmedgroup] = useState(false);
    const isConfirmedYesgroup = () => {
        setisConfirmedgroup(false);
        handleDeleteGroup();
        setLoading(true);
    }
    const closeisConfirmedgroup = () => {
        setisConfirmedgroup(false);
        window.onpopstate = null;
    };

    const [showLogouttAlert, setshowLogouttAlert] = useState(false); // State to control alert visibility
    const handleCloseLogoutAlert = () => {
        setshowLogouttAlert(false); // Close alert when clicked on the close button
        handleLogout();
    };

    const serialRefs = useRef({});
    const groupRefs = useRef({});

    useEffect(() => {
        if (highlightedSerial && serialRefs.current[highlightedSerial]) {
            const navbarHeight = 0.35 * window.innerHeight; // 1% of window height
            const elementTop = serialRefs.current[highlightedSerial].getBoundingClientRect().top;
            window.scrollTo({
                top: elementTop + window.pageYOffset - navbarHeight,
                behavior: 'smooth'
            });
        }
    }, [highlightedSerial]);

    useEffect(() => {
        if (highlightGroupName && groupRefs.current[highlightGroupName]) {
            const navbarHeight = 0.35 * window.innerHeight; // 1% of window height
            const elementTop = groupRefs.current[highlightGroupName].getBoundingClientRect().top;
            window.scrollTo({
                top: elementTop + window.pageYOffset - navbarHeight,
                behavior: 'smooth'

            });
            //  groupRefs.current[highlightGroupName].scrollIntoView({ behavior: 'smooth' });
        }
    }, [highlightGroupName]);

    const handleDateChange = (date) => {

        const formatedDate = formatDate(date);
        console.log('Date is ', formatedDate);
        setSelectedDate(formatedDate);
    };

    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}/${month}/${year}`;
    }


    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            type="text"
            value={value}
            onClick={onClick}
            className="form-control"
            ref={ref}
            id="Dateofoccupent"
        />
    ));


    return (
        <>
            {loading ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}

            <div className='MainClass'>
                <div>

                    {searchExecuted ? (
                        extractGroupNames().length > 0 ? (
                            <h4 style={{ color: '#8B0000' }}>Edit Details</h4>
                        ) : (
                            <>
                                <h4 style={{ color: '#8B0000' }}>Edit Details</h4>
                                <h6>No consumer details available for edit or delete.</h6>
                            </>
                        )
                    ) : null}

                </div>
                {extractGroupNames()
                    .sort() // Sort group names alphabetically
                    .map((groupName, groupIndex) => (
                        <div key={groupIndex}>
                            <div
                                className={`EditGRoupname ${highlightGroupName === groupName ? 'highlighted' : ''}`}
                                ref={(el) => groupRefs.current[groupName] = el}
                                onClick={() => handleGroupNameClick(groupName)}>
                                <h6>{groupName.replace(/_/g, ' ')}</h6>
                                <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                    <img
                                        src="https://img.icons8.com/ios-glyphs/30/edit--v1.png"
                                        alt="Edit Icon"
                                        style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                            <div className="rowContainer">
                                {Object.entries(data[groupName])
                                    .filter(([serial, info]) => info !== 'tariff' && info !== null && serial !== 'tariff')
                                    .map(([serial, info], index) => (
                                        <div key={index}
                                            ref={(el) => (serialRefs.current[serial] = el)}
                                            className={`groupcustomBox ${highlightedSerial === serial ? 'highlight' : ''}`}

                                            style={{ position: 'relative' }}
                                            onClick={() => handleCustomBoxClick(groupName, serial, info)}
                                        >
                                            <div className='serialNameParagraph'>
                                                <div className='serialNumberRow'>
                                                    <p className='serialNumberText'>{serial}</p>
                                                    <img
                                                        src="https://img.icons8.com/ios-glyphs/30/edit--v1.png"
                                                        alt="Edit Icon"
                                                        className='editIcon'
                                                    />
                                                </div>
                                                <p className='Nameparagraph '>{info.name}</p>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ))}
            </div>




            <Modal show={modalIsOpendata} onHide={handleClosed}
                size="lg"
                backdrop="static"
            >
                <Modal.Header closeButton={!loading}>
                    <Modal.Title>Edit Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className=' groupname'>
                        <div className='container '>
                            <div className='group-icons' >
                                <div style={{ display: 'flex', padding: '2%' }}>
                                    <img
                                        src="https://img.icons8.com/fluency/48/add-user-male.png"
                                        alt="Icon"
                                        style={{ width: '25px', height: '25px', marginLeft: '4%' }}
                                    />
                                    <p>{selectedGroupName.replace(/_/g, ' ')}</p>
                                </div>
                            </div>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg">
                                        <label> Group Name </label>
                                        <br />
                                        <input
                                            type="text"
                                            placeholder="Group Name"
                                            className="form-control underline-input"
                                            value={editGroupName.replace(/_/g, ' ')}
                                            onChange={handleGroupNameChange}
                                            disabled={loading}
                                            maxLength={20}
                                        />
                                        {EnterGroupnameError && <span style={{ color: 'red' }}>{EnterGroupnameError}</span>}
                                    </div>
                                    <div className="col-lg">
                                        <label> Tariff Rate (₹) </label>
                                        <br />
                                        <input
                                            type="text"
                                            placeholder="Tariff"
                                            className="form-control underline-input"
                                            value={tariff}
                                            disabled={loading}
                                            onChange={handleTariffChange}
                                        />
                                        <span style={{ fontSize: '10px' }}>Min. Limit 1.00, Max 99.99, Up to 2 decimal places for paise</span>
                                        {EnterTariffError && <span style={{ color: 'red' }}>{EnterTariffError}</span>}
                                    </div>
                                    <div className="col-lg">
                                        <button className="btn btn-primary btn-block" onClick={handleUpdate}>UPDATE</button>
                                        {/* <button className="btn btn-danger mt-3" onClick={handleDeleteGroupname}>Delete</button> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal show={modalIsOpen} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedSerialData && (
                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Meter Serial Number </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedSerialData.serial}
                                        onChange={(e) => handleInputChange('serial', e.target.value)}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Create/ Select group </label>
                                    <div className="position-relative" onClick={() => {
                                        setShowInputs(!showInputs); // Toggle inputs on icon click
                                    }}>
                                        <input
                                            type="text"
                                            className="form-control w-100" // Added Bootstrap class to set width to 100%
                                            placeholder="Select an option"

                                            style={{ width: '100%', paddingRight: '30px' }} // Adjusted width for responsiveness
                                            value={getInputValue()} // Call the function to determine input value
                                            onChange={handleSelectChange}
                                            disabled={loading}
                                        />
                                        <span
                                            className="position-absolute top-50 end-0 translate-middle-y"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setShowInputs(!showInputs); // Toggle inputs on icon click
                                            }}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                    <span>{groupError}</span>
                                </div>
                            </div>

                            {showInputs ? (
                                <div className="inputs-container" style={{ position: 'absolute', left: '28%', backgroundColor: '#fff', zIndex: '333' }}>
                                    <div>
                                        <div class="col-md-6 mb-3 text-start">
                                            <label class="form-label" >Group Name</label>
                                            <input
                                                type="text"
                                                placeholder="Group Name "
                                                className="input-field"
                                                name="input1"
                                                value={inputValues.input1}
                                                onChange={handleInputChange1}
                                                maxLength={20}

                                            />
                                            {groupNameError && <p style={{ color: 'red' }}>{groupNameError}</p>}
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label class="form-label ">Tariff Rate </label>
                                            <input
                                                type="text"
                                                placeholder="Tariff Rate"
                                                className="input-field"
                                                name="input2"
                                                value={inputValues.input2}

                                                onChange={handleInputChange1}
                                            />
                                            <p style={{ color: '#000000', fontSize: '10px', margin: '0', padding: "0", width: '140%' }}>Min. Limi 1.00, Max 99.99, Upto 2 deciaml places for paise</p>
                                            {tariffRateError && <p style={{ color: 'red' }}>{tariffRateError}</p>}
                                        </div>

                                    </div>

                                    <div className='btton' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <button className="custom-button" onClick={handleAddClick}>
                                            Add
                                        </button>
                                        <button className="custom-button" onClick={handleCloseClick}>
                                            Close
                                        </button>
                                    </div>
                                    <div>
                                        <hr style={{ width: '100%', border: '1.5px solid red' }} />
                                        <p>Select Exsting Group </p>

                                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}> {/* Add a scroll bar for overflow */}
                                            {
                                                existingGroups.map((group, index) => {
                                                    // Filter out 'time' from group names
                                                    // console.log("my group name data ", group);
                                                    if (group.includes('time')) {
                                                        return null; // Skip displaying if the group contains 'time'
                                                    }
                                                    // Calculate meters count for the current group
                                                    const groupData = data[group.replace(/ /g, '_')]; // Assuming your data structure contains group-related data
                                                    const metersCount = calculateMetersCount(groupData); // Replace this with your logic to count meters for the group


                                                    // const iconURL = ``;

                                                    return (

                                                        <React.Fragment key={index}>
                                                            <div style={{ display: 'flex', alignItems: 'center', width: "250px", }}>
                                                                <img
                                                                    src="https://img.icons8.com/fluency/100/user-group-man-woman.png"
                                                                    alt={`${group} icon`}
                                                                    style={{ width: '50px', height: '50px', marginRight: '20px' }} /> {/* Replace this with your icon */}
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <p style={{ margin: '1px' }} onClick={() => handleGroupClick(group)}>
                                                                        {group}
                                                                    </p>
                                                                    <p>No of Consumers: {metersCount}</p>

                                                                </div>

                                                            </div>
                                                            {((index + 1) % 2 === 0) && (index !== group.length - 1) && <hr style={{ width: '100%', margin: '5px 0' }} />}
                                                            {/* {(index + 1) % 2 === 0 && <hr style={{ width: '100%', margin: '5px 0' }} />} Add a horizontal line after every pair */}
                                                        </React.Fragment>
                                                    );
                                                })
                                            }
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div className="row">
                                <div className="col-md-6">
                                    <label>Meter Location</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedSerialData.info.location}
                                        onChange={(e) => handleInputChange('location', e.target.value)}
                                        maxLength={40}
                                        disabled={loading}
                                    />
                                    <span style={{ color: 'red' }}>{errorMessageLocation}</span>
                                </div>
                                <div className="col-md-6">

                                    <label>Tariff</label>
                                    {selectedGroupData && (
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={displayedInput2 || selectedSerialData.tariff}
                                            readOnly
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Consumer Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedSerialData.info.name}
                                        onChange={(e) => handleInputChange('name', e.target.value)}
                                        maxLength={20}
                                        disabled={loading}
                                    />

                                    <span style={{ color: 'red ' }}> {errorMessageName} </span>

                                </div>
                                <div className="col-md-6">
                                    <label>Consumer Mobile Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="mobileNumber"
                                        value={selectedSerialData.info.phone}
                                        onChange={(e) => handleInputChange('phone', e.target.value)}
                                        maxLength={10}
                                        disabled={loading}
                                    />
                                    <span style={{ color: 'red ' }} > {errorMessagePhone} </span>

                                </div>

                            </div>

                            <div className='row'>
                                <div className="col-md-7">
                                    <label>Consumer Alternet Mobile Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="consumerAltMobileNumber"
                                        //  value={selectedSerialData.info.altPhone}
                                        value={selectedSerialData.info.altPhone === 'na' ? '' : selectedSerialData.info.altPhone}
                                        onChange={(e) => handleInputChange('altPhone', e.target.value)}
                                        maxLength={10}
                                        disabled={loading}
                                    />
                                    <span style={{ color: 'red ' }} > {errorMessageAltPhone} </span>

                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <label>E-mail Address (Optional)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="consumerEmail"
                                        value={selectedSerialData.info.email === 'na' ? '' : selectedSerialData.info.email}
                                        onChange={(e) => handleInputChange('email', e.target.value)}
                                        disabled={loading}
                                        maxLength={40}
                                    />

                                    <span style={{ color: 'red ' }} > {emailError} </span>

                                </div

                                >


                                <div className="col-md-6">
                                    <label>Date of Occupancy</label>
                                    <DatePicker
                                        value={selectedDate} // Use the state variable here
                                        onChange={handleDateChange} // Update state when date changes
                                        showIcon
                                        dateFormat="dd/MM/yy"
                                        showYearDropdown
                                        showMonthDropdown
                                        className="form-control"
                                        customInput={<CustomInput />}
                                       
                                    />
                                </div>

                                {/* <div className="col-md-6">
                                    <label>Date of Occupancy</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        placeholder="Date of Occupancy"
                                        onClick={handleIconClick}
                                        ref={dateInputRef}
                                        value={
                                            selectedDate // Use selectedDate if it's available
                                                ? formatDate(selectedDate)
                                                : selectedSerialData.info.doo // If selectedDate is not available, use selectedSerialData.info.doo
                                        }
                                        disabled={loading}
                                    />
                                    <div className="date-picker-wrapper">
                                        {showDatePicker && (
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={(date) => {
                                                    setSelectedDate(date);
                                                    setShowDatePicker(false);
                                                }}
                                                popperPlacement="top"
                                                inline
                                                dateFormat="dd/MM/yy"
                                                showYearDropdown
                                                showMonthDropdown
                                                ref={dateInputRef}
                                            />
                                        )}
                                    </div>
                                </div>  */}
                            </div>
                        </div>
                    )}


                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center w-100">
                        <Button onClick={handleSavebutton} style={{ marginRight: '20px' }}>Update</Button>
                        <Button className="btn btn-danger" onClick={handleDelete}>Delete</Button>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
                <Modal.Body>
                    <p style={{ color: 'red', }}> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isResponseModel} onHide={closeResponse} backdrop="static" style={{ marginTop: '3%' }}>

                <Modal.Body>
                    <p style={{ color: 'red' }}> {modalMessageResponse}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeResponse}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isDialogOpenSavedata} onHide={closeDialogSavedata} backdrop="static" style={{ marginTop: '3%' }}>

                <Modal.Body>
                    <p> {modalSaveMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialogSavedata}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isConfirmed} onHide={closeisConfirmed} backdrop="static" style={{ marginTop: '3%' }}>
                <Modal.Body>
                    <p> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={isConfirmedYes}>
                        YES
                    </Button>

                    <Button variant="primary" onClick={closeisConfirmed}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isConfirmedgroup} onHide={closeisConfirmedgroup} disabled={loading} backdrop="static" style={{ marginTop: '3%' }}>
                <Modal.Body>
                    <p> Are you sure want to deleted this group name </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={isConfirmedYesgroup}>
                        YES
                    </Button>

                    <Button variant="primary" onClick={closeisConfirmedgroup}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>



            {showLogouttAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseLogoutAlert}
                        className='AlertDailog'
                    >
                        You have been logged-out due to log-in from another device.
                    </Alert>
                </div>
            )}


        </>
    );
}

export default Groupdetails;
