import React, { useEffect, useState } from 'react';
import { auth } from '../adminLogin/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, } from 'firebase/database';
import CommonFuctions from '../commonfunction';
import Navbar from '../adminLogin/navbar';
import firebase from 'firebase/compat/app'; // Import the Firebase app
import 'firebase/compat/firestore';
import PhoneSendOtp from '../adminLogin/phonesendotp';
import ResendTimer from '../adminLogin/resendotp'
import EmailSendotp from './email'
import Alert from '@mui/material/Alert';
import { Modal, Button } from 'react-bootstrap';

function Phoneemailverify() {

    const sessiontime = new CommonFuctions();

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [mobileOTP, setMobileOTP] = useState('');
    const [emailOTP, setEmailOTP] = useState('');
    const [mobileOTPError, setMobileOTPError] = useState('');
    const [emailOTPError, setEmailOTPError] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState('');


    // const { email } = location.state || {};
    // const { newPhone } = location.state || {};
    // const { newName } = location.state || {};
    // const { newAddress } = location.state || {};
    const email = localStorage.getItem('AdminEmail');
    const newName = localStorage.getItem('AdminName');
    const newAddress = localStorage.getItem('AdminAddress');
    const newPhone = localStorage.getItem('AdminAltnumber');
    const { handleButtonClick } = EmailSendotp({ email: email }); // Pass the email as a prop


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                //  console.log('Logged in user:', authUser.email);
                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                    const number = emailParts[0];
                    //   console.log('Extracted number:', number);
                    setPhoneNumber(number);
                    ///   fetchdata(number)
                    updateSessionActiveTime(number);
                    setLoading(false);
                }
            } else {
                setUser(null);
                window.location.href = '/';
            }
        });

        return () => unsubscribe();
    }, []);



    const updateSessionActiveTime = (phoneNumber) => {
        sessiontime.updateSessionTimeActiveUser(phoneNumber);
    }



    let canRefreshOrClose = true; // Allow refresh and close actions by default
    // Function to handle beforeunload event
    const handleBeforeUnload = (event) => {
        if (!canRefreshOrClose) {
            const message = 'Please do not reload this page, data may be lost.';
            event.returnValue = message;
            return message; // Required for some older browsers   
        }
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Function to enable/disable refresh and close actions
    const toggleRefreshAndClose = (enabled) => {
        canRefreshOrClose = enabled;
    };

    const handleSubmitClick = async (e) => {

        e.preventDefault(); // Prevent default form submission
        if (mobileOTP === '') {
            setMobileOTPError('Invalid mobile OTP');
            return;
        }
        if (emailOTP === '') {
            setMobileOTPError('Invalid mobile OTP');
            return;
        }
        e.preventDefault(); // Prevent default form submission


        const storedPhoneOTP = localStorage.getItem('otp'); // Get stored phone OTP
        const storedEmailOTP = localStorage.getItem('emailOTP'); // Get stored email OTP
        if (mobileOTP !== storedPhoneOTP) {
            setMobileOTPError('Invalid mobile OTP');
        } else if (emailOTP !== storedEmailOTP) {
            setEmailOTPError('Invalid email OTP');
        } else {

            setMobileOTPError('');
            setEmailOTPError('');
        }

        if (mobileOTP === storedPhoneOTP && emailOTP === storedEmailOTP) {

            setLoading(true);
            toggleRefreshAndClose(false); // Prevent refresh and close actions
            // const status = await sessiontime.checkInternetConnection(); // Call the function

            if (!navigator.onLine) {
                setIsDialogOpen(true);
                setModalMessage('No internet. Cannot access server.');
                setLoading(false);
                toggleRefreshAndClose(true); // Prevent refresh and close actions
                return;
            }

            const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function

            if (status === 'Poor connection') {
                setIsDialogOpen(true);
                setModalMessage('Poor internet. Cannot access server.');
                setLoading(false);
                toggleRefreshAndClose(true); // Prevent refresh and close actions
                return;
            }





            const storeSessionId = localStorage.getItem('sessionId');
            try {
                const { sessionId } = await sessiontime.HandleValidatSessiontime(phoneNumber);
                if (storeSessionId === sessionId) {
                    const db = getDatabase();
                    const adminProfilePath = `adminRootReference/adminDetails/${phoneNumber}/adminProfile`;
                    const snapshot = await get(ref(db, adminProfilePath));
                    const AdminfRef = firebase.database().ref(`adminRootReference/adminDetails/${phoneNumber}/adminProfile`);
                    const saveAdminReference = AdminfRef.toString();
                    const existingData = snapshot.val();
                    const existingKey = existingData.key;
                    const existingPassword = existingData.password;
                    const newData = {
                        ...existingData,
                        address: newAddress || '', // Retain the existing address
                        email: email || '', // Replace '' with a default value if needed
                        key: existingKey, // Retain the existing key
                        name: newName || '', // Replace '' with a default value if needed
                        password: existingPassword, // Retain the existing password
                        phoneNo: phoneNumber || '', // Replace '' with a default value if needed
                        phoneNo2: newPhone || '', // Replace '' with a default value if needed
                        // Add other fields as needed
                    };

                    const dataToSend = {
                        [saveAdminReference]: newData,
                    };
                    try {
                        await sessiontime.callWriteRtdbFunction(dataToSend);
                        sessiontime.updateSessionTimeActiveUser(phoneNumber);
                        localStorage.removeItem('AdminEmail');
                        localStorage.removeItem('AdminName');
                        localStorage.removeItem('AdminAddress');
                        localStorage.removeItem('AdminAltnumber');
                        localStorage.removeItem('otp');
                        localStorage.removeItem('emailOTP');

                        setModalMessage('Data Saved Successfully.');
                        setisDialogOpenDataSave(true);
                        toggleRefreshAndClose(true); // Prevent refresh and close actions
                        setLoading(false);

                    }
                    catch (error) {
                        setLoading(false);
                        setisDialogOpenDataSave(true);
                        // const errorMessage = `Response not recieved  from server-A.Please check if transaction completed successfully , else retry. `;
                        const errorMessage = `Response not received from server. Please check through enquiry if transaction completed successfully. (${error}).`;
                        setModalMessage(errorMessage);
                        toggleRefreshAndClose(true); // Prevent refresh and close actions

                    }
                    //  navigate('/admindetail');
                    // alert('Data saved successfully!');

                } else {

                    toggleRefreshAndClose(true);
                    setLoading(false);
                    setShowAlert(true);

                    // alert("Cannot login. Another session is active. Please retry after sometime. ");
                    // handleLogout();
                    // toggleRefreshAndClose(true); // Prevent refresh and close actions
                }

            } catch (error) {
                setLoading(false);
                setIsDialogOpen(true);
                const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
                setModalMessage(errorMessage);
                toggleRefreshAndClose(true); // Prevent refresh and close actions
            }
        } else {
        }
    };
    const [otpSendMessage, setotpSendMessage] = useState('');
    const resendOtp = async () => {

        const status = await sessiontime.checkInternetConnection(); // Call the function
        if (status === 'Poor connection.') {
            setIsDialogOpen(true);
            setModalMessage('No/Poor Internet connection , Please retry.');
            setLoading(false);
            //  toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }



        let phoneSuccess = false;
        let emailSuccess = false;
        // Send OTP to phone
        try {
            const phoneSendOtp = new PhoneSendOtp(newPhone);
            const phoneResult = await phoneSendOtp.sendOTP(newPhone);
            if (phoneResult == 411) {
                setMobileOTPError(`Invalid phone number: (${phoneResult})`);
            } else {
                phoneSuccess = true;
            }
        } catch (phoneError) {
            // console.error('Error sending OTP to phone:', phoneError);
        }
        // Send OTP to email
        try {
            const emailResult = await handleButtonClick(email);
            if (typeof emailResult === 'boolean') {
                if (emailResult) {
                    emailSuccess = true;
                    /// console.log('OTP sent to email successfully.');
                } else {
                    setEmailOTPError('Error sending OTP to email. Please try again.');
                }
            } else {
                ///console.error('Error:', emailResult);
            }
        } catch (emailError) {
            // console.error('Error sending OTP to email:', emailError);
        }

        // Check if both OTPs were sent successfully
        if (phoneSuccess && emailSuccess) {
            setotpSendMessage(' OTP sent successfully.');
        } else {
            setEmailOTPError('OTP sending failed.');
        }
    };

    const handleMobileOTPChange = (e) => {
        setotpSendMessage('');
        const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setMobileOTP(input);
        setMobileOTPError('');
    };

    const handleEmailOTPChange = (e) => {
        setotpSendMessage('');
        const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setEmailOTP(input);
        setEmailOTPError('');
    };


    // const handleSubmitClick = async (e) => {

    //     if (mobileOTP === '') {
    //         setMobileOTPError('Invalid mobile OTP');
    //         return;
    //     }
    //     if (emailOTP === '') {
    //         setMobileOTPError('Invalid mobile OTP');
    //         return;
    //     }
    //     e.preventDefault(); // Prevent default form submission
    //     handleOnSubmit(); // Call the submit function
    // };






    const history = useNavigate();
    const handleLogout = () => {
        auth.signOut().then(() => {
            history('/'); // Change '/login' to your login page route
        }).catch((error) => {
            // Handle any errors during logout
            console.error('Error logging out:', error.message);
        })
    }

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const closeDialog = () => {
        setIsDialogOpen(false);
        window.onpopstate = null;
    };

    const [isDialogOpenDataSave, setisDialogOpenDataSave] = useState(false);
    const closeDialogDataSave = () => {

        window.onpopstate = null;
        setisDialogOpenDataSave(false);
        navigate('/admindetail');
        window.location.reload(); // This will reload the page
    };


    
    const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
    const handleCloseAlert = () => {
        setShowAlert(false); // Close alert when clicked on the close button
        handleLogout();
    };





    return (
        <>



            <div>
                <Navbar />
            </div>


            {loading ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}

            <div className='containers'>
                <div className='formgroup'>
                    <div>
                        {/* <div>
                            <h3>Enter OTP</h3>
                        </div> */}
                        <label htmlFor="mobileOTP">Enter Mobile OTP</label>
                        <input
                            type="text"
                            className='form-control'
                            placeholder=" Mobile OTP"
                            value={mobileOTP}
                            onChange={handleMobileOTPChange}
                            maxLength={6}
                        />
                        {mobileOTPError && <p style={{ color: 'red' }} className="error">{mobileOTPError}</p>}
                    </div>
                    <div>
                        <label htmlFor="emailOTP">Enter E-mail OTP</label>
                        <input
                            type="text"
                            className='form-control'
                            placeholder=" E-mail OTP"
                            value={emailOTP}
                            onChange={handleEmailOTPChange}
                            maxLength={6}
                        />
                        <p style={{ color: 'red' }} className="error">{emailOTPError}</p>
                        <span style={{ color: 'blue' }}  >{otpSendMessage}</span>
                    </div>
                    {/* <div className='d-grid col-4'>
                        <button type="submit" className='btn btn-primary' onClick={handleSubmitClick}>
                            VERIFY
                        </button>
                    </div> */}

                    <div className="d-flex justify-content-center w-100">
                        {/* <button className="btn btn-danger" onClick={handleBackButton} style={{ marginRight: '50px' }}>Back</button> */}


                        <button type="submit" className='btn btn-primary' style={{ marginRight: '50px' }} onClick={handleSubmitClick}>
                            VERIFY
                        </button>

                        <ResendTimer onClick={resendOtp} />

                    </div>
                    <div>

                    </div>
                </div>
            </div>

            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
                {/* <Modal.Header closeButton>
      </Modal.Header>  */}
                <Modal.Body>
                    <p> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={isDialogOpenDataSave} onHide={closeDialogDataSave} backdrop="static" style={{ marginTop: '3%' }}>
                {/* <Modal.Header closeButton>
      </Modal.Header>  */}
                <Modal.Body>
                    <p> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialogDataSave}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {showAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseAlert}
                        className='AlertDailog'
                    >
                        You have been logged out due to login from another device.!
                    </Alert>
                </div>
            )}







        </>

    )

}

export default Phoneemailverify