import React, { useEffect, useState } from 'react';
import { auth } from '../adminLogin/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import Send from '../adminLogin/sendmail'; // Import the Send component
import PhoneSendOtp from '../adminLogin/phonesendotp';
import CommonFuctions from '../commonfunction';
import Navbar from '../adminLogin/navbar';
import { Modal, Button } from 'react-bootstrap';
import Alert from '@mui/material/Alert';


function Emailsendotp() {
    const [alertMessage, setAlertMessage] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [modalMessage1, setModalMessage1] = useState('');
    const sessiontime = new CommonFuctions();
    const [user, setUser] = useState(null);
    const [number, setNumber] = useState(''); // State to store the extracted number
    const { email, setEmail, errorMessage, handleButtonClick } = Send(); // Using your custom hook
    const navigate = useNavigate();
    const location = useLocation();
    const { newName, newPhone, newAddress } = location.state || {};
    const { newEmail } = location.state || {};
    // const initialEmail = location.state?.newEmail || ''; // Get email from location state

    const initialEmail = localStorage.getItem('AdminEmail');

    //  console.log("initialEmail ", initialEmail);
    useEffect(() => {
        setEmail(initialEmail); // Set initial email from location state
    }, [setEmail, initialEmail]);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                //  console.log("Logged in user:", authUser.email);
                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                    const extractedNumber = emailParts[0];
                    ///   console.log("Extracted number:", extractedNumber);
                    setNumber(extractedNumber); // Set the extracted number in the state
                    setLoading(false);
                    UpdateSession(extractedNumber)

                }
            } else {
                setUser(null);
                window.location.href = '/'; // Redirect to your login page
            }
        });

        // Cleanup function for unmounting
        return () => unsubscribe();
    }, []);




    const UpdateSession = async (extractedNumber) => {
        const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function
        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setLoading(false);
            return;
        }

        if (status === 'Poor connection.') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setLoading(false);
            return;
        }

        try {
            sessiontime.updateSessionTimeActiveUser(extractedNumber);
        } catch (error) {

            setLoading(false);
            setIsDialogOpen1(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
            setModalMessage1(errorMessage);

        }

    }




    const handleGetOTP = async () => {
        ///  console.log('GetOTP for phone number', number);
        const phoneSendOtp = new PhoneSendOtp(number);
        const result = await phoneSendOtp.sendOTP(number);

   //  console.log('Send otp Process  ',result);
        if (result == '200') {

            toggleRefreshAndClose(true); // Prevent refresh and close actions
            navigate('/emailverify', { state: { initialEmail, newName, newAddress, newPhone } });
            /// console.log('Opt send successfully ');
        }
        else if (result.status_code == '411') {
            console.log('Invalid Number ')
            setAlertMessage(`Invalid Number (${result.status_code})`)
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }
        else if (result.status_code == '416') {
            setAlertMessage(`Sms service temporarily not available. Please try. (${result.status_code})`);
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }
        else if (result == "Network Error") {
            setLoading(false);
            setIsDialogOpen(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Network error. Please retry.(${result})`;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }
        else {
            setLoading(false);
            setIsDialogOpen(true);
            const errorMessage = `Response not recieved  from server-S. Please check your internet connection (${result})`;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
        }

    };


    // let canRefreshOrClose = false; // Flag to control refresh and close actions
    let canRefreshOrClose = true; // Allow refresh and close actions by default
    // Function to handle beforeunload event
    const handleBeforeUnload = (event) => {
        if (!canRefreshOrClose) {
            const message = 'Please do not reload this page, data may be lost.';
            event.returnValue = message;
            return message; // Required for some older browsers   
        }
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Function to enable/disable refresh and close actions
    const toggleRefreshAndClose = (enabled) => {
        canRefreshOrClose = enabled;
    };

    const handleButtonClick1 = async (event) => {

        setLoading(true);
        toggleRefreshAndClose(false); // Prevent refresh and close actions
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.href);
        };
        //    const status = await sessiontime.checkInternetConnection(); // Call the function
        //  setShowChecker(status);

        const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function

        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }

        if (status === 'Poor connection.') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }
        ///  console.log('Session start ');
        const storeSessionId = localStorage.getItem('sessionId');
        try {
            const { sessionId } = await sessiontime.HandleValidatSessiontime(number);
            if (storeSessionId === sessionId) {
                // console.log('SessionId Match ');
                event.preventDefault();
                console.log('Session Validate  ');
                handleButtonClick(event);
                handleGetOTP();
                /// toggleRefreshAndClose(true); // Prevent refresh and close actions
                window.onpopstate = null;
                // navigate('/emailverify', { state: { initialEmail, newName, newAddress, newPhone } });
                toggleRefreshAndClose(true); // Prevent refresh and close actions
                
            } else {
                toggleRefreshAndClose(true); // Prevent refresh and close actions
                setLoading(false);
                setShowAlert(true);
                // // setIsSessionActive(false);
                // alert("Cannot login. Another session is active. Please retry after sometime. ");
                // // console.log('you are logg out ');
                // handleLogout();
            }

        } catch (error) {
            setLoading(false);
            setIsDialogOpen1(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
            setModalMessage1(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
        }
        // } else {
        //     setOnlineStatus(result);
        //     // alert(" No internet connection ");
        // }
    };
    const handleNumberChange = (event) => {
        setNumber(event.target.value);
    }

    const history = useNavigate();
    const handleLogout = () => {
        auth.signOut().then(() => {

            // Redirect to login page after successful logout
            history('/'); // Change '/login' to your login page route
        }).catch((error) => {
            // Handle any errors during logout
            console.error('Error logging out:', error.message);
        })
    }

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const closeDialog = () => {
        window.onpopstate = null;
        setIsDialogOpen(false);
        // window.location.reload(); // This will reload the page
    };

    const [isDialogOpen1, setIsDialogOpen1] = useState(false);
    const closeDialog1 = () => {
        window.onpopstate = null;
        setIsDialogOpen1(false);
        //  navigate('/admindetail');
        //   window.location.reload(); // This will reload the page
    };

    const handleBackButton = () => {
        window.onpopstate = null;
        navigate('/admindetail');
    }


    const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

    const handleCloseAlert = () => {
        setShowAlert(false); // Close alert when clicked on the close button
        handleLogout();
    };




    return (
        <>
            <div>
                <Navbar />
            </div>

            {loading ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}



            <div className='containers'>

                <div className='formgroup'>
                    {/* <div>
                        <h1>Verify Mobile Number & Email-id</h1>
                    </div> */}


                    <div>
                        <label htmlFor="phoneNumber">Mobile Number</label>
                        <input
                            type="text"
                            className='form-control'
                            placeholder="Enter phone number"
                            value={number ? `+91${number}` : '+91'}
                            onChange={handleNumberChange}
                            readOnly
                            disabled
                        />
                        {/* Display any response messages */}

                        <div>{alertMessage}</div>
                    </div>

                    <div>
                        <label htmlFor="email">E-mail</label>
                        <input
                            type="email"
                            className='form-control'
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled
                        />
                        {errorMessage && <p style={{ color: 'red' }} >{errorMessage}</p>}
                    </div>
                    <div className='d-grid col-4' >
                        {/* <button className='btn btn-primary' onClick={handleButtonClick1}>Send OTP</button> */}

                    </div>

                    <div>

                        <div className="d-flex justify-content-center w-100">
                            <button className="btn btn-danger" onClick={handleBackButton} style={{ marginRight: '50px' }}>Back</button>

                            <button className='btn btn-primary' onClick={handleButtonClick1}>Send OTP</button>


                        </div>
                    </div>
                    {/* {!isSessionActive && (
            <div className="alert alert-primary" role="alert">
              Cannot login. Another session is active. Please retry after sometime.
            </div>
          )} */}

                </div>
            </div>
            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
                <Modal.Body>
                    <p> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isDialogOpen1} onHide={closeDialog1} backdrop="static" style={{ marginTop: '3%' }}>
                <Modal.Body>
                    <p> {modalMessage1}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>




            {showAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseAlert}
                        className='AlertDailog'
                    >
                        You have been logged out due to login from another device.!
                    </Alert>
                </div>
            )}





        </>
    )
}

export default Emailsendotp;
