import React, { useState } from 'react';
import { NavLink , useLocation } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import CommonFunctions from '../commonfunction';

const InternetAwareNavLink = ({ to, ...rest }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const internet = new CommonFunctions();
  const handleClick = async (event) => {
    setLoading(true);
    if (!navigator.onLine) {
      event.preventDefault(); // Prevent default navigation behavior
      setLoading(false);
      setShowModal(true); // Show the Bootstrap modal
      return;
    }
    const status = await internet.checkInternetConnection(); // Call the function
    //  setShowChecker(status);
    if (status === 'Poor connection.') {
      setLoading(false)
      setShowModal(true); // Show the Bootstrap modal
      return;
    }


     // Check if the current location is the same as the clicked link
      if (location.pathname === to) {
        window.location.reload(); // Reload the page
      }

      // window.location.reload(); // Reload the page
  };
  const handleClose = () => {
    setShowModal(false);
  } 

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop="static">
        <Modal.Body>
           <p style={{color:'red'}}>No internet. Cannot access server.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <NavLink to={to} {...rest} onClick={handleClick} />



      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}

    </>
  );
};

export default InternetAwareNavLink;
