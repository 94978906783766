import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../adminLogin/navbar';
import { auth } from '../adminLogin/firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import '../meterdetailonserver/meterdetail.css';
import { database } from '../firebase';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CommonFuctions from '../commonfunction';
import PhoneshareIcond from '../Images/logo/phone_share.png'
import HomButton from '../Images/logo/home-button.png'
import {

  FacebookIcon,
  WhatsappIcon,
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,


} from "react-share";




function Ungroup({ highlightedSerial, data, meterList, phoneNumber  , setHighlightGroupName, setSelectedGroup }) {

  const [error, setError] = useState('');
  const [serialOptions, setSerialOptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedGroupData, setSelectedGroupData] = useState([]);
  const [searchExecuted, setSearchExecuted] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState('');
  const [mergedArray, setMergedArray] = useState([]);
  const [user, setUser] = useState(null); // State to hold user information
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSerial, setSelectedSerial] = useState(null)
  let [formettedtime, setFormerttedTime] = useState('');
  let [formettedtimeonmeter, setFormerttedTimeOnMeter] = useState('');
  let [rechargdata, setRechargData] = useState('');
  const [billData, setBillData] = useState({});
  const [kwhData, setKwhData] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reconfig, setReconfig] = useState('');
  const [monthData, setMonthData] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');


  const SessionTime = new CommonFuctions();
  const handleOpenModal = () => {
    setIsLoading(false);
    setModalIsOpen(true);
  };



  const handleCloseModal = () => {
    setBillData([]);
    setKwhData([]);
    setRechargData([]);
    setFormerttedTime([]);
    setFormerttedTimeOnMeter('');
    setMonthData([]);
    setModalIsOpen(false);

    // window.onpopstate = null;

  };



  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is logged in
        setUser(authUser);
        //  console.log("Logged in user:", authUser.email);
        const emailParts = authUser.email.split('@');
        if (emailParts.length === 2) {
        }
      } else {
        setUser(null);
        window.location.href = '/'; // Redirect to your login page
      }
    });
    return () => {
      // setIsLoading(false);
      unsubscribe(); // Cleanup function for unmounting
    };
  }, []);

  const extractSerialNumbers = () => {

    const extractedSerials = Object.values(data).reduce((acc, item) => {
      if (item && typeof item === 'object' && !Array.isArray(item)) {
        const keys = Object.keys(item);
        const filteredKeys = keys.filter((key) => !isNaN(Number(key)));
        acc.push(...filteredKeys.map(serial => ({ serial, name: item[serial].name })));
      }
      //  console.log("return data")
      return acc;
    }, []);
    setSerialOptions(extractedSerials);
  }
  useEffect(() => {
    extractSerialNumbers();
  }, [data]);

  const extractGroupNames = () => {
    if (data) {
      const groupNames = Object.keys(data).filter(key => key !== 'tariff');
      return groupNames;
    }
    return [];
  };

  useEffect(() => {
    // console.log('Group Names:', extractGroupNames());
  }, [data]);


  const [dataLoaded, setDataLoaded] = useState(false); // Track if data is loaded



  useEffect(() => {
    extractSerialNumbers();
    //handleSearch1();
  }, []);


  useEffect(() => {

    const merged = [...serialOptions, ...meterList.map(meterId => ({ serial: meterId }))];
    setMergedArray(merged);
  }, [serialOptions, meterList]);

  useEffect(() => {
    const merged = [...serialOptions, ...meterList.map(meterId => ({ serial: meterId }))];

    const uniqueSerialsMap = new Map();

    merged.forEach(item => {
      if (item.name) {
        // If a serial number already exists in the map, skip adding it (as we only want unique serials with names)
        if (!uniqueSerialsMap.has(item.serial)) {
          uniqueSerialsMap.set(item.serial, item.name); // Store serial number with its name
        }
      } else {
        // If no name is available, check for duplicates and store only one entry
        if (!uniqueSerialsMap.has(item.serial)) {
          uniqueSerialsMap.set(item.serial, null); // Store serial number without a name
        }
      }
    });
    const uniqueSerialsArray = Array.from(uniqueSerialsMap).map(([serial, name]) => ({ serial, name }));
    uniqueSerialsArray.sort((a, b) => a.serial.localeCompare(b.serial));

    setMergedArray(uniqueSerialsArray);
  }, [serialOptions, meterList]);

  const groupMergedArrayByNames = () => {
    const groupedMeters = {};

    mergedArray.forEach(({ serial, name }) => {
      if (name) {
        if (!groupedMeters[name]) {
          groupedMeters[name] = [];
        }
        groupedMeters[name].push(serial);
      }
    });

    return groupedMeters;
  };
  const groupedMeters = groupMergedArrayByNames();

  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;

  };


  const handleSerialClick = async (serial) => {

    setHighlightGroupName(null);
    setSelectedGroup(null);
    // Disable browser interactions


    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);

    };

    toggleRefreshAndClose(false); // Prevent refresh and close actions
    setIsLoading(true);
    
    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server.');
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      setIsLoading(false);
      /// alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
      //  alert('No/Poor Internet connection , Please retry. ftech data from firebase '); // Display the "Poor connection" message in an alert
      //  return;
    }
    const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function

    /// console.log('Call 2 ');

    if (status === 'Poor connection') {
      setIsDialogOpen(true);
      setModalMessage('Poor internet. Cannot access server.');
      setIsLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }


    // console.log('Session Start ');
    const storeSessionId = localStorage.getItem('sessionId');
    try {

      const { sessionId } = await SessionTime.HandleValidatSessiontime(phoneNumber);
      if (storeSessionId === sessionId) {

        SessionTime.updateSessionTimeActiveUser(phoneNumber)

        //console.log('Session Validate ');
        //  SessionTime.updateSessionTimeActiveUser(phoneNumber);
        setRechargData('');
        // handleMeterList(serial);
        //  handleGenerateButton(serial);
        setSelectedSerial(serial);
        //  handleOpenModal();
        /// toggleRefreshAndClose(false); // Prevent refresh and close actions

        try {
          const allserialpath = database.ref(`/adminRootReference/adminDetails/${phoneNumber}/meterList/${serial}`);
          const updateserialPath = allserialpath.toString();
          const serialDataRef = database.ref(`/adminRootReference/meterDetails/${serial}`);
          const UpdatePath = serialDataRef.toString();
          const path = [updateserialPath, UpdatePath];
          const result = await SessionTime.callMultipleReadfunction(path);
          const adminMeterList = JSON.parse(result[0]);
          delete adminMeterList.time;
          const serialData = JSON.parse(result[1]);
          delete serialData.time;

          // 《❎🔥  // meter Configuration:  date and time   🔥❎ 》
          const formattedDate = SessionTime.TimestampConverter(adminMeterList); // Use the utility function
          //  console.log('Formated time ', adminMeterList)

          setFormerttedTime(formattedDate);


          //  《❎🔥 handleGenerateButton Bill data   🔥❎ 》


          if (!serialData || serialData.rechargeToken === undefined) {
            ///  console.error("Serial data or recharge token not available111111");
          }
          else {
            /// console.log("Generate recharge token ", serialData.rechargeToken);
            const rechargeData = serialData.rechargeToken;
            // Convert the object to an array of key-value pairs
            //  console.log("Generate recharge token ", rechargeData);
            const dataArray = Object.entries(rechargeData);
            // Reverse the array
            const reversedArray = dataArray.reverse();
            // Exclude the last element in the reversed array
            const excludedLastElementArray = reversedArray.slice(0, reversedArray.length - 0);
            const reversedObject = Object.fromEntries(excludedLastElementArray);
            // Set the reversed data in your state
            setRechargData(reversedObject);
          }


          //  《❎🔥 handleGenerateButton All Recharge Details  🔥❎ 》


          const billData = serialData.ocData;
          const updatedBillData = serialData.ocData;
          // console.log("Generate recharge token ", updatedBillData);
          setBillData(updatedBillData);

          ///   《❎🔥 Month Data 🔥❎ 》

          // const monthData = Object.entries(serialData.billData);
          // const updatedMonthData = monthData.slice(1);

          //   console.log("Generate recharge token ", updatedMonthData);

          // setMonthData(updatedMonthData);


          // 《❎🔥 Billing History (Last 6 Months)   🔥❎ 》 

          if (serialData && serialData.billData && Object.keys(serialData.billData).length > 0) {
            const monthData = Object.entries(serialData.billData);
            const updatedMonthData = monthData.slice(1);
            //   console.log("Generate recharge token ", updatedMonthData);
            setMonthData(updatedMonthData);
          } else {
            ////console.error("Bill data not available or empty");
          }


          //  《❎🔥 Re-Configaruation Time   🔥❎ 》

          if (serialData && serialData.reConfigToken && serialData.reConfigToken.tokenUsedTime) {
            const reConfigtime = serialData.reConfigToken;
            // console.log("Generate recharge token ", reConfigtime);
            const tokenGeneratedTime = reConfigtime.tokenUsedTime;

            //  reConfigtime data and time 
            const reconfigformattedDate = SessionTime.TimestampConverter(tokenGeneratedTime); // Use the utility function
            setReconfig(reconfigformattedDate);
          } else {
            // console.error("ReConfigToken or tokenUsedTime not available");
          }




          //  《❎🔥 Meter Data update to server:   🔥❎ 》


          //  console.log("First child key under billData:", serialData.billData);

          if (serialData && serialData.billData) {
            const billDataKeys = Object.keys(serialData.billData);

            if (billDataKeys.length > 0) {
              const firstChildKey = billDataKeys[0].split(' ')[1];
              const parsedFirstChildKey = parseInt(firstChildKey); // Parse the input as an integer

              const reconfigformattedDate = SessionTime.TimestampConverter(parsedFirstChildKey); // Use the utility function
              setFormerttedTimeOnMeter(reconfigformattedDate);

            } else {
              // console.log("No keys under billData.");
            }
          } else {
            // console.log("Either serialData or billData is undefined.");
          }


          //  《❎🔥 KwH Data  🔥❎ 》

          //console.log('meter server info:', serialData);
          //   console.log("KwhData", serialData.kwhData);


          setKwhData(serialData.kwhData);

          setIsLoading(false);
          setModalIsOpen(true);
          toggleRefreshAndClose(true); // Prevent refresh and close actions

        } catch (error) {
          setIsDialogOpen(true);
          const errorMessage = `Response not received from server-A. Please retry. .(${error})`;
          setModalMessage(errorMessage);
          toggleRefreshAndClose(true); // Prevent refresh and close actions
          setIsLoading(false);

        }

      } else {

        alert("You have been logged-out due to log-in from another device.");
        // console.log('you are logg out ');
        handleLogout();
      }

    } catch (error) {

      setIsDialogOpen(true);
      const errorMessage = `Response not received from server-S. Please retry. (${error})`;
      setModalMessage(errorMessage);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      setIsLoading(false);
    }
  };


  const handleMeterList = async (serial) => {
    // console.log('phone number from meterDetails ', phoneNumber);
    try {

      const serialDataRef = database.ref(`/adminRootReference/adminDetails/${phoneNumber}/meterList/${serial}`);
      const snapshot = await serialDataRef.once('value');
      const serialData1 = snapshot.val();
      // console.log('meter server info 111:', serialData1);
      setIsLoading(false);
      /// toggleRefreshAndClose(false); // Prevent refresh and close actions

      const serialData = parseInt(serialData1); // Parse the input as an integer
      if (isNaN(serialData) || serialData < 0) {
        // console.error("Invalid timestamp");
      } else {
        const timestampDate = new Date(serialData);

        if (isNaN(timestampDate.getTime())) {
          // console.error("Invalid date");
        } else {
          const year = timestampDate.getFullYear().toString().slice(2);
          const month = ('0' + (timestampDate.getMonth() + 1)).slice(-2);
          const day = ('0' + timestampDate.getDate()).slice(-2);
          const hours = ('0' + timestampDate.getHours()).slice(-2);
          const minutes = ('0' + timestampDate.getMinutes()).slice(-2);
          const seconds = "00";
          const formattedDate = `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}.`;

          ///  console.log("Formatted Time: ", formattedDate);

          setFormerttedTime(formattedDate);
        }
      }

    } catch (error) {
      console.error('Error fetching meter data:', error);
      // Handle error (e.g., show an error message to the user)
    }
  }



  const handleGenerateButton = async (serial) => {

    try {
      // Ensure that serial is a string
      if (typeof serial !== 'string') {
        //   console.error("Invalid serial value. It must be a string.");
        return;
      }
      const serialDataRef = database.ref(`/adminRootReference/meterDetails/${serial}`);
      const snapshot = await serialDataRef.once('value');
      const serialData = snapshot.val();
      /// console.log("Serial dat ", serialData);
      //   console.log("Serial data rechargr  ", serialData.rechargeToken);
      if (!serialData || serialData.rechargeToken === undefined) {
        // console.error("Serial data or recharge token not available111111");
        return;
      }
      //  console.log("Generate recharge token ", serialData.rechargeToken);
      const rechargeData = serialData.rechargeToken;
      // Convert the object to an array of key-value pairs
      const dataArray = Object.entries(rechargeData);
      // Reverse the array
      const reversedArray = dataArray.reverse();
      // Exclude the last element in the reversed array
      const excludedLastElementArray = reversedArray.slice(0, reversedArray.length - 0);

      // Convert the excluded last element array back to an object
      const reversedObject = Object.fromEntries(excludedLastElementArray);

      // Set the reversed data in your state
      setRechargData(reversedObject);
    } catch (error) {
      // console.error("Error fetching or processing data:", error.message);
    }
  };


  const [tokenStatus, setTokenStatus] = useState([]);
  const isTokenAvailable = async (serialNumber, index) => {
    try {
      const meterDetailsPath = firebase.database().ref(`adminRootReference/meterDetails/${serialNumber}/reConfigToken`);
      const snapshot = await meterDetailsPath.once('value');
      const newData = snapshot.val();


      if (!newData) {
        // console.log(`Data not found for serial number ${serialNumber}`);
        return;
      }
      const isTransfer = newData.isTransfer;
      const token = newData.token;
      const tokenStatus = newData.tokenStatus;


      let status;
      if (tokenStatus === '0' && isTransfer === 'false') {
        status = "N";
      } else if (tokenStatus === '0' && isTransfer === 'true') {
        status = "T";
      } else {
        status = "";
      }

      setTokenStatus(prevState => ({
        ...prevState,
        [serialNumber]: status
      }));
    } catch (e) {
      console.log('Error Fetching:', e);
    }
  };

  // useEffect(() => {
  //   mergedArray.forEach(({ serial }, index) => {
  //     isTokenAvailable(serial, index);
  //   });
  // }, [mergedArray]);

  const history = useNavigate();
  const handleLogout = () => {
    auth.signOut().then(() => {
      // Redirect to login page after successful logout
      localStorage.removeItem('LoggIn', 'true');

      history('/'); // Change '/login' to your login page route
    }).catch((error) => {
      // Handle any errors during logout
      console.error('Error logging out:', error.message);
    })

    //// history('/'); // Change '/login' to your login page route
  }

  const SessionUpdate = (numberPart) => {
    SessionTime.updateSessionTimeActiveUser(numberPart);
  }




  const handleSharePhone = () => {
    console.log('Share Via Phone ', selectedSerial);
  }


  const closeDialog = () => {
    setIsDialogOpen(false);
    //  window.onpopstate = null;

    // window.location.reload(); // This will reload the page
  };


  const serialRefs = useRef({});

  useEffect(() => {
    if (highlightedSerial && serialRefs.current[highlightedSerial]) {
      const topOffset = 0.35 * window.innerHeight;
      const elementTop = serialRefs.current[highlightedSerial].getBoundingClientRect().top;
      window.scrollTo({
        top: elementTop + window.pageYOffset - topOffset,
        behavior: 'smooth'
      });
    }

  }, [highlightedSerial]);


  return (
    <>

      {isLoading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}
      <>

        <Modal show={modalIsOpen} onHide={handleCloseModal} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Meter Detials on server </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {
                selectedSerial ? ( // If selectedSerialInfo.serial is truthy
                  <div>
                    <h4 style={{ color: 'DodgerBlue' }}>Meter Details</h4>
                    <p>Serial: {selectedSerial || 'Not Available'}</p>
                    <p>Available Balance (₹): {kwhData && kwhData.balance !== undefined ? kwhData.balance : 'Not Available'}</p>
                    <p>TotalKwh (₹): {kwhData && kwhData.totalKwh !== undefined ? kwhData.totalKwh : 'Not Available'}</p>
                    <p>Earth Tamper Kwh (₹): {kwhData && kwhData.tamperKwh !== undefined ? kwhData.tamperKwh : 'Not Available'}</p>
                    <p>Configuration: {formettedtime && formettedtime !== undefined ? formettedtime : 'Not Available'}</p>
                    <p>Last Re-Configuration: {reconfig || 'Not Available'}</p>
                    <p>Current Tariff (₹) : {selectedSerial.tariffRate || 'Not Available'} </p>
                    <p>Location: {selectedSerial.location || 'Not Available'}</p>
                    <p>Meter Data update to server: {formettedtimeonmeter && formettedtimeonmeter !== undefined ? formettedtimeonmeter : 'Not Available'}</p>
                  </div>
                ) : (
                  <p>not available</p> // If selectedSerialInfo.serial is falsy
                )
              }



              <hr />

              <div>
                <h4 style={{ color: 'DodgerBlue' }}> Present Consumer Details</h4>


                <label htmlFor='groupName'>GroupName</label>
                <input
                  type='text'
                  className='form-control'
                  value={"Not Available "}
                  disabled
                  readOnly
                />


                <label htmlFor="name">Name </label>
                <input
                  type="text"
                  id="name"
                  className='form-control'
                  name="name"
                  value={"Not Available "}
                  placeholder="Name"

                  readOnly
                  disabled

                />
                <label htmlFor="mobile">Mobile Number  </label>
                <input
                  type="tel"
                  id="mobile"
                  className='form-control'
                  name="mobile"
                  value={"Not Available "}
                  placeholder="Mobile"
                  readOnly
                  disabled
                />

                <label htmlFor="mobile">Alternet Mobile Number  </label>
                <input
                  type="tel"
                  id="mobile"
                  className='form-control'
                  name="mobile"
                  value={"Not Available "}
                  readOnly
                  disabled
                />
                <label htmlFor="email">Email </label>
                <input
                  type="email"
                  id="email"
                  className='form-control'
                  name="email"
                  placeholder="Email"
                  value={"Not Available "} readOnly
                  disabled

                />
                <label htmlFor="doo">Date of Occupancy  </label>
                <input
                  type="text"
                  className='form-control'
                  id="doo"
                  name="doo"
                  value={"Not Available "}
                  readOnly
                  disabled

                />





              </div>

              <hr />

              <div>
                <h4 style={{ color: 'DodgerBlue' }}>Generated Recharge Token History (last 20 Tokens)</h4>
                {rechargdata ? (
                  Object.entries(rechargdata).map(([timestamp, billDetails]) => (

                    timestamp !== "tokenCount" && (
                      <div key={timestamp}>


                        {billDetails.tokenId === '00' && (
                          <p style={{ backgroundColor: 'green', color: 'white', width: '40%' }}>Emergency Recharge</p>
                        )}
                        <p>Token ID: {billDetails.tokenId}</p>
                        <p>Generated Time : {billDetails.tokenGenerationTime}</p>
                        <p>Amount : {billDetails.rechargeAmount}</p>
                        <p>tariff: {billDetails.tariffRate}</p>
                        <p>Cumulative Kwh: {billDetails.kwh}</p>
                        <p>Balance at the time of recharge: {billDetails.balance}</p>

                        <p>Share Token Link </p>

                        <FacebookShareButton style={{ margin: '10px' }} url={billDetails.link}>
                          <FacebookIcon size={35} round={true} url={billDetails.link} />
                        </FacebookShareButton>

                        <EmailShareButton style={{ margin: '10px' }} url={billDetails.link}>
                          <img width="38" height="38"
                            src="https://img.icons8.com/fluency/48/gmail-new.png"
                            url={billDetails.link}
                            alt="gmail-new"
                          />
                        </EmailShareButton>

                        <WhatsappShareButton style={{ margin: '10px' }} url={billDetails.link}>
                          <WhatsappIcon size={35} round={true} url={billDetails.link} />
                        </WhatsappShareButton>

                        <img width="38" height="38"

                          src={PhoneshareIcond}
                          // url={billDetails.link}
                          alt="Phone send "
                          onClick={handleSharePhone}
                        />




                        <hr></hr>
                      </div>

                    )
                  ))
                ) : (
                  <p>Not available</p>
                )}
              </div>

              <hr></hr>

              <div>
                <h4 style={{ color: 'DodgerBlue' }}>Over Credit Token History (last 20 Tokens)</h4>
                {billData ? (
                  Object.entries(billData).map(([timestamp, billDetails]) => (
                    <div key={timestamp}>
                      {/* Display specific serial data properties in separate <p> tags */}
                      <h5 style={{ backgroundColor: 'pink', color: 'black' }}>Over Credit Recharge</h5>
                      <p>Timestamp: {timestamp}</p>
                      <p>Token ID: {billDetails.tokenId}</p>
                      <p>Generated Time: {billDetails.tokenGenerationTime}</p>
                      <p>Amount: {billDetails.rechargeAmount}</p>
                      <p>tariff: {billDetails.tariffRate}</p>
                      <p>Cumulative Kwh: {billDetails.Kwh}</p>
                      <p>Balance at the time of recharge: {billDetails.balance}</p>
                    </div>
                  ))
                ) : (
                  <p>Not available</p>
                )}
                <hr></hr>
                <div>
                  <div>
                    <h4 style={{ color: 'DodgerBlue' }}>Billing History (Last 6 Months)</h4>
                    {monthData.length > 0 ? (
                      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                          <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                            <th style={{ padding: '8px', textAlign: 'left' }}>Month</th>
                            <th style={{ padding: '8px', textAlign: 'left' }}>Consumed Kwh</th>
                            <th style={{ padding: '8px', textAlign: 'left' }}>MD (Kw)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {monthData.map(([key, value], index) => (
                            <tr key={key} style={{ borderBottom: '1px solid #ddd' }}>
                              <td style={{ padding: '8px', textAlign: 'left' }}>{value.billKwh !== '-' ? key.substring(1) : null} </td>
                              <td style={{ padding: '8px', textAlign: 'left' }}>{value.billKwh !== '-' ? `${value.billKwh} ` : null}</td>
                              <td style={{ padding: '8px', textAlign: 'left' }}>{value.billMd && value.billMd !== '-' ? `${value.billMd} ` : null}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>Not available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>


          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button onClick={handleCloseModal}>Close </Button>
          </Modal.Footer>

        </Modal>

        <div className='MainClass'>
          <div>
            {meterList.length === 0 ? (
              <h5 style={{ backgroundColor: '#e3f2fd', display: 'inline', padding: '3px', }}>Note : Ungroup Meter is not available</h5>
            ) : (
              <h5 style={{ backgroundColor: '#e3f2fd', display: 'inline', padding: '3px', }}>Meter(s) without consumer details</h5>
            )}
          </div>
          <div className="rowContainer">
            {meterList.map((serial, index) => (
              <div key={index}
                className={`customBox ${highlightedSerial === serial ? 'highlight' : ''}`}
                ref={(el) => (serialRefs.current[serial] = el)}
                onClick={() => handleSerialClick(serial)}>
                <div style={{ display: 'flex' }}>
                  <p style={{ marginRight: '25px' }}>{serial}</p>
                 
                </div>
              </div>
            ))}
          </div>

        </div>

      </>


      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static">
        <Modal.Body>
          <p style={{ color: 'red' }}> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>

  );
}

export default Ungroup;
