import React, { useState, useEffect } from 'react';

const ResendTimer = ({ onClick }) => {
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const [resendDisabled, setResendDisabled] = useState(true); // Initially enable the resend button

  useEffect(() => {
    // Timer logic to decrement timer value every second
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000); // Runs every second

    // Clear interval when the timer reaches 0 or the component unmounts
    return () => clearInterval(countdown);
  }, [timer]);

  // Effect to enable the resend button after 1 minute
  useEffect(() => {
    if (timer === 0) {
      setResendDisabled(false); // Enable the resend button
    }
  }, [timer]);

  const handleResend = () => {
    // Logic to handle resending OTP
    setResendDisabled(true); // Disable the resend button
    // Simulate OTP sending (setTimeout is used here, replace it with actual OTP sending logic)
    onClick();
    setTimeout(() => {
      // After 1 minute, enable the resend button
      setResendDisabled(false);
      // Reset the timer to `10` seconds
      setTimer(60);
    }, 60000); // 1 minute in milliseconds
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-outline-primary btn-sm"
        onClick={handleResend}
        disabled={resendDisabled}
      >
        {timer > 0 ? `Resend ${timer}` : 'Resend OTP'}
      </button>
    </div>
  );
};

export default ResendTimer;
