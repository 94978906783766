import React, { useEffect, useState } from 'react';
import { auth } from './firebase'; // Ensure this import corresponds to your Firebase setup
import Navbar from './navbar';
import { useNavigate } from 'react-router-dom';
import 'sweetalert2/dist/sweetalert2.min.css';


function Welcome() {
 
  const history = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Home"; // Set the title when the component mounts

  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is signed in
        setUser(authUser); // Set the user state
        setLoading(false); // Set loading to false when data is fetched
        // console.log("User is logged in");
        // Handle signed-in user
        // const number = authUser.email.split('@')[0];
    
        // SessionValidate(number);
        //  console.log("user number is ", number);
      } else {
        // User is signed out
        //  console.log("User is logged out");
        setUser(null); // Set the user state to null when signed out
        setLoading(false); // Set loading to false when data is fetched
        // Handle signed-out user
       
      }
    });
    // Clean-up function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  //   const sessionId = localStorage.getItem('sessionId');
  // console.log('Retrieved sessionId:', sessionId);

  
  // const handleLogout = () => {
  //   auth.signOut().then(() => {
  //     // Redirect to login page after successful logout
  //     history('/'); // Change '/login' to your login page route
  //   }).catch((error) => {
  //     // Handle any errors during logout
  //     console.error('Error logging out:', error.message);
  //   });
  // };

  

  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, null, window.location.href);
  };

  // const SessionValidate = async (PhoneNumber) => {
  //   const storeSessionId = localStorage.getItem('sessionId');
  //   const { sessionId } = await sessiontime.HandleValidatSessiontime(PhoneNumber);
  //   if (storeSessionId === sessionId) {
  //     console.log('SessionId Match ');
  //   } else {
  //     alert("Cannot login. Another session is active. Please retry after sometime. ");
  //     console.log('you are logg out ');
  //     handleLogout();
  //   }
  // };



//   const handleAlert =()=>{
//    Swal.fire({
//     text: 'This is a SweetAlert2 popup with a backdrop!',
//     icon: 'error',
//     confirmButtonText: 'OK',
//     position: 'top',
//     backdrop: false // or 'rgba(0,0,0,0.5)' for a custom background color
// });
//   }

  return (
    <>


      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}
      
      {user ? (
        <>
          <div >

          <Navbar /> 


          </div>
          <div style={{ marginTop: '10%' , marginLeft:'10%'}}>

            {/* <div>
              <button  onClick={handleAlert}> Alert </button>
            </div> */}
          </div>




        </>
      ) : (
        <div>

        </div>
      )}
    </>
  );
}

export default Welcome;
