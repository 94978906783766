import React, { useEffect, useState } from 'react';
import { auth } from '../adminLogin/firebase';
import { useLocation, useNavigate } from 'react-router-dom';

import firebase from 'firebase/compat/app'; // Import the Firebase app
import 'firebase/compat/firestore';
import Generatetoken from './generatetokenkey';
import CommonFuctions from '../commonfunction';
import Navbar from '../adminLogin/navbar';
import { Modal, Button, } from 'react-bootstrap';
import PhoneSendOtp from '../adminLogin/phonesendotp';
import ResendTimer from '../adminLogin/resendotp'
import EmailSendotp from '../admin/email'

import Alert from '@mui/material/Alert';


const allSerialNo = [];

function Passwordotp() {
    const SessionTime = new CommonFuctions();
    const [user, setUser] = useState(null);
    const [mobileOTP, setMobileOTP] = useState('');
    const [emailOTP, setEmailOTP] = useState('');
    const [mobileOTPError, setMobileOTPError] = useState('');
    const [emailOTPError, setEmailOTPError] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [adminKey, setAdminKey] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    /// const { enteredPasswordModal } = location.state || {};

    const enteredPasswordModal = localStorage.getItem('Transferpassword');

    const mainFunction = new Generatetoken();
    let newPassword = enteredPasswordModal;
    const [adminEmail, setAdminEmail] = useState('');

    const { message } = location.state || {};


    const { handleButtonClick } = EmailSendotp({ email: adminEmail }); // Pass the email as a prop


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                // User is logged in
                setUser(authUser);
                //  console.log("Logged in user:", authUser.email);
                const emailParts = authUser.email.split('@'); // Split email by '@'
                if (emailParts.length === 2) {
                    const number = emailParts[0]; // Get the part before '@'
                    //    console.log("Extracted number:", number);
                    setPhoneNumber(number);
                    getAdminPassword(number);
                    SessionTime.updateSessionTimeActiveUser(number);

                    fetchdata(number);
                    //   getAllSerial(number);
                }
            } else {
                // No user is logged in, you can redirect to another page or handle accordingly
                setUser(null);
                // Example: Redirect to another page
                window.location.href = '/'; // Redirect to your login page
            }
        });

        return () => unsubscribe(); // Cleanup function for unmounting
    }, []);

    const [parsedData, setParsedData] = useState('');
    const fetchdata = async (number) => {

        const FetchAdmin = firebase.database().ref(`adminRootReference/adminDetails/${number}`);
        const Admindetialsref = FetchAdmin.toString();
        try {
            const result = await SessionTime.callCloudFunction(Admindetialsref);
            const parsedData = JSON.parse(result);
            setParsedData(parsedData);
        } catch (error) {
            //   console.log('Data Not fect from FDB');
        }
    }






    // let canRefreshOrClose = false; // Flag to control refresh and close actions
    let canRefreshOrClose = true; // Allow refresh and close actions by default
    // Function to handle beforeunload event
    const handleBeforeUnload = (event) => {
        if (!canRefreshOrClose) {
            const message = 'Please do not reload this page, data may be lost.';
            event.returnValue = message;
            return message; // Required for some older browsers   
        }
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Function to enable/disable refresh and close actions
    const toggleRefreshAndClose = (enabled) => {
        canRefreshOrClose = enabled;

    };


    const handleSubmitClick = async (e) => {
        if (mobileOTP === '') {
            setMobileOTPError('Invalid mobile OTP');
            return;
        }
        if (emailOTP === '') {
            setMobileOTPError('Invalid mobile OTP');
            return;
        }
        e.preventDefault(); // Prevent default form submission
        const storedPhoneOTP = localStorage.getItem('otp'); // Get stored phone OTP
        const storedEmailOTP = localStorage.getItem('emailOTP'); // Get stored email OTP

        if (mobileOTP !== storedPhoneOTP) {
            setMobileOTPError('Invalid mobile OTP');
        } else if (emailOTP !== storedEmailOTP) {
            setEmailOTPError('Invalid email OTP');
        } else {
            // Both OTPs are valid, proceed with the submission
            // You can also reset any existing errors here, if needed
            setMobileOTPError('');
            setEmailOTPError('');

            // Proceed with the submission
        }

        if (mobileOTP === storedPhoneOTP && emailOTP === storedEmailOTP) {
            // console.log('Admin phone number ', phoneNumber);
            //   console.log('new Phone  ',);

            setLoading(true);
            toggleRefreshAndClose(false); // Prevent refresh and close actions
            // Disable browser interactions
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, null, window.location.href);
            };
            const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function

            if (!navigator.onLine) {
                setIsDialogOpen(true);
                setModalMessage('No internet. Cannot access server.');
                setLoading(false);
                return;
            }

            if (status === 'Poor connection') {
                setIsDialogOpen(true);
                setModalMessage('Poor internet. Cannot access server.');
                setLoading(false);
                toggleRefreshAndClose(true); // Prevent refresh and close actions

                return;
            }
            // console.log('Validate  OTP : ');

            const storeSessionId = localStorage.getItem('sessionId');
            try {
                const { sessionId } = await SessionTime.HandleValidatSessiontime(phoneNumber);
                if (storeSessionId === sessionId) {

                    handleUpdatePassword();

                } else {


                    // alert("You have been logged-out due to log-in from another device.");
                    // /// console.log('you are logg out ');
                    // handleLogout();
                }

            } catch (error) {
                setLoading(false);
                setIsDialogOpen(true);
                const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
                setModalMessage(errorMessage);
            }

        } else {

        }



    };

    // old phone number and new Password

    const getAdminPassword = async (numberPart) => {

        const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function

        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setLoading(false);
            return;
        }

        if (status === 'Poor connection') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions

            return;
        }

        try {
            const FetchAdmin = firebase.database().ref(`/adminRootReference/adminDetails/${numberPart}/adminProfile`);
            const Admindetialsref = FetchAdmin.toString();
            const result = await SessionTime.callCloudFunction(Admindetialsref);
            const fetchedPassword = JSON.parse(result);
            delete fetchedPassword.time;

            // console.log("admin key :", fetchedPassword.key);
            setAdminKey(fetchedPassword?.key);
           // setOldPassword(fetchedPassword?.password);

            setAdminEmail(fetchedPassword?.email);
            // console.log("Admin key : ", fetchedPassword?.password)
            // setLoading(false);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            setIsDialogOpen(true);
            const errorMessage = `Response not recieved  from server-A. Please check your internet connection .(${error})`;
            setModalMessage(errorMessage);
        }

    };

    let allMeterDetailsGlobal = {}; // Define a global variable to store all meter details

    const getAllSerial = async (phoneNumber) => {

        const FetchAdmin = firebase.database().ref(`adminRootReference/adminDetails/${phoneNumber}/meterList`);
        const Admindetialsref = FetchAdmin.toString();
        try {
            const data = await SessionTime.callCloudFunction(Admindetialsref);
            const parsedData = JSON.parse(data);
            delete parsedData.time;
            ///  console.log("All Serial Number: ", parsedData);
            return parsedData; // Return the parsed data
        } catch (error) {
            ///  console.error('Error fetching meter details:', error);
            throw error; // Throw error to handle it outside the function
        }

    }

    const handlePhoneAllSerial = async (phoneNumber) => {

        const storeSessionId = localStorage.getItem('sessionId');
        try {
            const { sessionId } = await SessionTime.HandleValidatSessiontime(phoneNumber);
            if (storeSessionId === sessionId) {

                const FetchAdmin = firebase.database().ref(`adminRootReference/adminDetails/${phoneNumber}/meterList`);
                const Admindetialsref = FetchAdmin.toString();
                try {
                    const data = await SessionTime.callCloudFunction(Admindetialsref);
                    const parsedData = JSON.parse(data);
                    delete parsedData.time;

                    // Clear the global variable before populating it with new data
                    allMeterDetailsGlobal = {};

                    // Use Promise.all with map to await all async operations and collect results
                    await Promise.all(Object.keys(parsedData).map(async (serial) => {
                        // const meterDetailsPath = firebase.database().ref(`adminRootReference/meterDetails/${serial}`);

                        const FetchAdmin = firebase.database().ref(`adminRootReference/meterDetails/${serial}`);
                        const Admindetialsref = FetchAdmin.toString();
                        const data = await SessionTime.callCloudFunction(Admindetialsref);
                        const meterDetails = JSON.parse(data);
                        delete meterDetails.time;
                        // const snapshot = await meterDetailsPath.once('value');
                        // const meterDetails = snapshot.val();
                        allMeterDetailsGlobal[serial] = meterDetails; // Store meter details by serial number
                    }));

                    ///  console.log('All meter details:', allMeterDetailsGlobal); // Print all meter details after all async operations complete
                    return allMeterDetailsGlobal; // Return all meter details object
                } catch (error) {
                    ///  console.error('Error fetching meter details:', error);
                    throw error; // Throw error to handle it outside the function
                }

            } else {
                //   alert("You have been logged-out due to log-in from another device.");
                /// console.log('you are logg out ');
                //  handleLogout();
            }

        } catch (error) {
            setLoading(false);
            setIsDialogOpen(true);
            const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
            setModalMessage(errorMessage);
        }

    }


    const handleUpdatePassword = async () => {

        const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function
        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setLoading(false);
            return;
        }

        if (status === 'Poor connection') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions

            return;
        }


        //console.log("CHange password is ",newPassword);
        // console.log("Start function to create token ");


        const storeSessionId = localStorage.getItem('sessionId');
        try {
            const { sessionId } = await SessionTime.HandleValidatSessiontime(phoneNumber);
            if (storeSessionId === sessionId) {

              //  console.log('admin key :', adminKey);
                try {
                    const allMeterDetailsGlobal = await handlePhoneAllSerial(phoneNumber);
                    let tokenDataList = []; // Array to store token data for all meters
                    const mytime = await mainFunction.fireabseServerTimestamp();
                    let countGenerated = 0; // Initialize count of generated tokens
                    let countNotGenerated = 0; // Initialize count of not generated tokens

                    for (const serial of Object.keys(allMeterDetailsGlobal)) {
                        const reConfigToken = allMeterDetailsGlobal[serial]?.reConfigToken;

                        if (reConfigToken && reConfigToken.tokenStatus === "0" && reConfigToken.isTransfer === "true") {

                            countNotGenerated++;
                        }
                        else {
                            let type = '04';
                            // const TransferToken = mainFunction.isTransferToken(type, phonenumberlist, serial, transferPassword, transferKey, adminKey);
                            const updatePassword = await mainFunction.updatePassword(
                                mytime,
                                type,
                                phoneNumber,
                                serial,
                                newPassword,
                                adminKey
                            );
                            ///   console.log("Token for all meter :  ", updatePassword);
                            countGenerated++;
                            tokenDataList.push({ serial: serial, token: updatePassword }); // Store generated token 

                        }
                    }


                    const allTokensGenerated = tokenDataList.every(tokenData => tokenData.token);
                    if (countGenerated > 0 && allTokensGenerated) {
                        let dataToSend = {}; // Object to store data to be uploaded
                        for (const tokenData of tokenDataList) {
                            const serial = tokenData.serial;
                            const token = tokenData.token;
                            const updateToken = {
                                isTransfer: "false",
                                token: token, // Use the token from tokenData
                                tokenGeneratedTime: mytime,
                                tokenStatus: "0",
                                tokenUsedTime: "null",
                                transferPhoneNumber: "null",
                            };
                            dataToSend[serial] = updateToken;
                        }
                        const data = {
                            tokenDetails: dataToSend, // Use dataToSend instead of tokenDetails
                            newPassword: newPassword,
                            isDelete: false,
                        };
                        try {

                            //  console.log('Data to be uploaded:', data); // Print the data object
                            //   console.log('Generated Count : ', countGenerated);

                            await SessionTime.reConfigPassword(data);
                            SessionTime.updateSessionTimeActiveUser(phoneNumber);

                            let errorMessage;
                            if (countNotGenerated === 0) {
                                errorMessage = `Re-Configuration token (Normal) generated for ${countGenerated} meter(s).
                \n Your login credentials have been changed. You have been logged out.`;
                            } else {
                                errorMessage = `Re-Configuration token (Normal) generated for ${countGenerated} meter(s). Token not generated for ${countNotGenerated} meter(s)
                as transfer token is pending these meter(s).
                \n You have been logged out.`;
                            }

                            setLoading(false);
                            setIsDialogOpenResponse(true);
                            toggleRefreshAndClose(true); // Prevent refresh and close actions
                            setModalMessageResponse(errorMessage);

                        }
                        catch (error) {
                            setLoading(false);
                            setIsDialogOpenResponse(true);
                            const errorMessage = `Response not received from server-A. Please check if transaction completed successfully, else retry. (${error}).`;
                            setModalMessageResponse(errorMessage);
                            toggleRefreshAndClose(true); // Prevent refresh and close actions

                        }

                    } else {


                        // errorMessage = `Error: No tokens were generated successfully or no data to upload. Count of not generated tokens: ${countNotGenerated}`;
                        const errorMessage = `Re-Configuration token (Normal) generated for ${countGenerated} meter(s). Token not generated for ${countNotGenerated} meter(s)
            as transfer token is pending these meter(s).
            \n You have been logged out.`;

                        setLoading(false);
                        setIsDialogOpenResponse(true);
                        setModalMessageResponse(errorMessage);
                        toggleRefreshAndClose(true); // Prevent refresh and close actions

                        // const errorMessage = `Transefer token pending for ${countNotGenerated} meter(s).
                        // Can not generate any Re-Configuration token. Either delete pending tranfer token(s) or 
                        // Re-Configure meter(s) with peding transfer token(s). You have been logged out.`


                    }
                } catch (error) {
                    setLoading(false);
                    setIsDialogOpen(true);
                    const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
                    setModalMessage(errorMessage);
                    toggleRefreshAndClose(true); // Prevent refresh and close actions
                }
            } else {

                toggleRefreshAndClose(true); // Prevent refresh and close actions
                setLoading(false);
                setShowAlert(true);

            }
        } catch (error) {
            setLoading(false);
            setIsDialogOpen(true);
            const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions


        }
    };

    const handleLogout = () => {
        // auth.signOut().then(() => {
        //     // Redirect to login page after successful logout
        //     localStorage.clear();
        //     history('/'); // Change '/login' to your login page route
        // }).catch((error) => {
        //     // Handle any errors during logout
        //     console.error('Error logging out:', error.message);
        // })

    }

    const handleMobileOTPChange = (e) => {
        setotpSendMessage('');
        const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setMobileOTP(input);
        setMobileOTPError('');
    };

    const handleEmailOTPChange = (e) => {
        setotpSendMessage('');
        const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setEmailOTP(input);
        setEmailOTPError('');
    };



    const SessionUpdate = () => {
        SessionTime.updateSessionTimeActiveUser(phoneNumber);
    }
    const [modalMessage, setModalMessage] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const closeDialog = () => {
        setIsDialogOpen(false);
        window.onpopstate = null;
    };

    const [modalMessageResponse, setModalMessageResponse] = useState('');
    const [isDialogOpenResponse, setIsDialogOpenResponse] = useState(false);

    const closeDialogResponse = () => {
        setIsDialogOpenResponse(false);
        window.onpopstate = null;
        window.location.reload(); // This will reload the page
        // handleLogout();
    };



    const [otpSendMessage, setotpSendMessage] = useState('');

    const resendOtp = async () => {

        let phoneSuccess = false;
        let emailSuccess = false;
        setLoading(true);
        const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function

        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setLoading(false);
            return;
        }

        if (status === 'Poor connection') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setLoading(false);

            return;
        }

        // Send OTP to phone
        // try {
        const phoneSendOtp = new PhoneSendOtp(phoneNumber);
        const result = await phoneSendOtp.sendOTP(phoneNumber);

        if (result == '200') {
            phoneSuccess = true;
        }
        else if (result.status_code == '411') {
            //  console.log('Invalid Number ')
            setMobileOTPError(` Unable to send sms. Please check mobile no. (${result.status_code})`)
            setLoading(false);
            return;
        }
        else if (result.status_code == '416') {
            setMobileOTPError(`)SMS service is temporarily unavailable. Please retry. (${result.status_code}) `);
            setLoading(false);
            return;
        }

        else if (result == "Network Error") {
            setLoading(false);
            // const errorMessage = `Network error. Please retry(${error}). `;
            const errorMessage = `Network error. Please retry. (${result})`;
            setMobileOTPError(errorMessage);
            return;
        }
        else {
            setLoading(false);
            const errorMessage = `SMS service is temporarily unavailable. Please retry. (${result})`;
            setMobileOTPError(errorMessage);
        }


        //     if (phoneResult === 411) {
        //         setMobileOTPError(`Invalid phone number: (${phoneResult})`);
        //     } else {
        //         phoneSuccess = true;
        //     }
        // } catch (phoneError) {
        //     // console.error('Error sending OTP to phone:', phoneError);
        // }


        // Send OTP to email
        try {
            const emailResult = await handleButtonClick(adminEmail);
            if (typeof emailResult === 'boolean') {
                if (emailResult) {
                    emailSuccess = true;
                    /// console.log('OTP sent to email successfully.');
                } else {
                    setEmailOTPError('Error sending OTP to email. Please try again.');
                }
            } else {
                ///console.error('Error:', emailResult);
            }
        } catch (emailError) {
            // console.error('Error sending OTP to email:', emailError);
        }

        // Check if both OTPs were sent successfully
        if (phoneSuccess && emailSuccess) {
            setotpSendMessage(' OTP sent successfully.');
        } else {
            setEmailOTPError('OTP sending failed.');
        }
    };

    const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

    const handleCloseAlert = () => {
        setShowAlert(false); // Close alert when clicked on the close button
        //  handleLogout();
    };



    return (
        <>

            <div>

                <Navbar />

            </div>

            {loading ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}


            <div className='containers'>
                <div className='formgroup'>
                    <div>

                        <label htmlFor="mobileOTP">Enter Mobile OTP</label>
                        <input
                            type="text"
                            className='form-control'
                            placeholder=" Mobile OTP"
                            value={mobileOTP}
                            onChange={handleMobileOTPChange}
                            maxLength={6}
                        />
                        {mobileOTPError && <p style={{ color: 'red' }} className="error">{mobileOTPError}</p>}
                    </div>
                    <div>
                        <label htmlFor="emailOTP">Enter E-mail OTP</label>
                        <input
                            type="text"
                            className='form-control'
                            placeholder=" E-mail OTP"
                            value={emailOTP}
                            onChange={handleEmailOTPChange}
                            maxLength={6}
                        />
                        <p style={{ color: 'red' }} className="error">{emailOTPError}</p>
                        <p style={{ color: 'blue' }}  >{otpSendMessage}</p>

                        {mobileOTP && emailOTP && <p style={{ color: 'blue' }}>{message}</p>}
                    </div>
                    <div className='d-flex justify-content-center w-100'>
                        <button type="submit" className='btn btn-primary' style={{ marginRight: '50px' }} onClick={handleSubmitClick}>
                            VERIFY
                        </button> <div>
                            <ResendTimer onClick={resendOtp} />
                        </div>

                    </div>
                </div>
            </div>


            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>

                <Modal.Body>
                    <p> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isDialogOpenResponse} onHide={closeDialogResponse} backdrop="static" style={{ marginTop: '3%' }}>
                <Modal.Body>
                    <p style={{ color: 'red' }}> {modalMessageResponse}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialogResponse}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {showAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseAlert}
                        className='AlertDailog'
                    >
                        You have been logged out due to login from another device.!
                    </Alert>
                </div>
            )}




        </>
    )
}

export default Passwordotp