import React, { useState, useEffect } from 'react'
import Phonepasswordchange from '../reconfigtoken/phonepasswordchange'
import { auth } from '../adminLogin/firebase';
import { database } from '../firebase';
import firebase from 'firebase/compat/app'; // Import the Firebase app (latest version)
import 'firebase/compat/database';
import { useNavigate } from 'react-router-dom';
import CommonFuctions from '../commonfunction';
import '../adminLogin/login.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import Alert from '@mui/material/Alert';
import Dropdown from 'react-bootstrap/Dropdown';



function ChangeMobile({data}) {

    const navigate = useNavigate();
    const SessionTime = new CommonFuctions();
    const [user, setUser] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [selectedGroupData, setSelectedGroupData] = useState([]);
    const [searchExecuted, setSearchExecuted] = useState(false);
    const [meterList, setMeterList] = useState([]);
    const [error, setError] = useState('');
    const [mergedArray, setMergedArray] = useState([]);
  // const [data, setData] = useState({});
    const [serialOptions, setSerialOptions] = useState([]);

    const [selectOptions, setSelectOptions] = useState([]);
    const [showList, setShowList] = useState(false); // State to control list visibility
    const [showDropdown, setShowDropdown] = useState(false); // State to manage dropdown visibility


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                    const numberPart = emailParts[0];
                    setPhoneNumber(numberPart);
                  //  handleSearch(numberPart);
                    handleSearch1(numberPart);
                    getAdminPassword(numberPart);
                }
            } else {
                setUser(null);
                // Instead of redirecting, you can handle this case in your UI
                window.location.href = '/';
            }
        });
        return () => unsubscribe();
    }, []);



    const getAdminPassword = async (numberPart) => {

        const passwordRef = database.ref(`/adminRootReference/adminDetails/${numberPart}/adminProfile`);
        passwordRef.once('value', (snapshot) => {
            const fetchedPassword = snapshot.val();
            setPassword(fetchedPassword?.password || '');
            setLoading(false); // Set loading to false once data is loaded

        });
    };


    const handleSearch = async (phoneNumber) => {
        setLoading(true);
        const trimmedPhoneNumber = phoneNumber.trim();
        if (trimmedPhoneNumber !== '') {
            try {
                const dataRef = database.ref(`/adminRootReference/tenantDetails/${trimmedPhoneNumber}`);
                const snapshot = await dataRef.once('value');
                const newData = snapshot.val();
                // console.log('All data ', newData);
             //   setData(newData || {});
                setSelectedGroupData(newData);
                ///console.log("data", newData);

                // Extract select options based on received data
                if (newData) {
                    const options = Object.keys(newData).map(key => key.replace(/\s/g, '_'));
                    // console.log('option ', options);
                    setSelectOptions(options);
                }
                else {
                    // console.log('No meter list found');
                }
                setSearchExecuted(true);
            } catch (error) {
                //  console.error('Error fetching data:', error);
                // Handle error (e.g., show an error message to the user)
            }
        }
    };

    const extractSerialNumbers = () => {
        const extractedSerials = Object.values(data).reduce((acc, item) => {
            if (item && typeof item === 'object' && !Array.isArray(item)) {
                const keys = Object.keys(item);
                const filteredKeys = keys.filter((key) => !isNaN(Number(key)));
                acc.push(...filteredKeys.map(serial => ({ serial, name: item[serial].name })));
            }

            return acc;
        }, []);
        setSerialOptions(extractedSerials);
        /// console.log("return data", extractedSerials);

    }

    useEffect(() => {
        extractSerialNumbers();
    }, [data]);


    useEffect(() => {
        const merged = [...serialOptions, ...meterList.map(meterId => ({ serial: meterId }))];
        // console.log('mergedArray:', mergedArray);
        setMergedArray(merged);
    }, [serialOptions, meterList]);

    useEffect(() => {

        const merged = [...serialOptions, ...meterList.map(meterId => ({ serial: meterId }))];
        const uniqueSerialsMap = new Map();
        merged.forEach(item => {
            if (item.name) {
                // If a serial number already exists in the map, skip adding it (as we only want unique serials with names)
                if (!uniqueSerialsMap.has(item.serial)) {
                    uniqueSerialsMap.set(item.serial, item.name); // Store serial number with its name
                }
            } else {
                // If no name is available, check for duplicates and store only one entry
                if (!uniqueSerialsMap.has(item.serial)) {
                    uniqueSerialsMap.set(item.serial, null); // Store serial number without a name
                }
            }
        });
        const uniqueSerialsArray = Array.from(uniqueSerialsMap).map(([serial, name]) => ({ serial, name }));
        uniqueSerialsArray.sort((a, b) => a.serial.localeCompare(b.serial));
        setMergedArray(uniqueSerialsArray);
    }, [serialOptions, meterList]);

    useEffect(() => {
        extractSerialNumbers();
        handleSearch1();
    }, []);


    const handleSearch1 = async (numberPart) => {
        try {
            if (numberPart && typeof numberPart === 'string') {
                const phoneNumberValue = numberPart.trim();
                if (phoneNumberValue !== '') {
                    setPhoneNumber(phoneNumberValue); // Update phoneNumber state
                    const snapshot = await firebase.database().ref(`/adminRootReference/adminDetails/${phoneNumberValue}/meterList`).once('value');
                    const fetchedMeterList = snapshot.val();
                    // console.log("meteltelsit ", fetchedMeterList);
                    if (fetchedMeterList) {
                        const meterIds = Object.keys(fetchedMeterList);
                        setMeterList(Object.keys(fetchedMeterList));
                        Object.keys(fetchedMeterList).forEach(async (serialNumber) => {
                            await isTokenAvailable(serialNumber);
                            // deleteAllPendingToken(serialNumber);
                        });
                        setMeterList(meterIds);
                        setError('');
                    } else {
                        setMeterList([]);
                        // setError('No meter list found for this admin phone');
                        // setshowMeterListAlert(true);
                        // setLoading(false);
                    }
                }
            }
        } catch (error) {
            // console.error('Error fetching admin meter list:', error);
            setMeterList([]);
            // setError('Error fetching admin meter list. Please try again.');
        }
    };
    const [tokenStatus, setTokenStatus] = useState([]);

    
    const isTokenAvailable = async (serialNumber, index) => {
        try {
            const meterDetailsPath = firebase.database().ref(`adminRootReference/meterDetails/${serialNumber}/reConfigToken`);
            const snapshot = await meterDetailsPath.once('value');
            const newData = snapshot.val();
            if (newData !== null) {
                const isTransfer = newData.isTransfer;
                const token = newData.token;
                const tokenStatus = newData.tokenStatus;
                let status;
                if (tokenStatus === '0' && isTransfer === 'false') {
                    status = "N";
                } else if (tokenStatus === '0' && isTransfer === 'true') {
                    status = "T";
                } else {
                    status = "";
                }


                setTokenStatus(prevState => ({
                    ...prevState,
                    [serialNumber]: status
                }));
            } else {
                // Handle case where newData is null
                //  console.log('Data not found for serial number:', serialNumber);
            }
        } catch (e) {
            //  console.log('Error Fetching:', e);
        }
    };


    useEffect(() => {
        mergedArray.forEach(({ serial }, index) => {
            isTokenAvailable(serial, index);
        });
    }, [mergedArray]);



    const [showMeterListAlert, setshowMeterListAlert] = useState(false); // State to control alert visibility

    const handleCloseMeterlistAlert = () => {
        setshowMeterListAlert(false); // Close alert when clicked on the close button
        navigate('/');
    };

    return (
        <>
            <div className='ChangemobileMainSection'>
                {/* <div className='' style={{backgroundColor:"#e3f2fd" , display:'inline', padding:'10px'}}>
                  
                        Re-Configuration Token will be generated for all the meters present in the list
                  
                </div> */}

                <p className='ReConfigTokenHeading'>
                    Re-Configuration Token will be generated for all the meters present in the list

                </p>

                <div className='DrowpdownMenu'>
                    <Dropdown >
                        <Dropdown.Toggle
                            className='dropdowntoggleMenu'
                            style={{

                                backgroundColor: 'white',
                                color: 'black',

                            }}

                        >
                            View All Meter List
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropMenu' >
                            {mergedArray.map(({ serial, name }, index) => (
                                <Dropdown.Item
                                    key={index}
                                    value={name ? `${serial} ${name}` : serial}
                                    className="dropdownItemBox"
                                    disabled={tokenStatus[serial] !== 'selected'}
                                    style={{
                                        color: tokenStatus[serial] !== 'selected' ? 'gray' : 'black',
                                    }}
                                >
                                    {serial}{tokenStatus[serial]} {name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                
                <Phonepasswordchange />
            </div>


            {showMeterListAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseMeterlistAlert}
                        className='AlertDailog'
                    >
                        No configure meter found
                    </Alert>
                </div>
            )}
        </>
    )
}

export default ChangeMobile
