import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneSendOtp from '../adminLogin/phonesendotp';
import Send from '../adminLogin/sendmail'; // Import the Send component
import { auth } from '../adminLogin/firebase';
import '../adminLogin/login.css'
import CommonFuctions from '../commonfunction';
import Navbar from '../adminLogin/navbar';
import { Modal, Button } from 'react-bootstrap';
import Alert from '@mui/material/Alert';


function Phoneotp() {

  const SessionTime = new CommonFuctions();

  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  // const { enteredPhoneNumberModal } = location.state || {};
  // const { email } = location.state || {};
  const { enteredPasswordModal } = location.state || {};


  const enteredPhoneNumberModal = localStorage.getItem('TransferNumber');

  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(true);




  const [modalMessage, setModalMessage] = useState('');
  // console.log('new Address ', initialEmail);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        const emailParts = authUser.email.split('@');
        if (emailParts.length === 2) {
          const numberPart = emailParts[0];
          // console.log("Number", numberPart);
          setPhoneNumber(numberPart);
          SessionTime.updateSessionTimeActiveUser(numberPart);
          //  upDateSession(numberPart);
          setLoading(false);
        }
      } else {
        setUser(null);
        // Instead of redirecting, you can handle this case in your UI
        window.location.href = '/';
      }
    });
    return () => unsubscribe();
  }, []);
  // const initialEmail = location.state?.email || ''; // Get email from location state

  const initialEmail = localStorage.getItem('email');

  const { email, setEmail, errorMessage, handleButtonClick } = Send(); // Using your custom hook
  useEffect(() => {
    setEmail(initialEmail); // Set initial email from location state
  }, [setEmail, initialEmail]);



  const upDateSession = async (numberPart) => {

    const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function

    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }

    if (status === 'Poor connection.') {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

      return;
    }



    try {
      SessionTime.updateSessionTimeActiveUser(numberPart);
    } catch (error) {







    }
  }


  const handleGetOTP = async () => {
    // console.log('phone number 1212: ', enteredPhoneNumberModal);
    // const phoneSendOtp = new PhoneSendOtp(enteredPhoneNumberModal);
    // phoneSendOtp.sendOTP(enteredPhoneNumberModal);

    const phoneSendOtp = new PhoneSendOtp(enteredPhoneNumberModal);
    const result = await phoneSendOtp.sendOTP(enteredPhoneNumberModal);


    if (result == '200') {
      toggleRefreshAndClose(true); 
     // navigate('/emailandphoneverify', { state: { enteredPhoneNumberModal } });
      navigate('/emailandphoneverify', {
        state: {
          phoneNumber: enteredPhoneNumberModal,
          message: 'OTP has been sent successfully.'
        }
      });

    }
    else if (result.status_code == '411') {
    //  console.log('Invalid Number ')
      setAlertMessage(`Invalid Number (${result.status_code})`)
      setLoading(false);
      toggleRefreshAndClose(true);
      return;
    }
    else if (result.status_code == '416') {
      setAlertMessage(`)SMS service is temporarily unavailable. Please retry. (${result.status_code}) `);
      setLoading(false);
      toggleRefreshAndClose(true);
      return;
    }
    else if (result == "Network Error") {
      setLoading(false);
      setIsDialogOpen(true);
      // const errorMessage = `Network error. Please retry(${error}). `;
      const errorMessage = `Network error. Please retry. (${result})`;
      setModalMessage(errorMessage);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }
    else {
      setLoading(false);
      setIsDialogOpen(true);
      const errorMessage = `SMS service is temporarily unavailable. Please retry. (${result})`;
      setModalMessage(errorMessage);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

    }



    // try {
    //   const phoneSendOtp = new PhoneSendOtp(enteredPhoneNumberModal);
    //   const result = await phoneSendOtp.sendOTP(enteredPhoneNumberModal);
    //   if (result === 411) {
    //     setAlertMessage(`Invalid number : (${result})`);
    //     setLoading(false);
    //   } else {
    //     navigate('/emailandphoneverify', { state: { enteredPhoneNumberModal } });
    //   }
    // } catch (otpError) {
    // }




  };

  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;
  };

  const handleButtonClick1 = async (event) => {
    setAlertMessage('');
    setLoading(true);
    toggleRefreshAndClose(false); // Prevent refresh and close actions
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };
    // const status = await SessionTime.checkInternetConnection(); // Call the function

    const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function

    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }

    if (status === 'Poor connection') {
      setIsDialogOpen(true);
      setModalMessage('Poor internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }

    const storeSessionId = localStorage.getItem('sessionId');
    try {
      const { sessionId } = await SessionTime.HandleValidatSessiontime(phoneNumber);
      if (storeSessionId === sessionId) {

        ///  SessionUpdate();
        event.preventDefault();
        handleButtonClick(event);
        handleGetOTP();
        // navigate('/emailandphoneverify', { state: { enteredPhoneNumberModal } });

      } else {

        toggleRefreshAndClose(true); // Prevent refresh and close actions
        setLoading(false);
        setShowAlert(true); // Close alert when clicked on the close button

      }
    } catch (error) {

      setIsDialogOpen(true);
      const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
      setModalMessage(errorMessage);
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

    }
  };

  const SessionUpdate = () => {

    SessionTime.updateSessionTimeActiveUser(phoneNumber);
  }

  const history = useNavigate();

  const handleLogout = () => {
    auth.signOut().then(() => {
      localStorage.clear();
      // Redirect to login page after successful logout
      history('/'); // Change '/login' to your login page route
    }).catch((error) => {
      // Handle any errors during logout
      //  console.error('Error logging out:', error.message);
    })
  }


  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => {
    window.onpopstate = null;
    setIsDialogOpen(false);
    // window.location.reload(); // This will reload the page
  };


  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

  const handleCloseAlert = () => {
    setShowAlert(false); // Close alert when clicked on the close button
    handleLogout();
  };





  return (
    <>
      <div>
        <Navbar />
      </div>


      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}
      <div className='containers' >
        <div className='formgroup'>
          <div>
            <label htmlFor="phoneNumber">Mobile Number</label>
            <div className="input-container1">
              <input
                type="text"
                className='form-control'
                placeholder="Enter phone number"
                // value={enteredPhoneNumberModal}
                value={enteredPhoneNumberModal ? `+91${enteredPhoneNumberModal}` : '+91'}
                disabled
              />
              <i class="fas fa-phone"></i>
            </div>
            {/* Display any response messages */}

            {alertMessage && (
              <div className="alert-container">
                <p style={{ color: 'red' }}><i className="fas fa-exclamation-circle" style={{ color: 'red' }}></i> {alertMessage}</p>
              </div>
            )}

          </div>
          <div>
            <div>
              <label htmlFor="email">E-mail</label>
              <div className="input-container1">
                <input
                  type="email"
                  className='form-control'
                  placeholder="Enter your email"
                  value={email}
                  disabled
                />

                <i class="fa-solid fa-envelope"></i>
              </div>
              <p>{errorMessage}</p>
            </div>

          </div>
          <div className='d-grid col-6'>
            <button className='btn btn-primary' onClick={handleButtonClick1} >GET OTP</button>
          </div>
        </div>
      </div>


      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      {showAlert && (
        <div className="alert-overlay"
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={handleCloseAlert}
            className='AlertDailog'
          >
            You have been logged out due to login from another device.!
          </Alert>
        </div>
      )}






    </>
  )
}

export default Phoneotp