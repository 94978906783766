import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../adminLogin/navbar';
import { auth } from '../adminLogin/firebase';
import { database } from '../firebase';
import firebase from 'firebase/compat/app'; // Import the Firebase app (latest version)
import 'firebase/compat/database';
import Phonepasswordchange from './phonepasswordchange';
import IsDelateTranferToken from '../transfertoken/deletetransfertoken'
import './Phonepasswordchange.css'; // Import CSS file
import { useNavigate } from 'react-router-dom';
import CommonFuctions from '../commonfunction';
import { Modal, Button } from 'react-bootstrap';
import '../adminLogin/login.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import Alert from '@mui/material/Alert';


function Homepage() {

  const navigate = useNavigate();
  const SessionTime = new CommonFuctions();
  const [user, setUser] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedGroupData, setSelectedGroupData] = useState([]);
  const [searchExecuted, setSearchExecuted] = useState(false);
  const [meterList, setMeterList] = useState([]);
  const [error, setError] = useState('');
  const [mergedArray, setMergedArray] = useState([]);
  const [data, setData] = useState({});

  const [serialOptions, setSerialOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const [selectOptions, setSelectOptions] = useState([]);
  const [showList, setShowList] = useState(false); // State to control list visibility
  const [showDropdown, setShowDropdown] = useState(false); // State to manage dropdown visibility

  useEffect(() => {
    document.title = "Re-Configuration Token"; // Set the title when the component mounts
  }, []);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        const emailParts = authUser.email.split('@');
        if (emailParts.length === 2) {
          const numberPart = emailParts[0];
          setPhoneNumber(numberPart);
          handleSearch(numberPart);
          handleSearch1(numberPart);
          getAdminPassword(numberPart);
          SessionValidate(numberPart);
          SessionUpdate(numberPart);
          islastActiveTime(numberPart);
          /// downloadAllMeter(numberPart);
        }
      } else {
        setUser(null);
        // Instead of redirecting, you can handle this case in your UI
        window.location.href = '/';
      }
    });

    return () => unsubscribe();
  }, []);


  const islastActiveTime = async (numberPart) => {

    const internet = await SessionTime.internetAvailable();
    if (internet === 'Connected') {
      try {
        const result = await SessionTime.isLastActiveTime(numberPart);
        if (result) {
          setLoading(false);
          handleLogout();
          localStorage.setItem('isLoggedIn', true);
          return;
        }

      } catch (error) {
        // console.log('Getting Error in ', error);
      }

    }
    else {
      /// console.log("No internet Passwed ");
    }

  }

  const getAdminPassword = async (numberPart) => {

    const passwordRef = database.ref(`/adminRootReference/adminDetails/${numberPart}/adminProfile`);
    passwordRef.once('value', (snapshot) => {
      const fetchedPassword = snapshot.val();
      setPassword(fetchedPassword?.password || '');
      setLoading(false); // Set loading to false once data is loaded


    });
  };

  const handleSearch = async (phoneNumber) => {
    setLoading(true);
    const trimmedPhoneNumber = phoneNumber.trim();
    if (trimmedPhoneNumber !== '') {
      try {
        const dataRef = database.ref(`/adminRootReference/tenantDetails/${trimmedPhoneNumber}`);
        const snapshot = await dataRef.once('value');
        const newData = snapshot.val();
        // console.log('All data ', newData);
        setData(newData || {});
        setSelectedGroupData(newData);
        ///console.log("data", newData);

        // Extract select options based on received data
        if (newData) {
          const options = Object.keys(newData).map(key => key.replace(/\s/g, '_'));
          // console.log('option ', options);
          setSelectOptions(options);
        }
        else {
          // console.log('No meter list found');

        }

        setSearchExecuted(true);


      } catch (error) {
        //  console.error('Error fetching data:', error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  const extractSerialNumbers = () => {
    const extractedSerials = Object.values(data).reduce((acc, item) => {
      if (item && typeof item === 'object' && !Array.isArray(item)) {
        const keys = Object.keys(item);
        const filteredKeys = keys.filter((key) => !isNaN(Number(key)));
        acc.push(...filteredKeys.map(serial => ({ serial, name: item[serial].name })));
      }

      return acc;
    }, []);
    setSerialOptions(extractedSerials);
    /// console.log("return data", extractedSerials);

  }

  useEffect(() => {
    extractSerialNumbers();
  }, [data]);


  useEffect(() => {
    const merged = [...serialOptions, ...meterList.map(meterId => ({ serial: meterId }))];
    // console.log('mergedArray:', mergedArray);
    setMergedArray(merged);
  }, [serialOptions, meterList]);

  useEffect(() => {

    const merged = [...serialOptions, ...meterList.map(meterId => ({ serial: meterId }))];

    const uniqueSerialsMap = new Map();


    merged.forEach(item => {
      if (item.name) {
        // If a serial number already exists in the map, skip adding it (as we only want unique serials with names)
        if (!uniqueSerialsMap.has(item.serial)) {
          uniqueSerialsMap.set(item.serial, item.name); // Store serial number with its name
        }
      } else {
        // If no name is available, check for duplicates and store only one entry
        if (!uniqueSerialsMap.has(item.serial)) {
          uniqueSerialsMap.set(item.serial, null); // Store serial number without a name
        }
      }
    });
    const uniqueSerialsArray = Array.from(uniqueSerialsMap).map(([serial, name]) => ({ serial, name }));
    uniqueSerialsArray.sort((a, b) => a.serial.localeCompare(b.serial));
    setMergedArray(uniqueSerialsArray);
  }, [serialOptions, meterList]);

  useEffect(() => {
    extractSerialNumbers();
    handleSearch1();
  }, []);





  const handleSearch1 = async (numberPart) => {
    try {
      if (numberPart && typeof numberPart === 'string') {
        const phoneNumberValue = numberPart.trim();
        if (phoneNumberValue !== '') {
          setPhoneNumber(phoneNumberValue); // Update phoneNumber state
          const snapshot = await firebase.database().ref(`/adminRootReference/adminDetails/${phoneNumberValue}/meterList`).once('value');
          const fetchedMeterList = snapshot.val();
          // console.log("meteltelsit ", fetchedMeterList);
          if (fetchedMeterList) {
            const meterIds = Object.keys(fetchedMeterList);
            setMeterList(Object.keys(fetchedMeterList));
            Object.keys(fetchedMeterList).forEach(async (serialNumber) => {
              await isTokenAvailable(serialNumber);
              // deleteAllPendingToken(serialNumber);
            });
            setMeterList(meterIds);
            setError('');
          } else {
            setMeterList([]);
            // setError('No meter list found for this admin phone');
            setshowMeterListAlert(true);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      // console.error('Error fetching admin meter list:', error);
      setMeterList([]);
      // setError('Error fetching admin meter list. Please try again.');
    }
  };
  const [tokenStatus, setTokenStatus] = useState([]);

  const isTokenAvailable = async (serialNumber, index) => {
    try {
      const meterDetailsPath = firebase.database().ref(`adminRootReference/meterDetails/${serialNumber}/reConfigToken`);
      const snapshot = await meterDetailsPath.once('value');
      const newData = snapshot.val();
      if (newData !== null) {
        const isTransfer = newData.isTransfer;
        const token = newData.token;
        const tokenStatus = newData.tokenStatus;

        let status;


        if (tokenStatus === '0' && isTransfer === 'false') {
          status = "N";
        } else if (tokenStatus === '0' && isTransfer === 'true') {
          status = "T";
        } else {
          status = "";
        }


        setTokenStatus(prevState => ({
          ...prevState,
          [serialNumber]: status
        }));
      } else {
        // Handle case where newData is null
        //  console.log('Data not found for serial number:', serialNumber);
      }
    } catch (e) {
      //  console.log('Error Fetching:', e);
    }
  };

  useEffect(() => {
    mergedArray.forEach(({ serial }, index) => {
      isTokenAvailable(serial, index);
    });
  }, [mergedArray]);

  let allMeterDetailsGlobal = {}; // Define a global variable to store all meter details
  const downloadAllMeter = async (phoneNumber) => {

    const FetchAdmin = firebase.database().ref(`adminRootReference/adminDetails/${phoneNumber}/meterList`);
    const Admindetialsref = FetchAdmin.toString();
    try {
      const data = await SessionTime.callCloudFunction(Admindetialsref);
      const parsedData = JSON.parse(data);
      delete parsedData.time;
      allMeterDetailsGlobal = {};
      // Use Promise.all with map to await all async operations and collect results
      await Promise.all(Object.keys(parsedData).map(async (serial) => {
        const meterDetailsPath = firebase.database().ref(`adminRootReference/meterDetails/${serial}`);
        const Admindetialsref = meterDetailsPath.toString();

        const data = await SessionTime.callCloudFunction(Admindetialsref);
        const meterDetails = JSON.parse(data);

        allMeterDetailsGlobal[serial] = meterDetails; // Store meter details by serial number
      }));
      ///   console.log('All meter details:'); // Print all meter details after all async operations complete
      return allMeterDetailsGlobal; // Return all meter details object
    } catch (error) {
      // console.error('Error fetching meter details:', error);
      throw error; // Throw error to handle it outside the function
    }
  }

  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;
  };

  const deletePendingToken = async () => {
    // Disable browser interactions
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };


    const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function
    if (!navigator.onLine) {
      setisOpenInternet(true);
      setModalMessage('No internet. Cannot access server');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

      return;
    }
    if (status === 'Poor connection') {
      setisOpenInternet(true);
      setModalMessage('Poor internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

      return;
    }

    const storeSessionId = localStorage.getItem('sessionId');
    try {
      const { sessionId } = await SessionTime.HandleValidatSessiontime(phoneNumber);
      if (storeSessionId === sessionId) {
        try {
          const allMeterDetailsGlobal = await downloadAllMeter(phoneNumber);
          const child = {}; // Object to store paths for deletion

          Object.keys(allMeterDetailsGlobal).forEach(async (serial) => {
            ///  console.log(' All Serial :', serial);
            const reConfigToken = allMeterDetailsGlobal[serial]?.reConfigToken;
            if (reConfigToken && reConfigToken.isTransfer === "true" && reConfigToken.token !== "null") {
              const FetchAdmin = firebase.database().ref(`adminRootReference/meterDetails/${serial}/reConfigToken`);
              const Admindetialsref = FetchAdmin.toString();
              child[Admindetialsref] = null;
            } else {
              /// console.log('No Pending Token Available :',);
            }
          });

          if (Object.keys(child).length > 0) {
            try {
              await SessionTime.callWriteRtdbFunction(child);
              SessionTime.updateSessionTimeActiveUser(phoneNumber);
              setLoading(false);
              toggleRefreshAndClose(true); // Prevent refresh and close actions
              setisResponseOpen(true);
              // setModalMessage('Pending token Deleted succesfully.');
              const errorMessage = `Pending token deleted succesfully.`;
              setMessageResponse(errorMessage);
            } catch (error) {
              setisResponseOpen(true);
              const errorMessage = `Response not recieved  from server-A. Please check if transaction completed successfully, else retry. (${error}).`;
              setMessageResponse(errorMessage);
              setLoading(false);
              toggleRefreshAndClose(true); // Prevent refresh and close actions
            }
          } else {
            // console.log('No paths available for deletion.');
            setisOpenInternet(true);
            setModalMessage('No Transfer  token available.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions

          }
        } catch (error) {
          setisOpenInternet(true);
          const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
          setModalMessage(errorMessage);
          setLoading(false);
          toggleRefreshAndClose(true); // Prevent refresh and close actions
        }
      } else {

        setLoading(false);
        setShowAlert(true);
      }
    }
    catch (error) {
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      setisOpenInternet(true);
      const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
      setModalMessage(errorMessage);
      setLoading(false);
    }
  };

    const handleViewMeterlsit =()=>{

      console.log('Change page');
    }


  const confirmDelete = async () => {
    setModalMessage('Are you sure want to delete ');
    setisConfirmed(true);
  }
  const toggleListVisibility = () => {
    setShowList(!showList);
  };

  const handleInputClick = () => {
    setShowDropdown(!showDropdown);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const history = useNavigate();
  const handleLogout = () => {
    auth.signOut().then(() => {
      history('/'); // Change '/login' to your login page route
    }).catch((error) => {
      // Handle any errors during logout
      console.error('Error logging out:', error.message);
    })

    /// history('/'); 

  }


  const SessionValidate = async (numberPart) => {


    const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function
    if (!navigator.onLine) {
      setisOpenInternet(true);
      setModalMessage('No internet. Cannot access server');
      setLoading(false);
      return;
    }
    if (status === 'Poor connection') {
      setisOpenInternet(true);
      setModalMessage('Poor internet. Cannot access server.');
      setLoading(false);
      return;
    }

    const storeSessionId = localStorage.getItem('sessionId');
    try {
      const { sessionId } = await SessionTime.HandleValidatSessiontime(numberPart);

      if (storeSessionId === sessionId) {
        //  console.log('SessionId Match ', sessionId);
        return;
      } else {

        setLoading(false);
        setShowAlert(true);

      }

    } catch (error) {

      setisOpenInternet(true);
      const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
      setModalMessage(errorMessage);
      setLoading(false);


    }

  };

  const SessionUpdate = (numberPart) => {
    SessionTime.updateSessionTimeActiveUser(numberPart);
  }
  const [modalMessage, setModalMessage] = useState('');
  const [isOpenInternet, setisOpenInternet] = useState(false);
  const closeInternet = () => {
    setisOpenInternet(false);
    window.onpopstate = null;
  };
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [MessageResponse, setMessageResponse] = useState('');

  const closeResponse = () => {
    setisResponseOpen(false);
    window.location.reload(); // This will reload the page
    window.onpopstate = null;
  }

  const [isConfirmed, setisConfirmed] = useState(false);
  const isConfirmedYes = () => {
    setisConfirmed(false);
    deletePendingToken();
    setLoading(true);
    toggleRefreshAndClose(false); // Prevent refresh and close actions

  }
  const closeisConfirmed = () => {
    setisConfirmed(false);
    window.onpopstate = null;
  };
  const handleBackButton = () => {
    navigate('/');
  }



  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

  const handleCloseAlert = () => {
    setShowAlert(false); // Close alert when clicked on the close button
    handleLogout();
  };




  const [showMeterListAlert, setshowMeterListAlert] = useState(false); // State to control alert visibility

  const handleCloseMeterlistAlert = () => {
    setshowMeterListAlert(false); // Close alert when clicked on the close button
    navigate('/');
  };




  return (
    <>
      <Navbar />
      <>

        {loading ? (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999'
          }}>
            <div className="spinner-border text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}

        <div style={{ display: 'flex' }}>
          <div>
            <div className='sidebar  ' style={{ marginTop: '5%', height: '100%', width: '20%' }}>

              <div style={{ marginTop: '10%', fontWeight: 'bold' }}>
                {/* <p >Current Mobile number <br /> {'+91' + phoneNumber} </p> */}
                <label htmlFor="phoneNumber">Mobile Number</label>
                <div className='input-container1'>
                  <input
                    id='phoneNumber'
                    type="text"
                    // value={phoneNumber}
                    value={phoneNumber ? `+91${phoneNumber}` : '+91'}
                    className='form-control'
                    placeholder=" Phone Number"
                    readOnly
                    disabled
                  />
                  <i className="fas fa-phone"></i>
                </div>
              </div>

              <hr style={{ color: 'red' }}></hr>



              <div className="delete-button" >
                <p style={{ cursor: 'pointer', fontWeight: 'bold' }} >Change Admin No/Password</p>
              </div>


              <div className="delete-button" >
                <p style={{ cursor: 'pointer', fontWeight: 'bold' }} >Transfer meterss to another Admin </p>
              </div>


              <div className="delete-button" >
                <p style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={{handleViewMeterlsit}} >View Meter List with Re-configuration token status </p>
              </div>



              {/* <div className="delete-button">
                <p style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={confirmDelete}>Delete all Pending Transfer Token</p>
              </div> */}
              <hr style={{ color: 'red' }}></hr>
            </div>
          </div>
          <div className='container' style={{ marginLeft: '25%', marginTop: '6%' }} >
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1%', marginLeft: '1%', backgroundColor: 'blue', width: '60%', }}>
              <label style={{ fontWeight: 'bold', marginLeft: '5px', color: 'white' }} htmlFor="reconfigureTokenCheckbox">
                Re-Configuration Token will be generated for all the meters present in the list
              </label>
            </div>

            <div style={{ marginLeft: '1%', marginTop: '3%' }}>
              <select className="form-select w-50">
                <option disabled selected>View All Meter List</option>
                {mergedArray.map(({ serial, name }, index) => (
                  <option
                    key={index}
                    value={name ? `${serial} ${name}` : serial}
                    disabled={tokenStatus[serial] !== 'selected'}

                    style={{
                      color: tokenStatus[serial] !== 'selected' ? 'gray' : 'black',
                    }}
                  >
                    {serial}{tokenStatus[serial]} {name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <Phonepasswordchange />
            </div>
          </div>

        </div>
      </>

      <Modal show={isOpenInternet} onHide={closeInternet} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
        <Modal.Body>
          <p style={{ color: 'red' }}> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeInternet}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={isResponseOpen} onHide={closeResponse} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
        <Modal.Body>
          <p> {MessageResponse}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeResponse}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isConfirmed} onHide={closeisConfirmed} backdrop="static" style={{ marginTop: '3%' }}>

        <Modal.Body>
          <p style={{ color: 'red' }} > {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center w-100">
            <Button variant="primary" style={{ marginRight: '20px' }} onClick={isConfirmedYes}>
              YES
            </Button>

            <Button variant="success" onClick={closeisConfirmed}>
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>



      {showAlert && (
        <div className="alert-overlay"
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={handleCloseAlert}
            className='AlertDailog'
          >
            You have been logged out due to login from another device.!
          </Alert>
        </div>
      )}



      {showMeterListAlert && (
        <div className="alert-overlay"
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={handleCloseMeterlistAlert}
            className='AlertDailog'
          >
            No configure meter found
          </Alert>
        </div>
      )}



    </>

  );
}

export default Homepage;
