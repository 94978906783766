import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../adminLogin/navbar';
import { auth } from '../adminLogin/firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import '../meterdetailonserver/meterdetail.css';
import { database } from '../firebase';
import { Modal, Button } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import CommonFuctions from '../commonfunction';
import PhoneshareIcond from '../Images/logo/phone_share.png'

import {
    FacebookIcon,
    WhatsappIcon,
    FacebookShareButton,
    EmailShareButton,
    WhatsappShareButton,
} from "react-share";
import { setLogLevel } from 'firebase/app';

function Singlegroupmeter({ highlightedSerial, data, meterList, phoneNumber, highlightGroupName , setHighlightGroupName, setSelectedGroup}) {
    const SessionTime = new CommonFuctions();
    // const [data, setData] = useState({});
    // const [meterList, setMeterList] = useState([]);
    const [error, setError] = useState('');
    const [serialOptions, setSerialOptions] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedGroupData, setSelectedGroupData] = useState([]);
    const [searchExecuted, setSearchExecuted] = useState(false);
    // const [phoneNumber, setPhoneNumber] = useState('');
    const [mergedArray, setMergedArray] = useState([]);
    const [user, setUser] = useState(null); // State to hold user information
    const [selectedSerialInfo, setSelectedSerialInfo] = useState(null);
    // const [selectedSerialData, setSelectedSerialData] = useState(null);
    const [kwhData, setKwhData] = useState('');

    let [formettedtime, setFormerttedTime] = useState('');
    let [formettedtimeonmeter, setFormerttedTimeOnMeter] = useState('');
    let [rechargdata, setRechargData] = useState('');
    const [billData, setBillData] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [reconfig, setReconfig] = useState('');
    const [monthData, setMonthData] = useState('');
    const [updatetoken, setUpdateToken] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const handleOpenModal = () => {
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setReconfig([]);
        setBillData([]);
        setRechargData([]);
        setKwhData([]);
        setFormerttedTime([]);
        setFormerttedTimeOnMeter('');
        setMonthData([]);
        setModalIsOpen(false);
        window.onpopstate = null;

    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                // User is logged in
                setUser(authUser);
                // console.log("Logged in user:", authUser.email);
                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                    const numberPart = emailParts[0];
                    // console.log("Number part:", numberPart); // Log the extracted number part
                    //   setPhoneNumber(numberPart);
                    //  handleSearch(numberPart);
                    //   handleSearch1(numberPart);
                    // SessionValidate(numberPart);
                    //  SessionUpdate(numberPart);

                }
            } else {
                setUser(null);
                window.location.href = '/'; // Redirect to your login page
            }
        });
        return () => {

            unsubscribe(); // Cleanup function for unmounting
        };
    }, []);


    //     // Function to remove underscores from keys in an object
    // const removeUnderscores = (data) => {
    //     const modifiedData = {};
    //     for (const key in data) {
    //         if (Object.prototype.hasOwnProperty.call(data, key)) {
    //             const newKey = key.replace(/_/g, ' '); // Replace underscores with spaces
    //             modifiedData[newKey] = data[key];
    //         }
    //     }
    //     return modifiedData;
    // };

    // // The handleSearch function
    // const handleSearch = async (phoneNumber) => {
    //     const trimmedPhoneNumber = phoneNumber.trim();
    //     if (trimmedPhoneNumber !== '') {
    //         try {
    //             const dataRef = database.ref(`/adminRootReference/tenantDetails/${trimmedPhoneNumber}`);
    //             const snapshot = await dataRef.once('value');
    //             const newData = snapshot.val(); 
    //             setUpdateToken(newData);

    //             if (newData) {
    //                 const modifiedDataWithoutUnderscores = removeUnderscores(newData);
    //                 setSelectedGroupData(modifiedDataWithoutUnderscores);
    //                 setData(modifiedDataWithoutUnderscores || {});

    //                 const options = Object.keys(modifiedDataWithoutUnderscores).map(key => key.replace(/_/g, ' '));
    //                 setSelectOptions(options);
    //             }
    //             setSearchExecuted(true);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     }
    // };

    const handleSearch = async (phoneNumber) => {
        const trimmedPhoneNumber = phoneNumber.trim();
        if (trimmedPhoneNumber !== '') {
            try {
                const dataRef = database.ref(`/adminRootReference/tenantDetails/${trimmedPhoneNumber}`);
                const snapshot = await dataRef.once('value');
                const newData = snapshot.val();
                //  setData(newData || {});
                // setSelectedGroupData(newData);
                // console.log("data", newData);
                setIsLoader(false);
                setUpdateToken(newData);
                const modifiedDataWithoutTime = excludeTimeProperty(newData);
                // console.log('Modified Data without Time:', modifiedDataWithoutTime);

                setSelectedGroupData(modifiedDataWithoutTime);
                //  setData(modifiedDataWithoutTime || {});
                // Extract select options based on received data
                if (newData) {
                    const options = Object.keys(newData).map(key => key.replace(/\s/g, '_'));
                    setSelectOptions(options);
                }
                setSearchExecuted(true);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error (e.g., show an error message to the user)
            }
        }
    };

    const excludeTimeProperty = (data) => {
        const modifiedData = { ...data };
        delete modifiedData.time;
        return modifiedData;
    };

    const extractSerialNumbers = () => {
        const extractedSerials = Object.values(data).reduce((acc, item) => {
            if (item && typeof item === 'object' && !Array.isArray(item)) {
                const keys = Object.keys(item);
                const filteredKeys = keys.filter((key) => !isNaN(Number(key)));
                acc.push(...filteredKeys.map(serial => ({ serial, name: item[serial].name })));
            }
            // console.log("return data")
            return acc;
        }, []);
        setSerialOptions(extractedSerials);
    }
    useEffect(() => {
        extractSerialNumbers();
    }, [data]);
    const extractGroupNames = () => {
        if (data) {
            const groupNames = Object.keys(data).filter(key => key !== 'tariff');
            return groupNames;
        }
        return [];
    };

    useEffect(() => {
        // console.log('Group Names:', extractGroupNames());
    }, [data]);
    useEffect(() => {
        extractSerialNumbers();

    }, []);


    useEffect(() => {
        // Merge two arrays when either serialOptions or meterList changes
        const merged = [...serialOptions, ...meterList.map(meterId => ({ serial: meterId }))];
        setMergedArray(merged);
    }, [serialOptions, meterList]);


    useEffect(() => {

        const merged = [...serialOptions, ...meterList.map(meterId => ({ serial: meterId }))];

        const uniqueSerialsMap = new Map();
        merged.forEach(item => {
            if (item.name) {
                // If a serial number already exists in the map, skip adding it (as we only want unique serials with names)
                if (!uniqueSerialsMap.has(item.serial)) {
                    uniqueSerialsMap.set(item.serial, item.name); // Store serial number with its name
                }
            } else {
                // If no name is available, check for duplicates and store only one entry
                if (!uniqueSerialsMap.has(item.serial)) {
                    uniqueSerialsMap.set(item.serial, null); // Store serial number without a name
                }
            }
        });

        const uniqueSerialsArray = Array.from(uniqueSerialsMap).map(([serial, name]) => ({ serial, name }));

        uniqueSerialsArray.sort((a, b) => a.serial.localeCompare(b.serial));


        setMergedArray(uniqueSerialsArray);
    }, [serialOptions, meterList]);

    useEffect(() => {
        extractSerialNumbers();
    }, [updatetoken]);

    useEffect(() => {
        const merged = [...serialOptions, ...meterList.map(meterId => ({ serial: meterId }))];
        setMergedArray(merged);
    }, [serialOptions, meterList]);

    const handleSearch1 = async (numberPart) => {
        try {
            const phoneNumberValue = numberPart.trim();
            if (phoneNumberValue !== '') {
                const snapshot = await firebase.database().ref(`/adminRootReference/adminDetails/${phoneNumberValue}/meterList`).once('value');
                const fetchedMeterList = snapshot.val();

                if (fetchedMeterList) {
                    //   setMeterList(Object.keys(fetchedMeterList));
                    Object.keys(fetchedMeterList).forEach(async (serialNumber) => {
                        //  await isTokenAvailable(serialNumber);
                    });
                }

            }
        } catch (error) {
            console.error('Error fetching admin meter list:', error);
        }
    };
    const [tokenStatus, setTokenStatus] = useState([]);

    const isTokenAvailable = async (serialNumber, index) => {
        try {
            const meterDetailsPath = firebase.database().ref(`adminRootReference/meterDetails/${serialNumber}/reConfigToken`);
            const snapshot = await meterDetailsPath.once('value');
            const newData = snapshot.val();

            if (!newData) {
                // console.log(`Data not found for serial number ${serialNumber}`);
                return;
            }
            const isTransfer = newData.isTransfer;
            const token = newData.token;
            const tokenStatus = newData.tokenStatus;

            let status;
            if (tokenStatus === '0' && isTransfer === 'false') {
                // console.log("Signle *");
                status = "N";
            } else if (tokenStatus === '0' && isTransfer === 'true') {
                // console.log("Double  *");
                status = "T";
            } else {
                status = "";
            }

            setTokenStatus(prevState => ({
                ...prevState,
                [serialNumber]: status
            }));
        } catch (e) {
            console.log('Error Fetching:', e);
        }
    };

    // useEffect(() => {
    //     mergedArray.forEach(({ serial }, index) => {
    //         isTokenAvailable(serial, index);
    //     });
    // }, [mergedArray]);

    const groupMergedArrayByNames = () => {
        const groupedMeters = {};

        mergedArray.forEach(({ serial, name }) => {
            if (name) {
                if (!groupedMeters[name]) {
                    groupedMeters[name] = [];
                }
                groupedMeters[name].push(serial);
            }
        });

        return groupedMeters;
    };

    // let canRefreshOrClose = false; // Flag to control refresh and close actions
    let canRefreshOrClose = true; // Allow refresh and close actions by default
    // Function to handle beforeunload event
    const handleBeforeUnload = (event) => {
        if (!canRefreshOrClose) {
            const message = 'Please do not reload this page, data may be lost.';
            event.returnValue = message;
            return message; // Required for some older browsers   
        }
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Function to enable/disable refresh and close actions
    const toggleRefreshAndClose = (enabled) => {
        canRefreshOrClose = enabled;
    };
    const groupedMeters = groupMergedArrayByNames();

    const handleSerialClick = async (serial, info) => {
        // Disable browser interactions
        setHighlightGroupName(null);
        setSelectedGroup(null);

        setIsLoader(true);
        toggleRefreshAndClose(false); // Prevent refresh and close actions
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.href);
        };
        //    const status = await SessionTime.checkInternetConnection(); // Call the function

        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            setIsLoader(false);
            return;
        }
        const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function
        if (status === 'Poor connection') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setIsLoader(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }

        const storeSessionId = localStorage.getItem('sessionId');
        try {
            const { sessionId } = await SessionTime.HandleValidatSessiontime(phoneNumber);
            if (storeSessionId === sessionId) {
                SessionTime.updateSessionTimeActiveUser(phoneNumber);

                const groupName = Object.keys(data).find((group) => data[group][serial]);
                const info = mergedArray.find((item) => item.serial === serial);
                const tariff = data[groupName].tariff;
                const name = info.name;
                let newgroupName = groupName.replace(/ /g, "_");

                setSelectedSerialInfo({
                    serial: serial,
                    name: name,
                    groupName: groupName,
                    tariff: tariff
                    // Add other information as needed
                });

                try {

                    const serialDataRef = database.ref(`/adminRootReference/tenantDetails/${phoneNumber}/${newgroupName}/${serial}`);
                    const UpdatePath = serialDataRef.toString();
                    const allserialpath = database.ref(`/adminRootReference/adminDetails/${phoneNumber}/meterList/${serial}`);
                    const updateserialPath = allserialpath.toString();
                    const serialMeterDataRef = database.ref(`/adminRootReference/meterDetails/${serial}`);
                    const UpdateMeterPath = serialMeterDataRef.toString();
                    const path = [updateserialPath, UpdateMeterPath, UpdatePath];
                    const meterList = await SessionTime.callMultipleReadfunction(path);
                    const adminMeterList = JSON.parse(meterList[0]);
                    delete adminMeterList.time;
                    const serialData = JSON.parse(meterList[1]);
                    delete serialData.time;
                    const tennentData = JSON.parse(meterList[2]);
                    delete tennentData.time;

                    //   console.log('seraalt ', tennentData);

                    // 《❎🔥  meter Configuration:  date and time time   🔥❎ 》 
                    const formattedDate = SessionTime.TimestampConverter(adminMeterList); // Use the utility function
                    // console.log('Formated time ', formattedDate);
                    setFormerttedTime(formattedDate);

                    //  《❎🔥 handleGenerateButton All Recharge Details  🔥❎ 》

                    if (!serialData || serialData.rechargeToken === undefined) {
                        ///  console.error("Serial data or recharge token not available111111");
                    }
                    else {
                        /// console.log("Generate recharge token ", serialData.rechargeToken);
                        const rechargeData = serialData.rechargeToken;
                        // Convert the object to an array of key-value pairs
                        //  console.log("Generate recharge token ", rechargeData);
                        const dataArray = Object.entries(rechargeData);
                        // Reverse the array
                        const reversedArray = dataArray.reverse();
                        // Exclude the last element in the reversed array
                        const excludedLastElementArray = reversedArray.slice(0, reversedArray.length - 0);
                        const reversedObject = Object.fromEntries(excludedLastElementArray);
                        // Set the reversed data in your state
                        setRechargData(reversedObject);
                    }




                    // 《❎🔥 Meter Data update to server:   🔥❎ 》 
                    if (serialData && serialData.billData) {
                        const billDataKeys = Object.keys(serialData.billData);

                        if (billDataKeys.length > 0) {
                            const firstChildKey = billDataKeys[0].split(' ')[1];
                            const parsedFirstChildKey = parseInt(firstChildKey); // Parse the input as an integer

                            const reconfigformattedDate = SessionTime.TimestampConverter(parsedFirstChildKey); // Use the utility function
                            setFormerttedTimeOnMeter(reconfigformattedDate);

                        } else {
                            // console.log("No keys under billData.");
                        }
                    } else {
                        // console.log("Either serialData or billData is undefined.");
                    }


                    // 《❎🔥 Kwh AND MONTH ALL DATA    🔥❎ 》 

                    setKwhData(serialData.kwhData);

                    // 《❎🔥 Billing History (Last 6 Months)   🔥❎ 》 

                    if (serialData && serialData.billData && Object.keys(serialData.billData).length > 0) {
                        const monthData = Object.entries(serialData.billData);
                        const updatedMonthData = monthData.slice(1);
                        //   console.log("Generate recharge token ", updatedMonthData);
                        setMonthData(updatedMonthData);
                    } else {
                        ////console.error("Bill data not available or empty");
                    }


                    // // 《❎🔥   Last Re-Configuration:  🔥❎ 》

                    if (serialData && serialData.reConfigToken) {
                        const reConfigtime = serialData.reConfigToken;
                        //  console.log("tokenGeneratedTime111111111111111: ",serialData.reConfigToken.tokenUsedTime);
                        const tokenGeneratedTime = reConfigtime.tokenUsedTime;
                        // console.log("tokenGeneratedTime: ", tokenGeneratedTime);
                        const serialData1 = parseInt(tokenGeneratedTime); // Parse the input as an integer
                        const formattedDate = SessionTime.TimestampConverter(serialData1); // Use the utility function
                        setReconfig(formattedDate);

                    } else {
                        /// console.error("serialData or reConfigToken is undefined");
                    }

                    // 《❎🔥   ocDATA BillData Billing History (Last 6 Months)   🔥❎ 》

                    if (serialData && serialData.ocData) {
                        const updatedBillData = serialData.ocData;
                        // Convert object keys to an array and reverse it

                        // console.log(`aaaaaaaaaaaaaa11`, updatedBillData);

                        const keysArray = Object.keys(updatedBillData).reverse();

                        //   console.log(`iuhuihihiuhi:`, keysArray);
                        // Iterate over the reversed keys array
                        keysArray.forEach((key) => {
                            // console.log(`Key: ${key}, Value:`, updatedBillData[key]);
                        });

                        // If you want to update the state with reversed data, you can create a reversed object
                        const reversedBillData = keysArray.reduce((acc, key) => {
                            acc[key] = updatedBillData[key];
                            return acc;
                        }, {});

                        // console.log(`reversedBillData`, reversedBillData);
                        setBillData(reversedBillData);
                    } else {

                        // console.log('serialData or serialData.ocData is null or undefined.');

                    }

                    setIsLoader(false);
                    toggleRefreshAndClose(true); // Prevent refresh and close actions
                    setSelectedSerialData(tennentData);
                    handleOpenModal();
                } catch (error) {

                    setIsDialogOpen(true);
                    const errorMessage = `Response not recieved  from server-A. Please check your internet connection .(${error})`;
                    setModalMessage(errorMessage);
                    toggleRefreshAndClose(true); // Prevent refresh and close actions
                    setIsLoader(false);

                }

            } else {

                alert("You have been logged-out due to log-in from another device.");
                // console.log('you are logg out ');
                handleLogout();
            }
        } catch (error) {
            setIsDialogOpen(true);
            const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            setIsLoader(false);

        }
    };



    const handleSerialClickData = async (serial) => {

        const storeSessionId = localStorage.getItem('sessionId');
        try {
            const { sessionId } = await SessionTime.HandleValidatSessiontime(phoneNumber);
            if (storeSessionId === sessionId) {
                try {
                    // Fetch data from meterDetails for the given serial
                    const serialDataRef = database.ref(`/adminRootReference/meterDetails/${serial}`);
                    const snapshot = await serialDataRef.once('value');
                    const serialData = snapshot.val();
                    console.log('111111111', serialData);

                    const billDataKeys = Object.keys(serialData.billData);
                    if (billDataKeys.length > 0) {
                        const firstChildKey = billDataKeys[0].split(' ')[1];

                        const serialData = parseInt(firstChildKey); // Parse the input as an integer
                        if (isNaN(serialData) || serialData < 0) {
                            // console.error("Invalid timestamp");
                        } else {
                            const timestampDate = new Date(serialData);

                            if (isNaN(timestampDate.getTime())) {
                                console.error("Invalid date");
                            } else {
                                const year = timestampDate.getFullYear().toString().slice(2);
                                const month = ('0' + (timestampDate.getMonth() + 1)).slice(-2);
                                const day = ('0' + timestampDate.getDate()).slice(-2);
                                const hours = ('0' + timestampDate.getHours()).slice(-2);
                                const minutes = ('0' + timestampDate.getMinutes()).slice(-2);
                                const seconds = "00";
                                const formattedDate = `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}.`;
                                //  console.log("Formatted Time: ", formattedDate);
                                setFormerttedTimeOnMeter(formattedDate);
                            }
                        }
                        // Logging the result
                        //    console.log("First child key under billData:", firstChildKey);
                    } else {
                        // console.log("No keys under billData.");
                    }

                    setKwhData(serialData.kwhData);
                    const monthData = Object.entries(serialData.billData);
                    // Use slice to exclude the first element (index 0)
                    const updatedMonthData = monthData.slice(1);
                    setMonthData(updatedMonthData);
                    if (serialData && serialData.reConfigToken) {
                        const reConfigtime = serialData.reConfigToken;
                        //  console.log("tokenGeneratedTime111111111111111: ",serialData.reConfigToken.tokenUsedTime);
                        const tokenGeneratedTime = reConfigtime.tokenUsedTime;
                        // console.log("tokenGeneratedTime: ", tokenGeneratedTime);
                        const serialData1 = parseInt(tokenGeneratedTime); // Parse the input as an integer
                        if (isNaN(serialData1) || serialData1 < 0) {
                            //    console.error("Invalid timestamp");
                        } else {
                            const timestampDate = new Date(serialData1); // Use serialData1 instead of tokenGeneratedTime
                            if (isNaN(timestampDate.getTime())) {
                                console.error("Invalid date");
                            } else {
                                const year = timestampDate.getFullYear().toString().slice(2);
                                const month = ('0' + (timestampDate.getMonth() + 1)).slice(-2);
                                const day = ('0' + timestampDate.getDate()).slice(-2);
                                const hours = ('0' + timestampDate.getHours()).slice(-2);
                                const minutes = ('0' + timestampDate.getMinutes()).slice(-2);
                                const seconds = "00";
                                const formattedTime = `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
                                // console.log("Formatted Time: ", formattedTime);
                                setReconfig(formattedTime);
                                // setFormattedTime(formattedTime);
                            }
                        }
                    } else {
                        /// console.error("serialData or reConfigToken is undefined");
                    }

                    const updatedBillData = serialData.ocData;
                    // Convert object keys to an array and reverse it

                    console.log(`aaaaaaaaaaaaaa11`, updatedBillData);

                    const keysArray = Object.keys(updatedBillData).reverse();

                    console.log(`iuhuihihiuhi:`, keysArray);
                    // Iterate over the reversed keys array
                    keysArray.forEach((key) => {
                        console.log(`Key: ${key}, Value:`, updatedBillData[key]);
                    });

                    // If you want to update the state with reversed data, you can create a reversed object
                    const reversedBillData = keysArray.reduce((acc, key) => {
                        acc[key] = updatedBillData[key];
                        return acc;
                    }, {});

                    console.log(`reversedBillData`, reversedBillData);
                    setBillData(reversedBillData);

                } catch (error) {
                    ///  console.error('Error fetching serial data:', error);
                }

            } else {

                alert("You have been logged-out due to log-in from another device.");
                // console.log('you are logg out ');
                handleLogout();
            }
        } catch (error) {
            setIsDialogOpen(true);
            const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            setIsLoader(false);

        }

    };


    const handleMeterList = async (serial) => {
        // console.log('phone number from meterDetails ', phoneNumber);
        try {

            const serialDataRef = database.ref(`/adminRootReference/adminDetails/${phoneNumber}/meterList/${serial}`);
            const snapshot = await serialDataRef.once('value');
            const serialData1 = snapshot.val();
            //  console.log('meter server info 111:', serialData1);

            //   setIsLoader(false);           
            const serialData = parseInt(serialData1); // Parse the input as an integer
            if (isNaN(serialData) || serialData < 0) {
                // console.error("Invalid timestamp");
            } else {
                const timestampDate = new Date(serialData);

                if (isNaN(timestampDate.getTime())) {
                    // console.error("Invalid date");
                } else {
                    const year = timestampDate.getFullYear().toString().slice(2);
                    const month = ('0' + (timestampDate.getMonth() + 1)).slice(-2);
                    const day = ('0' + timestampDate.getDate()).slice(-2);
                    const hours = ('0' + timestampDate.getHours()).slice(-2);
                    const minutes = ('0' + timestampDate.getMinutes()).slice(-2);
                    const seconds = "00";
                    const formattedDate = `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
                    // console.log("Formatted Time: ", formattedDate);
                    setFormerttedTime(formattedDate);
                }
            }

        } catch (error) {
            ///  console.error('Error fetching meter data:', error);
            // Handle error (e.g., show an error message to the user)
        }
    }


    const handleGenerateButton = async (serial) => {

        try {
            const serialDataRef = database.ref(`/adminRootReference/meterDetails/${serial}`);
            const snapshot = await serialDataRef.once('value');
            const serialData = snapshot.val();

            if (!serialData || !serialData.rechargeToken) {
                //  console.error("Serial data or recharge token not available");
                return;
            }
            /// console.log("Generate recharge token ", serialData.rechargeToken);

            const rechargeData = serialData.rechargeToken;

            // Convert the object to an array of key-value pairs
            const dataArray = Object.entries(rechargeData);

            setIsLoader(false);

            // Reverse the array
            const reversedArray = dataArray.reverse();

            // Exclude the last element in the reversed array
            // const excludedLastElementArray = reversedArray.slice(0, reversedArray.length - 1);
            const excludedLastElementArray = reversedArray.slice(0, reversedArray.length - 0);

            // Convert the excluded last element array back to an object
            const reversedObject = Object.fromEntries(excludedLastElementArray);

            // Set the reversed data in your state
            setRechargData(reversedObject);
        } catch (error) {
            //    console.error("Error fetching or processing data:", error.message);
        }
    };


    const history = useNavigate();
    const handleLogout = () => {
        auth.signOut().then(() => {
            // Redirect to login page after successful logout
            localStorage.removeItem('LoggIn', 'true');
            history('/'); // Change '/login' to your login page route

        }).catch((error) => {
            // Handle any errors during logout
            console.error('Error logging out:', error.message);
        })

    }


    const SessionUpdate = (numberPart) => {
        SessionTime.updateSessionTimeActiveUser(numberPart);
    }

    const handleSharePhone = () => {

        // console.log('Share Via Phone ' ,selectedSerialData.phone);

    }

    const [modalMessage, setModalMessage] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const closeDialog = () => {
        setIsDialogOpen(false);
        // window.location.reload(); // This will reload the page
        window.onpopstate = null;
    };


    const [selectedSerialData, setSelectedSerialData] = useState({
        name: '',
        phone: '',
        email: '',
        doo: '',
    });

    const handleChange = (e, field) => {
        setSelectedSerialData({
            ...selectedSerialData,
            [field]: e.target.value,
        });
    };







    const serialRefs = useRef({});
    const groupRefs = useRef({});

    useEffect(() => {
        if (highlightedSerial && serialRefs.current[highlightedSerial]) {
            const navbarHeight = 0.35 * window.innerHeight; // 1% of window height
            const elementTop = serialRefs.current[highlightedSerial].getBoundingClientRect().top;
            window.scrollTo({
                top: elementTop + window.pageYOffset - navbarHeight,
                behavior: 'smooth'
            });
        }
    }, [highlightedSerial]);





    useEffect(() => {
        if (highlightGroupName && groupRefs.current[highlightGroupName]) {
            const navbarHeight = 0.35 * window.innerHeight; // 1% of window height
            const elementTop = groupRefs.current[highlightGroupName].getBoundingClientRect().top;
            window.scrollTo({
                top: elementTop + window.pageYOffset - navbarHeight,
                behavior: 'smooth'

            });
            //  groupRefs.current[highlightGroupName].scrollIntoView({ behavior: 'smooth' });
        }
    }, [highlightGroupName]);


    return (
        <>

            {isLoader ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}
            <>

                <Modal show={modalIsOpen} onHide={handleCloseModal} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Meter Details on server </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>

                            {selectedSerialInfo && (
                                <div className="selected-serial-info">
                                    {
                                        selectedSerialInfo.serial ? (
                                            <div>
                                                <h4 style={{ color: 'DodgerBlue' }}>Meter Details</h4>
                                                <p>Serial: {selectedSerialInfo.serial || 'Not Available'}</p>
                                                <p>Available Balance (₹): {kwhData && kwhData.balance !== undefined ? kwhData.balance : 'Not Available'}</p>
                                                <p>TotalKwh (₹): {kwhData && kwhData.totalKwh !== undefined ? kwhData.totalKwh : 'Not Available'}</p>
                                                <p>Earth Tamper Kwh (₹): {kwhData && kwhData.tamperKwh !== undefined ? kwhData.tamperKwh : 'Not Available'}</p>
                                                <p>Configuration: {formettedtime || 'Not Available'}</p>
                                                <p>Last Re-Configuration: {reconfig || 'Not Available'}</p>
                                                <p>Current Tariff (₹): {selectedSerialData.tariff && selectedSerialData.tariff !== undefined ? selectedSerialData.tariff : 'Not Available'}</p>
                                                <p>Location: {selectedSerialData.location || 'Not Available'}</p>
                                                <p>Meter Data update to server: {formettedtimeonmeter || 'Not Available'}</p>

                                            </div>
                                        ) : (
                                            <p>not available</p>
                                        )
                                    }

                                    <hr></hr>


                                    <h4 style={{ color: 'DodgerBlue' }}> Present Consumer Details</h4>

                                    {selectedSerialInfo && (
                                        <div>


                                            <label htmlFor='groupName'>GroupName</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                //  value={selectedSerialInfo.groupName}
                                                value={selectedSerialInfo.groupName.replace(/_/g, ' ')}
                                                disabled
                                                readOnly
                                            />


                                        </div>
                                    )}

                                    {selectedSerialData && (
                                        <div>

                                            <label htmlFor="name">Name </label>
                                            <input
                                                type="text"
                                                id="name"
                                                className='form-control'
                                                name="name"
                                                value={selectedSerialData.name}
                                                placeholder="Name"
                                                onChange={(e) => handleChange(e, 'name')} // Add onChange handler
                                                readOnly
                                                disabled

                                            />
                                            <label htmlFor="mobile">Mobile Number  </label>
                                            <input
                                                type="tel"
                                                id="mobile"
                                                className='form-control'
                                                name="mobile"
                                                value={selectedSerialData.phone}
                                                placeholder="Mobile"
                                                onChange={(e) => handleChange(e, 'phone')} // Add onChange handler
                                                readOnly
                                                disabled
                                            />

                                            <label htmlFor="mobile">Alternet Mobile Number  </label>
                                            <input
                                                type="tel"
                                                id="mobile"
                                                className='form-control'
                                                name="mobile"
                                                value={selectedSerialData.altPhone || "Not Available"}
                                                placeholder="Mobile"
                                                onChange={(e) => handleChange(e, 'phone')} // Add onChange handler
                                                readOnly
                                                disabled
                                            />
                                            <label htmlFor="email">Email </label>
                                            <input
                                                type="email"
                                                id="email"
                                                className='form-control'
                                                name="email"
                                                value={selectedSerialData.email || 'na'}
                                                placeholder="Email"
                                                onChange={(e) => handleChange(e, 'email')} // Add onChange handler
                                                readOnly
                                                disabled

                                            />
                                            <label htmlFor="doo">Date of Occupancy  </label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                id="doo"
                                                name="doo"
                                                value={selectedSerialData.doo}
                                                placeholder="DOO"
                                                onChange={(e) => handleChange(e, 'doo')} // Add onChange handler
                                                readOnly
                                                disabled

                                            />


                                        </div>

                                    )}

                                    <hr></hr>

                                    <div>
                                        <h4 style={{ color: 'DodgerBlue' }}>Generated Recharge Token History (last 20 Tokens)</h4>
                                        {Object.entries(rechargdata).length > 1 ? (
                                            Object.entries(rechargdata).map(([timestamp, billDetails]) => (
                                                <div key={timestamp}>
                                                    {/* Display specific serial data properties in separate <p> tags */}
                                                    {timestamp !== 'tokenCount' && billDetails && (
                                                        <>
                                                            {/* <p>Timestamp: {timestamp}</p> */}

                                                            {billDetails.tokenId === '00' && (
                                                                <p style={{ backgroundColor: 'green', color: 'white', width: '40%' }}>Emergency Recharge</p>
                                                            )}
                                                            <p>Token ID: {billDetails.tokenId && billDetails.tokenId !== undefined ? billDetails.tokenId : 'Not Available'}</p>
                                                            <p>Generated Time: {billDetails.tokenGenerationTime && billDetails.tokenGenerationTime !== undefined ? billDetails.tokenGenerationTime : 'Not Available'}</p>
                                                            <p>Amount: {billDetails.rechargeAmount && billDetails.rechargeAmount !== undefined ? billDetails.rechargeAmount : 'Not Available'}</p>
                                                            <p>Tariff: {billDetails.tariffRate && billDetails.tariffRate !== undefined ? billDetails.tariffRate : 'Not Available'}</p>
                                                            <p>Cumulative kwh: {billDetails.kwh && billDetails.kwh !== undefined ? billDetails.kwh : 'Not Available'}</p>
                                                            <p>Balance at time of recharge: {billDetails.balance && billDetails.balance !== undefined ? billDetails.balance : 'Not Available'}</p>
                                                            {/* <p>Link: {billDetails.link}</p> */}
                                                            <div style={{ display: 'flex' }}>

                                                                <p style={{ margin: '10px', padding: '10px' }}>Share Via </p>

                                                                <FacebookShareButton style={{ margin: '10px' }} url={billDetails.link}>
                                                                    <FacebookIcon size={35} round={true} url={billDetails.link} />
                                                                </FacebookShareButton>

                                                                <EmailShareButton style={{ margin: '10px' }} url={billDetails.link}>
                                                                    <img width="38" height="38"
                                                                        src="https://img.icons8.com/fluency/48/gmail-new.png"
                                                                        url={billDetails.link}
                                                                        alt="gmail-new"
                                                                    />
                                                                </EmailShareButton>

                                                                <WhatsappShareButton style={{ margin: '10px' }} url={billDetails.link}>
                                                                    <WhatsappIcon size={35} round={true} url={billDetails.link} />
                                                                </WhatsappShareButton>

                                                                <div style={{ margin: '10px' }}>
                                                                    <img width="38" height="38"

                                                                        src={PhoneshareIcond}
                                                                        // url={billDetails.link}
                                                                        alt="Phone send "
                                                                        onClick={handleSharePhone}
                                                                    />

                                                                </div>


                                                            </div>

                                                            <hr></hr>

                                                        </>
                                                    )}

                                                </div>
                                            ))
                                        ) : (
                                            <p>Not available</p>
                                        )}
                                    </div>
                                    <hr></hr>

                                    <div>
                                        <h4 style={{ color: 'DodgerBlue' }}>Over Credit Token History (Last 20 Token)</h4>
                                        {billData && Object.entries(billData).length > 0 ? (
                                            Object.entries(billData).map(([timestamp, billDetails]) => (
                                                <div key={timestamp}>
                                                    {/* Display specific serial data properties in separate <p> tags */}
                                                    <p style={{ backgroundColor: 'green', color: 'white', width: '40%' }}>Over Credit Recharge</p>
                                                    {/* <p>Timestamp: {timestamp}</p> */}
                                                    <p>Token ID: OC</p>
                                                    <p>Generated Time: {billDetails.tokenGenerationTime && billDetails.tokenGenerationTime !== undefined ? billDetails.tokenGenerationTime : 'Not Available'}</p>
                                                    <p>Amount: {billDetails.rechargeAmount && billDetails.rechargeAmount !== undefined ? billDetails.rechargeAmount : 'Not Available'}</p>
                                                    <p>tariff: {billDetails.tariffRate && billDetails.tariffRate !== undefined ? billDetails.tariffRate : 'Not Available'}</p>
                                                    <p>Cumulative Kwh: {billDetails.kwh && billDetails.kwh !== undefined ? billDetails.kwh : 'Not Available'}</p>
                                                    <p>Balance at the time of recharge: {billDetails.balance && billDetails.balance !== undefined ? billDetails.balance : 'Not Available'}</p>
                                                    <hr></hr>
                                                </div>
                                            ))
                                        ) : (
                                            <p>Not available</p>
                                        )}
                                    </div>

                                    <hr></hr>

                                    <div>
                                        <h4 style={{ color: 'DodgerBlue' }}>Billing History (Last 6 Months)</h4>
                                        {monthData.length > 0 ? (
                                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                    <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                                                        <th style={{ padding: '8px', textAlign: 'left' }}>Month</th>
                                                        <th style={{ padding: '8px', textAlign: 'left' }}>Consumed Kwh</th>
                                                        <th style={{ padding: '8px', textAlign: 'left' }}>MD (Kw)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {monthData.map(([key, value], index) => (
                                                        <tr key={key} style={{ borderBottom: '1px solid #ddd' }}>
                                                            <td style={{ padding: '8px', textAlign: 'left' }}>{value.billKwh !== '-' ? key.substring(1) : null} </td>
                                                            <td style={{ padding: '8px', textAlign: 'left' }}>{value.billKwh !== '-' ? `${value.billKwh} ` : null}</td>
                                                            <td style={{ padding: '8px', textAlign: 'left' }}>{value.billMd && value.billMd !== '-' ? `${value.billMd} ` : null}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <p>Not available</p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="justify-content-center">
                        <Button onClick={handleCloseModal}>Close </Button>
                    </Modal.Footer>


                </Modal>


                <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
                    {/* <Modal.Header closeButton>
      </Modal.Header>  */}
                    <Modal.Body>
                        <p style={{ color: 'red' }}> {modalMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={closeDialog}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <div className='MainClass'>
                <div>
                    {extractGroupNames().length > 0 && (
                        <h5 style={{ backgroundColor: '#e3f2fd', display: 'inline', padding: '3px' }} > Meter(s) with consumer details</h5>
                    )}
                </div>
                {extractGroupNames().map((groupName, groupIndex) => (
                    <div key={groupIndex}>
                        <h6
                            className={` EditGRoupname ${highlightGroupName === groupName ? 'highlighted' : ''}`}
                            ref={(el) => groupRefs.current[groupName] = el}
                        >{groupName.replace(/_/g, ' ')}</h6>
                        {/* Render items with names for each group */}
                        <div className="rowContainer" style={{ cursor: 'pointer' }}>
                            {data[groupName] && Object.entries(data[groupName]).map(([serial, info], index) => (
                                serial !== 'tariff' && info && (
                                    <div key={index}
                                        ref={(el) => (serialRefs.current[serial] = el)}
                                        className={`groupcustomBox ${highlightedSerial === serial ? 'highlight' : ''}`}
                                        onClick={() => handleSerialClick(serial, info )}>
                                        <p> {serial}  </p>
                                        <p className='Nameparagraph'> {info.name}</p>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                ))}

                </div>

            </>
        </>




    );
}

export default Singlegroupmeter;