import React, { useState, useEffect } from 'react';
import Navbar from '../adminLogin/navbar';
import GenerateToken from './generateToken';
import PendingTokens from './requestpendingtoken';
import { auth } from '../adminLogin/firebase';
import 'firebase/compat/database';

function Generaterecharge() {
    const [activeComponent, setActiveComponent] = useState('Pendingtoken');
    const [loading, setLoading] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                    setLoading(false);
                }
            } else {
                window.location.href = '/';
            }
        });

        return () => unsubscribe();
    }, []);

    const handleSidebarItemClick = (componentName) => {
        setActiveComponent(componentName);
        setIsSidebarVisible(false); // Hide the sidebar
    };

    const renderComponent = () => {
        switch (activeComponent) {
            case 'ChangeMobile':
                return <GenerateToken />;
            case 'TransferMeter':
                return <PendingTokens />;
            default:
                return <PendingTokens />;
        }
    };

    const getSidebarItemStyle = (componentName) => {
        return {
            fontSize: '18px',
            cursor: 'pointer',
            backgroundColor: activeComponent === componentName ? '#2196f3' : 'transparent',
            color: activeComponent === componentName ? 'white' : 'black',
            padding: '10px',
            borderRadius: '5px',
            margin: '5px 0'
        };
    };

    const toggleSidebar = () => {
        setIsSidebarVisible(prevState => !prevState);
    };

    return (
        <>
            <Navbar /> 
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '9999',
                    }}
                >
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
            
            <div className={`sidebar-toggle ${isSidebarVisible ? 'hidden' : ''}`} onClick={toggleSidebar}>
                {isSidebarVisible ? 'X' : '☰'}
            </div>
            <div className='openMenu'>
                <div className={`sidebarss ${isSidebarVisible ? 'visible' : ''}`}>
                    <div className='groups-container'>
                        <div className="" style={{ backgroundColor: '#e3f2fd' }}>
                            <p
                                style={getSidebarItemStyle('ChangeMobile')}
                                className="paragraph"
                                onClick={() => handleSidebarItemClick('ChangeMobile')}
                            >
                                 Generate Recharge Token for Single Meter(s)
                            </p>
                        </div>
                        <div className="" style={{ backgroundColor: '#e3f2fd' }}>
                            <p
                                style={getSidebarItemStyle('TransferMeter')}
                                className="paragraph"
                                onClick={() => handleSidebarItemClick('TransferMeter')}
                            >
                                Generate Recharge Token for Pending Request(s)
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ marginTop: '8%', width: '80%' }}>
                    {renderComponent()}
                </div>
            </div>
        </>
    );
}

export default Generaterecharge;



// import '../adminLogin/login.css';
// import React, { useState, useEffect, useRef } from 'react';
// import { database } from '../firebase';
// import { auth } from '../adminLogin/firebase';
// import Navbar from '../adminLogin/navbar';
// import Singlemeter from './singlemeter';
// import Adin from '../admin';
// import './generete.css';
// import { useNavigate } from 'react-router-dom';
// import { Modal, Button } from 'react-bootstrap';
// import Swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';
// import CommonFunctions from '../commonfunction';
// import Sidebar from '../sidebar';

// function Generaterecharge() {
//   const Sessionid = new CommonFunctions();
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [user, setUser] = useState(null);
//   const [activeComponent, setActiveComponent] = useState('Adin'); // Set default to 'Adin'
//   const [loading, setLoading] = useState(false);
//   const [modalMessage, setModalMessage] = useState('');
//   const [isDialogOpen, setIsDialogOpen] = useState(false);
//   const [isSidebarVisible, setIsSidebarVisible] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     document.title = "Generate Recharge Token"; // Set the title when the component mounts

//     const unsubscribe = auth.onAuthStateChanged((authUser) => {
//       if (authUser) {
//         setUser(authUser);
//         const emailParts = authUser.email.split('@');
//         if (emailParts.length === 2) {
//           const number = emailParts[0];
//           setPhoneNumber(number);
//           updateSessionActiveTime(number);
//         }
//       } else {
//         setUser(null);
//         navigate('/'); // Redirect to your login page
//       }
//     });

//     return () => unsubscribe(); // Cleanup function for unmounting
//   }, [navigate]);

//   const handleSidebarItemClick = async (componentName) => {
//     setLoading(true);
//     const status = await Sessionid.checkInternetConnection();

//     if (!navigator.onLine || status === 'Poor connection.') {
//       setModalMessage('No/Poor Internet connection. Cannot access server.');
//       setIsDialogOpen(true);
//       setLoading(false);
//       return;
//     }

//     const storeSessionId = localStorage.getItem('sessionId');
//     try {
//       const { sessionId } = await Sessionid.HandleValidatSessiontime(phoneNumber);
//       if (storeSessionId === sessionId) {
//         Sessionid.updateSessionTimeActiveUser(phoneNumber);
//         setActiveComponent(componentName);
//       } else {
//         alert("You have been logged-out due to log-in from another device.");
//         handleLogout();
//       }
//     } catch (error) {
//       setModalMessage(`Response not received from server. Please check your internet connection. (${error})`);
//       setIsDialogOpen(true);
//     }
//     setLoading(false);
//   };

//   const updateSessionActiveTime = (number) => {
//     Sessionid.updateSessionTimeActiveUser(number);
//   };

//   const handleLogout = () => {
//     auth.signOut().then(() => {
//       navigate('/'); // Redirect to login page after successful logout
//     }).catch((error) => {
//       console.error('Error logging out:', error.message);
//     });
//   };

//   const toggleSidebar = () => {
//     setIsSidebarVisible(prevState => !prevState);
//   };

//   const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
//   const toggleSidebars = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   const [sidebarMarginTop, setSidebarMarginTop] = useState(20); // Default margin-top
//   const contentRef = useRef(null);

//   useEffect(() => {
//     if (contentRef.current) {
//       const contentHeight = contentRef.current.offsetHeight;
//       setSidebarMarginTop(contentHeight + 20); // Adjust margin as needed
//     }
//   }, [contentRef.current]);

//   return (
//     <>

     
//       <div className="sidebar-toggle" onClick={toggleSidebar}>
//         <img
//           src="https://img.icons8.com/material-outlined/24/expand-arrow.png"
//           alt="Toggle Sidebar"
//         />
//       </div>
//       <div className={`sidebarss ${isSidebarVisible ? 'visible' : ''}`}>
//         <div className='heading-containers'>
//           <p
//             className={`sidebar-item ${activeComponent === 'Singlemeter' ? 'active' : ''}`}
//             onClick={() => handleSidebarItemClick('Singlemeter')}
//           >
//             Generate Recharge Token for Single Meter
//           </p>
//         </div>
//         <div className='heading-containers'>
//           <p
//             className={`sidebar-item ${activeComponent === 'Adin' ? 'active' : ''}`}
//             onClick={() => handleSidebarItemClick('Adin')}
//           >
//             Generate Recharge Token for Pending Request(s)
//           </p>
//         </div>
//       </div>
//       <div className={`main-contents ${isSidebarVisible ? 'sidebar-visible' : ''}`}>
//         {activeComponent === 'Singlemeter' && <Singlemeter />}
//         {activeComponent === 'Adin' && <Adin />}
//       </div>



//       {loading && (
//         <div style={{
//           position: 'fixed',
//           top: 0,
//           left: 0,
//           width: '100%',
//           height: '100%',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           zIndex: '9999'
//         }}>
//           <div className="spinner-border text-danger" role="status">
//             <span className="sr-only">Loading...</span>
//           </div>
//         </div>
//       )}

//       <Modal show={isDialogOpen} onHide={() => setIsDialogOpen(false)} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
//         <Modal.Body>
//           <p>{modalMessage}</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="primary" onClick={() => setIsDialogOpen(false)}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default Generaterecharge;
