
import React, { useState } from 'react';
import { functions, httpsCallable } from './adminLogin/firebase';

const Message =async (message, email)=> {
  // const [email, setEmail] = useState('');
  // const [message, setMessage] = useState('');

  // const handleSendMessage = async () => {
    try {
      // Get the Cloud Function reference
      const sendMessage = httpsCallable(functions, 'sendMessage');

      // Call the Cloud Function with the required data
      const result = await sendMessage({ email, message });

      // Handle the result
      console.log(result.data); // Assuming the Cloud Function returns an object with a 'result' property
      return result.data;

    } catch (error) {
      // Handle errors
      console.error('Error calling sendMessage function:', error);
      return error;
    }
  };
  
export default Message;
