import React, { useEffect, useState } from 'react'
import { auth } from '../adminLogin/firebase';
import firebase from 'firebase/compat/app'; // Import the Firebase app (latest version)
import 'firebase/compat/database';
import 'sweetalert2/dist/sweetalert2.min.css';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';


function TokenStatus() {
   const [user, setUser] = useState(null);
   const navigate = useNavigate();
   const [meterList, setMeterList] = useState([]);

   const [tokenStatus, setTokenStatus] = useState({});
   const [showMeterListAlert, setShowMeterListAlert] = useState(false); // State to control alert visibility
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((authUser) => {
         if (authUser) {
            setUser(authUser);
            const emailParts = authUser.email.split('@');
            if (emailParts.length === 2) {
               const numberPart = emailParts[0];
               //console.log('Number part is ', numberPart);
               handleSearch1(numberPart);
            }
         } else {
            setUser(null);
            window.location.href = '/';
         }
      });

      return () => unsubscribe();
   }, []);

   const handleSearch1 = async (numberPart) => {
      try {
         const snapshot = await firebase.database().ref(`/adminRootReference/adminDetails/${numberPart}/newMeterList`).once('value');
         const fetchedMeterList = snapshot.val();
         /// console.log("meterList ", fetchedMeterList);
         if (fetchedMeterList) {
            const meterIds = Object.keys(fetchedMeterList);
            setMeterList(meterIds);
            meterIds.forEach(async (serialNumber) => {
               await isTokenAvailable(serialNumber);
            });
            setLoading(false);
         } else {
            setMeterList([]);
            setShowMeterListAlert(true);
            setLoading(false);
         }
      } catch (error) {
         console.error('Error fetching admin meter list:', error);
         setMeterList([]);
      }
   };

   const isTokenAvailable = async (serialNumber) => {
      try {
         const meterDetailsPath = firebase.database().ref(`adminRootReference/meterDetails/${serialNumber}/reConfigToken`);
         const snapshot = await meterDetailsPath.once('value');
         const newData = snapshot.val();
         if (newData !== null) {
            const isTransfer = newData.isTransfer;
            const tokenStatus = newData.tokenStatus;
            let status;
            if (tokenStatus === '0' && isTransfer === 'false') {
               status = "N";
            } else if (tokenStatus === '0' && isTransfer === 'true') {
               status = "T";
            } else {
               status = "";
            }
            setTokenStatus(prevState => ({
               ...prevState,
               [serialNumber]: status
            }));
         }
      } catch (e) {
         console.error('Error Fetching:', e);
      }
   };

   const handleCloseMeterListAlert = () => {
      setShowMeterListAlert(false);
      navigate('/');
   };

   return (
      <>

         {loading && (
            <div
               style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: '9999',
               }}
            >
               <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         )}

         <div className='MeterList'>
            <div style={{backgroundColor:'#e3f2fd' ,  display:'inline', padding:"5px"}}>
               <label htmlFor="reconfigureTokenCheckbox">
                  Note : Meter serial number with latest pending re-configuration token
               </label>
            </div>

            <div >
               {meterList.map((serial, index) => (
                  <div key={index} className='customBox'>
                     <div style={{display:'flex',  }}>
                        <p>{serial}</p>
                        <p style={{ color: 'red',}}>{tokenStatus[serial]}</p>
                     </div>
                  </div>
               ))}
            </div>
         </div>
         
         {showMeterListAlert && (
            <div className="alert-overlay">
               <Alert
                  variant="filled"
                  severity="error"
                  onClose={handleCloseMeterListAlert}
                  className='AlertDailog'
               >
                  No configure meter found
               </Alert>
            </div>
         )}
      </>
   )
}

export default TokenStatus;
