import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './adminLogin/login'
import Home from './adminLogin/home'
import Signup from './adminLogin/signup'
import Welcome from './adminLogin/welcome'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Createaccount from './adminLogin/createaccount'
import Signin from './adminLogin/signin'
import Getotp from './adminLogin/getotp'
import Send from './adminLogin/sendmail'
import Adminpage from './adminLogin/adminpage'
import Phonesendotp from './adminLogin/phonesendotp'
import Forgetpaasword from './adminLogin/forgetpassword'
import Verifyphone from './adminLogin/verifyphone'
import Profile from './adminLogin/profile'
import NewApp from './NewApp'
import Verifyphoneotp from './adminLogin/verifyphoneotp'
import Meterdetail from './meterdetailonserver/meterdetail'
import Forgetpassverify from './adminLogin/fogetpassverify'
import Showpass from './adminLogin/showpassword'
import Admindetail from './admin/admindetail'
import Sendphoneotp from './admin/sendphoneotp'
import Verifynumber from './admin/verifunumber'
import Emailsendotp from './admin/emailsendotp'
import Phoneemailotp from './admin/phoneemailotp'
import Emailverify from './admin/emailverify'
import Sendemailotp from './admin/email'
import Phoneemailverify from './admin/phoneemailverify'
import Consumerdetails from './consumerdetials/consumerdetail'
import NameUpdater from './admin/new'
import Groupdetails from './meterdetailonserver/groupdetails';
import Generaterecharge from './generaterecharge/generateallrecharge';
import Ungroup from './meterserver/ungroup';
import Singlegroupmeter from './meterserver/singlegroupmeter';
import Servermeter from './meterserver/servermeter';
import Message from './message';
import Homepage from './reconfigtoken/reconfigtoken'
import Phonepasswordchange from './reconfigtoken/phonepasswordchange';
import Phoneotp from './reconfigtoken/phoneotp';
import Phoneemail from './reconfigtoken/phoneemail';
import Phoneandenailverify from './reconfigtoken/emailandphoneverify';
import Wel from './reconfigtoken/wel';
import Passwordchange from './reconfigtoken/passwordchange';
import Passwordotp from './reconfigtoken/passwordotp';
import Phoneemailotpverify from './reconfigtoken/phoneemailotpverify';
import Transfer from './transfertoken/transefer';
import Transferphoneemail from './transfertoken/transferphoneemail'
import Createtime from './admin/createtime'
import Pendingtoken from './generaterecharge/pendingtoken'
import Ratan from './testapp/ratan'
import Homepagelogin from './home/homepagelogin'
import InternetConnect from './InternetConnect'
import Header from './navbar/header';
import RemoverMeter from './removeMeter/removeMeter'
import GenerateReconfig from './generateConfigToken/generateConfig'
import ChangeMobile from './generateConfigToken/changeMobile'
import TokenStatus from './generateConfigToken/tokenStatus';
import TranseferMemter from './generateConfigToken/transferMeter'
import Bottom from './navbar/botton'
import ConsumerDetails from './consumerdetials/meterdetial'
import Meterdetialserver from './meterserver/meterdetailserver'
import MainMenu from './mainenu';

import Navbar from './adminLogin/navbar';

function App() {


  // Remove Duplate tab (do not open duplicate Tab )
  useEffect(() => {
    const checkDuplicatePage = () => {
      localStorage.openpages = Date.now();
      window.addEventListener('storage', function (e) {
        if (e.key === 'openpages') {
          localStorage.page_available = Date.now();
        }
        if (e.key === 'page_available') {
          //  setIsDialogOpen(true);
          //  setModalMessage('Page already open. Please use the original page instead');
          // alert('Page already open. Please use the original page instead.');
          // Open a new window with a URL (can be about:blank or any other)
          const newWindow = window.open('about:blank', '_self');
          // Close the current tab when the alert is dismissed
          newWindow.onload = () => window.close();
        }
      }, false);
    };
    checkDuplicatePage();
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener('storage', checkDuplicatePage);
    };
  }, []); // Empty dependency array to run this effect only once

  //   //isable Inspect Menu 
  // useEffect(() => {
  //   document.addEventListener('contextmenu', handleContextMenu);
  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);

  // const handleContextMenu = (event) => {
  //   event.preventDefault(); // Prevent the default right-click behavior
  //   alert('This function is not allowed here.'); // Show an alert message
  // };

  return (
    <>



    
      <Router>
        <div className='app'>
      
          <section>
            <Routes>
        
       
              <Route path="/mainenu" element={<MainMenu />} />
              <Route path="/meterdetial" element={<ConsumerDetails />} />
              <Route path="/meterdetailserver" element={<Meterdetialserver />} />
              <Route path="/generateConfig" element={<GenerateReconfig />} />
              <Route path="/header" element={<Header />} />
              <Route path="/botton" element={<Bottom />} />
              <Route path="/ratan" element={<Ratan />} />

              <Route path="/home" element={<Home />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path='/welcome' element={<Welcome />} />
              <Route path='/createaccount' element={<Createaccount />} />
              <Route path='/signin' element={<Signin />} />
              <Route path='/getotp' element={<Getotp />} />
              <Route path='/adminpage' element={<Adminpage />} />
              <Route path='/sendmail' element={<Send />} />
              <Route path='/phonesendotp' element={<Phonesendotp />} />
              <Route path='/forgetpassword' element={<Forgetpaasword />} />
              <Route path='/verifyphone' element={<Verifyphone />} />
              <Route path='/verifyphoneotp' element={<Verifyphoneotp />} />

              <Route path='/profile' element={<Profile />} />
              <Route path='/NewApp' element={<NewApp />} />
              <Route path='/meterdetail' element={<Meterdetail />} />
              <Route path='/fogetpassverify' element={<Forgetpassverify />} />
              <Route path='/showpassword' element={<Showpass />} />
              <Route path='/admindetail' element={<Admindetail />} />
              <Route path='/sendphoneotp' element={<Sendphoneotp />} />
              <Route path='/verifunumber' element={<Verifynumber />} />
              <Route path='/emailsendotp' element={<Emailsendotp />} />
              <Route path='/phoneemailotp' element={<Phoneemailotp />} />
              <Route path='/emailverify' element={< Emailverify />} />
              <Route path='/email' element={< Sendemailotp />} />
              <Route path='/phoneemailverify' element={< Phoneemailverify />} />
              <Route path='/new' element={< NameUpdater />} />
              <Route path='/consumerdetail' element={< Consumerdetails />} />
              <Route path='/groupdetails' element={<Groupdetails />} />


              <Route path='/generateallrecharge' element={<Generaterecharge />} />

              <Route path='/ungroup' element={<Ungroup />} />

              <Route path='/singlegroupmeter' element={<Singlegroupmeter />} />
              <Route path='/servermeter' element={<Servermeter />} />
              <Route path='/message' element={<Message />} />
              <Route path='/reconfigtoken' element={<Homepage />} />
              <Route path='/phonepasswordchange' element={<Phonepasswordchange />} />
              <Route path='/phoneotp' element={<Phoneotp />} />
              <Route path='/phoneemail' element={<Phoneemail />} />
              <Route path='/emailandphoneverify' element={<Phoneandenailverify />} />
              <Route path='/wel' element={<Wel />} />
              <Route path='/passwordchange' element={<Passwordchange />} />
              <Route path='/passwordotp' element={<Passwordotp />} />
              <Route path='/phoneemailotpverify' element={<Phoneemailotpverify />} />
              <Route path='/transefer' element={<Transfer />} />
              <Route path='/transferphoneemail' element={<Transferphoneemail />} />
              <Route path="/createtime" element={<Createtime />} />
              <Route path="/pendingtoken" element={<Pendingtoken />} />

              <Route path="/" element={<Homepagelogin />} />
              <Route path="/InternetConnect" element={<InternetConnect />} />
              <Route path="/removeMeter/*" element={<RemoverMeter />} />
              <Route path="/removeMeter/*" element={<RemoverMeter />} />

              <Route path="changeMobile" element={<ChangeMobile />} />
              <Route path="transferMeter" element={<TranseferMemter />} />
              <Route path="tokenStatus" element={<TokenStatus />} />

            </Routes>
          </section>
        </div>

      </Router>




    </>
  )
}

export default App
