import React, { useState, useEffect } from 'react';
import Send from './sendmail'; // Import the Send component
import { useNavigate } from 'react-router-dom';
import PhoneSendOtp from './phonesendotp';
import { ref,  getDatabase } from 'firebase/database';
// Make sure to import the Firebase database instance if not already imported

import CommonFuctions from '../commonfunction';
import './login.css';
import Mainhomepage from '../home/mainhomepage'
import { Modal, Button } from 'react-bootstrap';


const Forgetpassword = () => {
  const SessionTime = new CommonFuctions();
  const navigate = useNavigate();
 // const localphoneNumber = location.state?.phoneNumber || '';
  const [loading, setLoading] = useState(true);
  // const phoneNumber = location.state && location.state.phoneNumber;
  const { email, setEmail, errorMessage, handleButtonClick } = Send();
  //const { phoneNumber, responseMessage, handleInputChange, sendOTP, validatePhoneNumber } = Phonesendotp();
  const [modalMessage, setModalMessage] = useState('');


  const localphoneNumber = localStorage.getItem('forgetphoneNumber');
  const [adminEmail, setAdminEmail] = useState('');


  useEffect(() => {
    setEmail(email); // Set initial email from location state
  }, [setEmail,]);


  useEffect(() => {
    const fetchData = async () => {
      const status = await SessionTime.checkInternetConnection(); // Assuming SessionTime is properly defined

      if (!navigator.onLine) {
        setIsDialogOpen(true);
        setModalMessage('No/Poor Internet connection. Cannot access server.');
        setLoading(false);
        return;
      }

      if (status === 'Poor connection.') {
        setIsDialogOpen(true);
        setModalMessage('No/Poor Internet connection. Cannot access server.');
        setLoading(false);
        return;
      }

      const db = getDatabase(); // Assuming getDatabase() returns a valid database instance
      const adminRootReference = ref(db, `adminRootReference/adminDetails/${localphoneNumber}/adminProfile`);
      const path = adminRootReference.toString();
      try {
        const data = await SessionTime.callCloudFunction(path); // Assuming callCloudFunction is properly defined
        const parsedData = JSON.parse(data);
        setLoading(false);

        setAdminEmail(parsedData.email);
        setEmail(parsedData.email);
      } catch (error) {

        setIsDialogOpen(true);
        const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
        setModalMessage(errorMessage);
        setLoading(false);
        return;

      }
    };
    fetchData();
  }, [localphoneNumber]);

  const handleGetOTP = async () => {
    const status = await SessionTime.checkInternetConnection(); // Assuming SessionTime is properly defined 
    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection. Cannot access server.');
      setLoading(false);
      return;
    }

    if (status === 'Poor connection.') {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection. Cannot access server.');
      setLoading(false);
      return;
    }
    /// console.log(' phone number  1212: ', localphoneNumber);
    const phoneSendOtp = new PhoneSendOtp(localphoneNumber);
    const result = phoneSendOtp.sendOTP(localphoneNumber); // Assuming this function sends the OTP 
    // Redirect to another page after sending OTP (change '/other-page' to your desired route)
    localStorage.setItem('forgetEmail', adminEmail);

  };
  const handleButtonClick1 = async (event) => {
    const status = await SessionTime.checkInternetConnection(); // Assuming SessionTime is properly defined 


    setLoading(true);

    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection. Cannot access server.');
      setLoading(false);
      return;
    }

    if (status === 'Poor connection.') {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection. Cannot access server.');
      setLoading(false);
      return;
    }

    event.preventDefault();
    handleButtonClick(event);
    handleGetOTP();
    navigate('/fogetpassverify', { state: { localphoneNumber } });
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };



  return (
    <>

      <div>
        <Mainhomepage />
      </div>

      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}

      <div className='containers'>
        <div className='formgroup'>
          <div>
            <label htmlFor="phoneNumber">Mobile Number</label>
            <div className="input-container1">
              <input
                type="text"
                className='form-control'
                placeholder="Enter phone number"
                defaultValue={localphoneNumber ? `+91${localphoneNumber}` : '+91'}
                onChange={null}
                readOnly
              />
              <i className="fas fa-phone" />
            </div>

           
          </div>
          <div>
            <label htmlFor="phoneNumber">E-mail</label>
            <div className="input-container1">
              <input
                type="email"
                className='form-control'
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly
              />
              <i class="fa-solid fa-envelope"></i>
            </div>

            <p style={{ color: 'red ' }}>{errorMessage}</p>
          </div>
          <div className='d-grid col-4'>
            <button className='btn btn-primary' onClick={handleButtonClick1}>GET OTP</button>
          </div>
        </div>
      </div>


      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>




    </>
  );
};

export default Forgetpassword;
