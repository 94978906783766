import React, { useEffect, useState, } from 'react';
import { auth } from '../adminLogin/firebase';
import 'firebase/compat/database';
import { database } from '../firebase';
import Singlegroupmeter from './singlegroupmeter';
import Ungroup from './ungroup'
import Navbar from '../adminLogin/navbar';
import SearchIcon from '@mui/icons-material/Search';
import CommonFuctions from '../commonfunction';
import { Modal, Button } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';



function Meterdetialserver() {

    const getData = new CommonFuctions();
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const [highlightedSerial, setHighlightedSerial] = useState(null); // State to manage the highlighted serial number
    const [errorMessage, setErrorMessage] = useState(''); // State to manage the error message
    const [phoneNumber, setPhoneNumber] = useState('');
    const [data, setData] = useState({});
    const [meterList, setMeterList] = useState([]);
    const [allMeter, setAllMeter] = useState([]);
    const [allGroupName, setAllGroupName] = useState([]);
    const [loading, setLoading] = useState(true);
    const [highlightGroupName, setHighlightGroupName] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {

                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                    const numberPart = emailParts[0];
                    setPhoneNumber(numberPart);
                    handleGetDatafromFirebar(numberPart)
                }
            } else {

            }
        });
        return () => {
            unsubscribe();
        };
    }, []);


    async function handleGetDatafromFirebar(numberPart) {
        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setLoading(false);
            return;
        }
        const status = await getData.checkInternetConnectionAvailable(); // Call the function
        if (status === 'Poor connection') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setLoading(false);
            return;
        }
        try {

            const storeSessionId = localStorage.getItem('sessionId');
            const { sessionId } = await getData.HandleValidatSessiontime(numberPart);

            if (storeSessionId !== sessionId) {
                setLoading(false);
                setShowAlert(true);
                return;
            }

            const allSerialPathRef = database.ref(`/adminRootReference/tenantDetails/${numberPart}`);
            const updateSerialPath = allSerialPathRef.toString();
            const serialMeterDataRef = database.ref(`/adminRootReference/adminDetails/${numberPart}/newMeterList`);
            const updateMeterPath = serialMeterDataRef.toString();
            const path = [updateSerialPath, updateMeterPath];
            const meterList = await getData.callMultipleReadfunction(path);

            // 《❎🔥   Print tennentDetials  Data form firebase   🔥❎ 》 
            const tennentDetials = JSON.parse(meterList[0]);
            delete tennentDetials.time;
            setData(tennentDetials || {});
            const allGroupname = Object.keys(tennentDetials || {});
            setAllGroupName(allGroupname);

            // 《❎🔥   Print AdminDetials Data form firebase   🔥❎ 》 
            const adminDetails = JSON.parse(meterList[1]);
            delete adminDetails.time;
            if (adminDetails) {

                console.log('Data is ', adminDetails);
                const serialNumbers = Object.keys(adminDetails);

                if (serialNumbers.length === 0) {
                    setshowMeterListAlert(true);
                    setLoading(false);
                }
                else {
                    
                    setAllMeter(serialNumbers)
                    const filteredMeters = Object.entries(adminDetails).filter(([serialNumber, details]) => !details.groupName);
                    const meterIds = filteredMeters.map(([serialNumber, details]) => serialNumber);
                    // console.log('Filtered Meter List:', meterIds);
                    setMeterList(meterIds);
                }
            }


                // else {
                //      console.log('No meter list found for this admin phone');
                //     setshowMeterListAlert(true);
                //     setLoading(false);

                // }

                // Add any additional logic here, such as updating the UI or further processing of the data
            } catch (error) {

                setIsDialogOpen(true);
                const errorMessage = `Response not received from server-A. Please retry. (${error})`;
                setModalMessage(errorMessage);
            }

            finally {
                setLoading(false);
            }
        }


    const handleInputChange = (e) => {
            setSelectedGroup(null);
            setErrorMessage('');
            setHighlightedSerial(null);
            setHighlightGroupName(null);
            const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
            setInputValue(value);
        };

        const handleButtonClick = () => {

            setHighlightedSerial(null);
            setSelectedGroup(null);
            setHighlightGroupName(null);
            // console.log('Number is ', inputValue);

            const trimmedInput = inputValue.trim();
            if (inputValue.length < 6) {
                setErrorMessage('Please enter valid serial number')
                return
            }

            if (trimmedInput === '') {
                setErrorMessage('Please enter a valid Serial number')
            } else {

                if (allMeter.includes(trimmedInput)) {
                    setHighlightedSerial(trimmedInput);
                    setErrorMessage(''); // Clear any previous error message
                }
                else {

                    setErrorMessage('Meter not found ');
                }
            }
        };
        const handleGroupNameClick = (groupName) => {
            if (selectedGroup === groupName) {
                // If the group is already selected, deselect it
                setSelectedGroup(null);
                setHighlightGroupName(null);
            } else {
                setSelectedGroup(selectedGroup === groupName ? null : groupName);
                setHighlightGroupName(groupName);
                setIsSidebarVisible(false);
            }
        }
        const toggleSidebar = () => {
            setIsSidebarVisible(prevState => !prevState);
        };
        const serialNumbers = [];
        const extractSerialNumbers = (obj) => {
            for (const key in obj) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    extractSerialNumbers(obj[key]);
                }
                if (/^\d+$/.test(key)) {
                    serialNumbers.push(key);
                }
            }
        };

        extractSerialNumbers(data);
        const [isDialogOpen, setIsDialogOpen] = useState(false);
        const closeDialog = () => {
            setIsDialogOpen(false);
        };



        const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
        const handleCloseAlert = () => {
            setShowAlert(false); // Close alert when clicked on the close button
            handleLogout();
        };


        const [showMeterListAlert, setshowMeterListAlert] = useState(false); // State to control alert visibility
        const handleCloseMeterlistAlert = () => {
            setshowMeterListAlert(false); // Close alert when clicked on the close button
            navigate('/');
        };




        const history = useNavigate();
        const handleLogout = () => {
            auth.signOut().then(() => {
                history('/'); // Change '/login' to your login page route
            }).catch((error) => {
                //     //     // Handle any errors during logout
                console.error('Error logging out:', error.message);
            })
            /// history('/'); 
        }


        return (
            <>

                <Navbar />
                {loading ? (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '9999'
                    }}>
                        <div className="spinner-border text-danger" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : null}


                {/* <div className={`sidebar-toggle ${isSidebarVisible ? 'hidden' : ''}`} onClick={toggleSidebar}>
                <img
                    src="https://img.icons8.com/material-outlined/24/expand-arrow.png"
                    alt="Toggle Sidebar"
                />
            </div> */}

                <div className={`sidebar-toggle ${isSidebarVisible ? 'hidden' : ''}`} onClick={toggleSidebar}>
                    {isSidebarVisible ? 'X' : '☰'}
                </div>

                <div className={`sidebarss ${isSidebarVisible ? 'visible' : ''}`}>
                    <div className="heading-container">
                        <h2 className="heading">Search Consumer Group </h2>
                    </div>

                    <div className="groups-container">

                        {allGroupName.map((groupName, index) => (
                            <div
                                key={index}

                                className={`customgroupitems ${selectedGroup === groupName ? 'selected' : ''}`}
                                onClick={() => handleGroupNameClick(groupName)}

                            >
                                <div className='cgroup-box'>
                                    <img
                                        src="https://img.icons8.com/fluency/100/user-group-man-woman.png"
                                        className='group-icon'
                                        alt="User Group Icon"
                                    />
                                    <p className='customegroup-name'>
                                        {groupName.replace(/_/g, ' ')}
                                    </p>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>

                <div className={`header1 ${isSidebarVisible ? 'sidebar-visible' : ''}`}>
                    <div className='headerTabs1'>
                        <div className='custom-input-container'>
                            <div className='input-container'>
                                <TextField
                                    error={!!errorMessage} /* Ensure error prop is boolean */
                                    label="Enter Meter Serial No."
                                    id="outlined-size-small"
                                    size="small"
                                    variant="outlined"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    helperText={errorMessage}
                                    inputProps={{ maxLength: 6 }}
                                    InputLabelProps={{ style: { color: 'black' } }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '25px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'black'
                                        },
                                        '& .MuiFormHelperText-root': {
                                            color: 'red',
                                        }
                                    }}
                                />
                            </div>

                            <Button
                                variant="outlined"
                                type="submit"
                                style={{

                                    border: 'none',
                                    background: '#007bff',
                                    color: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    whiteSpace: 'nowrap', // Prevent the button from breaking into multiple lines

                                }}
                                onClick={handleButtonClick}
                            >
                                <SearchIcon />
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={`main-content1 ${isSidebarVisible ? 'sidebar-visible' : ''}`}>
                    <Ungroup
                        highlightedSerial={highlightedSerial}
                        setHighlightGroupName={setHighlightGroupName}
                        setSelectedGroup={setSelectedGroup}
                        data={data}
                        meterList={meterList}
                        phoneNumber={phoneNumber}
                    />
                    <div style={{ marginTop: '1%' }}>
                        <Singlegroupmeter
                            highlightedSerial={highlightedSerial}
                            setHighlightGroupName={setHighlightGroupName}
                            setSelectedGroup={setSelectedGroup}
                            data={data}
                            meterList={meterList}
                            phoneNumber={phoneNumber}
                            highlightGroupName={highlightGroupName} // Pass the selected group name
                        />
                    </div>
                </div>


                <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
                    <Modal.Body>
                        <p style={{ color: 'red' }}> {modalMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={closeDialog}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>




                {showAlert && (
                    <div className="alert-overlay"
                    >
                        <Alert
                            variant="filled"
                            severity="error"
                            onClose={handleCloseAlert}
                            className='AlertDailog'
                        >
                            You have been logged out due to login from another device.!
                        </Alert>
                    </div>
                )}

                {showMeterListAlert && (
                    <div className="alert-overlay"
                    >
                        <Alert
                            variant="filled"
                            severity="error"
                            onClose={handleCloseMeterlistAlert}
                            className='AlertDailog'
                        >
                            No configure meter found
                        </Alert>
                    </div>
                )}





            </>
        )
    }

    export default Meterdetialserver
