import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PhoneSendOtp from '../adminLogin/phonesendotp';
import { useNavigate } from 'react-router-dom';
import { auth } from '../adminLogin/firebase';
import Navbar from '../adminLogin/navbar';
import CommonFuctions from '../commonfunction';
import { Modal, Button } from 'react-bootstrap';
import Alert from '@mui/material/Alert';


function Sendphone() {
  const sessiontime = new CommonFuctions();
  const navigate = useNavigate();
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalMessage, setModalMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [modalMessage1, setModalMessage1] = useState('');

  const { newPhone, newName, newAddress, newEmail } = location.state || {};

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is logged in
        setUser(authUser);
        //  console.log("Logged in user:", authUser.email);
        const emailParts = authUser.email.split('@'); // Split email by '@'
        if (emailParts.length === 2) {
          const number = emailParts[0]; // Get the part before '@'
          // console.log("Extracted number:", number);
          setPhoneNumber(number);
          handleUpdateSession(number)
          setLoading(false);
        }
      } else {
        // No user is logged in, you can redirect to another page or handle accordingly
        setUser(null);
        // Example: Redirect to another page
        window.location.href = '/'; // Redirect to your login page
      }
    });

    return () => unsubscribe(); // Cleanup function for unmounting
  }, []);


  const storedPhoneNumber = localStorage.getItem('AdminAltnumber');

  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;
  };


  const handleGetOTP = async () => {

    setAlertMessage('');
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };

    toggleRefreshAndClose(false); // Prevent refresh and close actions
    setLoading(true);
    // const status = await sessiontime.checkInternetConnection(); // Call the function
    //  setShowChecker(status);

    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server.');
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }


    const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function

    if (status === 'Poor connection') {
      setIsDialogOpen(true);
      setModalMessage('Poor internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

      return;
    }


    // if (status === 'Poor connection.') {
    //   setIsDialogOpen(true);
    //   setModalMessage('No/Poor Internet connection , Please retry.');
    //   toggleRefreshAndClose(true); // Prevent refresh and close actions
    //   setLoading(false);
    //   // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
    //   return;
    // }


    // try {
    //   const isLastActive = await sessiontime.isLastActiveTime(phoneNumber);
    //   if (isLastActive) {
    //     setLoading(false);
    //     toggleRefreshAndClose(true); // Prevent refresh and close actions
    //     handleLogout();
    //     localStorage.setItem('isLoggedIn', true);
    //   }
    //   else {
    //   }
    // } catch (error) {

    //   setIsDialogOpen(true);
    //   setModalMessage(`Response not recieved  from server-S. Please check your internet connection .(${error})`);
    //   setLoading(false);
    //   toggleRefreshAndClose(true); // Prevent refresh and close actions

    // }


    const storeSessionId = localStorage.getItem('sessionId');
    try {
      const { sessionId } = await sessiontime.HandleValidatSessiontime(phoneNumber);
      if (storeSessionId === sessionId) {

        const phoneSendOtp = new PhoneSendOtp(storedPhoneNumber);
        const result = await phoneSendOtp.sendOTP(storedPhoneNumber);
        /// console.log('status_code is :', result);

        if (result == '200') {

          toggleRefreshAndClose(true); // Prevent refresh and close actions
          navigate('/verifunumber', { state: { newPhone, newName, newAddress, newEmail } });
          /// console.log('Opt send successfully ');

        }
        else if (result.status_code == '411') {
          // console.log('Invalid Number ')
          setAlertMessage(`Invalid Number (${result.status_code})`)
          setLoading(false);
          toggleRefreshAndClose(true); // Prevent refresh and close actions

          return;
        }
        else if (result.status_code == '416') {
          setAlertMessage(`Sms service temporarily unavailable. Please try. (${result.status_code})  `);
          setLoading(false);
          toggleRefreshAndClose(true); // Prevent refresh and close actions

          return;
        }
        else if (result == "Network Error") {
          setLoading(false);
          setIsDialogOpen1(true);
          // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
          const errorMessage = `Network error. Please retry. (${result})`;
          setModalMessage1(errorMessage);
          toggleRefreshAndClose(true); // Prevent refresh and close actions
          return;
        }
        else {
          setLoading(false);
          setIsDialogOpen1(true);
          const errorMessage = `Sms service temporarily unavailable. Please try. (${result.status_code}) `;
          setModalMessage1(errorMessage);
          toggleRefreshAndClose(true); // Prevent refresh and close actions

        }


      } else {

        setLoading(false);
        setShowAlert(true);
        
        // alert("Cannot login. Another session is active. Please retry after sometime. ");
        // // console.log('you are logg out ');
        // handleLogout();
      }
    } catch (error) {
      setLoading(false);
      setIsDialogOpen1(true);
      // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
      const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
      setModalMessage1(errorMessage);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

    }
  };
  const history = useNavigate();
  const handleLogout = () => {
    auth.signOut().then(() => {
      // Redirect to login page after successful logout
      history('/'); // Change '/login' to your login page route
    }).catch((error) => {
      // Handle any errors during logout
      ///  console.error('Error logging out:', error.message);
    })

  }



  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => {
    toggleRefreshAndClose(true); // Prevent refresh and close actions

    setIsDialogOpen(false);
    // window.location.reload(); // This will reload the page
  };
  const [isDialogOpen1, setIsDialogOpen1] = useState(false);
  const closeDialog1 = () => {
    toggleRefreshAndClose(true); // Prevent refresh and close actions
    setIsDialogOpen1(false);
    // navigate('/admindetail');
    //   window.location.reload(); // This will reload the page
  };

  const handleBackButton = () => {
    navigate('/admindetail');
  }

  const handleUpdateSession = async (number) => {

    // const status = await sessiontime.checkInternetConnection(); // Call the function
    const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function

    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server. ');
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }

    if (status === 'Poor connection.') {
      setIsDialogOpen(true);
      setModalMessage('Poor internet. Cannot access server.');
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }
    try {
      sessiontime.updateSessionTimeActiveUser(number);
    } catch (error) {
      setLoading(false);
      setIsDialogOpen1(true);
      // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
      const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
      setModalMessage1(errorMessage);

    }
  }

  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

  const handleCloseAlert = () => {
    setShowAlert(false); // Close alert when clicked on the close button
    handleLogout();
  };



  return (
    <>

      <div>
        <Navbar />
      </div>

      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}

      <div className='containers'>
        <div className='formgroup'>
          {/* <h3>Verify Mobile Number</h3> */}
          <div>
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              className='form-control'
              placeholder="Phone Number"
              defaultValue={newPhone ? `+91${newPhone}` : (storedPhoneNumber ? `+91${storedPhoneNumber}` : '+91')}
              readOnly
              disabled
            />
            {alertMessage && (
              <div className="alert-container">
                <p style={{ color: 'red' }}><i className="fas fa-exclamation-circle" style={{ color: 'red' }}></i> {alertMessage}</p>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center w-100">
            <button className="btn btn-danger" onClick={handleBackButton} style={{ marginRight: '50px' }}>Back</button>
            <button className='btn btn-primary' onClick={handleGetOTP}>Get OTP</button>
          </div>
        </div>

      </div>



      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p style={{ color: 'red' }}> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* REsponse from session vaidation  */}

      <Modal show={isDialogOpen1} onHide={closeDialog1} backdrop="static" style={{ marginTop: '3%' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p style={{ color: 'red' }}> {modalMessage1}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      {showAlert && (
        <div className="alert-overlay"
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={handleCloseAlert}
            className='AlertDailog'
          >
            You have been logged out due to login from another device.!
          </Alert>
        </div>
      )}





    </>
  )
}

export default Sendphone;
