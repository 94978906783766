import React, { useEffect, useState } from 'react';
import { auth } from '../adminLogin/firebase';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import CommonFuctions from '../commonfunction';
import Navbar from '../adminLogin/navbar';
import { Modal, Button } from 'react-bootstrap';

import firebase from 'firebase/compat/app'; // Import the Firebase app
import 'firebase/compat/firestore';
import PhoneSendOtp from '../adminLogin/phonesendotp';
import ResendTimer from '../adminLogin/resendotp'
import EmailSendotp from './email'

import Alert from '@mui/material/Alert';




function Emailverify() {

  const navigate = useNavigate();
  const sessiontime = new CommonFuctions();
  const [user, setUser] = useState(null);
  const [mobileOTP, setMobileOTP] = useState('');
  const [emailOTP, setEmailOTP] = useState('');
  
  const [mobileOTPError, setMobileOTPError] = useState('');
  const [emailOTPError, setEmailOTPError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const storedEmail = localStorage.getItem('AdminEmail');
  const newName = localStorage.getItem('AdminName');
  const newAddress = localStorage.getItem('AdminAddress');
  const newPhone = localStorage.getItem('AdminAltnumber');

  const { handleButtonClick } = EmailSendotp({ email: storedEmail }); // Pass the email as a prop


  // const { initialEmail, newName, newAddress, newPhone } = location.state || {};

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        //  console.log('Logged in user:', authUser.email);
        const emailParts = authUser.email.split('@');
        if (emailParts.length === 2) {
          const number = emailParts[0];
          //   console.log('Extracted number:', number);
          setPhoneNumber(number);
          fetchdata(number)
          UpdateSession(number);
          setLoading(false);
        }
      } else {
        setUser(null);
        window.location.href = '/';
      }
    });

    return () => unsubscribe();
  }, []);




  const UpdateSession = async (number) => {

    const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function
    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server.');
      setLoading(false);
      return;
    }

    if (status === 'Poor connection.') {
      setIsDialogOpen(true);
      setModalMessage('Poor internet. Cannot access server.');
      setLoading(false);
      return;
    }

    try {

      sessiontime.updateSessionTimeActiveUser(number);


    } catch (error) {
      setLoading(false);
      setIsDialogOpen(true);
      // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
      const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
      setModalMessage(errorMessage);

    }

  }


  const [parsedData, setParsedData] = useState('');
  const fetchdata = async (number) => {

    const FetchAdmin = firebase.database().ref(`adminRootReference/adminDetails/${number}`);
    const Admindetialsref = FetchAdmin.toString();
    try {
      const result = await sessiontime.callCloudFunction(Admindetialsref);
      const parsedData = JSON.parse(result);
      /// console.log('admin daTa : ', parsedData);
      setParsedData(parsedData);
    } catch (error) {
      //   console.log('Data Not fect from FDB');
    }
  }

  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;
  };

  const handleSubmitClick = async (e) => {

    e.preventDefault();
    if (mobileOTP === '') {
      setMobileOTPError('Invalid mobile OTP');
      return;
    }
    if (emailOTP === '') {
      setMobileOTPError('Invalid mobile OTP');
      return;
    }
    const storedPhoneOTP = localStorage.getItem('otp'); // Get stored phone OTP
    const storedEmailOTP = localStorage.getItem('emailOTP'); // Get stored email OTP
    if (mobileOTP !== storedPhoneOTP) {
      setMobileOTPError('Invalid mobile OTP');
      return;
    }
    if (emailOTP !== storedEmailOTP) {
      setEmailOTPError('Invalid email OTP');
      return;
    }
    if (mobileOTP === storedPhoneOTP && emailOTP === storedEmailOTP) {


      setLoading(true);
      toggleRefreshAndClose(false); // Prevent refresh and close actions
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, null, window.location.href);
      };

      //   const status = await sessiontime.checkInternetConnection(); // Call the function

      const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function

      //  setShowChecker(status);




      if (!navigator.onLine) {
        setIsDialogOpen(true);
        setModalMessage('No internet. Cannot access server.');
        setLoading(false);
        toggleRefreshAndClose(true); // Prevent refresh and close actions

        return;
      }

      if (status === 'Poor connection.') {
        setIsDialogOpen(true);
        setModalMessage('Poor internet. Cannot access server.');
        setLoading(false);
        toggleRefreshAndClose(true); // Prevent refresh and close actions

        // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
        return;
      }

      // const result = await sessiontime.isLastActiveTime(phoneNumber);
      // if (result) {
      //   window.onpopstate = null;
      //   setLoading(false);
      //   toggleRefreshAndClose(true); // Prevent refresh and close actions
      //   handleLogout();
      //   localStorage.setItem('isLoggedIn', true);
      //   return;
      // }


      const storeSessionId = localStorage.getItem('sessionId');
      try {
        const { sessionId } = await sessiontime.HandleValidatSessiontime(phoneNumber);
        if (storeSessionId === sessionId) {

          const db = getDatabase();
          const adminProfilePath = `adminRootReference/adminDetails/${phoneNumber}/adminProfile`;
          const AdminfRef = firebase.database().ref(`adminRootReference/adminDetails/${phoneNumber}/adminProfile`);
         

          const saveAdminReference = AdminfRef.toString();
          const snapshot = await get(ref(db, adminProfilePath));
          const existingData = snapshot.val();
          const existingKey = existingData.key;
          const existingPassword = existingData.password;
          const newData = {
            // Update email in the newData object
            address: newAddress || '', // Retain the existing address
            email: storedEmail || existingData.storedEmail || '', // Replace '' with a default value if needed
            key: existingKey, // Retain the existing key
            name: newName || '', // Replace '' with a default value if needed
            password: existingPassword, // Retain the existing password
            phoneNo: phoneNumber || '', // Replace '' with a default value if needed
            phoneNo2: newPhone || '', // Replace '' with a default value if needed
            // Add other fields as needed
          };

          const dataToSend = {
            [saveAdminReference]: newData,
          };

          try {

            toggleRefreshAndClose(true); // Prevent refresh and close actions
            await sessiontime.callWriteRtdbFunction(dataToSend);
            sessiontime.updateSessionTimeActiveUser(phoneNumber);
            localStorage.removeItem('AdminEmail');
            localStorage.removeItem('AdminName');
            localStorage.removeItem('AdminAddress');
            localStorage.removeItem('AdminAltnumber');
            localStorage.removeItem('otp');
            localStorage.removeItem('emailOTP');
            // console.log('Update Succesffullyyy ');
            setModalMessage('Data Saved Successfully.');
            setisDialogOpenDataSave(true)
      
            setLoading(false);
          }
          catch (error) {
            setLoading(false);
            setisDialogOpenDataSave(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Response not recieved  from server-A. Please check if transaction completed successfully, else retry.  (${error}).`;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
          }

        } else {

          setLoading(false);
          setShowAlert(true);

        }

      } catch (error) {

        setLoading(false);
        setIsDialogOpen(true);
        toggleRefreshAndClose(true); // Prevent refresh and close actions
        const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
        setModalMessage(errorMessage);
      }

    } else {

    }
  };


  const [otpSendMessage, setotpSendMessage] = useState('');

  const resendOtp = async () => {
    let phoneSuccess = false;
    let emailSuccess = false;
    // Send OTP to phone
    try {
      const phoneSendOtp = new PhoneSendOtp(phoneNumber);
      const phoneResult = await phoneSendOtp.sendOTP(phoneNumber);
      if (phoneResult == '200') {

        phoneSuccess = true;

      }
      else if (phoneResult.status_code == '411') {
        console.log('Invalid Number ')
        setMobileOTPError(`Invalid Number (${phoneResult.status_code})`)
        setLoading(false);
        return;
      }
      else if (phoneResult.status_code == '416') {
        setMobileOTPError(`Sms service temporarily not available. Please try.  (${phoneResult.status_code}) `);
        setLoading(false);
        return;
      }
      else if (phoneResult == "Network Error") {
        setLoading(false);
        setIsDialogOpen(true);
        // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
        const errorMessage = ` Network error. Please retry. (${phoneResult})`;
        setModalMessage(errorMessage);

        return;
      }
      else {
        setLoading(false);
        setIsDialogOpen(true);
        const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${phoneResult})`;
        setModalMessage(errorMessage);
        toggleRefreshAndClose(true); // Prevent refresh and close actions

      }


      // if (phoneResult === 411) {
      //   setMobileOTPError(`Invalid phone number: (${phoneResult})`);
      // } else {
      //   phoneSuccess = true;
      // }


    } catch (phoneError) {
      // console.error('Error sending OTP to phone:', phoneError);
    }
    // Send OTP to email
    try {
      const emailResult = await handleButtonClick(storedEmail);
      if (typeof emailResult === 'boolean') {
        if (emailResult) {
          emailSuccess = true;
          /// console.log('OTP sent to email successfully.');
        } else {
          setEmailOTPError('Error sending OTP to email. Please try again.');
        }
      } else {
        ///console.error('Error:', emailResult);
      }
    } catch (emailError) {
      // console.error('Error sending OTP to email:', emailError);
    }

    // Check if both OTPs were sent successfully
    if (phoneSuccess && emailSuccess) {
      setotpSendMessage(' OTP sent successfully.');
    } else {
      setEmailOTPError('OTP sending failed.');
    }
  };


  const handleMobileOTPChange = (e) => {
    setotpSendMessage('');
    const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    setMobileOTP(input);
    setMobileOTPError('');

  };

  const handleEmailOTPChange = (e) => {
    setotpSendMessage('');
    const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characterssdffs
    setEmailOTP(input);
    setEmailOTPError('');
  };

  const handleSubmitClick1 = async (e) => {
    if (mobileOTP === '') {
      setMobileOTPError('Invalid mobile OTP');
      return;
    }

    if (emailOTP === '') {

      setMobileOTPError('Invalid mobile OTP');
      return;
    }
    // const storeSessionId = localStorage.getItem('sessionId');
    // try {
    //   const { sessionId } = await sessiontime.HandleValidatSessiontime(phoneNumber);
    //   if (storeSessionId === sessionId) {

    setLoading(true);
    const status = await sessiontime.checkInternetConnection(); // Call the function
    //  setShowChecker(status);
    if (status === 'Poor connection.') {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection , Please retry.');
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }
    e.preventDefault(); // Prevent default form submission

  };

  const history = useNavigate();
  const handleLogout = () => {
    auth.signOut().then(() => {
      history('/'); // Change '/login' to your login page route
    }).catch((error) => {
      // Handle any errors during logout
      console.error('Error logging out:', error.message);
    })
  }



  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => {
    window.onpopstate = null;
    setIsDialogOpen(false);
  };

  const [isDialogOpenDataSave, setisDialogOpenDataSave] = useState(false);

  const closeDialogDataSave = () => {
    window.onpopstate = null;
    setisDialogOpenDataSave(false);
    navigate('/admindetail');
    window.location.reload(); // This will reload the page
  };


  const handleBackButton = () => {
    window.onpopstate = null;
    navigate('/admindetail');
  }


  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

  const handleCloseAlert = () => {
    setShowAlert(false); // Close alert when clicked on the close button
    handleLogout();
  };



  return (
    <>
      <div>
        <Navbar />
      </div>

      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}


      <div className='containers'>
        <div className='formgroup'>
          {/* <div>
            
              <h3>Enter OTP</h3>
            </div> */}
          <div>
            <label htmlFor="mobileOTP">Enter Mobile OTP</label>
            <input
              type="text"
              className='form-control'
              placeholder="Mobile OTP"
              value={mobileOTP}
              onChange={handleMobileOTPChange}
              maxLength={6}
            />
            {mobileOTPError && <p style={{ color: 'red' }} className="error">{mobileOTPError}</p>}
          </div>
          <div>
            <label htmlFor="emailOTP">Enter E-mail OTP</label>
            <input
              type="text"
              className='form-control'
              placeholder="E-mail OTP"
              value={emailOTP}
              onChange={handleEmailOTPChange}
              maxLength={6}
            />
            <p style={{ color: 'red' }} className="error">{emailOTPError}</p>
            <span style={{ color: 'blue' }}>{otpSendMessage}</span>
            
          </div>

          <div className="d-flex justify-content-center w-100">

            <button type="submit" className='btn btn-primary' style={{ marginRight: '50px' }} onClick={handleSubmitClick}>
              VERIFY
            </button>

            <div>

              <ResendTimer onClick={resendOtp} />
            </div>



          </div>



        </div>
      </div>





      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={isDialogOpenDataSave} onHide={closeDialogDataSave} backdrop="static" style={{ marginTop: '3%' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialogDataSave}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>




      {showAlert && (
        <div className="alert-overlay"
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={handleCloseAlert}
            className='AlertDailog'
          >
            You have been logged out due to login from another device.!
          </Alert>
        </div>
      )}




    </>
  );
}

export default Emailverify;
