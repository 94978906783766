import { useEffect, useState } from 'react';
import React from 'react';
import { auth } from './firebase'; // Ensure this import corresponds to your Firebase setup

function Home() {
  

  return (
    <div>
      <h3>This is home page </h3>
    </div>
  );
}

export default Home;
