import React from 'react'
import Sidebar from './sidebar'


function MainMenu() {
    return (
        <>
                <Sidebar />    
        </>

    )
}

export default MainMenu
