import React, {  useEffect } from 'react';
import { functions, httpsCallable } from '../adminLogin/firebase'; // Adjust the path accordingly

const EmailSendotp = ({ email }) => {
  useEffect(() => {
    renderReCaptcha();
  }, []);

  const handleButtonClick = async () => {

    // console.log('call function ', email);
    try {
      const grecaptcha = window.grecaptcha; // Ensure the reCAPTCHA Enterprise library is loaded globally

      if (grecaptcha.enterprise) {
         await grecaptcha.enterprise.execute('6Lf2xj8pAAAAAPLYWM0no195q0oMmhLLOo6Y8o00', { action: 'LOGIN' });
        if (email && validateEmail(email)) {
        //  sendEmailOTP(email); // Use passed email to send OTP
          return sendEmailOTP(email); // Return the result of sending OTP
        } else {
        //  console.log('Please enter a valid email address.');
         // return false; // Return false for invalid email
          return false; // Return false for invalid email

        }
      } else {
       // console.error('reCAPTCHA Enterprise library not loaded.');
        return false; // Return false for invalid email

      }
    } catch (error) {
     // console.error('Error executing reCAPTCHA:', error);
     // console.log('Error sending OTP. Please try again.');
      return error; // Return false for invalid email

    }
  };

  const sendEmailOTP = async (email) => {
    try {
      const sendEmailCallable = httpsCallable(functions, 'sendEmail');
      const result = await sendEmailCallable({ email });

      if (result.data) {
        const emailOTP = result.data; // Assuming the OTP is directly in result.data
        console.log('OTP sent by email:', emailOTP);
        localStorage.setItem('emailOTP', emailOTP); // Store email OTP in local storage
      //  console.log('OTP sent successfully.');
        return true; // Return true if OTP is sent successfully
      } else {
       // console.log('Error sending OTP. Please try again.');
        return false; // Return false if OTP sending fails
      }
    } catch (error) {
      // console.log('Error sending OTP. Please try again.');
      /// console.error('Error sending email:', error.message);
     // return false; // Return false if OTP sending fails due to an error
      return error.message; // Return the error message if OTP sending fails due to an error

    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Function to render reCAPTCHA
  const renderReCaptcha = () => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/enterprise.js?render=explicit';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  };

  // Return the handleButtonClick function for use in the component
  return {
    handleButtonClick,
  };
};

export default EmailSendotp;
