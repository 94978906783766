import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneSendOtp from '../adminLogin/phonesendotp';
import Send from '../adminLogin/sendmail'; // Import the Send component
import { auth } from '../adminLogin/firebase';
import Navbar from '../adminLogin/navbar';
import { Modal, Button } from 'react-bootstrap';
import CommonFuctions from '../commonfunction';
import firebase from 'firebase/compat/app'; // Import the Firebase app (latest version)
import 'firebase/compat/database';


function Transfer() {
  const SessionTime = new CommonFuctions();
  const location = useLocation();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalMessage, setModalMessage] = useState('');
  const phonenumberlist = localStorage.getItem('phonenumberlist');
  //const initialEmail = localStorage.getItem('transferEmail');

  const [initialEmail, setinitialEmail] = useState('');



  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is logged in
        // console.log("Logged in user:", authUser.email);
        const emailParts = authUser.email.split('@');
        if (emailParts.length === 2) {
          
          const numberPart = emailParts[0];
          handleSearch();
        }
      } else {

        window.location.href = '/'; // Redirect to your login page
      }
    });
    return () => {

      unsubscribe(); // Cleanup function for unmounting
    };
  }, []);





  // const initialEmail = location.state?.transferEmail || ''; // Get email from location state
  const { setEmail, errorMessage, handleButtonClick } = Send(); // Using your custom hook
  useEffect(() => {
    setEmail(initialEmail); // Set initial email from location state
  }, [setEmail, initialEmail]);

  const handleSearch = async () => {
    const snapshot = await firebase.database().ref(`/adminRootReference/adminDetails/${phonenumberlist}/adminProfile/email`).once('value');
    const fetchedMeterList = snapshot.val();
    setinitialEmail(fetchedMeterList);
    setLoading(false);
  }


  const handleGetOTP = async () => {
    const phoneSendOtp = new PhoneSendOtp(phonenumberlist);
    const result = await phoneSendOtp.sendOTP(phonenumberlist);

    if (result == '200') {
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      /// navigate('/verifunumber', { state: { newPhone, newName, newAddress, newEmail } });
      /// console.log('Opt send successfully ');
      navigate('/transferphoneemail', {
        state: {
          phoneNumber: phonenumberlist,
          message: 'OTP has been sent successfully.'
        }
      });

    }
    else if (result.status_code == '411') {
      //  console.log('Invalid Number ')
      setAlertMessage(`Unable to send sms. Please check mobile no. (${result.status_code})`)
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }
    else if (result.status_code == '416') {
      setAlertMessage(`)SMS service is temporarily unavailable. Please retry. (${result.status_code}) `);
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }
    else if (result == "Network Error") {
      setLoading(false);
      setIsDialogOpen(true);
      // const errorMessage = `Network error. Please retry(${error}). `;
      const errorMessage = `Network error. Please retry. (${result})`;
      setModalMessage(errorMessage);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }
    else {
      setLoading(false);
      setIsDialogOpen(true);
      const errorMessage = `SMS service is temporarily unavailable. Please retry. (${result})`;
      setModalMessage(errorMessage);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
    }
  };


  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;
  };


  
  const handleButtonClick1 = async (event) => {
    event.preventDefault();
    setAlertMessage('');
    setLoading(true);
    ///  Disable browser interactions
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };

    toggleRefreshAndClose(false); // Prevent refresh and close actions  
    const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function
    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true);
      return;
    }
    if (status === 'Poor connection') {
      setIsDialogOpen(true);
      setModalMessage('Poor internet. Cannot access server.');
      setLoading(false);
      toggleRefreshAndClose(true);
      return;
    }

    handleButtonClick(event);
    handleGetOTP();
    // navigate('/transferphoneemail', { state: { phonenumberlist, transferPassword, transferKey } });
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => {
    window.onpopstate = null;
    setIsDialogOpen(false);
    // window.location.reload(); // This will reload the page
  };



  const history = useNavigate();
  const handleLogout = () => {
    auth.signOut().then(() => {
      // Redirect to login page after successful logout
      localStorage.clear();
      history('/'); // Change '/login' to your login page route
    }).catch((error) => {
      //     // Handle any errors during logout
      ///
      console.error('Error logging out:', error.message);
    })
  }
  return (
    <>

      <div>
        <Navbar />
      </div>

      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}

      <div className='containers'>
        <div className='formgroup'>
          {/* <div>
            <h>Verify Mobile Number & Email-id</h>
          </div> */}

          <div>
            <label htmlFor="phoneNumber">Mobile Number</label>
            <div className='input-container1'>
              <input
                type="text"
                className='form-control'
                placeholder="Enter phone number"
                value={phonenumberlist}
                disabled

              />

              <i class="fa-solid fa-user"></i>
            </div>
            {alertMessage && (
              <div className="alert-container">
                <p style={{ color: 'red' }}><i className="fas fa-exclamation-circle" style={{ color: 'red' }}></i> {alertMessage}</p>
              </div>
            )}



          </div>

          <div>
            <div>
              <label htmlFor="email">E-mail</label>
              <div className='input-container1'>


                <input
                  type="email"
                  className='form-control'
                  placeholder="Enter your email"
                  //value={transferEmail}
                  value={initialEmail}
                  disabled
                />


                <i class="fa-solid fa-envelope"></i>

              </div>
              <p style={{ color: 'red ' }}>{errorMessage}</p>
            </div>

          </div>
          <div className='d-grid col-5'>
            <button className='btn btn-primary' onClick={handleButtonClick1} >GET OTP</button>
          </div>
        </div>
      </div>



      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p style={{ color: 'red' }}> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>




    </>
  )
}

export default Transfer