import React, { useEffect, useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import { getDatabase, ref, get,  } from 'firebase/database';
import { auth } from '../adminLogin/firebase';
import CommonFuctions from '../commonfunction';
import Navbar from '../adminLogin/navbar';
import firebase from 'firebase/compat/app'; // Import the Firebase app
import 'firebase/compat/firestore';
import { Modal, Button } from 'react-bootstrap';
import PhoneSendOtp from '../adminLogin/phonesendotp';
import ResendTimer from '../adminLogin/resendotp'
import Alert from '@mui/material/Alert';



function Verifynumber() {
  const sessiontime = new CommonFuctions();
  const navigate = useNavigate();

  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const newEmail = localStorage.getItem('AdminEmail');
  const newName = localStorage.getItem('AdminName');
  const newAddress = localStorage.getItem('AdminAddress');
  const newPhone = localStorage.getItem('AdminAltnumber');

  // const { newPhone, newName, newAddress, newEmail } = location.state || {};
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalMessage, setModalMessage] = useState('');

  const storedPhoneOTP = localStorage.getItem('otp');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        //   console.log('Logged in user:', authUser.email);
        const emailParts = authUser.email.split('@');
        if (emailParts.length === 2) {
          const number = emailParts[0];
          //  console.log('Extracted number:', number);
          setPhoneNumber(number);
          fetchdata(number);
          sessiontime.updateSessionTimeActiveUser(number);

          setLoading(false);
        }
      } else {
        setUser(null);
        window.location.href = '/';
      }
    });

    return () => unsubscribe();
  }, []);

  const [parsedData, setParsedData] = useState('');
  const fetchdata = async (number) => {

    const FetchAdmin = firebase.database().ref(`adminRootReference/adminDetails/${number}`);
    const Admindetialsref = FetchAdmin.toString();
    try {
      const result = await sessiontime.callCloudFunction(Admindetialsref);
      const parsedData = JSON.parse(result);

      // const newMeterList = parsedData.newMeterList;
      // // Print all serial numbers in newMeterList
      // Object.keys(newMeterList).forEach(serialNumber => {
      //   console.log('Serial Number:', serialNumber);
      // });

      setParsedData(parsedData);

    } catch (error) {
      //   console.log('Data Not fect from FDB');
    }
  }

  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;
  };

  const onSubmitotp = async (e) => {



    e.preventDefault();

    if (otp === '') {
      setError('Invalid mobile OTP');
      return;
    }
    if (otp !== storedPhoneOTP) {
      setError('Invalid mobile OTP');
      return;
    }
    if (otp === storedPhoneOTP) {

      setLoading(true);
      toggleRefreshAndClose(false); // Prevent refresh and close actions
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, null, window.location.href);
      };



      const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function 
      // const status = await sessiontime.checkInternetConnection(); // Call the function
      //  setShowChecker(status);

      if (!navigator.onLine) {
        setIsDialogOpen(true);
        setModalMessage('No internet. Cannot access server.');
        setLoading(false);
        toggleRefreshAndClose(true); // Prevent refresh and close actions
        // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
        return;
      }

      if (status === 'Poor connection.') {
        setIsDialogOpen(true);
        setModalMessage('Poor internet. Cannot access server.');
        setLoading(false);
        toggleRefreshAndClose(true); // Prevent refresh and close actions

        // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
        return;
      }



      const storeSessionId = localStorage.getItem('sessionId');
      try {
        const { sessionId } = await sessiontime.HandleValidatSessiontime(phoneNumber);
        if (storeSessionId === sessionId) {

          const db = getDatabase();
          const adminProfilePath = `adminRootReference/adminDetails/${phoneNumber}/adminProfile`;
          const AdminfRef = firebase.database().ref(`adminRootReference/adminDetails/${phoneNumber}/adminProfile`);
          const saveAdminReference = AdminfRef.toString();
          const snapshot = await get(ref(db, adminProfilePath));
          const existingData = snapshot.val();
          const existingKey = existingData.key;
          const existingPassword = existingData.password;
          // Prepare the updated data
          const newData = {
            address: newAddress || '', // Retain the existing address
            email: newEmail || '', // Replace '' with a default value if needed
            key: existingKey, // Retain the existing key
            name: newName || '', // Replace '' with a default value if needed
            password: existingPassword, // Retain the existing password
            phoneNo: phoneNumber || '', // Replace '' with a default value if needed
            phoneNo2: newPhone || '', // Replace '' with a default value if needed
            // Add other fields as needed
          };

          const updates = {}; // Object to accumulate updates


          if (parsedData !== null) {
            console.log('Parsed Data:', parsedData);

            const newMeterList = parsedData.newMeterList;

            // Iterate through each serial number in newMeterList
            Object.keys(newMeterList).forEach((serialNumber) => {
              console.log('Serial Number:', serialNumber);

              // Construct Firebase path for each serial number
              const path = `/adminRootReference/meterDetails/${serialNumber}/phoneNo2`;
              const phoneNo2 = firebase.database().ref(path).toString();

              // Example of data to send using the constructed path
              updates[saveAdminReference] = newData; // Replace with actual newData
              updates[phoneNo2] = newPhone; // Replace with actual newPhone
            });

          }


          // const dataToSend = {
          //   [saveAdminReference]: newData,
          //   [phoneNo2]: newPhone
          // };


          try {

            //  console.log("data is ", updates);
            await sessiontime.callWriteRtdbFunction(updates);
            sessiontime.updateSessionTimeActiveUser(phoneNumber);
            localStorage.removeItem('AdminEmail');
            localStorage.removeItem('AdminName');
            localStorage.removeItem('AdminAddress');
            localStorage.removeItem('AdminAltnumber');
            localStorage.removeItem('otp');
            console.log('Update Succesffullyyy ');
            setModalMessage('Data Saved Successfully.');
            setisDialogOpenDataSave(true);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            setLoading(false);
          }
          catch (error) {
            setLoading(false);
            setisDialogOpenDataSave(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Response not received from server. Please check through enquiry if transaction completed successfully. (${error}). `;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
          }

        } else {

          toggleRefreshAndClose(true); // Prevent refresh and close actions
          setLoading(false);
          setShowAlert(true);
          // setIsSessionActive(false);
          // alert("Cannot login. Another session is active. Please retry after sometime. ");
          // // console.log('you are logg out ');
          // handleLogout();
        }
      } catch (error) {
        setLoading(false);
        setIsDialogOpen(true);
        const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
        setModalMessage(errorMessage);
        toggleRefreshAndClose(true); // Prevent refresh and close actions
      }

    } else {
      //
      setError('Enter valid OTP.');
    }
  };
  const [otpSendMessage, setotpSendMessage] = useState('');
  const resendOtp = async () => {
    // Send OTP to phone


    const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function 
    // const status = await sessiontime.checkInternetConnection(); // Call the function
    //  setShowChecker(status);

    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server.');
      setLoading(false);

      toggleRefreshAndClose(true); // Prevent refresh and close actions
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }

    if (status === 'Poor connection.') {
      setIsDialogOpen(true);
      setModalMessage('Poor internet. Cannot access server.');
      setLoading(false);

      toggleRefreshAndClose(true); // Prevent refresh and close actions

      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }

    const phoneSendOtp = new PhoneSendOtp(newPhone);
    const result = await phoneSendOtp.sendOTP(newPhone);
    if (result == '200') {
      toggleRefreshAndClose(true);
      setotpSendMessage('OTP sent successfully.')
    }
    else if (result.status_code == '411') {
      //  console.log('Invalid Number ')
      setError(`Invalid Number (${result.status_code})`)
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

      return;
    }
    else if (result.status_code == '416') {
      setError(`Sms service temporarily not available. Please retry. (${result.status_code}) `);
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

      return;
    }
    else if (result == "Network Error") {
      setLoading(false);
      // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
      const errorMessage = `Network error. Please retry. (${result})`;
      setError(errorMessage);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }
    else {
      setLoading(false);
      const errorMessage = `SMS service is temporarily unavailable. Please retry.(${result})`;
      setError(errorMessage);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

    }








    // if (phoneResult === 411) {
    //   setError(`Invalid phone number: (${phoneResult})`);
    // } else {
    //   setotpSendMessage('OTP sent successfully.')

    // }

  }


  const history = useNavigate();
  const handleLogout = () => {
    auth.signOut().then(() => {
      // Redirect to login page after successful logout
      localStorage.removeItem('LoggIn', 'true');
      history('/'); // Change '/login' to your login page route
    }).catch((error) => {
      // Handle any errors during logout
      console.error('Error logging out:', error.message);
    })

  }

  const handleMobileOTPChange = (e) => {
    setotpSendMessage('');
    const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    setOtp(input);
    setError('');
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => {
    // toggleRefreshAndClose(true); // Prevent refresh and close actions
    window.onpopstate = null;
    setIsDialogOpen(false);

  };







  const [isDialogOpenDataSave, setisDialogOpenDataSave] = useState(false);
  const closeDialogDataSave = () => {
    window.onpopstate = null;
    // toggleRefreshAndClose(true); // Prevent refresh and close actions
    setisDialogOpenDataSave(false);
    navigate('/admindetail');
    /// window.location.reload(); // This will reload the page
  };
  
  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

  const handleCloseAlert = () => {
    setShowAlert(false); // Close alert when clicked on the close button
    handleLogout();
  };





  return (
    <>
      <div>
        <Navbar />
      </div>
      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}

      <div className="containers">
        <form className="formgroup">
          <div>
            <label htmlFor="phoneNumber">Enter OTP </label>
            <input
              type="text"
              value={otp}
              className="form-control"
              onChange={handleMobileOTPChange}
              required
              maxLength={6}
              placeholder="Mobile-OTP "
            />
            <div style={{ color: 'red' }}>{error}</div>
            <span style={{ color: 'blue' }} >{otpSendMessage}</span>
          </div>
          <div className="d-flex justify-content-center w-100">
            {/* <button className="btn btn-danger" onClick={handleBackButton} style={{ marginRight: '50px' }}>Back</button> */}

            <button type="submit" className="btn btn-primary" style={{ marginRight: '50px' }} onClick={onSubmitotp}>
              VERIFY
            </button>
            <div>
              <ResendTimer onClick={resendOtp} />
            </div>

          </div>


        </form>

        <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
          <Modal.Body>
            <p> {modalMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closeDialog}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={isDialogOpenDataSave} onHide={closeDialogDataSave} backdrop="static" style={{ marginTop: '3%' }}>
          <Modal.Body>
            <p> {modalMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closeDialogDataSave}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


        {showAlert && (
          <div className="alert-overlay"
          >
            <Alert
              variant="filled"
              severity="error"
              onClose={handleCloseAlert}
              className='AlertDailog'
            >
              You have been logged out due to login from another device.!
            </Alert>
          </div>
        )}



      </div>
    </>
  );
}

export default Verifynumber;
