import React, { useState, useEffect } from 'react'
import Mainhomepage from './mainhomepage'
import Home from '../Images/homepage/Home.png';
import Profile from '../Images/homepage/Profile.png';
import Clients from '../Images/homepage/Clients.png';
import Footer from './footer'
import CommonFuctions from '../commonfunction';
import { Modal, Button } from 'react-bootstrap';


function Homepagelogin() {
  const internet = new CommonFuctions();
 
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');



  ///const status =  internet.checkInternetConnection(); // Call the function
  useEffect(() => {
    const checkInternet = async () => {
      const status = await internet.checkInternetConnection();
     
     /// console.log('Status is', status);
    };
    checkInternet();
    // Cleanup function (optional)
    return () => {
      // Perform cleanup if needed
    };
  }, []); // Empty dependency array to run effect only once on mount


  
  const loggedIn = localStorage.getItem('isLoggedIn');
    // console.log('Log in Values :', loggedIn);
  // Function to handle opening the dialog
  const openDialog = () => {
    setIsDialogOpen(true);
    setModalMessage('You have been logged out due to inactivity.');
  };
  
  // Function to handle closing the dialog
  const closeDialog = () => {
    setIsDialogOpen(false);
    setModalMessage('');
    localStorage.removeItem('isLoggedIn'); // Remove the 'isLoggedIn' data
  };

  useEffect(() => {
    if (loggedIn === 'true') {
      openDialog();
    }
  }, [loggedIn]); // Run this effect whenever `loggedIn` changes



  return (
    <>
      <div>
        <Mainhomepage />
      </div>
      <div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img
          style={{ width: '100%', height: '700px' }}
          src={Home}
          alt="My Image"
        />
      </div>
      <div>
        <img
          style={{ width: '100%' }}
          src={Profile}
          alt="My Image"
        />
      </div>
      <div>
        <img
          style={{ width: '100%' }}
          src={Clients}
          alt="My Image"
        />
      </div>
      <div>
        <Footer />
      </div>


      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static">
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>



  )
}

export default Homepagelogin
