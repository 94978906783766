import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div>
      <button className="toggle-btn" onClick={toggleSidebar}>
        {isOpen ? 'X' : '☰'}
      </button>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <nav className="sidebar-nav">
          <ul>
            <li>
              <NavLink to="/singlemeter" activeClassName="active">
                SingleMeter
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin" activeClassName="active">
                REcharge 
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
