import React, { useEffect, useState } from 'react';
import { auth } from '../adminLogin/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import Send from '../adminLogin/sendmail'; // Import the Send component
import PhoneSendOtp from '../adminLogin/phonesendotp';
import CommonFuctions from '../commonfunction';
import Navbar from '../adminLogin/navbar';
import { Modal, Button } from 'react-bootstrap';
import Alert from '@mui/material/Alert';


function Phoneemailotp() {


    const navigate = useNavigate();
    const sessiontime = new CommonFuctions();
    const [user, setUser] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const location = useLocation();
    const { newName } = location.state || {};
    const { newAddress } = location.state || {};
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState('');
    const initialEmail = localStorage.getItem('AdminEmail');
    const newPhone = localStorage.getItem('AdminAltnumber');

    const { email, setEmail, errorMessage, handleButtonClick } = Send(); // Using your custom hook
    useEffect(() => {
        setEmail(initialEmail); // Set initial email from location state
    }, [setEmail, initialEmail]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {

                setUser(authUser);

                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                    const number = emailParts[0];

                    setPhoneNumber(number);
                    handleUpdateSession(number)
                }
            } else {
                setUser(null);
                window.location.href = '/';
            }
        });

        return () => unsubscribe(); // Cleanup function for unmounting
    }, []);


    const handleUpdateSession = async (number) => {
        // const status = await sessiontime.checkInternetConnection(); // Call the function
        const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function

        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server. ');
            setLoading(false);
            // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
            return;
        }
        if (status === 'Poor connection.') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setLoading(false);
            // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
            return;
        }
        try {
            sessiontime.updateSessionTimeActiveUser(number);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setIsDialogOpen(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
            setModalMessage(errorMessage);

        }
    }


    const [alertMessage, setAlertMessage] = useState('');
    const handleGetOTP = async () => {

        ///  old Code  if need then Unchanged 
        const phoneSendOtp = new PhoneSendOtp(newPhone);
        const result = await phoneSendOtp.sendOTP(newPhone);

        if (result == '200') {
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            navigate('/phoneemailverify', { state: { newName, email, newPhone, newAddress } });

        }
        else if (result.status_code == '411') {
            //  console.log('Invalid Number ')
            setAlertMessage(`Invalid Number (${result.status_code})`)
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions

            return;
        }
        else if (result.status_code == '416') {
            setAlertMessage(`)SMS service is temporarily unavailable. Please retry. (${result.status_code}) `);
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions

            return;
        }
        else if (result == "Network Error") {
            setLoading(false);
            setIsDialogOpen(true);
            // const errorMessage = `Network error. Please retry(${error}). `;
            const errorMessage = `Network error. Please retry. (${result})`;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }
        else {
            setLoading(false);
            setIsDialogOpen(true);
            const errorMessage = `SMS service is temporarily unavailable. Please retry. (${result})`;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions

        }


    };









    let canRefreshOrClose = true; // Allow refresh and close actions by default
    // Function to handle beforeunload event
    const handleBeforeUnload = (event) => {
        if (!canRefreshOrClose) {
            const message = 'Please do not reload this page, data may be lost.';
            event.returnValue = message;
            return message; // Required for some older browsers   
        }
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Function to enable/disable refresh and close actions
    const toggleRefreshAndClose = (enabled) => {
        canRefreshOrClose = enabled;
    };


    const handleButtonClick1 = async (event) => {
        setLoading(true);
        toggleRefreshAndClose(false); // Prevent refresh and close actions
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.href);
        };

        // const status = await sessiontime.checkInternetConnection(); // Call the function

        const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function

        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }

        if (status === 'Poor connection') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }


        // try {
        //     const isLastActive = await sessiontime.isLastActiveTime(phoneNumber);
        //     if (isLastActive) {
        //         setLoading(false);
        //         toggleRefreshAndClose(true); // Prevent refresh and close actions
        //         handleLogout();
        //         localStorage.setItem('isLoggedIn', true);
        //     }
        //     else {
        //     }
        // } catch (error) {

        //     setLoading(false);
        //     toggleRefreshAndClose(true); // Prevent refresh and close actions
        //     setIsDialogOpen(true);
        //     setModalMessage(`Response not recieved  from server-S. Please check your internet connection .(${error})`);

        // }

        const storeSessionId = localStorage.getItem('sessionId');
        try {
            const { sessionId } = await sessiontime.HandleValidatSessiontime(phoneNumber);
            if (storeSessionId === sessionId) {
                event.preventDefault();
                handleButtonClick(event);
                handleGetOTP();

            } else {
                
                toggleRefreshAndClose(true); // Prevent refresh and close actions
                setLoading(false);
                setShowAlert(true);
            }

        } catch (error) {
            setIsDialogOpen(true);
            const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
            setModalMessage(errorMessage);
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
      
        }
    };

    const history = useNavigate();
    const handleLogout = () => {
        auth.signOut().then(() => {
            history('/'); // Change '/login' to your login page route
        }).catch((error) => {
            // Handle any errors during logout
            //  console.error('Error logging out:', error.message);
        })

    }
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const closeDialog = () => {
        window.onpopstate = null;
        setIsDialogOpen(false);
    };

    const handleBackButton = () => {
        navigate('/admindetail');
    }


    const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

    const handleCloseAlert = () => {
        setShowAlert(false); // Close alert when clicked on the close button
        handleLogout();
    };







    return (
        <>
            <div>
                <Navbar />
            </div>

            {loading ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}

            <div className='containers'>
                <div className='formgroup'>
                    {/* <div>
                        <h1>Verify Mobile Number & Email-id</h1>
                    </div> */}

                    <div>
                        <label htmlFor="phoneNumber">Mobile Number</label>
                        <input
                            type="text"
                            className='form-control'
                            placeholder="Enter phone number"
                            defaultValue={newPhone ? `+91${newPhone}` : '+91'}
                            readOnly
                            disabled
                        />
                        {/* Display any response messages */}

                    </div>

                    <div>
                        <label htmlFor="email">E-mail</label>
                        <input
                            type="email"
                            className='form-control'
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            readOnly
                            disabled
                        />
                        <p style={{ color: 'red' }}  >{errorMessage}</p>

                        {alertMessage && (
                            <div className="alert-container">
                                <p style={{ color: 'red' }}><i className="fas fa-exclamation-circle" style={{ color: 'red' }}></i> {alertMessage}</p>
                            </div>
                        )}

                    </div>

                    {/* <div className='d-grid col-4'>
                        <button className='btn btn-primary' onClick={handleButtonClick1} >GET OTP</button>
                    </div> */}

                    <div className="d-flex justify-content-center w-100">
                        <button className="btn btn-danger" onClick={handleBackButton} style={{ marginRight: '50px' }}>Back</button>
                        <button className='btn btn-primary' onClick={handleButtonClick1} >GET OTP</button>

                    </div>

                </div>
            </div>




            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
                {/* <Modal.Header closeButton>
      </Modal.Header>  */}
                <Modal.Body>
                    <p> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {showAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseAlert}
                        className='AlertDailog'
                    >
                        You have been logged out due to login from another device.!
                    </Alert>
                </div>
            )}


        </>


    )
}

export default Phoneemailotp