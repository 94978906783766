// Importing the Realtime Database instance
import firebase from 'firebase/compat/app'; // Import the Firebase app
import 'firebase/compat/firestore'; // Import Firestore
import { ref, getDatabase, } from 'firebase/database';


class CommonFuctions {
  // this function is used for checking is there any memter serial exist in group 
  async isAnyMeterExists(path) {

    // const fireabsePath = path.toString();

    try {
      const response = await this.callCloudFunction(path);
      const parsedData = JSON.parse(response);
      //  console.log('Parsed Data:', parsedData);

      let childCount = 0;
      for (const key in parsedData) {
        if (key !== 'tariff' && key !== 'time') {
          childCount++;
        }
      }
      /// console.log('Number of children (excluding "tariff" and "time"): ', childCount);
      return childCount;

    } catch (error) {
      /// console.error('Error calling cloud function or parsing data:', error)

      throw error;
      // return -1;
    }
  }


  isAnyMeterExist(path) {
    return path.once('value')
      .then(snapshot => {
        let childCount = 0;

        snapshot.forEach(childSnapshot => {
          const childKey = childSnapshot.key;
          /// console.log('Call data count child ',childKey);
          // Exclude the child with the name "tariff"
          if (childKey !== 'tariff') {
            childCount++;
          }
        });
        ///  console.log('Number of children (excluding "tariff"): ', childCount);
        return childCount;
      })
      .catch(error => {
        //  console.error('Error fetching data:', error);
        return -1;
      });
  }



  // callCloudFunction = async (path) => {
  //   try {
  //     // Call the cloud function
  //     const readRtdb = firebase.functions().httpsCallable('readRtdb');
  //     // Provide any data required by the cloud function (if needed)
  //     const response = await readRtdb({ dbRef: path });
  //     // Handle the response
  //     ///   console.log('fetch data from  firebase ',response.data);
  //     return response.data;
  //   } catch (error) {

  //     // console.error('Error calling cloud function: when call session data', error);
  //     throw error;
  //   }
  // };


  // this function is use to delete data from firebase  

  deleteFirebaseReference(path) {

    return path.once('value')
      .then(snapshot => {
        // Check if groupName has no children
        if (!snapshot.exists() || !snapshot.hasChildren()) {
          // Remove the groupName node
          return path.remove();
        } else {
          // groupName has children, do something else or log a message
          console.log('has children, not removed');
          return Promise.resolve(); // Resolve the promise without removing
        }
      })
      .then(() => {
        console.log('Removed');
      })
      .catch(error => {
        console.error('Error:', error);
        return Promise.reject(error);
      });
  }


  // 《❎🔥   Call function from firebase to read data from firebase function...........   🔥❎ 》


  // callCloudFunction = async (path) => {
  //   try {
  //     // Call the cloud function
  //     const readRtdb = firebase.functions().httpsCallable('readRtdb');
  //     // Provide any data required by the cloud function (if needed)
  //     const response = await readRtdb({ dbRef: path });
  //     // Handle the response
  //     ///   console.log('fetch data from  firebase ',response.data);
  //     return response.data;
  //   } catch (error) {

  //     // console.error('Error calling cloud function: when call session data', error);
  //     throw error;
  //   }
  // };





  // 《❎🔥   Read Firbase Cloud Function and Set Timeout🔥❎ 》

  callCloudFunction = async (path) => {
    try {
      // Call the cloud function with a promise that resolves after 5 seconds
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('FirebaseError: internal'));
        }, 10000); // 5000 milliseconds = 5 seconds
      });
      // Call the cloud function and wait for either the response or timeout
      const readRtdb = firebase.functions().httpsCallable('readRtdb');
      const cloudFunctionPromise = readRtdb({ dbRef: path });

      // Wait for either the cloud function response or the timeout
      const response = await Promise.race([cloudFunctionPromise, timeoutPromise]);

      // Handle the response
      return response.data;
    } catch (error) {
      // Handle errors from the cloud function or timeout
      throw error;
    }
  };



  // 《❎🔥   Write  Firbase Cloud Function🔥❎ 》

  // callWriteRtdbFunction = async (data) => {
  //   try {

  //     const writeRtdb = firebase.functions().httpsCallable('writeRtdb');
  //     const response = await writeRtdb(data);
  //     // Return the response data if needed
  //     return response.data;
  //   } catch (error) {
  //     //return error;
  //     throw error;
  //   }
  // };


  // 《❎🔥   Write  Firbase Cloud Function and Set Timeout🔥❎ 》

  callWriteRtdbFunction = async (data) => {
    /// console.log("Call function to update data");
    try {
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('FirebaseError: internal'));
        }, 10000); // Set the timeout period (in milliseconds), e.g., 5000 for 5 seconds
      });

      const writeRtdb = firebase.functions().httpsCallable('writeRtdb');
      const response = await Promise.race([writeRtdb(data), timeoutPromise]);

      // Return the response data if needed
      return response.data;
    } catch (error) {

      throw error;

    }
  };

  // 《❎🔥  Multilple ReadDB  from firebase Cloud Functio  🔥❎ 》

  // callMultipleReadfunction = async (paths) => {
  //   try {
  //     const readRtdb = firebase.functions().httpsCallable('readRtdb');
  //     const promises = paths.map(path => readRtdb({ dbRef: path }));
  //     const responses = await Promise.all(promises);

  //     const data = responses.map(response => response.data);
  //     return data;
  //   } catch (error) {
  //     throw error;
  //   }
  // };


  // 《❎🔥  Multilple ReadDB  from firebase Cloud Function with Timeout 🔥❎ 》

  callMultipleReadfunction = async (paths) => {
    try {
      const readRtdb = firebase.functions().httpsCallable('readRtdb');
      const promises = paths.map(path => readRtdb({ dbRef: path }));

      // Adding a timeout promise
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('Firebase Internal'));
        }, 5000); // 5 seconds timeout
      });

      const responses = await Promise.race([Promise.all(promises), timeoutPromise]);
      const data = responses.map(response => response.data);
      return data;
    } catch (error) {
      throw error;
    }
  };



  //  《❎🔥 Call SignUp Function   🔥❎ 》

  callSignupFunction = async (data) => {
    try {
      const writeRtdb = firebase.functions().httpsCallable('signUp');
      const response = await writeRtdb(data);
      // Return the response data if needed
      // console.log('Reconfig response:', response.data);
      return response.data;
    } catch (error) {
      // Handle error
      //  console.error('Error calling reConfig:', error);
      throw error;
    }
  }



  callReconfigToken = async (data) => {
    try {
      const writeRtdb = firebase.functions().httpsCallable('reConfig');
      const response = await writeRtdb(data);
      // Return the response data if needed
      // console.log('Reconfig response:', response.data);
      return response.data;
    } catch (error) {
      // Handle error
      //  console.error('Error calling reConfig:', error);
      throw error;
    }
  }


  reConfigPassword = async (data) => {
    try {
      const writeRtdb = firebase.functions().httpsCallable('reConfigPassword');
      const response = await writeRtdb(data);
      // Return the response data if needed
      // console.log('Reconfig response:', response.data);
      return response.data;
    } catch (error) {
      // Handle error
      //  console.error('Error calling reConfig:', error);
      throw error;
    }
  }


  // 《❎🔥  Transefer token  🔥❎ 》

  transferToken = async (data) => {

    try {
      const writeRtdb = firebase.functions().httpsCallable('transfer');
      const response = await writeRtdb(data);
      // Return the response data if needed
      // console.log('Reconfig response:', response.data);
      return response.data;
    } catch (error) {
      // Handle error
      //  console.error('Error calling reConfig:', error);
      throw error;
    }

  }


  async HandleValidatSessiontime(adminPhoneNumber) {
    // console.log('function call ');
    try {
      const db = getDatabase(); // Assuming getDatabase is defined elsewhere
      // Session validation process
      const adminProfilePath = `adminRootReference/adminDetails/${adminPhoneNumber}/sessionData`;
      const fullAdminProfilePath = `https://mij-prepaid-meter-default-rtdb.firebaseio.com/${adminProfilePath}`;
      const path = fullAdminProfilePath.toString();
      const data = await this.callCloudFunction(path);
      const parsedData = JSON.parse(data);
      //  console.log('Last active time ',data.lastActive);
      return { lastActive: parsedData.lastActive, sessionId: parsedData.sessionId }; // Return the lastActive and sessionId values as an object
    }
    catch (error) {
      //  console.error('response s ', error);
      // return error; // Return the error instead of throwing it
      throw error;
    }
  }


  async updateSessionTimeInLogIn(PhoneNumber) {
    const db = getDatabase(); // Assuming getDatabase is defined elsewhere
    const adminRootReference = ref(db, `adminRootReference/adminDetails/${PhoneNumber}/sessionData`);
    const serverTimestamp = await this.fireabseServerTimestamp();
    const path = adminRootReference.toString();
    const data = await this.callCloudFunction(path);
    const parsedData = JSON.parse(data);
    //  console.log('sessio time ', parsedData);
    const newData = {
      lastActive: serverTimestamp.toString(),
      sessionId: serverTimestamp.toString(),
      time: parsedData.time,
    };
    const dataToSend = {
      [path]: newData // Wrap your data in an object with the appropriate path key
    };

      await this.callWriteRtdbFunction(dataToSend);
    // console.log("logg in session id update :", result);

    return serverTimestamp;
  }

  async updateSessionTimeActiveUser(PhoneNumber) {
    try {
      const db = getDatabase(); // Assuming getDatabase is defined elsewhere
      const adminRootReference = ref(db, `adminRootReference/adminDetails/${PhoneNumber}/sessionData`);
      const serverTimestamp = await this.fireabseServerTimestamp();
      const path = adminRootReference.toString();
      const data = await this.callCloudFunction(path);
      const parsedData = JSON.parse(data);
      //  console.log('sessio time ', parsedData);
      const newData = {
        lastActive: serverTimestamp.toString(),
        sessionId: parsedData.sessionId,
        time: serverTimestamp.toString(),
      };
      const dataToSend = {
        [path]: newData // Wrap your data in an object with the appropriate path key
      };
      await this.callWriteRtdbFunction(dataToSend);
    }
    catch (error) {
      //  console.error("Error in Session id update or not ", error);
      // Handle the error further if needed
      return error;
    }
  }




  fireabseServerTimestamp = async () => {
    try {
      const timestampRef = firebase.database().ref('.info/serverTimeOffset');
      const snapshot = await timestampRef.once('value');
      const serverTimeOffset = snapshot.val() || 0; // Get the offset value, or default to 0
      // Calculate the current server timestamp by adding the offset to the local timestamp
      const serverTimestamp = Date.now() + serverTimeOffset;

      // console.log('Firebase Server Timestamp:', serverTimestamp);
      return serverTimestamp; // Return the server timestamp if needed
    } catch (error) {
      //  console.error('Error fetching Firebase server time:', error);

      return null;
    }
  };



  async isLastActiveTime(PhoneNumber) {
    try {
      const db = getDatabase(); // Assuming getDatabase is defined elsewhere
      const adminRootReference = ref(db, `adminRootReference/adminDetails/${PhoneNumber}/sessionData`);
      const serverTimestamp = await this.fireabseServerTimestamp();
      const path = adminRootReference.toString();
      const data = await this.callCloudFunction(path);
      const parsedData = JSON.parse(data);
      const lastActive = parsedData.lastActive;
      const fiveMinutesInMillis = 5 * 60 * 1000; // 5 minutes in milliseconds
      //const fiveMinutesInMillis =  90* 60 * 1000; // 5 minutes in milliseconds

      const timeDifference = serverTimestamp - lastActive;
      if (timeDifference <= fiveMinutesInMillis) {
        //  console.log('User is active within 5 minutes.');
        return false; // User is active
      } else {
        // console.log('User is inactive for more than 5 minutes.');
        return true; // User is inactive
        // Call logout function or perform logout actions here
      }

    } catch (error) {
      console.error("Error in Session id update or not ", error);
      // Handle the error further if needed
      return error;
    }
  }


  isCheckInterNet = () => {

    return navigator.onLine;

  }



  // checkInternetConnectionAvailable = async () => {
  //   const img = new Image();
  //   img.onload = img.onerror = () => {
  //     // setInternetStatus(true); // Internet is available
  //   };
  //  img.src = 'https://www.example.com/your-image.png'; // Replace with an actual image URL
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       if (!img.complete) {
  //       ///  console.log('Poor internet connection detected after 5 seconds.');
  //         resolve('Poor connection');
  //       } else {
  //         resolve('Good connection');
  //       }
  //     }, 5000);
  //   });

  // };


  checkInternetConnectionAvailable = async () => {
    return new Promise((resolve) => {
      let timeoutId;

      // Create a timeout that will resolve to 'Poor connection' after 5 seconds
      const timeoutPromise = new Promise((resolveTimeout) => {
        timeoutId = setTimeout(() => {
          resolveTimeout('Poor connection');
        }, 5000);
      });

      // Fetch data to check internet connection
      const fetchPromise = fetch('https://www.google.com/', { method: 'GET', mode: 'no-cors' })
        .then(response => {
          if (response.ok || response.status === 0) {
            clearTimeout(timeoutId); // Clear timeout if fetch is successful
            return 'Good connection';
          } else {
            return 'Poor connection';
          }
        })
        .catch(() => {
          return 'Poor connection';
        });

      // Wait for either fetchPromise or timeoutPromise to resolve
      Promise.race([fetchPromise, timeoutPromise])
        .then((result) => {
          resolve(result);
        });
    });
  };




  checkInternetConnection = async () => {
    let responseReceived = false;
    // console.log('Call this function ');
    try {
      // if (!navigator.onLine) {
      //   //  console.log('No internet connection.');
      //   return 'No internet ';
      // }
      //  console.log('Checking internet connection...');
      const response = await Promise.race([
        fetch('https://www.google.com', { mode: 'no-cors' }),
        new Promise(resolve => setTimeout(resolve, 5000)) // Timeout after 5 seconds
      ]);
      if (!response.ok) {
        // console.log('No internet connection.');
        return 'No internet connection.';
      } else {
        // console.log('Internet is available');
        responseReceived = true;
        return 'Internet is available';
      }
    } catch (error) {
      /// console.error('Error occurred while checking internet connection:', error.message);
      if (!responseReceived) {
        //  console.log('No/Poor Internet connection , Please retry.'); // If no response is received within 5 seconds
        return 'Poor connection.';
      }
      return 'No internet connection....';
    }
  };



  internetAvailable = async () => {
    try {
      const response = await fetch('https://cors-anywhere.herokuapp.com/https://www.google.com', { method: 'HEAD' });
      if (response.ok) {
        return 'Connected';
      } else {
        return 'Poor connection.';
      }
    } catch (error) {
      return 'No internet connection.';
    }
  }



  checkInternetAvaialble = async () => {
    let responseReceived = false;
    // console.log('Call this function ');
    try {
      if (!navigator.onLine) {
        //  console.log('No internet connection.');
        return 'No internet ';
      }
      //  console.log('Checking internet connection...');
      const response = await Promise.race([
        fetch('https://www.google.com', { mode: 'no-cors' }),
        new Promise(resolve => setTimeout(resolve, 1000)) // Timeout after 5 seconds
      ]);
      if (!response.ok) {
        // console.log('No internet connection.');
        return 'No internet connection.';
      } else {
        // console.log('Internet is available');
        responseReceived = true;
        return 'Internet is available';
      }
    } catch (error) {
      // console.error('Error occurred while checking internet connection:', error.message);
      if (!responseReceived) {
        //  console.log('No/Poor Internet connection , Please retry.'); // If no response is received within 5 seconds
        return 'Poor connection.';
      }
      return 'No internet connection....';
    }
  };



  TimestampConverter = (timestamp) => {

    const serialData1 = parseInt(timestamp); // Parse the input as an integer
    if (isNaN(serialData1) || serialData1 < 0) {
      //  console.error("Invalid timestamp");
    } else {
      const timestampDate = new Date(serialData1); // Use serialData1 instead of tokenGeneratedTime
      if (isNaN(timestampDate.getTime())) {
        //    console.error("Invalid date");
      } else {
        const year = timestampDate.getFullYear().toString().slice(2);
        const month = ('0' + (timestampDate.getMonth() + 1)).slice(-2);
        const day = ('0' + timestampDate.getDate()).slice(-2);
        const hours = ('0' + timestampDate.getHours()).slice(-2);
        const minutes = ('0' + timestampDate.getMinutes()).slice(-2);
        const seconds = "00";
        const formattedTime = `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
        //  console.log("Formatted Time: ", formattedTime);
        return formattedTime;
        // setFormattedTime(formattedTime);
      }
    }

  };



  // checkInternetConnections = async () => {
  //   try {
  //     if (!navigator.onLine) {
  //       console.log('No internet connection.');
  //       return 'No internet connection.';
  //     }
  //     const response = await Promise.race([
  //       fetch('https://www.google.com', { mode: 'no-cors' }),
  //       new Promise(resolve => setTimeout(resolve, 5000)) // Timeout after 5 seconds
  //     ]);
  //     if (!response) {
  //       console.log('Poor internet connection, please retry.');
  //       return 'Poor internet connection, please retry.';
  //     }
  //     console.log('Internet connection available.');
  //     return 'Internet connection available.';
  //   } catch (error) {
  //     console.error('Error occurred while checking internet connection:', error);
  //     return 'Error occurred while checking internet connection.';
  //   }
  // };


  checkInternetConnections = async () => {
    try {
      const response = await Promise.race([
        fetch('https://www.google.com', { mode: 'no-cors' }),
        new Promise(resolve => setTimeout(resolve, 5000)) // Timeout after 5 seconds
      ]);

      if (!response) {
        console.log('No internet');
      } else if (response.status === 200) {
        console.log('Internet available');
      } else {
        console.log('Poor internet');
      }
    } catch (error) {
      console.error('Error checking internet connectivity:', error);
    }
  };





}

export default CommonFuctions;