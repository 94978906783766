import React, { useState, useEffect } from 'react'
import CompanyLogo from '../Images/Maxwell.png'
import { NavLink, useNavigate } from 'react-router-dom';
import './footer.css'
import { auth } from '../adminLogin/firebase'
import InternetAwareNavLink from '../adminLogin/InternetAwareNavLink';
import { ref, getDatabase, onValue } from 'firebase/database';


function Mainhomepage() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [phoneNo, setPhone] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is signed in
        setUser(authUser); // Set the user state
        const number = authUser.email.split('@')[0];
        fetchEmailFromFirebase(number);
        /// console.log("user number is ", number);
      } else {
        setUser(null); // Set the user state to null when signed out
      }
    });
    // Clean-up function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const fetchEmailFromFirebase = (storedPhoneNumber) => {
    const db = getDatabase();
    const adminRootReference = ref(db, `adminRootReference/adminDetails/${storedPhoneNumber}/adminProfile`);
    onValue(adminRootReference, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setName(data.name === 'null' ? '' : data.name || '');
        setPhone(data.phoneNo === 'null' ? '' : data.phoneNo || '');
        // Set other state variables similarly for other fields
        // setLoading(false);
      } else {
        //  console.log("Data not found in Firebase.");
        // setLoading(false);
      }
    });
  };
  const handleLogout = () => {
    // console.log('You Click on  Lougout Button ')
    auth.signOut().then(() => {
      navigate('/login'); // Redirect to the login page after logout
    }).catch(error => console.log(error));
  };

  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const handleNagivatePage = () => {
    navigate('/login'); // Redirect to the login page after logout
  }

  return (

    <>

      <div className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="container-fluid">
          <div className="topnav" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto d-flex flex-row underline">
              <NavLink className="nav-items">
                <img src={CompanyLogo} className="logo" alt="homepage_logo" />
              </NavLink>
              <li className="navlink-item">
                <NavLink to="/" className="nav-link">Home</NavLink>
              </li>
              <li className="navlink-item">
                <NavLink to="" className="nav-link">About</NavLink>
              </li>
              <li className="navlink-item">
                <NavLink to="" className="nav-link">Contact</NavLink>
              </li>
              
              <div >
                {user ? (
                  <li className="navlink-item">
                    <button className="nav-link  nav-link-button" onClick={handleLogout} > logout </button>
                  </li>
                ) : (
                  <li className="navlink-item">
                    <NavLink to="/login" className="nav-link  nav-link-button ">Login </NavLink>
                  </li>

                )}
              </div>


              <li className="nav-item navlink-item d-none d-lg-block">

                <p style={{ float: 'left', margin: '5px', marginTop: '-5px', fontFamily: 'sans-serif' }}>
                  {/* {name.length > 10 ? `${name.substring(0, 8)}..` : name}  */}
                  {name}
                  {phoneNo}
                </p>
              </li>

            </ul>
          </div>
        </div>
      </div>
      {user && (

        <nav className="navbar navbar-expand-lg navbar-light  fixed-top mt-5" style={{ backgroundColor: '#e3f2fd' }}>
          <div className="container-fluid">
            {/* <a className="navbar-brand d-lg-none" >
              {name.length > 10 ? `${name.substring(0, 8)}..` : name} {phoneNo}
            </a> */}

            <a className="navbar-brand d-lg-none ml-2">
              <i className="fas fa-user-tie" style={{ fontSize: '14px' }} ></i> {/* Admin icon */}
              {/* {name.length > 10 ? `${name.substring(0, 8)}..` : name} */}
              <span style={{ fontSize: '16px' }}>{name}</span>
              <i className="fas fa-phone-alt" style={{ marginLeft: '8px', fontSize: '14px' }}></i> {/* Phone icon */}
              <span style={{ fontSize: '16px' }}> {phoneNo}</span>
            </a>

            <button className="navbar-toggler togglerr" type="button" onClick={toggleNavbar}
              aria-controls="navbarSupportedContent" aria-expanded={!collapsed} aria-label="Toggle navigation">
              <i className={`fas ${collapsed ? 'fa-bars' : 'fa-times'} tooglebutton`}></i>
            </button>

            {/* <button className="navbar-toggler togglerr" type="button" onClick={toggleNavbar}
              aria-controls="navbarSupportedContent" aria-expanded={!collapsed} aria-label="Toggle navigation">
              <i className="fas fa-bars tooglebutton"></i>
            </button> */}
            <div className={`collapse navbar-collapse ${collapsed ? '' : 'show'}`} id="navbarSupportedContent">
              <ul className="navbar-nav ">
                <li className="nav-item lini-nav-items">
                  <InternetAwareNavLink to="/admindetail" className="nav-link" activeClassName="active">Admin Details</InternetAwareNavLink>
                </li>
                <li className="nav-item lini-nav-items">
                  <InternetAwareNavLink to="/meterdetial" className="nav-link" activeClassName="active"  >Consumer Details</InternetAwareNavLink>
                </li>
                <li className="nav-item lini-nav-items">
                  <InternetAwareNavLink to="/generateConfig" className="nav-link" activeClassName="active">Generate Re-Configuration Token</InternetAwareNavLink>
                </li>
                <li className="nav-item lini-nav-items">
                  <InternetAwareNavLink to="/generateallrecharge" className="nav-link" activeClassName="active">Generate Recharge Token</InternetAwareNavLink>
                </li>
                <li className="nav-item lini-nav-items">
                  <InternetAwareNavLink to="/meterdetailserver" className="nav-link" activeClassName="active">Meter Details on Server</InternetAwareNavLink>
                </li>

                <li className="nav-item">
                  <InternetAwareNavLink to="/removeMeter/deletemeter" className="nav-link" activeClassName="active">Delete Meter</InternetAwareNavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}

    </>


  )
}

export default Mainhomepage
