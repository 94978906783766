import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Mainhomepage from '../home/mainhomepage'
import CommonFuctions from '../commonfunction';
import { Modal, Button } from 'react-bootstrap';

import 'sweetalert2/dist/sweetalert2.min.css';
import PhoneSendOtp from './phonesendotp';
import ResendTimer from './resendotp'
import { getAuth, signInWithEmailAndPassword, } from "firebase/auth";
import Alert from '@mui/material/Alert';

function Verifyphoneotp() {
  const sessiontime = new CommonFuctions();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false); // State to track loading status
  const [modalMessage, setModalMessage] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const storedPhoneNumber = localStorage.getItem('phoneNumber');
  const storePassowrd = localStorage.getItem('password');
  const [otpSendMessage, setotpSendMessage] = useState('');
  const [displayMessage, setDisplayMessage] = useState(true);
  const { message } = location.state || {};

  // console.log ('local phone number: ' ,storePassowrd);

  const [otp, setOtp] = useState(''); // Initialize OTP state with an empty string
  const [error, setError] = useState('');
  const storedPhoneOTP = localStorage.getItem('otp'); // Get stored phone OTP

  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;
  };


  const onSubmitotp = async (e) => {

    e.preventDefault();

    if (otp.trim() === '') { // Check if OTP is empty or contains only spaces
      setError('Please enter the OTP.'); // Set error message
      return; // Exit the function
    }
    if (otp === storedPhoneOTP) {
      setLoading(true); // Set loading status to true
      toggleRefreshAndClose(false); // Prevent refresh and close actions
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, null, window.location.href);
      };

      // SessionValidate();
     // console.log('call 1 ');
      if (!navigator.onLine) {
        setIsDialogOpen(true);
        setModalMessage('No internet. Cannot access server.');
        setLoading(false);
        toggleRefreshAndClose(true); // Prevent refresh and close actions
        return;
      }
      //  const status = await sessiontime.checkInternetConnection(); // Call the function

      const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function

  

      if (status === 'Poor connection') {
        setIsDialogOpen(true);
        setModalMessage('Poor internet. Cannot access server.');
        toggleRefreshAndClose(true); // Prevent refresh and close actions
        setLoading(false);
        return;
      }

      try {

        const { lastActive } = await sessiontime.HandleValidatSessiontime(storedPhoneNumber);
        const severTimestammp = await sessiontime.fireabseServerTimestamp();
        if (severTimestammp - lastActive <= 60000) {
     
          toggleRefreshAndClose(true); // Prevent refresh and close actions
          setLoading(false); // Set loading status to true
          setShowAlert(true);
        }

        else {

          try {
  
            const auth = getAuth();
            const email = `${storedPhoneNumber}@gmail.com`; // Convert phone number to custom email format
            const userCredential = await signInWithEmailAndPassword(auth, email, storePassowrd);
          
            const sessionId = await sessiontime.updateSessionTimeInLogIn(storedPhoneNumber);
            //  console.log("Sessdion Id :", sessionId);
            localStorage.setItem('sessionId', sessionId);
            updateSessionTime();
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            setLoading(true); // Set loading status to true
            navigate('/welcome');
          } catch (error) {

            setIsDialogOpen(true);
            setModalMessage(`Response not received from server. Please retry. (${error})`);
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions 
          //  console.log(`Error occurred during login. Please try again. (${error})`);
          }
        }
      }
      catch (error) {
        setIsDialogOpen(true);
        setModalMessage(`Response not received from server. Please retry. (${error})`);
        setLoading(false);
        toggleRefreshAndClose(true); // Prevent refresh and close actions

      }
    } else {
      setError(' Invalid OTP.'); // Set error message
      return; // Exit the function
    }

  };





  const resendOtp = async () => {
    if (displayMessage) {
      setDisplayMessage(false);
    }

    const phoneSendOtp = new PhoneSendOtp(storedPhoneNumber);
    const result = await phoneSendOtp.sendOTP(storedPhoneNumber);

    if (result == '200') {

      toggleRefreshAndClose(true); // Prevent refresh and close actions
      setotpSendMessage(' OTP sent successfully.');
      /// console.log('Opt send successfully ');

    }
    else if (result.status_code === '411') {
      // console.log('Invalid Number ')
      setError(`Invalid Number (${result.status_code})`)
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }
    else if (result.status_code === '416') {
      setError(`SMS service is temporarily unavailable. Please retry. (${result.status_code})`);
      setLoading(false);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

      return;
    }
    else if (result === "Network Error") {
      setLoading(false);
      setIsDialogOpen(true);
      // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
      const errorMessage = `Network error. Please retry. (${result})`;
      setModalMessage(errorMessage);
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      return;
    }
    else {
      setLoading(false);
      setIsDialogOpen(true);
      const errorMessage = `SMS service is temporarily unavailable. Please retry.(${result})`;
      setModalMessage(errorMessage);
      toggleRefreshAndClose(true); // Prevent refresh and close actions

    }





    //   try {
    //   if (phoneResult === 411) {
    //     setError(`Invalid phone number: (${phoneResult})`);
    //   }
    //   else {

    //     setotpSendMessage(' OTP sent successfully.');
    //   }
    // } catch (phoneError) {
    //   // console.error('Error sending OTP to phone:', phoneError);
    // }

  };



  const updateSessionTime = async () => {

    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('password');
    localStorage.removeItem('otp');
    localStorage.removeItem('emailOTP');
    /// localStorage.setItem('LoggIn', 'true');

  }

  const handleOnChange = (e) => {
    setotpSendMessage('');
    setError('');
    const value = e.target.value.replace(/\s/g, ''); // Remove spaces
    if (/^\d*$/.test(value)) { // Test if the value contains only digits
      setOtp(value); // Update the OTP state if it's valid
    }

    // Remove the message when the user starts entering OTP
    if (displayMessage) {
      setDisplayMessage(false);
    }

  }

  const closeDialog = () => {
    setIsDialogOpen(false);
  };


  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

  const handleCloseAlert = () => {
    setShowAlert(false); // Close alert when clicked on the close button
    navigate('/login');
  };




  return (

    <>
      <div>
        <Mainhomepage />
      </div>

      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}

      <div className='containers'>
        <form className='formgroup' onSubmit={onSubmitotp}>
          <div>

            <label htmlFor="phoneNumber">Enter OTP </label>
            <input
              type="text"
              value={otp}
              className='form-control'
              onChange={handleOnChange}
              maxLength={6}

              placeholder="Mobile-OTP "
            />
            <div style={{ color: 'red' }}>{error}</div>
            <span style={{ color: 'blue' }}>{otpSendMessage}</span>
            {displayMessage && (
              <div>

                <p style={{ color: 'blue' }}>{message}</p>

              </div>
            )}
          </div>

          <div className="d-flex justify-content-center w-100">
            <button type="submit" className='btn btn-primary' style={{ marginRight: '50px' }}>VERIFY</button>

            <div>
              <ResendTimer onClick={resendOtp} />
            </div>

          </div>


        </form>
      </div>



      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" >
        <Modal.Body>
          <p style={{ color: 'red' }}> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>




      {showAlert && (
        <div className="alert-overlay"
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={handleCloseAlert}
            className='AlertDailog'
          >
            You have been logged out due to login from another device.!
          </Alert>
        </div>
      )}










    </>
  );
}

export default Verifyphoneotp;
