import React, { useState, useEffect } from 'react'
import { auth } from '../adminLogin/firebase';
import './header.css'
import Bottom from './botton';

function Header() {
   
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                //console.log('Logged in user:', authUser.email);
                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                   
                }
            } else {
              
                window.location.href = '/';
            }
        });

        return () => unsubscribe();
    }, []);



    return (
        <>
            <div>
                <div className="fixed-top">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <a className="navbar-brand" href="#">Navbar 1</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup1" aria-controls="navbarNavAltMarkup1" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup1">
                            <div className="navbar-nav">
                                <a className="nav-item nav-link active" href="#">Home <span className="sr-only">(current)</span></a>
                                <a className="nav-item nav-link" href="#">Features</a>
                                <a className="nav-item nav-link" href="#">Pricing</a>
                                <a className="nav-item nav-link disabled" href="#">Disabled</a>
                            </div>
                        </div>
                    </nav>
                </div>

            </div>
            <div className="mt-5">
                <Bottom />
            </div>
        </>


    )
}

export default Header
