import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { auth } from './firebase'; // Import your Firebase authentication instance
import { ref, get, getDatabase } from 'firebase/database';
import { database } from './firebase'; // Import your Firebase database instance
import Mainhomepage from '../home/mainhomepage'
import { Modal, Button } from 'react-bootstrap';
import PhoneSendOtp from './phonesendotp';
import ResendTimer from './resendotp'
import EmailSendotp from '../admin/email'
import CommonFuctions from '../commonfunction';


function Forgetpassverify() {
    const SessionTime = new CommonFuctions();
    const navigate = useNavigate();
    const location = useLocation();
    const localphoneNumber = location.state?.localphoneNumber || '';
    const [mobileOTP, setMobileOTP] = useState('');
    const [emailOTP, setEmailOTP] = useState('');
    const [mobileOTPError, setMobileOTPError] = useState('');
    const [emailOTPError, setEmailOTPError] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loading, setLoading] = useState(false); // State to track loading status
    const storeEmail = localStorage.getItem('forgetEmail');
    const storedPhone = localStorage.getItem('forgetphoneNumber');

    const { handleButtonClick } = EmailSendotp({ email: storeEmail }); // Pass the email as a prop


    // let canRefreshOrClose = false; // Flag to control refresh and close actions
    let canRefreshOrClose = true; // Allow refresh and close actions by default
    // Function to handle beforeunload event
    const handleBeforeUnload = (event) => {
        if (!canRefreshOrClose) {
            const message = 'Please do not reload this page, data may be lost.';
            event.returnValue = message;
            return message; // Required for some older browsers   
        }
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Function to enable/disable refresh and close actions
    const toggleRefreshAndClose = (enabled) => {
        canRefreshOrClose = enabled;

    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setMobileOTPError('');
        setEmailOTPError('');

        if (!mobileOTP) {
            setMobileOTPError('Enter valid mobile OTP');
            return;
        }

        if (!emailOTP) {
            setEmailOTPError('Enter  valid Email OTP');
            return;
        }
        const storedPhoneOTP = localStorage.getItem('otp'); // Get stored phone OTP
        const storedEmailOTP = localStorage.getItem('emailOTP'); // Get stored email OTP

        if (mobileOTP !== storedPhoneOTP) {
            setMobileOTPError('Incorrect mobile OTP');
        }

        if (emailOTP !== storedEmailOTP) {
            setEmailOTPError('Incorrect email OTP');
        }

        if (mobileOTP === storedPhoneOTP && emailOTP === storedEmailOTP) {

            setLoading(true);
            toggleRefreshAndClose(false); // Enable refresh and close actions again after data saving is complete

            if (!navigator.onLine) {
                setIsDialogOpen(true);
                setModalMessage('No/Poor Internet connection. Cannot access server.');
                setLoading(false);
                toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
                return;
            }

            const status = await SessionTime.checkInternetConnection(); // Call the function
            if (status === 'Poor connection.') {
                setIsDialogOpen(true);
                setModalMessage('No/Poor Internet connection. Cannot access server.');
                setLoading(false);
                toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
                return;
            }

            console.log('Number is ', storedPhone)
            const db = getDatabase(); // Assuming getDatabase() returns a valid database instance
            const adminRootReference = ref(db, `adminRootReference/adminDetails/${storedPhone}/adminProfile`);
            const path = adminRootReference.toString();
            try {
                const data = await SessionTime.callCloudFunction(path); // Assuming callCloudFunction is properly defined
                const parsedData = JSON.parse(data);
                const password = parsedData.password

                //console.log('all data is ', parsedData);
               /// console.log('password is ', password);

                localStorage.setItem('ForgetPassword', password);
                toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
                localStorage.removeItem('otp');  //Get stored phone OTP
                localStorage.removeItem('emailOTP'); // Get stored email OTP
                localStorage.removeItem('forgetEmail');

                ///  Navigating to the 'showpassword' route with userNumber and password as state
                navigate('/login', { state: { password, storedPhone } });
            } catch (error) {
                setIsDialogOpen(true);
                const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
                setModalMessage(errorMessage);
                setLoading(false);
                toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
                return;
            }
        } else {

        }
    }

    const handleMobileOTPChange = (e) => {
        setotpSendMessage('');
        setMobileOTP(e.target.value);
        setMobileOTPError('');
    };

    const handleEmailOTPChange = (e) => {
        setotpSendMessage('');
        setEmailOTP(e.target.value);
        setEmailOTPError('');
    };



    const closeDialog = () => {
        setIsDialogOpen(false);
        window.onpopstate = null;

        // window.location.reload(); // This will reload the page
    };

    const [otpSendMessage, setotpSendMessage] = useState('');

    const resendOtp = async () => {
        let phoneSuccess = false;
        let emailSuccess = false;

        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No/Poor Internet connection. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
            return;
        }

        const status = await SessionTime.checkInternetConnection(); // Call the function
        if (status === 'Poor connection.') {
            setIsDialogOpen(true);
            setModalMessage('No/Poor Internet connection. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
            return;
        }

        // Send OTP to phone
        try {
            const phoneSendOtp = new PhoneSendOtp(storedPhone);
            const phoneResult = await phoneSendOtp.sendOTP(storedPhone);
            if (phoneResult === 411) {
                setMobileOTPError(`Invalid phone number: (${phoneResult})`);
            } else {
                phoneSuccess = true;
            }
        } catch (phoneError) {
            // console.error('Error sending OTP to phone:', phoneError);
        }
        // Send OTP to email
        try {
            const emailResult = await handleButtonClick(storeEmail);
            if (typeof emailResult === 'boolean') {
                if (emailResult) {
                    emailSuccess = true;
                    /// console.log('OTP sent to email successfully.');
                } else {
                    setEmailOTPError('Error sending OTP to email. Please try again.');
                }
            } else {
                ///console.error('Error:', emailResult);
            }
        } catch (emailError) {
            // console.error('Error sending OTP to email:', emailError);
        }
        // Check if both OTPs were sent successfully
        if (phoneSuccess && emailSuccess) {
            setotpSendMessage(' OTP sent successfully.');
        } else {
            // setEmailOTPError('OTP sending failed.');
        }
    };




    return (
        <>


            <div>


                <Mainhomepage />

            </div>

            {loading ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}

            <div className='containers'>
                <form className='formgroup' onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email">Enter Mobile OTP</label>
                        <input
                            type="text"
                            className='form-control'
                            placeholder=" Mobile OTP"
                            value={mobileOTP}
                            onChange={handleMobileOTPChange}
                            maxLength={6}
                        />
                        <p style={{ color: 'red' }} className="error">{mobileOTPError}</p>
                    </div>
                    <div>
                        <label htmlFor="password">Enter E-mail OTP</label>
                        <input
                            type="text"
                            className='form-control'
                            placeholder=" E-mail OTP"
                            value={emailOTP}
                            onChange={handleEmailOTPChange}
                            maxLength={6}
                        />
                        <p style={{ color: 'red' }} className="error">{emailOTPError}</p>
                        <p style={{ color: 'blue' }}>{otpSendMessage}</p>
                    </div>

                    <div className='d-flex justify-content-center w-100'>
                        <button className='btn btn-primary' type="submit" style={{ marginRight: '50px' }} >
                            VERIFY
                        </button>


                        <div>

                            <ResendTimer onClick={resendOtp} />
                        </div>

                    </div>

                </form>
            </div>



            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
                {/* <Modal.Header closeButton>
      </Modal.Header>  */}
                <Modal.Body>
                    <p> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Forgetpassverify;
