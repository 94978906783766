import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Deletemeter from './deletemeter'
import HandlegetOtp from './hadlegetotp'

import Verifyotp from './verifyotp'

function RemoverMeter() {
    return (
        <>

            <section>
                <Routes>
                    <Route path="deletemeter" element={<Deletemeter />} />
                    <Route path="hadlegetotp" element={<HandlegetOtp/>} />
                    <Route path="verifyotp" element={<Verifyotp/>} />
                  
                </Routes>
            </section>



        </>
    )
}

export default RemoverMeter
