import React, { useEffect, useState } from "react";
import { auth } from "../adminLogin/firebase";
import firebase from "firebase/compat/app"; // Import the Firebase app (latest version)
import "firebase/compat/database";
import "sweetalert2/dist/sweetalert2.min.css";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { FaChevronDown } from "react-icons/fa";
import "./transfer.css";
import CommonFuctions from "../commonfunction";
import Button from '@mui/material/Button'; // Import the Button component


function TranseferMemter({meterList}) {
  const common = new CommonFuctions();
  const database = firebase.database();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [errorPhoneMessage, setErrorPhoneMessage] = useState(null);
  const [newPhone, setNewPhone] = useState("");
  const [user, setUser] = useState(null);
//  const [meterList, setMeterList] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
  const [selectAll, setSelectAll] = useState(false); // State for "Select All" checkbox
  const [checkedItems, setCheckedItems] = useState({}); // State to store checked status of individual checkboxes
  const [selectedSerials, setSelectedSerials] = useState([]);
  const [allmobileNumber, setAllmobileNumber] = useState([]);
  const [adminPhone, setAdminPhone] = useState("");
  const [allMeterCount, setAllMeterCount] = useState("");


  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setSelectAll(false);
    // Reset all checkboxes to unchecked
    const updatedCheckedItems = {};
    meterList.forEach((serial) => {
      updatedCheckedItems[serial] = false;
    });
    setCheckedItems(updatedCheckedItems);
    setOpenModal(false);
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        const emailParts = authUser.email.split("@");
        if (emailParts.length === 2) {
          const numberPart = emailParts[0];
          setAdminPhone(numberPart);
          handleSearch1(numberPart);
        }
      } else {
        setUser(null);
        window.location.href = "/";
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSearch1 = async (numberPart) => {

    try {
      const setemeter = database.ref(`/adminRootReference/adminDetails/`);
      const updateSetMeter = setemeter.toString();
      const result = await common.callCloudFunction(updateSetMeter);
      const serialData = JSON.parse(result);
      delete serialData.time;
      const meterIds = Object.keys(serialData);
      setAllmobileNumber(meterIds);

      // // console.log("meterList ", mobileNumber);
      // const snapshot = await firebase
      //   .database()
      //   .ref(`/adminRootReference/adminDetails/${numberPart}/newMeterList`)
      //   .once("value");
      // const fetchedMeterList = snapshot.val();
      // if (fetchedMeterList) {
      //   const meterIds = Object.keys(fetchedMeterList);
      //   setMeterList(meterIds);
      // } 



    } catch (error) {
      console.error("Error fetching admin meter list:", error);

    }
    finally {
      setLoading(false);
    }
  };



  const handlePhoneChange = (event) => {
    setErrorPhoneMessage("");
    const value = event.target.value.replace(/\D/g, ""); // Remove non-digits

    if (value.charAt(0) === "0") {
      setErrorPhoneMessage("Should not begin with zero.");
    } else {
      setErrorPhoneMessage("");
      setNewPhone(value);
    }
  };



  const handleSelectAllChange = () => {
    // Toggle the state for "Select All"
    setSelectAll(!selectAll);
    // Update checked items for all checkboxes
    const updatedCheckedItems = {};
    meterList.forEach((serial) => {
      updatedCheckedItems[serial] = !selectAll;
    });
    setCheckedItems(updatedCheckedItems);
  };

  const handleCheckboxChange = (event, serial) => {
    const { checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [serial]: checked,
    }));
  };

  const handleSelectedSerial = () => {
    // Example logic to handle when "Proceed" button is clicked with selected serials
    const selectedSerials = meterList.filter((serial) => checkedItems[serial]);

    const totalSelectedSerials = selectedSerials.length;

    //console.log("Total number of selected serials:", totalSelectedSerials);

    setAllMeterCount(totalSelectedSerials);
    setSelectedSerials(selectedSerials);

    setOpenModal(false);
  };

  const handleGenerateButton = () => {
    if (newPhone.trim() === "") {
      setErrorPhoneMessage("Please Enter Mobile number ");
      return;
    }
    if (newPhone.length < 10) {
      setErrorPhoneMessage("Invalid Mobile number ");
      return;
    }

    if (newPhone === adminPhone) {
      setErrorPhoneMessage("No change in exsting mobile no.");
      return;
    }

    if (allmobileNumber.includes(newPhone)) {
      // console.log('Mobile number found in the list.');
    } else {
      setErrorPhoneMessage("Account does not Exist with this mobile number ");
      return;
    }

    if (selectedSerials.length === 0) {
      // setErrorserialNumber("Please Select Meter ");
      return;
    }

    // Log or print all selected serial numbers
    //  console.log('Selected Serials:', selectedSerials);
    // Or if you want to display them in a certain way, you can iterate over selectedSerials
    selectedSerials.forEach((serial) => {
      console.log(serial);
      // Or display in a different way, such as appending to a string or displaying in UI
    });

    // Store the selected serials in local storage
    localStorage.setItem("selectedSerials", JSON.stringify(selectedSerials));
    localStorage.setItem("phonenumberlist", newPhone);
    navigate("/transefer");
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjust width for responsiveness
    maxWidth: 400, // Optional: Set a maximum width if needed
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          }}
        >
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      <div className="TrnaseferContainer">
        <div>
          <div style={{ marginBottom: "5px" }}>
            <label htmlFor="phoneNumber">Mobile Number</label>
            <div className="input-container1 ">
              <input
                type="text"
                className="form-control"
                placeholder="Mobile Number"
                value={newPhone}
                onChange={handlePhoneChange}
                maxLength={10}
              />
              <i className="fas fa-phone"></i>
            </div>
            <p style={{ color: "red " }}>{errorPhoneMessage}</p>
          </div>

          <div style={{ marginTop: "5%" }}>
            <div>
              <div
                className="input-container1"
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Select Serial"
                  readOnly
                  onClick={handleOpenModal}
                  style={{ cursor: "pointer" }}
                />
                <FaChevronDown
                  style={{
                    position: "absolute",
                    right: "10px",
                    pointerEvents: "none",
                  }}
                />
              </div>

              <div style={{ color: "red" }}>
                {/* <p>{errorSerialnumber}</p> */}
                <p>
                  {allMeterCount > 0
                    ? `${allMeterCount} Meter(s) Selected`
                    : "No Meter(s) Selected"}
                </p>
              </div>
            </div>

            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              BackdropProps={{ onClick: (e) => e.stopPropagation() }} // Prevent modal from closing on backdrop click
            >
              <Box sx={modalStyle}>
                <h4>Select Serial</h4>
                <div className="serial-container">
                  <div className="selectAllContainer" key="selectAll">
                    <span className="selectAllText">Select All</span>
                    <input
                      className="CheckBox "
                      type="checkbox"
                      onChange={handleSelectAllChange}
                      checked={selectAll}
                    />
                  </div>


                  {meterList.map((serial, index) => (
                    <div
                      key={index}
                      className="serial-box"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: "1px solid #ccc",
                        padding: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <div>
                        {serial}
                      </div>
                      <input
                        className="CheckBox"
                        type="checkbox"
                        checked={checkedItems[serial] || false}
                        onChange={(e) => handleCheckboxChange(e, serial)}
                      />
                    </div>
                  ))}
                </div>

                <div
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button variant="contained" onClick={handleCloseModal}>
                    Close
                  </Button>
                  <Button variant="contained" onClick={handleSelectedSerial}>
                    Proceed
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>
        </div>

        <div>
          <p>
            <span style={{ color: 'red' }}>
              Note:
            </span>
            Only latest ReConfig token is available. Any pending token is
            removed. Any ReConfig transfer token is pending for a meter then
            no ReConfig token (Normal or Transfer ) can be generated for this
            meter. Meter must be ReConfigured first.

          </p>
        </div>

        <div
          style={{ marginTop: "23px", display: "flex", justifyContent: "center", width: "100%" }}
          className="d-flex justify-content-between center"
        >
          <button className="btn btn-primary" onClick={handleGenerateButton}>
            Generate Token
          </button>
        </div>

      </div>
    </>
  );
}

export default TranseferMemter;
