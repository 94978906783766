import { useState, useEffect } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css';
import Mainhomepage from '../home/mainhomepage'
import PhoneSendOtp from './phonesendotp';
import { validateEmailAddress } from '../validation/validation';
import { Modal, Button } from 'react-bootstrap';
import CommonFuctions from '../commonfunction';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PhoneIcon from '@mui/icons-material/Phone';
import AccountCircle from '@mui/icons-material/AccountCircle'; // Make sure to import the user name icon
import EmailIcon from '@mui/icons-material/Email'; // Import the email icon
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Import the address icon




import Send from './sendmail';

function Signin() {

    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [phoneError, setPhoneError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [ConfirmpasswordError, setConfirmpasswordError] = useState('');
    const { email, setEmail, errorMessage, handleButtonClick, } = Send();
    const [showPassword, setShowPassword] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');


    const [loading, setLoading] = useState(false);
    const sessiontime = new CommonFuctions();


    const handlePhoneChange = (input) => {
        setPhoneError('');
        let value = input.replace(/\D/g, '');
        if (value === '0') {
            // Show error message
            setPhoneError("Should not begin with Zero");
            value = '';
        } else {
            // Clear error message
            setPhoneError("");
            setPhoneNumber(value);
        }
    };


    const handlePasswordChange = (input) => {
        setPasswordError('')
        const trimmedInput = input.trim();
        if (trimmedInput.startsWith(' ')) {
            setPasswordError(' ');
        } else {
            setPasswordError('');
            setPassword(trimmedInput);
        }
    };

    const handleConfirmPassword = (event) => {
        setConfirmpasswordError('');
        const value = event.target.value;
        setconfirmPassword(value);
    };

    // let canRefreshOrClose = false; // Flag to control refresh and close actions
    let canRefreshOrClose = true; // Allow refresh and close actions by default
    // Function to handle beforeunload event
    const handleBeforeUnload = (event) => {
        if (!canRefreshOrClose) {
            const message = 'Please do not reload this page, data may be lost.';
            event.returnValue = message;
            return message; // Required for some older browsers   
        }
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Function to enable/disable refresh and close actions
    const toggleRefreshAndClose = (enabled) => {
        canRefreshOrClose = enabled;

    };

    const onSendButtonClick = async () => {
        // // console.log('nextphone ',phoneNumber);
        // const phoneSendOtp = new PhoneSendOtp(phoneNumber);
        // const result = phoneSendOtp.sendOTP(phoneNumber);
        try {
            const phoneSendOtp = new PhoneSendOtp(phoneNumber);
            const result = await phoneSendOtp.sendOTP(phoneNumber);
            if (result == 411) {
                setAlertMessage(`Invalid number : (${result})`);
                setLoading(false);
                toggleRefreshAndClose(false); // Prevent refresh and close actions
            } else {
                //   console.log ('OTP send succesfully');
                //  setAlertMessage(`OTP send succesfully`);
                navigate('/getotp');

                toggleRefreshAndClose(true);  
            }
        } catch (otpError) {
        }

    };

    const [errorMessage1, setErrorMessage] = useState('');

    const handleChangeEmail = (event) => {
        setErrorMessage('');
        //   const value = event.target.value;
        const value = event.target.value.trim();
        setEmail(value);
    }
    const handleButtonClick1 = async (event) => {
        event.preventDefault(); // Prevent the default behavior
        if (phoneNumber.trim() === '') {
            setPhoneError("Enter valid mobile number ");
            return;
        }
        if (phoneNumber.length < 10) {
            setPhoneError("Enter valid mobile number ");
            return;
        }
        if (email.trim() === '') {
            setErrorMessage('Please enter an email.');
            return;
        }
        if (!validateEmailAddress(email)) {
            setErrorMessage('Please enter an email.');
            return;
        }

        // const emailError = validateEmail(email);
        // if (emailError) {
        //     setErrors(prevErrors => ({
        //         ...prevErrors,
        //         email: emailError,
        //     }));
        //     return;
        // }

        // if (!email) {
        //     setErrors(prevErrors => ({
        //         ...prevErrors,
        //         email: "",
        //     }));
        //     return;
        // }

        // if (!password) {
        //     setPasswordError('Password cannot be empty');
        //     return;
        // }

        if (!password) {
            setPasswordError('Password cannot be empty');
            return;
        } else if (password.length < 8) {
            setPasswordError('Min 8 characters.');
            return;
        }

        if (confirmPassword.trim() === '') {
            setConfirmpasswordError('Please enter confirm password');
            return;
        }
        if (password !== confirmPassword) {
            ///  setPasswordError('Password mismatch');
            setConfirmpasswordError('Password mismatch');
            return;
        }

        const status = await sessiontime.checkInternetConnection(); // Call the function
        if (status === 'Poor connection.') {
            setIsDialogOpen(true);
            setModalMessage('No/Poor Internet connection. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions

            return;
        }

        localStorage.setItem('phoneNumber', phoneNumber);
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);


        // Continue with your logic if passwords are valid and match
        setLoading(true);
        toggleRefreshAndClose(false); // Prevent refresh and close actions
        handleButtonClick(event); // Pass the event object to handleButtonClick from Send component
        onSendButtonClick(); // Call the onSendButtonClick function or other logic if needed
    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');


    const closeDialog = () => {
        setIsDialogOpen(false);

    };



    return (
        <>

            <div>
                <Mainhomepage />
            </div>



            {loading ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}

            <div className='containers'>
                <div className='formgroup'>
                    <div>
                        <h3>Create Account</h3>
                    </div>


                    <div>


                        <label htmlFor="phone-number">Admin Phone Number</label>
                        <div className="input-container1">

                            <input
                                type="text"
                                className='form-control'
                                placeholder="Admin Phone Number"
                                value={phoneNumber}
                                onChange={(e) => handlePhoneChange(e.target.value)}
                                maxLength={10}
                            />
                            <i className="fas fa-phone" />
                        </div>
                        <span style={{ color: "red" }}>{phoneError && <p className="error">{phoneError}</p>}</span>

                        {alertMessage && (
                            <div className="alert-container">
                                <p style={{ color: 'red' }}><i className="fas fa-exclamation-circle" style={{ color: 'red' }}></i> {alertMessage}</p>
                            </div>
                        )}
                    </div>

                    <div>
                        <label htmlFor="email">Admin E-mail</label>
                        <div className="input-container1">
                            <input
                                type="email"
                                className='form-control'
                                placeholder="Admin E-mail"
                                value={email}

                                onChange={handleChangeEmail}
                            />
                            <i class="fa-solid fa-envelope"></i>
                        </div>
                        {errorMessage1 && <p style={{ color: 'red' }}>{errorMessage1}</p>}

                        <span style={{ color: "red" }}>{errors.email && <p className="error">{errors.email}</p>}</span>
                    </div>

                    <div>
                        <label htmlFor="password">Password</label>
                        <div className="input-container1">
                            <input
                                type="password"
                                className='form-control'
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => handlePasswordChange(e.target.value)}
                                maxLength={16}
                            />

                            <i className="fas fa-lock password-icon"></i>
                        </div>
                        <span style={{ color: "red" }}><p className="error">{passwordError}</p></span>
                        <div>

                        </div>
                    </div>

                    <div>
                        <label htmlFor="password">Confirm Password</label>
                        <div className="input-container1">
                            <input
                                type="password"
                                className='form-control'
                                placeholder="Enter Confirm Password"
                                value={confirmPassword}
                                onChange={handleConfirmPassword}
                                maxLength={16}

                            />
                            <i className="fas fa-lock password-icon"></i>
                        </div>
                        <span style={{ color: "red" }}><p className="error">{ConfirmpasswordError}</p></span>
                        <p style={{ color: "black" }}> Minimum 8 characters </p>
                    </div>


                    {/* <div className='d-grid col-5  w-100'>
                        <button className='btn btn-primary' onClick={handleButtonClick1} >
                            CREATE ACCOUNT
                        </button>
                    </div> */}

                    <div className='button-container'>
                        <button className='btn btn-primary col-12 col-md-12' onClick={handleButtonClick1}>
                            CREATE ACCOUNT
                        </button>
                    </div>



                </div>

            </div>



            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" >

                <Modal.Body>
                    <p> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )

}

export default Signin