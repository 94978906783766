import '../adminLogin/login.css';
import React, { useState, useEffect } from 'react';
import { auth } from '../adminLogin/firebase';
import adminprofile from '../Images/logo/adminprofile.png';
import './adminpage.css'
import Navbar from '../adminLogin/navbar';
import { ref, getDatabase } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import Createtime from './createtime';
import { Modal } from 'react-bootstrap';
import CommonFuctions from '../commonfunction';
import 'sweetalert2/dist/sweetalert2.min.css';
import firebase from '../firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PhoneIcon from '@mui/icons-material/Phone';
import AccountCircle from '@mui/icons-material/AccountCircle'; // Make sure to import the user name icon
import EmailIcon from '@mui/icons-material/Email'; // Import the email icon
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Import the address icon
import Button from '@mui/material/Button'; // Import the Button component


import { validateName, validateAddress, validateEmailAddress } from '../validation/validation';

function Admindetail() {

    const navigate = useNavigate();
    const [user, setUser] = useState(null); // State to hold user information
    const [loading, setLoading] = useState(true); // State to track loading status
    const [phoneNumber, setPhoneNumber] = useState(''); // State to hold phone number
    const [email, setEmail] = useState(''); // State to hold email
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNo2, setPhoneNo2] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newName, setNewName] = useState('');
    const [newAddress, setNewAddress] = useState('');
    const [newPhone, setNewPhone] = useState('');
    const [initialEmail, setInitialEmail] = useState('');
    const [originalPhoneNo2, setOriginalPhoneNo2] = useState('');
    const [errorAddres, setErrorAddress] = useState('');
    const [errorName, setErrorName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessage1, setErrorMessageemail] = useState('');
    const [erronphoneMessage, setErrorPhoneMessage] = useState('');
    const sessionTime = new CommonFuctions();
    const [password, setPassword] = useState('');
    const [key, setKey] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [modalMessage1, setModalMessage1] = useState('');

    const [data, setData] = useState({});

    const [updateData, setUpdateData] = useState(null); // State to hold the update data


    useEffect(() => {
        document.title = "Admin Details"; // Set the title when the component mounts
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                // User is logged in
                setUser(authUser);
                //  console.log("Logged in user:", authUser.email);
                const emailParts = authUser.email.split('@'); // Split email by '@'
                if (emailParts.length === 2) {
                    const number = emailParts[0]; // Get the part before '@'
                    // console.log("Extracted number:", number);
                    setPhoneNumber(number); // Set extracted number in state
                    fetchEmailFromFirebase(number);
                    updateSessionActiveTime(number);
                    //  isCheckLastActiveTime(number);
                }
            } else {
                // No user is logged in, you can redirect to another page or handle accordingly
                setUser(null);
                // Example: Redirect to another page
                window.location.href = '/'; // Redirect to your login page
            }
        });

        return () => unsubscribe(); // Cleanup function for unmounting
    }, []);


    useEffect(() => {
        // Setting the initial loaded email value
        setInitialEmail(email);
    }, [email]);

    useEffect(() => {
        setOriginalPhoneNo2(phoneNo2);
    }, [phoneNo2]);
    const handleNameChange = (event) => {
        setshowMessage(false);
        setMessageResponse('');
        setModalMessage('');
        setModalMessage1('');

        setErrorMessage('');
        let newName = event.target.value;
        // Replace consecutive spaces with a single space
        newName = newName.replace(/\s+/g, ' ');
        //const newName = event.target.value;
        const validationError = validateName(newName); // Use validateName function 
        // if (newName.charAt(0) === ' ') {
        //     return;
        // }
        if (validationError) {
            setErrorName(validationError);
            //  setNewName(''); // Clear the input field value
        } else {
            setErrorName('');
            setNewName(newName);
        }
    };


    const handleEmailChange = (event) => {
        setshowMessage(false);
        setMessageResponse('');
        setModalMessage('');
        setModalMessage1('');

        const { value } = event.target;
        const trimmedValue = value.trim(); // Remove leading and trailing spaces
        if (trimmedValue === value) { // Check if trimming did not change the value
            setErrorMessageemail('');
            setNewEmail(trimmedValue);
        } else {
            //  setErrorMessageemail('Eshould not start or end with a space.');
        }
    };


    const handleAddressChange = (event) => {
        setshowMessage(false);
        setMessageResponse('');
        setModalMessage('');
        setModalMessage1('');


        let address = event.target.value;
        // Replace consecutive spaces with a single space
        address = address.replace(/\s+/g, ' ');
        //const address = event.target.value;
        const addressError = validateAddress(address);

        if (addressError) {
            // Display the address error message
            setErrorAddress(addressError);
        } else {
            // Update the state with the valid address
            setNewAddress(address);
            setErrorAddress("");
        }
    };
    
    const handlePhoneChange = (event) => {
        setshowMessage(false);
        setMessageResponse('');
        setModalMessage('');
        setModalMessage1('');
        setErrorPhoneMessage('');
        const value = event.target.value.replace(/\D/g, ''); // Remove non-digits

        // Check if the first character is '0'
        if (value.charAt(0) === '0') {
            // Display error message
            setErrorPhoneMessage("Should not begin with zero.");
        }
        else if (newName.charAt(0) === ' ') {

            return;
        }
        else {
            // Clear error message if no longer applicable
            setErrorPhoneMessage("");
            // Update state
            setNewPhone(value);
        }

    };


    const fetchEmailFromFirebase = async (storedPhoneNumber) => {

        const status = await sessionTime.checkInternetConnectionAvailable(); // Call the function
        //  const status = await sessionTime.checkInternetConnectionAvailable(); // Call the function
        if (!navigator.onLine) {
            // setIsDialogOpen(true);

            setModalMessage('No internet. Cannot access server');
            setshowMessage(true);
            setLoading(false);
            return;
        }

        if (status === 'Poor connection') {
            //  setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setshowMessage(true);
            setLoading(false);
            return;
        }

        const storeSessionId = localStorage.getItem('sessionId');
        try {

            const { sessionId } = await sessionTime.HandleValidatSessiontime(storedPhoneNumber);
            if (storeSessionId === sessionId) {
                const db = getDatabase();
                const adminRootReference = ref(db, `adminRootReference/adminDetails/${storedPhoneNumber}/adminProfile`);
                ///   adminRootReference.onDisconnect().set("I disconnected!")
                const path = adminRootReference.toString();
                try {

                    const data = await sessionTime.callCloudFunction(path);
                    /// console.log('Friebase_data :', data);
                    const parsedData = JSON.parse(data);
                    // onValue(adminRootReference, (snapshot) => {
                    //     const data = snapshot.val();

                    if (parsedData) {
                        //   console.log("Data from Firebase:", data);
                        setNewName(parsedData.name === 'null' ? '' : parsedData.name || '');
                        setName(parsedData.name === 'null' ? '' : parsedData.name || '');
                        setEmail(parsedData.email || '');
                        setNewEmail(parsedData.email || '');
                        setPassword(parsedData.password || '');
                        setKey(parsedData.key || '');
                        setAddress(parsedData.address === 'null' ? '' : parsedData.address || '');
                        setNewAddress(parsedData.address === 'null' ? '' : parsedData.address || '');
                        setPhoneNo2(parsedData.phoneNo2 === 'null' ? '' : parsedData.phoneNo2 || '');
                        setNewPhone(parsedData.phoneNo2 === 'null' ? '' : parsedData.phoneNo2 || '');
                        setLoading(false); // Hide loader after data is fetched
                        // Data not found in Firebase
                    } else {

                        //  console.log("Data not found in Firebase.");
                        setLoading(false); // Hide loader
                    }

                } catch (error) {
                    setLoading(false);
                    //  setIsDialogOpen1(true);
                   const errorMessage = `Response not received from server-A. Please retry..(${error})`;
                    setModalMessage1(errorMessage);
                    setshowMessage(true);
                    // setLoading(false); // Hide loader
                }

            } else {

                setLoading(false);
                setShowAlert(true);
            }

        } catch (error) {
            setLoading(false);
            // setIsDialogOpen1(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Response not received from server-S. Please retry .(${error})`;
            setModalMessage1(errorMessage);
            setshowMessage(true);
            // setLoading(false); // Hide loader
        }

    };


    // Remove Duplate tab (do not open duplicate Tab )
    useEffect(() => {
        const checkDuplicatePage = () => {
            localStorage.openpages = Date.now();
            window.addEventListener('storage', function (e) {
                if (e.key === 'openpages') {
                    localStorage.page_available = Date.now();
                }
                if (e.key === 'page_available') {
                    //  setIsDialogOpen(true);
                    //  setModalMessage('Page already open. Please use the original page instead');
                    // alert('Page already open. Please use the original page instead.');
                    // Open a new window with a URL (can be about:blank or any other)
                    const newWindow = window.open('about:blank', '_self');
                    // Close the current tab when the alert is dismissed
                    newWindow.onload = () => window.close();
                }
            }, false);
        };
        checkDuplicatePage();
        return () => {
            // Cleanup: Remove the event listener when the component unmounts
            window.removeEventListener('storage', checkDuplicatePage);
        };
    }, []); // Empty dependency array to run this effect only once

    // let canRefreshOrClose = false; // Flag to control refresh and close actions
    let canRefreshOrClose = true; // Allow refresh and close actions by default
    // Function to handle beforeunload event

    const handleBeforeUnload = (event) => {
        if (!canRefreshOrClose) {
            const message = 'Please do not reload this page, data may be lost.';
            event.returnValue = message;
            return message; // Required for some older browsers   
        }
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Function to enable/disable refresh and close actions
    const toggleRefreshAndClose = (enabled) => {
        canRefreshOrClose = enabled;
    };



    const saveDataOnFirebase = async () => {
        setLoading(true);
        // Disable browser interactions
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, null, window.location.href);
        };
        toggleRefreshAndClose(false); // Prevent refresh and close actions
        // const status = await sessionTime.checkInternetConnection(); // Call the function
        const status = await sessionTime.checkInternetConnectionAvailable(); // Call the function
        // console.log('check 1 ');

        if (!navigator.onLine) {
            //  setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }

        if (status === 'Poor connection') {
            //   setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }

        const storeSessionId = localStorage.getItem('sessionId');
        try {
            const { sessionId } = await sessionTime.HandleValidatSessiontime(phoneNumber);

            if (storeSessionId === sessionId) {
                const adminProfilePath = `adminRootReference/adminDetails/${phoneNumber}/adminProfile`;
                const fullAdminProfilePath = `https://mij-prepaid-meter-default-rtdb.firebaseio.com/${adminProfilePath}`;
                const newData = {
                    address: newAddress === '' ? 'null' : newAddress,
                    email: newEmail || '',
                    key: key,
                    name: newName === '' ? 'null' : newName,
                    password: password,
                    phoneNo: phoneNumber || '',
                    phoneNo2: newPhone === '' ? 'null' : newPhone,
                    // Add other fields as needed
                };
                const dataToSend = {
                    [fullAdminProfilePath]: newData
                };
                try {

                    // Directly set the state using newData
                    setNewName(newData.name === 'null' ? '' : newData.name || '');
                    setName(newData.name === 'null' ? '' : newData.name || '');
                    setAddress(newData.address === 'null' ? '' : newData.address || '');
                    setNewAddress(newData.address === 'null' ? '' : newData.address || '');
                    await sessionTime.callWriteRtdbFunction(dataToSend);
                    updateSessionActiveTime(phoneNumber);
                    const errorMessage = ` Data Saved Successfully.`;
                    setMessageResponse(errorMessage);
                    setshowMessage(true);
                    //  setisResponseSever(true);
                    setLoading(false);
                    toggleRefreshAndClose(true); // Prevent refresh and close actions
                }
                catch (error) {
                    setLoading(false);
                    // setisResponseSever(true);
                    const errorMessage = ` Response not received from server. Please check through enquiry if transaction completed successfully. (${error}) `;
                    setMessageResponse(errorMessage);
                    toggleRefreshAndClose(true); // Prevent refresh and close actions
                }

            } else {

                setLoading(false);
                setShowAlert(true);
            }

        } catch (error) {
            setLoading(false);
            // setIsDialogOpen1(true);
            const errorMessage = `Response not received from server-S. Please retry. (${error})`;
            setModalMessage1(errorMessage);
            toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
        }
    }


    const handlesaveButton = async () => {

        setshowMessage(false);
        setMessageResponse('');
        setModalMessage('');
        setModalMessage1('');

        if (newPhone === phoneNumber) {
            setErrorPhoneMessage("Mobile number already exist..");
            return;
        }
        if (newPhone.length < 10 && newPhone.length > 0) {
            setErrorPhoneMessage("Enter valid number.");
            return;
        }

        if (newName === "") {
            setErrorMessage("Cannot be empty. ");
            return;
        } else {

        }

        if (newEmail.trim() === '') {
            setErrorMessageemail('Please enter an email.');
            return;
        }

        if (!validateEmailAddress(newEmail)) {
            setErrorMessageemail('Enter Valid Email');
            return;
        }

        localStorage.setItem('AdminName', newName);
        localStorage.setItem('AdminEmail', newEmail);
        localStorage.setItem('AdminAltnumber', newPhone);
        localStorage.setItem('AdminAddress', newAddress);

        if (newName !== name) {

            // test name and email and phone and address
            if (newEmail !== email) {
                if (newPhone !== phoneNo2) {
                    if (newPhone !== '') {
                        if (newAddress !== address) {
                            //     console.log("name email phone address changed");
                            navigate('/phoneemailotp', { state: { newName, newEmail, newPhone, newAddress } });
                        } else {
                            //  console.log("name email phone  changed");
                            navigate('/phoneemailotp', { state: { newName, newEmail, newPhone, newAddress } });
                        }
                    } else {
                        navigate('/emailsendotp', { state: { newName, newEmail, newPhone, newAddress } });
                    }

                }
                else if (newAddress !== address) {
                    //   console.log("name email address changed");
                    navigate('/emailsendotp', { state: { newName, newEmail, newPhone, newAddress } });
                } else {
                    //  console.log("name email  changed");
                    navigate('/emailsendotp', { state: { newName, newEmail, newPhone, newAddress } });
                }
            }
            else if (newPhone !== phoneNo2) {
                if (newPhone !== '') {
                    if (newAddress !== address) {
                        //     console.log("name phone address changed");
                        navigate('/sendphoneotp', { state: { newName, newEmail, newPhone, newAddress } });
                    } else {
                        //   console.log(" name phone  changed");
                        navigate('/sendphoneotp', { state: { newName, newEmail, newPhone, newAddress } });
                    }
                } else {
                    saveDataOnFirebase();
                }
            }
            else if (newAddress !== address) {
                //  console.log("name  address changed");
                saveDataOnFirebase();
            }
            else {
                //  console.log("name changed");
                saveDataOnFirebase();
            }
        }
        else if (newEmail !== email) {

            if (newPhone !== phoneNo2) {
                if (newPhone !== '') {
                    if (newAddress !== address) {
                        //   console.log(" email phone  Address changed");
                        navigate('/phoneemailotp', { state: { newName, newEmail, newPhone, newAddress } });
                    }
                    else {
                        //  console.log(" email phone changed");
                        navigate('/phoneemailotp', { state: { newName, newEmail, newPhone, newAddress } });
                    }
                } else {
                    navigate('/emailsendotp', { state: { newName, newEmail, newPhone, newAddress } });

                }
            }
            else if (newAddress !== address) {
                //   console.log(" email address changed");
                navigate('/emailsendotp', { state: { newName, newEmail, newPhone, newAddress } });
            }
            else {
                //  console.log(" email  changed");
                navigate('/emailsendotp', { state: { newName, newEmail, newPhone, newAddress } });
            }
        }
        else if (newPhone !== phoneNo2) {


            if (newPhone !== '') {
                if (newAddress != address) {
                    //  console.log("phone  address changed");
                    navigate('/sendphoneotp', { state: { newName, newEmail, newPhone, newAddress } });
                } else {
                    //  console.log("phone   changed");
                    navigate('/sendphoneotp', { state: { newName, newEmail, newPhone, newAddress } });
                }
            } else {
                saveDataOnFirebase();
            }
        }

        else if (newAddress !== address) {
            //  console.log("address changed");
            saveDataOnFirebase();
        }
        else {
            // setIsDialogOpen(true);
            setshowMessage(true);
            setModalMessage('No change in existing data');
            setLoading(false);
            // alert("No change in existing data.")
        }
    }

    const updateSessionActiveTime = (phoneNumber) => {
        sessionTime.updateSessionTimeActiveUser(phoneNumber);
    }
    const history = useNavigate();
    const handleLogout = () => {
        auth.signOut().then(() => {
            history('/'); // Change '/login' to your login page route
        }).catch((error) => {
            //     //     // Handle any errors during logout
            console.error('Error logging out:', error.message);
        })
        /// history('/'); 
    }

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const [isDialogOpen1, setIsDialogOpen1] = useState(false);
    const closeDialog1 = () => {
        setIsDialogOpen1(false);
    };

    const [isResponseSever, setisResponseSever] = useState(false);
    const [MessageResponse, setMessageResponse] = useState('');

    const closeResponse = () => {
        setNewAddress('');
        setName('');
        setNewName('');
        setNewEmail('');
        setEmail('');
        setNewPhone('');
        setPhoneNo2('');
        setisResponseSever(false);
        window.location.reload(); // This will reload the page
        //   window.onpopstate = null;
    }

    const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

    const [showMessage, setshowMessage] = useState(false);

    const handleCloseAlert = () => {
        setShowAlert(false); // Close alert when clicked on the close button
        handleLogout();
    };

    return (
        <>
            <Navbar />

            <>
                {loading ? (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '9999'
                    }}>
                        <div className="spinner-border text-danger" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : null}


                <div className='containers'>
                    {showMessage && (
                        <div className="alert alert-primary "

                            role="alert">
                            {MessageResponse || modalMessage || modalMessage1}
                        </div>
                    )}


                    {/* <div>
                        <p style={{ textAlign: 'center', marginBottom: '5px', color: 'red' }} >{MessageResponse || modalMessage || modalMessage1}</p>
                    </div> */}

                    <div style={{ marginTop: '1px' }} >
                        <div style={{ marginTop: '1px' }} className='AdminPhoto'>
                            <img
                                // style={{ height: '20%', width: '20%', }}
                                src={adminprofile}
                                alt="adminprofile"
                            />
                        </div>
                        <div className='style'>
                            <p style={{ textAlign: 'center', marginBottom: '5px' }}> < Createtime /></p>
                        </div>
                    </div>


                    <div className='formgroup'>

                        <div >
                            <TextField
                                label="Admin Mobile Number "
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                                value={phoneNumber ? `+91${phoneNumber}` : '+91'} // Concatenate with phoneNumber state if available, otherwise show +91
                                onChange={(e) => setPhoneNumber(e.target.value.replace('+91', ''))} // To allow users to modify without +91
                                InputLabelProps={{
                                    style: { color: 'black' }
                                }}
                                InputProps={{
                                    style: { color: 'black' },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}

                                disabled
                            />
                        </div>

                        <div >
                            <TextField
                                error={!!errorMessage || !!errorName} /* Ensure error prop is boolean */
                                label="Name"
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                                value={newName !== null ? newName : name} // Concatenate with phoneNumber state if available, otherwise show +91
                                helperText={errorMessage && errorName ? `${errorMessage}, ${errorName}` : errorMessage || errorName} // Display both error messages if available
                                inputProps={{ maxLength: 20 }}
                                onChange={handleNameChange}
                                InputLabelProps={{
                                    style: { color: 'black' }
                                }}
                                InputProps={{
                                    style: { color: 'black' },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>


                        <div >
                            <TextField
                                error={errorMessage1} /* Ensure error prop is boolean */
                                label="E-mail"
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                                value={newEmail !== null ? newEmail : email}
                                helperText={errorMessage1} // Display both error messages if available
                                inputProps={{ maxLength: 40 }}
                                onChange={handleEmailChange}
                                InputLabelProps={{
                                    style: { color: 'black' }
                                }}
                                InputProps={{
                                    style: { color: 'black' },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>


                        <div >
                            <TextField
                                error={erronphoneMessage} /* Ensure error prop is boolean */
                                label="Alternate Mobile Number"
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                                value={newPhone !== null ? newPhone : phoneNo2}
                                onChange={handlePhoneChange}
                                helperText={erronphoneMessage} // Display both error messages if available
                                inputProps={{ maxLength: 10 }}
                                InputLabelProps={{
                                    style: { color: 'black' }
                                }}
                                InputProps={{
                                    style: { color: 'black' },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div >
                            <TextField
                                error={errorAddres} /* Ensure error prop is boolean */
                                label="Address"
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                                value={newAddress !== null ? newAddress : address}
                                onChange={handleAddressChange}
                                helperText={errorAddres} // Display both error messages if available
                                inputProps={{ maxLength: 40 }}
                                InputLabelProps={{
                                    style: { color: 'black' }
                                }}
                                InputProps={{
                                    style: { color: 'black' },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LocationOnIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>


                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlesaveButton}
                            style={{ marginTop: '10px' }}
                        >
                            UPDATE DETAILS
                        </Button>
                    </div>


                </div>


            </>

            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
                <Modal.Body>
                    <p style={{ color: 'red' }}> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isDialogOpen1} onHide={closeDialog1} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>

                <Modal.Body>
                    <p style={{ color: 'red' }}> {modalMessage1}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={isResponseSever} onHide={closeResponse} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>

                <Modal.Body>
                    <p> {MessageResponse}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeResponse}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {showAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseAlert}
                        className='AlertDailog'
                    >
                        You have been logged out due to login from another device.!
                    </Alert>
                </div>
            )}


        </>
    )
}

export default Admindetail