import { useState, useEffect } from 'react';
import React from 'react';

import { ref, set, getDatabase } from 'firebase/database'; // Import necessary database functions

import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'; // Import the 'createUserWithEmailAndPassword' function
import './login.css';
import { useNavigate } from 'react-router-dom';
import Mainhomepage from '../home/mainhomepage'
import CommonFuctions from '../commonfunction';
import { Modal, Button } from 'react-bootstrap';
import PhoneSendOtp from './phonesendotp';

import ResendTimer from './resendotp'
import EmailSendotp from '../admin/email'





function Getotp() {
  const navigate = useNavigate();
  const [mobileOTP, setMobileOTP] = useState('');
  const [emailOTP, setEmailOTP] = useState('');

  const [storedPhoneNumber, setStoredPhoneNumber] = useState('');
  const [storedEmail, setStoredEmail] = useState('');
  const [storedPassword, setStoredPassword] = useState('');
  const [customEmail, setCustomEmail] = useState(''); // Declare customEmail state
  const [mobileOTPError, setMobileOTPError] = useState('');
  const [emailOTPError, setEmailOTPError] = useState('');
  const SessionTime = new CommonFuctions();
  const [loading, setLoading] = useState(false); // State to track loading status
  const [modalMessage, setModalMessage] = useState('');
  const [modalMessageAlreadyopen, setModalMessageAlreadyopen] = useState('');
  const [modalMessageResponse, setModalMessageResponse] = useState('');

  
  const { handleButtonClick } = EmailSendotp({ email: storedEmail }); // Pass the email as a prop
  useEffect(() => {
    const storedPhone = localStorage.getItem('phoneNumber');
    setStoredPhoneNumber(storedPhone || ''); // Handling null values
    const email = storedPhone ? `${storedPhone}@gmail.com` : '';
    setCustomEmail(email); // Set the custom email in the state
    const storedEmail = localStorage.getItem('email');
    setStoredEmail(storedEmail || ''); // Handling null values
    const storedPw = localStorage.getItem('password');
    setStoredPassword(storedPw || ''); // Handling null values

  }, []);

  const isNumeric = /^\d+$/; // Regular expression to match only digits
  const maxLength = 6;
  const handleMobileOTPChange = (e) => {
    setMobileOTPError('');
    const { value } = e.target;
    if (value === '' || (value.length <= maxLength && isNumeric.test(value))) {
      setMobileOTP(value);
    }
  };
  const handleEmailOTPChange = (e) => {
    setEmailOTPError('');
    const { value } = e.target;
    if (value === '' || (value.length <= maxLength && isNumeric.test(value))) {
      setEmailOTP(value);
    }
  };
  // Function to generate a random key
  function generateRandomKey(length) {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let randomKey = '';

    for (let i = 0; i < length; i++) {
      randomKey += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return randomKey;
  }
  // Usage example
  const keyLength = 16; // Specify the length of the key
  const randomKey = generateRandomKey(keyLength);

  // let canRefreshOrClose = false; // Flag to control refresh and close actions
  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMobileOTPError('');
    setEmailOTPError('');

    // if (mobileOTP.length !== maxLength || emailOTP.length !== maxLength) {
    //   alert('Please enter a 6-digit OTP for both fields.');
    //   return;
    // }

    if (!mobileOTP) {
      setMobileOTPError('Enter valid mobile OTP');
      return;
    }

    if (!emailOTP) {
      setEmailOTPError('Enter valid mobile OTP');
      return;
    }

    // Original otp 
    const storedPhoneOTP = localStorage.getItem('otp'); // Get stored phone OTP
    const storedEmailOTP = localStorage.getItem('emailOTP'); // Get stored email OTP

    if (mobileOTP !== storedPhoneOTP) {
      setMobileOTPError('Incorrect mobile OTP');
    }

    if (emailOTP !== storedEmailOTP) {
      setEmailOTPError('Incorrect email OTP');
    }


    if ((mobileOTP === storedPhoneOTP && emailOTP === storedEmailOTP)) {

      setLoading(true);
      if (!navigator.onLine) {
        setIsDialogOpen(true);
        setModalMessage('No/Poor Internet connection. Cannot access server.');
        setLoading(false);
        toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
        return;
      }

      const status = await SessionTime.checkInternetConnection(); // Call the function
      if (status === 'Poor connection.') {
        setIsDialogOpen(true);
        setModalMessage('No/Poor Internet connection. Cannot access server.');
        setLoading(false);
        toggleRefreshAndClose(true); // Enable refresh and close actions again after data saving is complete
        return;
      }

      try {
        const authInstance = getAuth(); // Get the auth instance
        const userCredential = await createUserWithEmailAndPassword(
          authInstance,
          customEmail,
          storedPassword
        );

        //    console.log('User registered:', userCredential.user);     
        if (userCredential) {
          try {
            const database = getDatabase(); // Get the database instance

            const passwordPath = `common/adminPhoneList/${storedPhoneNumber}/password`;
            await set(ref(database, passwordPath), storedPassword);
            const adminProfilePath = `adminRootReference/adminDetails/${storedPhoneNumber}/adminProfile`;
            const userData = {
              address: 'null',
              email: storedEmail,
              key: randomKey,
              name: 'null',
              password: storedPassword,
              phoneNo: storedPhoneNumber,
              phoneNo2: 'null',
              // Add other fields as needed
            };



            await set(ref(database, adminProfilePath), userData);
            const sessionId = await SessionTime.updateSessionTimeInLogIn(storedPhoneNumber);
            //  console.log("Sessdion Id :", sessionId);
            localStorage.setItem('sessionId', sessionId);
            localStorage.setItem('LoggIn', 'true');

            localStorage.removeItem('emailOTP');
            localStorage.removeItem('otp');
            setLoading(false);
            const messega = `Account created successfully!`;
            setIsDialogOpenResponse(true);
            setModalMessageResponse(messega);

          } catch (error) {
            console.error('Error saving data to database:', error.message);
            // Handle the error
          }
        }
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          setIsDialogOpenAlreadyopen(true);
          setModalMessageAlreadyopen('An Account is already exist  with this mobile number.')
          setLoading(false);
        } else {

        }
      }
    } else {
    }
  };


  const [otpSendMessage, setotpSendMessage] = useState('');


  const resendOtp = async () => {
    let phoneSuccess = false;
    let emailSuccess = false;
    // Send OTP to phone
    try {
      const phoneSendOtp = new PhoneSendOtp(storedPhoneNumber);
      const phoneResult = await phoneSendOtp.sendOTP(storedPhoneNumber);
      if (phoneResult == 411) {
        setMobileOTPError(`Invalid phone number: (${phoneResult})`);
      } else {
        phoneSuccess = true;
      }
    } catch (phoneError) {
      // console.error('Error sending OTP to phone:', phoneError);
    }
    // Send OTP to email
    try {
      const emailResult = await handleButtonClick(storedEmail);
      if (typeof emailResult === 'boolean') {
        if (emailResult) {
          emailSuccess = true;
          /// console.log('OTP sent to email successfully.');
        } else {
          setEmailOTPError('Error sending OTP to email. Please try again.');
        }
      } else {
        ///console.error('Error:', emailResult);
      }
    } catch (emailError) {
      // console.error('Error sending OTP to email:', emailError);
    }

    // Check if both OTPs were sent successfully
    if (phoneSuccess && emailSuccess) {
      setotpSendMessage(' OTP sent successfully.');
    } else {
      setEmailOTPError('OTP sending failed.');
    }
  };

  useEffect(() => {
    const storedPhone = localStorage.getItem('phoneNumber');
    const storedEmail = localStorage.getItem('email');
    setStoredPhoneNumber(storedPhone || ''); // Handling null values
    setStoredEmail(storedEmail || ''); // Handling null values
  }, []);


  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => {
    setIsDialogOpen(false);
    // window.location.reload(); // This will reload the page
  };

  const [isDialogOpenResponse, setIsDialogOpenResponse] = useState(false);
  const closeDialogResponse = () => {
    setIsDialogOpenResponse(false);
    navigate('/welcome');
  };
  const [isDialogOpenAlreadyopen, setIsDialogOpenAlreadyopen] = useState(false);
  const closeDialogAlreadyopen = () => {
    setIsDialogOpenAlreadyopen(false);
    navigate('/');
    // window.location.reload(); // This will reload the page
  };

  return (
    <>

      <div>
        <Mainhomepage />
      </div>
      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}

      <div className='containers'>
        <form className='formgroup' onSubmit={handleSubmit}>

          <div>

            <label htmlFor="email">Enter Mobile OTP</label>
            <input
              type="text"
              className='form-control'
              placeholder=" Mobile OTP"
              value={mobileOTP}
              onChange={handleMobileOTPChange}
            />

            {mobileOTPError && <p style={{ color: 'red' }} className="error">{mobileOTPError}</p>}
          </div>
          <div>
            <label htmlFor="password">Enter E-mail OTP</label>
            <input
              type="text"
              className='form-control'
              placeholder=" E-mail OTP"
              value={emailOTP}
              onChange={handleEmailOTPChange}
            />

            {emailOTPError && <p style={{ color: 'red' }} className="error">{emailOTPError}</p>}

            <span style={{ color: 'blue' }} >{otpSendMessage}</span>
          </div>


          <div className='button-row'  style={{display:'flex', justifyContent:'space-between' }}>
            <button className='btn btn-primary' type="submit">
              VERIFY
            </button>
            <div style={{marginTop:'15px', marginLeft:'20px'}}>
            <ResendTimer onClick={resendOtp} />
            </div>
          </div>

          {/* <div className='d-grid col-4'>
            <button className='btn btn-primary' type="submit">
              VERIFY
            </button>
          </div>

          <div>

            <ResendTimer onClick={resendOtp} />
          </div> */}

        </form>
      </div>



      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={isDialogOpenAlreadyopen} onHide={closeDialogAlreadyopen} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p> {modalMessageAlreadyopen}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialogAlreadyopen}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={isDialogOpenResponse} onHide={closeDialogResponse} backdrop="static" style={{ marginTop: '3%', pointerEvents: loading ? 'none' : 'auto' }}>
        {/* <Modal.Header closeButton>
      </Modal.Header>  */}
        <Modal.Body>
          <p> {modalMessageResponse}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialogResponse}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>





    </>
  );
}

export default Getotp;
