import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PhoneSendOtp from '../adminLogin/phonesendotp';
import { useNavigate } from 'react-router-dom';
import { auth } from '../adminLogin/firebase';
import Navbar from '../adminLogin/navbar';
import CommonFuctions from '../commonfunction';
import { Modal, Button } from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import ResendTimer from '../adminLogin/resendotp'
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';


function Verifyotp() {
    const sessiontime = new CommonFuctions();
    const navigate = useNavigate();
    const location = useLocation();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const storedPhoneOTP = localStorage.getItem('otp'); // Get stored phone OTP
    const [otpSendMessage, setotpSendMessage] = useState('');
    const [displayMessage, setDisplayMessage] = useState(true);
    const { message } = location.state || {};
    // console.log ('local phone number: ' ,storePassowrd);
    const [otp, setOtp] = useState(''); // Initialize OTP state with an empty string
    const [error, setError] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogSavedata, setIsDialogSavedata] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const [modalMessageSavedata, setModalMessageSavedata] = useState('');

    const [fetchedMeterList, setFetchedMeterList] = useState({});
    const database = firebase.database();
    const [meterList, setMeterList] = useState([]);

    const serialNumber = localStorage.getItem('deletedSerial');


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                // User is logged in
                setUser(authUser);
                //  console.log("Logged in user:", authUser.email);
                const emailParts = authUser.email.split('@'); // Split email by '@'
                if (emailParts.length === 2) {
                    const number = emailParts[0]; // Get the part before '@'
                    setPhoneNumber(number);
                    handleSearch(number)
                    setLoading(false);

                }
            } else {
                // No user is logged in, you can redirect to another page or handle accordingly
                setUser(null);
                // Example: Redirect to another page
                window.location.href = '/'; // Redirect to your login page
            }
        });

        return () => unsubscribe(); // Cleanup function for unmounting
    }, []);




    const handleSearch = async (phoneNumber) => {
        try {
            const snapshot = await firebase.database().ref(`/adminRootReference/adminDetails/${phoneNumber}/newMeterList`).once('value');
            const fetchedMeterList = snapshot.val();
            if (fetchedMeterList) {
                const serialNumbers = Object.keys(fetchedMeterList);
                setMeterList(serialNumbers);
                setFetchedMeterList(fetchedMeterList);
                // console.log('Data : ', fetchedMeterList);
            } else {
                setFetchedMeterList({});
                setMeterList([]);  // Set an empty array if no meter list is found
                setShowAlert(true);
            }
        } catch (error) {

            console.error('Error retrieving data:', error);
        } finally {
            setLoading(false);
        }
    };

    // let canRefreshOrClose = false; // Flag to control refresh and close actions
    let canRefreshOrClose = true; // Allow refresh and close actions by default
    // Function to handle beforeunload event
    const handleBeforeUnload = (event) => {
        if (!canRefreshOrClose) {
            const message = 'Please do not reload this page, data may be lost.';
            event.returnValue = message;
            return message; // Required for some older browsers   
        }
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Function to enable/disable refresh and close actions
    const toggleRefreshAndClose = (enabled) => {
        canRefreshOrClose = enabled;
    };



    const onSubmitotp = async (e) => {

        e.preventDefault();

        if (otp.trim() === '') { // Check if OTP is empty or contains only spaces
            setError('Please enter the OTP.'); // Set error message
            return; // Exit the function
        }
        if (otp === storedPhoneOTP) {
            setLoading(true); // Set loading status to true
            toggleRefreshAndClose(false); // Prevent refresh and close actions
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, null, window.location.href);
            };

            if (!navigator.onLine) {
                setIsDialogOpen(true);
                setModalMessage('No internet. Cannot access server.');
                setLoading(false);
                toggleRefreshAndClose(true); // Prevent refresh and close actions
                return;
            }
            //  const status = await sessiontime.checkInternetConnection(); // Call the function

            const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function

            if (status === 'Poor connection') {
                setIsDialogOpen(true);
                setModalMessage('Poor internet. Cannot access server.');
                toggleRefreshAndClose(true); // Prevent refresh and close actions

                setLoading(false);
                return;
            }

            handleDeleteMeterlist();

        } else {
            setError(' Invalid OTP.'); // Set error message
            return; // Exit the function
        }

    };

    const handleDeleteMeterlist = async () => {

        const meterDetails = fetchedMeterList[serialNumber];

        if (serialNumber !== null) {
            try {
                const path = firebase.database().ref(`/adminRootReference/adminDetails/${phoneNumber}/newMeterList/${serialNumber}`);
                const deleteMeter = path.toString();

                const path2 = firebase.database().ref(`/adminRootReference/meterDetails/${serialNumber}`);
                const meterDetials = path2.toString();

                const setemeter = database.ref(`/adminRootReference/adminDetails/${phoneNumber}/deletedMeterList/${serialNumber}`);
                const updateSetMeter = setemeter.toString();

                // Get the current server timestamp
                const serverTimestamp = await sessiontime.fireabseServerTimestamp();

                let dataToSend;

                if (meterDetails && meterDetails.groupName) {
                    const groupname = meterDetails.groupName;
                    console.log("check Groupname :", groupname);
                    const formattedGroupName = groupname.split(' ').join('_');
                    const deleteTennent = firebase.database().ref(`/adminRootReference/tenantDetails/${phoneNumber}/${formattedGroupName}/${serialNumber}`);
                    const deleteTennentDetials = deleteTennent.toString();

                    dataToSend = {
                        [updateSetMeter]: serverTimestamp,
                        [deleteTennentDetials]: null,
                        [deleteMeter]: null,
                        [meterDetials]: null
                    };
                } else {
                    dataToSend = {
                        [updateSetMeter]: serverTimestamp,
                        [deleteMeter]: null,
                        [meterDetials]: null
                    };
                }

                console.log('save data ', dataToSend);
                 const result = await sessiontime.callWriteRtdbFunction(dataToSend);      
                console.log('save data ', result);
                setIsDialogSavedata(true);
                const message = `Meter Serial number deleted succesfully `;
                setModalMessageSavedata(message);
                setLoading(false);
                toggleRefreshAndClose(true); // Prevent refresh and close actions

            } catch (error) {



                toggleRefreshAndClose(true); // Prevent refresh and close actions
                console.log('Something went wrong ', error);
                
            }
        }

    };

    const handleOnChange = (e) => {
        setotpSendMessage('');
        setError('');
        const value = e.target.value.replace(/\s/g, ''); // Remove spaces
        if (/^\d*$/.test(value)) { // Test if the value contains only digits
            setOtp(value); // Update the OTP state if it's valid
        }
        // Remove the message when the user starts entering OTP
        if (displayMessage) {
            setDisplayMessage(false);
        }
    }
    const resendOtp = async () => {
        if (displayMessage) {
            setDisplayMessage(false);
        }
        const phoneSendOtp = new PhoneSendOtp(phoneNumber);
        const result = await phoneSendOtp.sendOTP(phoneNumber);

        if (result == '200') {

            toggleRefreshAndClose(true); // Prevent refresh and close actions
            setotpSendMessage(' OTP sent successfully.');
            /// console.log('Opt send successfully ');
        }
        else if (result.status_code == '411') {
            // console.log('Invalid Number ')
            setError(`Invalid Number (${result.status_code})`)
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }
        else if (result.status_code == '416') {
            setError(`SMS service is temporarily unavailable. Please retry. (${result.status_code})`);
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }
        else if (result == "Network Error") {
            setLoading(false);
            setIsDialogOpen(true);
            // const errorMessage = `Response not recieved  from server-A. (${error}). Please check if transaction completed successfully , else retry. `;
            const errorMessage = `Network error. Please retry. (${result})`;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions
            return;
        }
        else {
            setLoading(false);
            setIsDialogOpen(true);
            const errorMessage = `SMS service is temporarily unavailable. Please retry.(${result})`;
            setModalMessage(errorMessage);
            toggleRefreshAndClose(true); // Prevent refresh and close actions

        }
    };

    const closeDialogSavedata = () => {
        navigate('/removeMeter/deletemeter');
       // window.location.reload();
        setIsDialogSavedata(false);
        localStorage.removeItem('deletedSerial');
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };


    const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

    const handleCloseAlert = () => {
        setShowAlert(false); // Close alert when clicked on the close button
        navigate('/login');
    };


    return (
        <>

            <div>
                <Navbar />
            </div>


            {loading ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}

            <div className='containers'>
                <form className='formgroup' onSubmit={onSubmitotp}>
                    <div>

                        <label htmlFor="phoneNumber">Enter OTP </label>
                        <input
                            type="text"
                            value={otp}
                            className='form-control'
                            onChange={handleOnChange}
                            maxLength={6}

                            placeholder="Mobile-OTP "
                        />
                        <div style={{ color: 'red' }}>{error}</div>
                        <span style={{ color: 'blue' }}>{otpSendMessage}</span>
                        {displayMessage && (
                            <div>

                                <p style={{ color: 'blue' }}>{message}</p>

                            </div>
                        )}
                    </div>

                    <div className="d-flex justify-content-center w-100">
                        <button type="submit" className='btn btn-primary' style={{ marginRight: '50px' }}>VERIFY</button>

                        <div>
                            <ResendTimer onClick={resendOtp} />
                        </div>

                    </div>


                </form>
            </div>


            <Modal show={isDialogSavedata} onHide={closeDialogSavedata} backdrop="static" >
                <Modal.Body>
                    <p style={{ color: 'red' }}> {modalMessageSavedata}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialogSavedata}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>





            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" >
                <Modal.Body>
                    <p style={{ color: 'red' }}> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>






            {showAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseAlert}
                        className='AlertDailog'
                    >
                        You have been logged out due to login from another device.!
                    </Alert>
                </div>
            )}





        </>
    )
}

export default Verifyotp
