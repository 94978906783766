import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import InternetAwareNavLink from './InternetAwareNavLink';
import Mainhomepage from '../home/mainhomepage';

function Navbar() {

  
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };


  return (
    <>
      <>

      <Mainhomepage />

        {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="#">Navbar</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="#">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav> */}

{/* 
        <div className='SecondNavbar' >
          <nav className="navbar navbar-expand-lg navbar-light  CustomeNavbar " style={{ backgroundColor: '#e3f2fd', marginTop: '3%' }}>
            <div className="container-fluid">
              <button className="navbar-toggler togglerr" type="button" onClick={toggleNavbar}
                aria-controls="navbarSupportedContent" aria-expanded={!collapsed} aria-label="Toggle navigation">
                <i className="fas fa-bars tooglebutton"></i>
              </button>
              <div className={`collapse navbar-collapse ${collapsed ? '' : 'show'}`} id="navbarSupportedContent">
                <ul className="navbar-nav ">
                  <li className="nav-item lini-nav-items">
                    <InternetAwareNavLink to="/admindetail" className="nav-link" activeClassName="active">Admin Details</InternetAwareNavLink>
                  </li>
                  <li className="nav-item lini-nav-items">
                    <InternetAwareNavLink to="/test" className="nav-link" activeClassName="active"  >Consumer Details</InternetAwareNavLink>
                  </li>
                  <li className="nav-item lini-nav-items">
                    <InternetAwareNavLink to="/generateConfig" className="nav-link" activeClassName="active">Generate Re-Configuration Token</InternetAwareNavLink>
                  </li>
                  <li className="nav-item lini-nav-items">
                    <InternetAwareNavLink to="/generateallrecharge" className="nav-link" activeClassName="active">Generate Recharge Token</InternetAwareNavLink>
                  </li>
                  <li className="nav-item lini-nav-items">
                    <InternetAwareNavLink to="/groupmeter" className="nav-link" activeClassName="active">Meter Details on Server</InternetAwareNavLink>
                  </li>

                  <li className="nav-item">
                    <InternetAwareNavLink to="/removeMeter/deletemeter" className="nav-link" activeClassName="active">Delete Meter</InternetAwareNavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div> */}


      </>

    </>
  );
}

export default Navbar;
