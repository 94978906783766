import React, { useState, useEffect } from 'react';
import Navbar from '../adminLogin/navbar';
import ChangeMobile from './changeMobile';
import TransferMeter from './transferMeter';
import TokenStatus from './tokenStatus';
import 'firebase/compat/database';
import { database } from '../firebase';
import { auth } from '../adminLogin/firebase';
import CommonFuctions from '../commonfunction';
import Alert from '@mui/material/Alert';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'firebase/compat/database';
import './Sidebar.css';


function GenerateReconfig() {


    const getData = new CommonFuctions();
    const navigate = useNavigate();
    const [activeComponent, setActiveComponent] = useState('ChangeMobile');
    const [loading, setLoading] = useState(true);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [data, setData] = useState({});
    const [allMeter, setAllMeter] = useState([]);
    const [allGroupName, setAllGroupName] = useState([]);
    const [modalMessage, setModalMessage] = useState('');
    const [meterList, setMeterList] = useState([]);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {

                    // setLoading(false);
                    const numberPart = emailParts[0];
                    setPhoneNumber(numberPart);
                    handleGetDatafromFirebar(numberPart)

                }
            } else {
                window.location.href = '/';
            }
        });

        return () => unsubscribe();
    }, []);




    async function handleGetDatafromFirebar(numberPart) {

        const status = await getData.checkInternetConnectionAvailable(); // Call the function
        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setLoading(false);
            return;
        }
        if (status === 'Poor connection') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setLoading(false);
            return;
        }

        try {
            const storeSessionId = localStorage.getItem('sessionId');
            const { sessionId } = await getData.HandleValidatSessiontime(numberPart);

            if (storeSessionId === sessionId) {
                const allSerialPathRef = database.ref(`/adminRootReference/tenantDetails/${numberPart}`);
                const updateSerialPath = allSerialPathRef.toString();
                const serialMeterDataRef = database.ref(`/adminRootReference/adminDetails/${numberPart}/newMeterList`);
                const updateMeterPath = serialMeterDataRef.toString();
                const path = [updateSerialPath, updateMeterPath];
                const meterList = await getData.callMultipleReadfunction(path);

                // 《❎🔥   Print tennentDetials  Data form firebase   🔥❎ 》 
                const tennentDetials = JSON.parse(meterList[0]);
                delete tennentDetials.time;

                setData(tennentDetials || {});
                const allGroupname = Object.keys(tennentDetials || {});
                setAllGroupName(allGroupname);

                // 《❎🔥   Print AdminDetials Data form firebase   🔥❎ 》 
                const adminDetails = JSON.parse(meterList[1]);
                delete adminDetails.time;

                if (adminDetails) {

                    const serialNumbers = Object.keys(adminDetails);
                    if (serialNumbers.length === 0) {
                        setshowMeterListAlert(true);
                        setLoading(false);
                    }
                    else {
                        setMeterList(serialNumbers);
                    }

                }
            } else {

                setLoading(false);
                setShowAlert(true);
            }

        } catch (error) {
            setIsDialogOpen(true);
            const errorMessage = `Response not received from server-A. Please retry. (${error})`;
            setModalMessage(errorMessage);
        }
        finally {
            setLoading(false);
        }
    }


    const handleSidebarItemClick = (componentName) => {
        setActiveComponent(componentName);
        setIsSidebarVisible(false); // Hide the sidebar
    };

    const renderComponent = () => {
        switch (activeComponent) {
            case 'ChangeMobile':
                return <ChangeMobile data={data} />;
            case 'TransferMeter':
                return <TransferMeter meterList={meterList} />;
            case 'TokenStatus':
                return <TokenStatus />;
            default:
                return <TransferMeter />;
        }
    };

    const getSidebarItemStyle = (componentName) => {
        return {
            fontSize: '18px',
            cursor: 'pointer',
            backgroundColor: activeComponent === componentName ? '#2196f3' : 'transparent',
            color: activeComponent === componentName ? 'white' : 'black',
            padding: '10px',
            borderRadius: '5px',
            margin: '5px 0'
        };
    };

    const toggleSidebar = () => {
        setIsSidebarVisible(prevState => !prevState);
    };


    const history = useNavigate();
    const handleLogout = () => {
        auth.signOut().then(() => {
            history('/'); // Change '/login' to your login page route
        }).catch((error) => {
            //     //     // Handle any errors during logout
            console.error('Error logging out:', error.message);
        })
        /// history('/'); 
    }


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
    const handleCloseAlert = () => {
        setShowAlert(false); // Close alert when clicked on the close button
        handleLogout();
    };

    const [showMeterListAlert, setshowMeterListAlert] = useState(false); // State to control alert visibility
    const handleCloseMeterlistAlert = () => {
        setshowMeterListAlert(false); // Close alert when clicked on the close button
        navigate('/');
    };




    return (
        <>
            <Navbar />
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '9999',
                    }}
                >
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}


            <div
                className={`sidebar-toggle ${isSidebarVisible ? 'sidebar-open' : ''}`}
                onClick={toggleSidebar}
            >
                {isSidebarVisible ? 'X' : '☰'}
            </div>

            <div className='openMenu'>
                <div className={`sidebarss ${isSidebarVisible ? 'visible' : ''}`}>
                    <div className='groups-container'>
                        <div className="" style={{ backgroundColor: '#e3f2fd' }}>
                            <p
                                style={getSidebarItemStyle('ChangeMobile')}
                                className="paragraph"
                                onClick={() => handleSidebarItemClick('ChangeMobile')}
                            >
                                Change Admin No/Password
                            </p>
                        </div>
                        <div className="" style={{ backgroundColor: '#e3f2fd' }}>
                            <p
                                style={getSidebarItemStyle('TransferMeter')}
                                className="paragraph"
                                onClick={() => handleSidebarItemClick('TransferMeter')}
                            >
                                Transfer Meters to Another Admin
                            </p>
                        </div>
                        <div className="" style={{ backgroundColor: '#e3f2fd' }}>
                            <p
                                style={getSidebarItemStyle('TokenStatus')}
                                className="paragraph"
                                onClick={() => handleSidebarItemClick('TokenStatus')}
                            >
                                View Meter list with re-config token statussss
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ marginTop: '8%', width: '80%' }}>
                    {renderComponent()}
                </div>
            </div>






            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
                <Modal.Body>
                    <p style={{ color: 'red' }}> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {showAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseAlert}
                        className='AlertDailog'
                    >
                        You have been logged out due to login from another device.!
                    </Alert>
                </div>
            )}

            {showMeterListAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseMeterlistAlert}
                        className='AlertDailog'
                    >
                        No configure meter found
                    </Alert>
                </div>
            )}




        </>
    );
}

export default GenerateReconfig;
