import React from 'react'

function Bottom() {
    return (
        <>

            <div>
                <div className="fixed-top" style={{ marginTop: '56px' }}>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <a className="navbar-brand" href="#">Navbar 2</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup2" aria-controls="navbarNavAltMarkup2" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup2">
                            <div className="navbar-nav">
                                <a className="nav-item nav-link active" href="#">Home <span className="sr-only">(current)</span></a>
                                <a className="nav-item nav-link" href="#">Features</a>
                                <a className="nav-item nav-link" href="#">Pricing</a>
                                <a className="nav-item nav-link disabled" href="#">Disabled</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>


        </>
    )
}

export default Bottom
