import React, { useState, useEffect } from 'react';
import Navbar from '../adminLogin/navbar';
import '../admin/adminpage.css';
import { auth } from '../adminLogin/firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './style.css'
import { Modal, Button, } from 'react-bootstrap';
import CommonFuctions from '../commonfunction';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';





function Deletemeter() {
    const common = new CommonFuctions();
    const navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const [serialNumber, setSerialNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [meterList, setMeterList] = useState([]);
    const [adminNumber, setAdminNumber] = useState('');
    const database = firebase.database();
    const [fetchedMeterList, setFetchedMeterList] = useState({});
    const [deletedData, setDeletedData] = useState({});


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                    const number = emailParts[0];
                    setAdminNumber(number);
                    handleSearch(number);
                }
            } else {
                window.location.href = '/';
            }
        });

        return () => unsubscribe();
    }, []);



    const handleSearch = async (phoneNumber) => {


        const status = await common.checkInternetConnectionAvailable(); // Call the function
        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setloading(false);
            return;
        }
        if (status === 'Poor connection') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setloading(false);
            return;
        }

        
        // const storeSessionId = localStorage.getItem('sessionId');
        // const { sessionId } = await common.HandleValidatSessiontime(adminNumber);

        // console.log('storeSessionId', storeSessionId);
        // if (storeSessionId === sessionId) {

        // }

        // else {
        //     setloading(false);
        //     setShowAlert(true);

        // }


        try {
                const serialMeterDataRef = database.ref(`/adminRootReference/adminDetails/${phoneNumber}/newMeterList`);
                const updateMeterPath = serialMeterDataRef.toString();

                const result = await common.callCloudFunction(updateMeterPath);
                const adminDetails = JSON.parse(result);
                delete adminDetails.time;

                if (adminDetails) {
                    const serialNumbers = Object.keys(adminDetails);

                    if (serialNumbers.length === 0) {
                        setshowMeterListAlert(true);
                        setloading(false);
                    }
                    else {
                        setMeterList(serialNumbers);
                    }
                }

        } catch (error) {

            setIsDialogOpen(true);
            const errorMessage = ` Response not received from server-A. Please retry. (${error})`;
            setModalMessage(errorMessage);
            console.error('Error retrieving data:', error);
        }
        finally {
            setloading(false);
        }

    };



    const handleDeleteMeter = () => {

        if (serialNumber.length < 6) {
            setErrorMessage("Invalid serial number");
        } else {
            // Check if the entered serial number exists in meterList
            if (meterList.includes(serialNumber)) {
                //  console.log('Meter list match', serialNumber);

                setisdeletedSerial(true);
            } else {
                setErrorMessage("Invalid serial number");
            }
        }
    };



    const handleDeleteMeterlist = async () => {

        const meterDetails = fetchedMeterList[serialNumber];
        const groupname = meterDetails.groupName;

        if (serialNumber !== null) {
            try {


                const path = firebase.database().ref(`/adminRootReference/adminDetails/${adminNumber}/newMeterList/${serialNumber}`);
                const deleteMeter = path.toString();

                const path2 = firebase.database().ref(`/adminRootReference/meterDetails/${serialNumber}`);
                const meterDetials = path2.toString();

                const setemeter = database.ref(`/adminRootReference/adminDetails/${adminNumber}/deletedMeterList/${serialNumber}`);
                const updateSetMeter = setemeter.toString();

                // Get the current server timestamp
                const serverTimestamp = await common.fireabseServerTimestamp();

                let dataToSend;

                if (meterDetails && meterDetails.groupName) {
                    const groupname = meterDetails.groupName;
                    console.log("check Groupname :", groupname);
                    const formattedGroupName = groupname.split(' ').join('_');
                    const deleteTennent = firebase.database().ref(`/adminRootReference/tenantDetails/${adminNumber}/${formattedGroupName}/${serialNumber}`);
                    const deleteTennentDetials = deleteTennent.toString();

                    dataToSend = {
                        [updateSetMeter]: serverTimestamp,
                        [deleteTennentDetials]: null,
                        [deleteMeter]: null,
                        [meterDetials]: null
                    };
                } else {
                    dataToSend = {
                        [updateSetMeter]: serverTimestamp,
                        [deleteMeter]: null,
                        [meterDetials]: null
                    };
                }

                console.log('save data ', dataToSend);
                const result = await common.callWriteRtdbFunction(dataToSend);
                setSerialNumber('');
                //  console.log('save data ', result);
                alert("save data successfully ");

            } catch (error) {
                console.log('Something went wrong ', error);
            }
        }



    };





    const handleShowDeleteMEter = async () => {

        setloading(true);
        const status = await common.checkInternetConnectionAvailable(); // Call the function
        if (!navigator.onLine) {
            setIsDialogOpen(true);
            setModalMessage('No internet. Cannot access server.');
            setloading(false);
            return;
        }
        if (status === 'Poor connection') {
            setIsDialogOpen(true);
            setModalMessage('Poor internet. Cannot access server.');
            setloading(false);
            return;
        }


        // const storeSessionId = localStorage.getItem('sessionId');
        // const { sessionId } = await common.HandleValidatSessiontime(adminNumber);
        // if (storeSessionId === sessionId) {


        // }
        // else {

        //     setloading(false);
        //     setShowAlert(true);
        // }


        try {

            const setemeter = database.ref(`/adminRootReference/adminDetails/${adminNumber}/deletedMeterList/`);
            const updateSetMeter = setemeter.toString();
            const result = await common.callCloudFunction(updateSetMeter);
            const serialData = JSON.parse(result);
            delete serialData.time;
            // Convert timestamps to formatted strings
            const formattedData = Object.entries(serialData).reduce((acc, [serial, time]) => {
                acc[serial] = common.TimestampConverter(time);
                return acc;
            }, {});
            setDeletedData(formattedData);
            setshowDeleteData(true);


        } catch (error) {
            setIsDialogOpen(true);
            const errorMessage = ` Response not received from server. Please check through enquiry if transaction completed successfully. (${error}) `;
            setModalMessage(errorMessage);

        }
        finally {
            setloading(false);
        }

    }

    const handleInputChange = (e) => {
        setErrorMessage('');
        const value = e.target.value.replace(/\D/g, '').slice(0, 6);
        if (value.charAt(0) === '0') {
            setErrorMessage('Should not begin with Zero.');
            return;
        }
        setSerialNumber(value);
    };

    const [showDeleteData, setshowDeleteData] = useState(false);
    const closeDeletedata = () => {
        setshowDeleteData(false);
    }

    const [isdeletedSerial, setisdeletedSerial] = useState(false);

    const isConfirmedYesSerial = () => {
        setisdeletedSerial(false);
        localStorage.setItem('deletedSerial', serialNumber);
        navigate('/removeMeter/hadlegetotp');
    }

    const closedeletedSerial = () => {
        setisdeletedSerial(false);

    }
    const [showMeterListAlert, setshowMeterListAlert] = useState(false); // State to control alert visibility
    const handleCloseMeterlistAlert = () => {
        setshowMeterListAlert(false); // Close alert when clicked on the close button
        navigate('/');
    };

    const [modalMessage, setModalMessage] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const closeDialog = () => {
        setIsDialogOpen(false);
    };


    const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
    const [showMessage, setshowMessage] = useState(false);
    const handleCloseAlert = () => {
        setShowAlert(false); // Close alert when clicked on the close button
        // handleLogout();
    };

    const history = useNavigate();
    const handleLogout = () => {
        auth.signOut().then(() => {
            history('/'); // Change '/login' to your login page route
        }).catch((error) => {
            //     //     // Handle any errors during logout
            console.error('Error logging out:', error.message);
        })
        /// history('/'); 
    }




    // CSS styles as a JavaScript object
    const boxStyle = {
        border: '1px solid #ddd',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    };

    return (
        <>

            <Navbar />

            {loading ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}


            <div className='containers'>
                <p style={{ color: 'red' }}> Note: After Deleting meter you cannot find it. This Action will delete </p>
                <div className='formgroup'>
                    <div >
                        <label htmlFor="SerialNumber">Enter Meter Serial Number</label>
                        <div className="input-container1 d-flex">
                            <input
                                type="text"
                                className='form-control flex-grow-1'
                                style={{ width: '250px' }} // add width to input
                                placeholder="Enter Meter Serial Number"
                                value={serialNumber}
                                onChange={handleInputChange}
                                maxLength={6}
                            />
                            <i className="fas fa-search"></i>
                        </div>
                        <p style={{ color: 'red' }}>{errorMessage}</p>

                    </div>
                    <div className="d-flex justify-content-center" style={{ marginTop: '1%', width: '40%' }}>
                        <button className='btn btn-danger flex-grow-1' onClick={handleDeleteMeter}>DELETE</button>
                    </div>

                    <div className="d-flex justify-content-center" style={{ marginTop: '4%', width: '40%' }}>
                        <button className='btn btn-primary flex-grow-1' onClick={handleShowDeleteMEter}>SHOW DELETED METERS</button>
                    </div>
                </div>
            </div>


            <Modal show={showDeleteData} onHide={closeDeletedata} backdrop="static" style={{ marginTop: '3%' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Deleted Meter Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(deletedData).length > 0 ? (
                        Object.entries(deletedData).map(([serial, time]) => (
                            <div key={serial} style={boxStyle}>
                                <p>Serial number : <span style={{ color: 'blue' }}>{serial} </span> </p>
                                <p>Deleted  Time :<span style={{ color: 'blue' }} > {time} </span> </p>
                            </div>
                        ))
                    ) : (
                        <div style={boxStyle}>
                            <p style={{ color: 'red ' }}>Deleted meter serials not  found.</p>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <Modal show={isdeletedSerial} onHide={closedeletedSerial} backdrop="static" style={{ marginTop: '3%' }}>
                <Modal.Body>
                    <p> Are you sure what to delete </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={isConfirmedYesSerial}>
                        YES
                    </Button>

                    <Button variant="success" onClick={closedeletedSerial}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>
                <Modal.Body>
                    <p style={{ color: 'red' }}> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {showAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseAlert}
                        className='AlertDailog'
                    >
                        You have been logged out due to login from another device.!
                    </Alert>
                </div>
            )}




            {showMeterListAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseMeterlistAlert}
                        className='AlertDailog'
                    >
                        No configure meter found
                    </Alert>
                </div>
            )}




        </>
    );
}

export default Deletemeter;
