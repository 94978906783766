import React, { useEffect, useState } from 'react';
import { auth } from '../adminLogin/firebase';
import { database } from '../firebase';
import './Phonepasswordchange.css'; // Import CSS file
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CommonFuctions from '../commonfunction';
import { ref, getDatabase } from 'firebase/database';
import Alert from '@mui/material/Alert';
import '../adminLogin/login.css'


function Phonepasswordchange() {

    const SessionTime = new CommonFuctions();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [enteredPhoneNumberModal, setEnteredPhoneNumberModal] = useState('');
    const [enteredPasswordModal, setEnteredPasswordModal] = useState('');
    const [confirmPassowrd, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [phonenumberlist, setPhonenumberList] = useState('');
    const [transferEmail, setTranseferEmail] = useState('');
    const [transferPassword, setTransferPassword] = useState('');
    const [transferKey, setTranseferKey] = useState('');
    const [transferPhoneNo2,setTransferPhoneNo2] = useState('');
    const [message, setMessage] = useState('');
   // const [showPassword, setShowPassword] = useState(false);
    const [phoneError, setErrorPhoneError] = useState("");
    const [allmobileNumber, setAllmobileNumber] = useState([]);


    //  const handleClose = () => setShowModal(false);

    const handleShow = () => {
        setLoading(false);
        setShowModal(true)
    };
    const handleClose = () => {
        setShowModal(false);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                const emailParts = authUser.email.split('@');
                if (emailParts.length === 2) {
                    const numberPart = emailParts[0];
                    //  console.log("Number", numberPart);
                    setPhoneNumber(numberPart);
                    getAdminPassword(numberPart);
                }
            } else {
                setUser(null);
                // Instead of redirecting, you can handle this case in your UI
                window.location.href = '/';
            }
        });

        return () => unsubscribe();
    }, []);


    const getAdminPassword = async(numberPart) => {

        const setemeter = database.ref(`/adminRootReference/adminDetails/`);
        const updateSetMeter = setemeter.toString();
        const result = await SessionTime.callCloudFunction(updateSetMeter);
        const serialData = JSON.parse(result);
        delete serialData.time;
    
        const meterIds = Object.keys(serialData);
       // console.log('phone number ', meterIds);
         setAllmobileNumber(meterIds);

        const passwordRef = database.ref(`/adminRootReference/adminDetails/${numberPart}/adminProfile`);
        passwordRef.once('value', (snapshot) => {
            const fetchedPassword = snapshot.val();
            // console.log("Fetched password", fetchedPassword.password);
            //  console.log("Fetched password", fetchedPassword.email);
            setEmail(fetchedPassword.email);
            setPassword(fetchedPassword.password);
            setLoading(false);
        });
    };

    const getAdminPhoneList = async (enteredPhoneNumber) => {

        const db = getDatabase();
        const adminRootReference = ref(db, `adminRootReference/adminDetails/`);
        ///   adminRootReference.onDisconnect().set("I disconnected!")
        const path = adminRootReference.toString();
        try {
            const data = await SessionTime.callCloudFunction(path);
           /// console.log('Friebase_data :', data);
           
            const fetchedPasswords = JSON.parse(data);
            for (const phonelist in fetchedPasswords) {
                if (fetchedPasswords.hasOwnProperty(phonelist)) {
                    if (phonelist === enteredPhoneNumber) {
                        const showMessage = `Account already exist with this mobile number. 
                        You are generating transfer token. This token will be used to transfer your meter(s) to`;
                        setLoading(false);
                        //  setShowModal(true)
                        setMessage(showMessage);
                        getTransferAdminemail(phonelist)
                        setPhonenumberList(phonelist)
                        return;
                    }
                }
            }
            setMessage(""); // Clear error if no matching phone number is found
            // });
        } catch (error) {

            setisOpenInternet(true);
            setModalMessage(`Response not recieved  from server-S. Please check your internet connection .(${error})`);
            /// alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
            setLoading(false);
            return;
        }

    };

    
    const getTransferAdminemail = (phonelist) => {

        const passwordRef = database.ref(`/adminRootReference/adminDetails/${phonelist}/adminProfile`);
        passwordRef.once('value', (snapshot) => {
            const fetchedPassword = snapshot.val();
            setTranseferEmail(fetchedPassword.email);
            setTransferPassword(fetchedPassword.password);
            setTranseferKey(fetchedPassword.key);
            setTransferPhoneNo2(fetchedPassword.phoneNo2)

        });
    };

    const handleSubmit = async (e) => {
        
        e.preventDefault();
        const enteredPhoneNumber = e.target.newPhoneNumber.value;
        const enteredPassword = e.target.newPassword.value;
        const confirmPassword = e.target.ConfirmPassword.value;
        // console.log('confirm password :', confirmPassword);
        if (!enteredPhoneNumber.trim() && !enteredPassword.trim() && !confirmPassword.trim()) {
            setError("Please enter at least one field.");
            return;
        }

        if(allmobileNumber.includes(enteredPhoneNumber)){
            setError("Mobile number already Exist please try to  another number ");
            return;
        }

        if (enteredPhoneNumber === phoneNumber) {
            setError("No change in existing mobile no. and password Re-Config token will not be generated");
            return;
        }
        else {
            // console.log("New Phone Number:", enteredPhoneNumber);
            if (enteredPassword === password) {
                setError("No change in existing mobile no. and password Re-Config token will not be generated");
                return;
            }
            if (enteredPhoneNumber.trim() !== '') {
                if (!/^\d{10}$/.test(enteredPhoneNumber)) {
                    setError('Invalid mobile number.');
                    return;
                }
            } else {
                // No mobile number entered, do nothing or handle accordingly
            }

            if (enteredPassword.trim() !== '') {
                if (confirmPassword.trim() === '') {
                    setError('Please Confirm Password.');
                    return;
                }
                if (enteredPassword.trim() !== confirmPassword.trim()) {
                    setError('Password Mismatched.');
                    return;
                }
            } else if (confirmPassword.trim() !== '') {
                setError('Please enter new password .');
                return;
            }


            setLoading(true);
            const status = await SessionTime.checkInternetConnectionAvailable(); // Call the function
            if (!navigator.onLine) {
                setisOpenInternet(true);
                setModalMessage('No/Poor Internet connection. Cannot access server.');
                setLoading(false);
                return;
            }
            if (status === 'Poor connection') {
                setisOpenInternet(true);
                setModalMessage('Poor internet. Cannot access server.');
                setLoading(false);
                return;
            }

            const storeSessionId = localStorage.getItem('sessionId');
            try {
                const { sessionId } = await SessionTime.HandleValidatSessiontime(phoneNumber);
                if (storeSessionId === sessionId) {
                    //  console.log("New Password:", enteredPassword);
                    getAdminPhoneList(enteredPhoneNumber);
                    handleShow();
                    // setLoading(false);
                    // setShowModal(true)

                } else {
                    setLoading(false);
                    setShowAlert(true);
                }

            } catch (error) {
                setisOpenInternet(true);
                setModalMessage(`Response not recieved  from server-S. Please check your internet connection .(${error})`);
                setLoading(false);
                return;
            }
        }
    };

    
    const handleModalSubmit = async () => {


        localStorage.setItem('TransferNumber', enteredPhoneNumberModal);
        localStorage.setItem('Transferpassword', enteredPasswordModal);
        localStorage.setItem('transferEmail', transferEmail);
        localStorage.setItem('email', email);
        localStorage.setItem('phonenumberlist', phonenumberlist);
        localStorage.setItem('transferAdminPassword', transferPassword);
        localStorage.setItem('transferKey', transferKey);
        localStorage.setItem('transferPhoneNo2', transferPhoneNo2);

        // Update only Phone Number 
        if ((enteredPhoneNumberModal !== phoneNumber) && (enteredPasswordModal == null || enteredPasswordModal.length === 0) && enteredPhoneNumberModal !== phonenumberlist) {
            navigate('/phoneotp', { state: { email, enteredPhoneNumberModal, enteredPasswordModal, phoneNumber } });
        } else if (enteredPasswordModal !== password && (enteredPhoneNumberModal == null || enteredPhoneNumberModal.length === 0)) {
            // Update only Password 
            navigate('/passwordchange', { state: { email, enteredPasswordModal, phoneNumber } });
        } else if (enteredPhoneNumberModal !== phoneNumber && enteredPasswordModal !== password && enteredPasswordModal.length > 0) {
            // Update both password and phonenumber 
            navigate('/phoneemail', { state: { enteredPhoneNumberModal, email, enteredPasswordModal } });

        } else if (enteredPhoneNumberModal === phonenumberlist && (enteredPasswordModal == null || enteredPasswordModal.length === 0)) {

            // tranfer token  for phonenumber 
           // navigate('/transefer', { state: { phonenumberlist, transferEmail, transferPassword, transferKey } });
        }

    };
    const [errorphone, SetErrorphone] = useState();
    const handleChange = (e) => {

        setErrorPhoneError('');
        setError('');
        const { value } = e.target;
        if (value.charAt(0) === '0') {
            SetErrorphone("Should not begin with Zero. ");
        }
        else {

            setEnteredPhoneNumberModal(value.replace(/[^0-9]|^0/g, ''));
            SetErrorphone("");
        }
    };
    const handlePassowrd = (e) => {
        const value = e.target.value;
        setEnteredPasswordModal(value);
        setError('');
    }
    const handleConfirmPassowrd = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
        setError('');
    }
    // const togglePasswordVisibility = () => {
    //     setShowPassword(!showPassword);
    // };

    const history = useNavigate();
    const handleLogout = () => {
        auth.signOut().then(() => {
            localStorage.removeItem('LoggIn', 'true');
            // Redirect to login page after successful logout
            history('/'); // Change '/login' to your login page route
        }).catch((error) => {
            // Handle any errors during logout
            console.error('Error logging out:', error.message);
        })
    }


    const [isOpenInternet, setisOpenInternet] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const closeInternet = () => {
        setisOpenInternet(false);
        // window.location.reload(); // This will reload the page
    };


    const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

    const handleCloseAlert = () => {
        setShowAlert(false); // Close alert when clicked on the close button
        handleLogout();
    };





    return (
        <>

            {loading ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999'
                }}>
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}

            <div className='container'>
                <div className='passwordinputFields'>

                    <form className='formsection' onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className='lable' htmlFor="newPhoneNumber">New mobile number</label>
                            <div className='input-container1'>
                                <input type='text'
                                    id="newPhoneNumber"
                                    placeholder='Enter Mobile Number'
                                    maxLength={10}
                                    className='form-control'
                                    value={enteredPhoneNumberModal}
                                    onChange={handleChange}
                                    style={{ paddingLeft: '30px' }}
                                />
                                <i className="fas fa-phone"></i>

                            </div>
                            {errorphone && (
                                <div style={{ color: 'red' }}>{errorphone}</div>
                            )}
                            
                            <div style={{ color: 'red' }}>{phoneError
                            }</div>

                            <span>Leave blank if don't want to change </span>
                        </div>

                        <div className="form-group">
                            <label htmlFor="newPassword">New Password</label>
                            <div className='input-container1'>
                                <input
                                  
                                    type='password'
                                    id="newPassword"
                                    placeholder='Enter New Password'
                                    maxLength={20}
                                    autoComplete="new-passowrd"
                                    value={enteredPasswordModal}
                                    onChange={handlePassowrd}
                                    style={{ paddingLeft: '30px' }}
                                />
                                
                                <i className="fas fa-lock password-icon"></i>
                            </div>
                            <span>Leave blank if don't want to change </span>
                        </div>

                        <div className="form-group">

                            <label htmlFor="ConfirmPassword">Confirm new password</label>
                            <div className='input-container1'>
                                <input
                                    type='password'
                                    id="ConfirmPassword"
                                    placeholder='Confirm new password'
                                    maxLength={20}
                                    autoComplete="new-passowrd"
                                    value={confirmPassowrd}
                                    onChange={handleConfirmPassowrd}
                                    style={{ paddingLeft: '30px' }}
                                />
                                <i className="fas fa-lock password-icon"></i>
                            </div>
                            <p>Minimum 8 characters, leave blank if don't want to change</p>

                            <div style={{ color: 'red' }}>{error}</div>
                        </div>
                        <div>
                            <p style={{ fontSize: '20px' }}>Note: Only the latest token is available. All pending tokens will be removed.</p>
                        </div>
                        <div style={{ marginLeft: '23%', marginTop: '20px' }}>
                           
                            <button type="submit" className="btn btn-primary">Generate Token</button>
                        </div>
                    </form>

                </div>

            </div>

            {/* Modal */}
            <Modal show={showModal} onHide={handleClose} backdrop="static">
                <Modal.Header>
                    <Modal.Title>
                        Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message && (
                        <p >{message} <span style={{ color: 'blue' }}> +91{enteredPhoneNumberModal}</span>.
                            Password will not be changed. Are you sure want to generate meter transfer token?.</p>
                    )}

                    {!message && (
                        <div>
                            {/* Display new phone number and password */}
                            {enteredPhoneNumberModal && !enteredPasswordModal && (
                                <p>Your account mobile number will be changed to <span style={{ color: 'blue' }}> +91{enteredPhoneNumberModal}</span>. Password will not be changed. Are you sure to generate re-configuration token?. </p>
                            )}

                            {!enteredPhoneNumberModal && enteredPasswordModal && (
                                // <p>Your New Password is: {enteredPasswordModal}</p>
                                <p>Your account password will be changed. Mobile number will not be changed. Are you sure to generate re-configuration token?.</p>
                            )}

                            {enteredPhoneNumberModal && enteredPasswordModal && (
                                <p>Your account mobile number will be changed to <span style={{ color: 'blue' }}> +91{enteredPhoneNumberModal}</span> and your password will be changed. Are you sure to generate re-configuration token?.</p>
                            )}
                        </div>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        NO
                    </Button>
                    {/* Conditionally render the button based on your requirements */}


                    <Button variant="primary" onClick={handleModalSubmit}>
                        YES
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={isOpenInternet} onHide={closeInternet} backdrop="static" style={{ marginTop: '3%' }}>
                {/* <Modal.Header closeButton>
      </Modal.Header>  */}
                <Modal.Body>
                    <p style={{ color: 'red' }}> {modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeInternet}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>



            {showAlert && (
                <div className="alert-overlay"
                >
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleCloseAlert}
                        className='AlertDailog'
                    >
                        You have been logged out due to login from another device.!
                    </Alert>
                </div>
            )}





        </>
    )
}

export default Phonepasswordchange 