import React, { useEffect, useState } from 'react';
import Navbar from '../adminLogin/navbar';
import 'firebase/compat/database';
import '../meterdetailonserver/meterdetail.css';
import { auth } from '../adminLogin/firebase';
import { database } from '../firebase';

import { useLocation, useNavigate } from 'react-router-dom';

import firebase from 'firebase/compat/app'; // Import the Firebase app
import 'firebase/compat/firestore';
import Generatetoken from '../reconfigtoken/generatetokenkey';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import { Modal, Button } from 'react-bootstrap';

import PhoneSendOtp from '../adminLogin/phonesendotp';
import ResendTimer from '../adminLogin/resendotp'
import EmailSendotp from '../admin/email'

import CommonFuctions from '../commonfunction';

const allSerialNo = [];
let countA = 0;
const tokenGenerrateType = '';

function Transferphoneemail() {
  const SessionTime = new CommonFuctions();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOTP, setMobileOTP] = useState('');
  const [emailOTP, setEmailOTP] = useState('');
  const [mobileOTPError, setMobileOTPError] = useState('');
  const [emailOTPError, setEmailOTPError] = useState('');
  const [oldAdminNumber, setOldAdminNumber] = useState('');
  const [modalMessageResponse, setModalMessageResponse] = useState('');
  const [isDialogOpenResponse, setIsDialogOpenResponse] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [password, setPassword] = useState('');

  const [transferKey, setTransferKey] = useState('');
  const [transferPassword, setTransferPassword] = useState('');
  const [adminphone2, setTransferPhoneNo2] = useState('');


  const [adminEmail, setAdminEmail] = useState('');
  const [adminKey, setAdminKey] = useState('');
  const [loading, setLoading] = useState(false); // State to track loading status
  // const { phonenumberlist } = location.state || {};
  // const { email } = location.state || {};
  // const { transferPassword } = location.state || {};
  // const { transferKey } = location.state || {};
  const mainFunction = new Generatetoken();

  const phonenumberlist = localStorage.getItem('phonenumberlist');
  // const transferPassword = localStorage.getItem('Transferpassword');
  //  const transferKey = localStorage.getItem('transferKey');
  // const adminphone2 = localStorage.getItem('transferPhoneNo2');

  /// console.log('number is ', phonenumberlist);
  const { message } = location.state || {};

  const { handleButtonClick } = EmailSendotp({ email: adminEmail }); // Pass the email as a prop

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is logged in
        setUser(authUser);
        // console.log("Logged in user:", authUser.email);
        const emailParts = authUser.email.split('@');
        if (emailParts.length === 2) {
          const numberPart = emailParts[0];
          setOldAdminNumber(numberPart);
          getAdminPassword(numberPart);
          handleGetSerialData();
          SessionTime.updateSessionTimeActiveUser(numberPart);
          //  handlePhoneSerialList(numberPart);
          // setLoading(false);
          //  console.log("Number part:", numberPart); // Log the extracted number part
        }
      } else {
        setUser(null);
        window.location.href = '/'; // Redirect to your login page
      }
    });
    return () => {
      unsubscribe(); // Cleanup function for unmounting
    };
  }, []);




  const getAdminPassword = async (numberPart) => {
    const status = await SessionTime.checkInternetConnection(); // Call the function
    if (status === 'Poor connection.') {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection , Please retry.');
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }

    const FetchAdmin = firebase.database().ref(`adminRootReference/adminDetails/${phonenumberlist}/adminProfile`);
    const Admindetialsref = FetchAdmin.toString();
    const data = await SessionTime.callCloudFunction(Admindetialsref);
    const parsedData = JSON.parse(data);
    delete parsedData.time;
    const transferKey = parsedData.key;
    setTransferKey(transferKey);
    const transferPassword = parsedData.password;
    setTransferPassword(transferPassword);
    setTransferPhoneNo2(parsedData.phoneNo2)
    // delete serialData.time;


    const passwordRef = database.ref(`/adminRootReference/adminDetails/${numberPart}/adminProfile`);
    passwordRef.once('value', (snapshot) => {
      const fetchedPassword = snapshot.val();
      setAdminKey(fetchedPassword?.key);
      //  console.log("Admin key : ", fetchedPassword?.key)
      setPassword(fetchedPassword?.password);
      setAdminEmail(fetchedPassword?.email);
      setLoading(false);

      //  console.log("Admin password : ", fetchedPassword?.password);



    });
  };

  const handleSubmitClick = async () => {

    // if (mobileOTP === '') {
    //   setMobileOTPError('Invalid mobile OTP');
    //   return;
    // }
    // if (emailOTP === '') {
    //   setEmailOTPError('Invalid email OTP');
    //   return;
    // }
    // const storedPhoneOTP = localStorage.getItem('otp'); // Get stored phone OTP
    // const storedEmailOTP = localStorage.getItem('emailOTP'); // Get stored email OTP
    // if (mobileOTP !== storedPhoneOTP) {
    //   setMobileOTPError('Invalid mobile OTP');
    //   return;
    // }

    // if (emailOTP !== storedEmailOTP) {
    //   setEmailOTPError('Invalid email OTP');
    //   return;
    // }
    // if (mobileOTP === storedPhoneOTP && emailOTP === storedEmailOTP) {

    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection , Please retry.');
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }

    const status = await SessionTime.checkInternetConnection(); // Call the function
    if (status === 'Poor connection.') {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection , Please retry.');
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }
    // handlePhoneSerialList();
    isTokenAvailable();
    // } else {
    // }
  };

  let allMeterDetailsGlobal = {}; // Define a global variable to store all meter details
  const handleGetSerialData = async () => {
    const selectedSerials = JSON.parse(localStorage.getItem('selectedSerials')) || [];
    // console.log('Retrieved Serials:', selectedSerials);
    for (const serial of selectedSerials) {
      const FetchAdmin = firebase.database().ref(`adminRootReference/meterDetails/${serial}`);
      const Admindetialsref = FetchAdmin.toString();
      try {
        const data = await SessionTime.callCloudFunction(Admindetialsref);
        const meterDetails = JSON.parse(data);
        delete meterDetails.time;
        // console.log(meterDetails);
        allMeterDetailsGlobal[serial] = meterDetails; // Store meter details by serial number
      } catch (error) {
        console.error(`Error fetching data for serial ${serial}:`, error);
      }
    }
    return allMeterDetailsGlobal; // Return all meter details object
  };


  let canRefreshOrClose = true; // Allow refresh and close actions by default
  // Function to handle beforeunload event
  const handleBeforeUnload = (event) => {
    if (!canRefreshOrClose) {
      const message = 'Please do not reload this page, data may be lost.';
      event.returnValue = message;
      return message; // Required for some older browsers   
    }
  };
  // Add event listener for beforeunload
  window.addEventListener('beforeunload', handleBeforeUnload);
  // Function to enable/disable refresh and close actions
  const toggleRefreshAndClose = (enabled) => {
    canRefreshOrClose = enabled;
  };
  let countGenerated = 0;
  let countNotGenerated = 0;



  const isTokenAvailable = async () => {
    setLoading(true);
    toggleRefreshAndClose(false); // Prevent refresh and close actions
    // Disable browser interactions
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };

    ///  console.log('Stage 1 ');

    if (!navigator.onLine) {
      //  console.log('No internet');
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection , Please retry..');
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }


    const status = await SessionTime.checkInternetConnection(); // Call the function
    if (status === 'Poor connection.') {
      ///  console.log('Stage 2 ');
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection , Please retry.');
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }


    // try {
    //   const isLastActive = await SessionTime.isLastActiveTime(oldAdminNumber);
    //   if (isLastActive) {
    //     setLoading(false);
    //     toggleRefreshAndClose(true); // Prevent refresh and close actions
    //     handleLogout();
    //     localStorage.setItem('isLoggedIn', true);
    //   }
    //   else {
    //   }
    // } catch (error) {

    //   setIsDialogOpen(true);
    //   setModalMessage(`Response not recieved  from server-S. Please check your internet connection .(${error})`);
    //   setLoading(false);
    //   toggleRefreshAndClose(true); // Prevent refresh and close actions

    // }


    //  console.log('Stage 3');
    const storeSessionId = localStorage.getItem('sessionId');

    try {
      const { sessionId } = await SessionTime.HandleValidatSessiontime(oldAdminNumber);
      if (storeSessionId === sessionId) {

        try {

          // const allMeterDetailsGlobal = await handlePhoneSerialList(oldAdminNumber);

          const allMeterDetailsGlobal = await handleGetSerialData();

         /// console.log('data is11 :', allMeterDetailsGlobal)

          let tokenDataList = []; // Array to store token data for all meters

          for (const serial of Object.keys(allMeterDetailsGlobal)) {
            const reConfigToken = allMeterDetailsGlobal[serial]?.reConfigToken;
            if (reConfigToken && reConfigToken.tokenStatus === "0" && reConfigToken.isTransfer === "true") {
              countNotGenerated++;

            } else {
              let type = '04';
              const TransferToken = mainFunction.isTransferToken(type, phonenumberlist, serial, transferPassword, transferKey, adminKey);
              countGenerated++;
              tokenDataList.push({ serial: serial, token: TransferToken }); // Store generated token 
            }
          }

          // Check if all tokens were generated successfully
          const allTokensGenerated = tokenDataList.every(tokenData => tokenData.token);
          if (countGenerated > 0 && allTokensGenerated) {
            let dataToSend = {}; // Object to store data to be uploaded
            for (const tokenData of tokenDataList) {
              const serial = tokenData.serial;
              const token = tokenData.token;
              const configRef = firebase.database().ref(`adminRootReference/meterDetails/${serial}/reConfigToken`);
              const saveTariffReference = configRef.toString();
              const mytime = await mainFunction.fireabseServerTimestamp();
              const data = {
                isTransfer: "true",
                token: token,
                tokenGeneratedTime: mytime,
                tokenStatus: "0",
                tokenUsedTime: 'null',
                transferPhoneNumber: phonenumberlist,
              };
              dataToSend[serial] = data;
            }

            const Updatedata = {
              newPhone: phonenumberlist,
              tokenDetails: dataToSend, // Use dataToSend instead of tokenDetails
              oldPhone: oldAdminNumber,
              newAdminPhone2: adminphone2,
              transferState: 'update',
            };

            
            try {

             //  console.log('Data to be uploaded:', Updatedata); // Print the data object
              // console.log('Generated Count : ', countGenerated);
               await SessionTime.transferToken(Updatedata);
               localStorage.removeItem('selectedSerials');

              await SessionTime.callWriteRtdbFunction(dataToSend);

              let errorMessage;
              if (countNotGenerated === 0) {

                errorMessage = `Re-Configuration token (transfer) generated for ${countGenerated}  meter(s).
                  \nMeter(s) must be Re-Configured for transfer to new admin. You have been logged out.`;

              } else {
                errorMessage = `Re-Configuration token (Transfer) generated for ${countGenerated} meter(s). Token not generated for ${countNotGenerated} meter(s)
    as transfer token is pending these meter(s).
    \n You have been logged out.`;
              }

              setLoading(false);
              toggleRefreshAndClose(true); // Prevent refresh and close action
              setIsDialogOpenResponse(true);
              setModalMessageResponse(errorMessage);

              // localStorage.removeItem('otp');
              // localStorage.removeItem('emailOTP');

            } catch (error) {
              toggleRefreshAndClose(true); // Prevent refresh and close actions
              setLoading(false);
              setIsDialogOpenResponse(true);
              const errorMessage = `Response not received from server-A. Please check if the transaction completed successfully, else retry. (${error}).`;
              setModalMessageResponse(errorMessage);
            }

          } else {

            const errorMessage = `Re-Configuration token (Transfer) generated for ${countGenerated} meter(s). Token not generated for ${countNotGenerated} meter(s)
              as transfer token is pending these meter(s).
              \n You have been logged out.`;
            setLoading(false);
            toggleRefreshAndClose(true); // Prevent refresh and close action
            setIsDialogOpenResponse(true);
            setModalMessageResponse(errorMessage);
          }



        } catch (error) {
          toggleRefreshAndClose(true); // Prevent refresh and close actions
          //   console.log('Soemthing  wrong download data');
          setLoading(false);
          setIsDialogOpen(true);
          const errorMessage = `Response not recieved  from server-S. Please check your internet connection .(${error})`;
          setModalMessage(errorMessage);
        }


      } else {
        //   alert("You have been logged-out due to log-in from another device.");
        /// console.log('you are logg out ');
        //  handleLogout();
      }

    } catch (error) {
      toggleRefreshAndClose(true); // Prevent refresh and close actions
      //   console.log('Soemthing  wrong download data');
      setLoading(false);
      setIsDialogOpen(true);
      const errorMessage = `Response not recieved  from server-S. Please check your internet connection ....(${error})`;
      setModalMessage(errorMessage);
    }

  };



  const handleMobileOTPChange = (e) => {
    setotpSendMessage('');
    const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    setMobileOTP(input);
    setMobileOTPError('');
  };


  const handleEmailOTPChange = (e) => {
    setotpSendMessage('');
    const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    setEmailOTP(input);
    setEmailOTPError('');
  };


  const closeDialog = () => {
    setIsDialogOpen(false);
    window.onpopstate = null;
  };

  const closeDialogResponse = () => {
    setIsDialogOpenResponse(false);
    // window.location.reload(); // This will reload the page 
    // handleLogout();
    window.onpopstate = null;
  };

  const history = useNavigate();
  const handleLogout = () => {
    auth.signOut().then(() => {
      // Redirect to login page after successful logout
      history('/'); // Change '/login' to your login page route
    }).catch((error) => {
      // Handle any errors during logout
      console.error('Error logging out:', error.message);
    })
  }


  const [otpSendMessage, setotpSendMessage] = useState('');

  const resendOtp = async () => {
    let phoneSuccess = false;
    let emailSuccess = false;


    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection , Please retry.');
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }

    const status = await SessionTime.checkInternetConnection(); // Call the function

    if (status === 'Poor connection.') {
      setIsDialogOpen(true);
      setModalMessage('No/Poor Internet connection , Please retry.');
      setLoading(false);
      // alert('No/Poor Internet connection , Please retry.'); // Display the "Poor connection" message in an alert
      return;
    }


    try {
      const phoneSendOtp = new PhoneSendOtp(phonenumberlist);
      const phoneResult = await phoneSendOtp.sendOTP(phonenumberlist);
      if (phoneResult === 411) {
        setMobileOTPError(`Invalid phone number: (${phoneResult})`);
      } else {
        phoneSuccess = true;
      }
    } catch (phoneError) {
      // console.error('Error sending OTP to phone:', phoneError);
    }
    // Send OTP to email
    try {
      const emailResult = await handleButtonClick(adminEmail);
      if (typeof emailResult === 'boolean') {
        if (emailResult) {
          emailSuccess = true;
          /// console.log('OTP sent to email successfully.');
        } else {
          setEmailOTPError('Error sending OTP to email. Please try again.');
        }
      } else {
        ///console.error('Error:', emailResult);
      }
    } catch (emailError) {
      // console.error('Error sending OTP to email:', emailError);
    }

    // Check if both OTPs were sent successfully
    if (phoneSuccess && emailSuccess) {
      setotpSendMessage(' OTP sent successfully.');
    } else {
      setEmailOTPError('OTP sending failed.');
    }
  };







  return (
    <>

      <div>
        <Navbar />
      </div>

      {loading ? (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999'
        }}>
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : null}


      <div className='containers'>
        <div className='formgroup'>
          <div>
            {/* <div>
              <h3>Enter OTP</h3>
            </div> */}
            <label htmlFor="mobileOTP">Enter Mobile OTP</label>
            <input
              type="text"
              className='form-control'
              placeholder="Mobile OTP"
              value={mobileOTP}
              onChange={handleMobileOTPChange}
              maxLength={6}
            />
            <p style={{ color: 'red' }} className="error">{mobileOTPError}</p>
          </div>
          <div>
            <label htmlFor="emailOTP">Enter E-mail OTP</label>
            <input
              type="text"
              className='form-control'
              placeholder="E-mail OTP"
              value={emailOTP}
              onChange={handleEmailOTPChange}
              maxLength={6}
            />
            <p style={{ color: 'red' }} className="error">{emailOTPError}</p>'
            <span>{otpSendMessage}</span>
          </div>
          <div className='d-flex justify-content-center w-100'>
            <button type="submit" className='btn btn-primary' style={{ marginRight: '50px' }} onClick={handleSubmitClick}>
              VERIFY
            </button>

            <div>
              <ResendTimer onClick={resendOtp} />
            </div>


          </div>


        </div>
      </div>

      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" style={{ marginTop: '3%' }}>

        <Modal.Body>
          <p style={{ color: 'red' }} >  {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={isDialogOpenResponse} onHide={closeDialogResponse} backdrop="static" style={{ marginTop: '3%' }}>

        <Modal.Body>
          <p style={{ color: 'red' }} > {modalMessageResponse}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialogResponse}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>




    </>
  )
}

export default Transferphoneemail