import React, { useState, useEffect } from 'react';
import { getAuth,  fetchSignInMethodsForEmail,  } from "firebase/auth";
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../testapp/test.css';
import { Modal, Button } from 'react-bootstrap';
import CommonFuctions from '../commonfunction';
import Mainhomepage from '../home/mainhomepage';




const Login = () => {

  const database = firebase.database();
  const sessiontime = new CommonFuctions();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  

  useEffect(() => {
    //   // Check if there are saved values in localStorage and set the state
    const ForgetPassword = localStorage.getItem('ForgetPassword');
    const forgetphoneNumber = localStorage.getItem('forgetphoneNumber');
    if (forgetphoneNumber) setPhoneNumber(forgetphoneNumber);
    if (ForgetPassword) setPassword(ForgetPassword);
    // Add event listener for beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      ///  Remove event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, []);
  const handleBeforeUnload = () => {
    ///  Clear input values when the page is about to unload (e.g., on refresh or leaving the page)
    localStorage.removeItem('forgetphoneNumber');
    localStorage.removeItem('ForgetPassword');
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   handleLogin(event);
  // }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPhoneNumberError('');
    setPasswordError('');
    if (!phoneNumber) {
      setPhoneNumberError('Enter valid mobile number');
      return;
    }
    if (phoneNumber.length !== 10) {
      setPhoneNumberError('Enter valid mobile number.');
      return;
    }
    if (!password) {
      setPasswordError('Enter valid Password');
      return;
    }
    if (password.length < 8) {
      setPasswordError('Enter valid Password');
      return;
    }
    setIsLoading(true);
    const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function
    if (!navigator.onLine) {
      setIsDialogOpen(true);
      setModalMessage('No internet. Cannot access server.');
      setIsLoading(false);
      return;
    }
    // const status = await sessiontime.checkInternetConnectionAvailable(); // Call the function

    if (status === 'Poor connection') {
      setIsDialogOpen(true);
      setModalMessage('Poor internet. Cannot access server.');
      setIsLoading(false);
      return;
    }

    try {
      const setemeterRef = database.ref(`/adminRootReference/adminDetails/${phoneNumber}/adminProfile/`);
      const updateSetMeter = setemeterRef.toString();
      // console.log('Firebase reference: ', updateSetMeter);
      const result = await sessiontime.callCloudFunction(updateSetMeter);
      const data = JSON.parse(result);
      delete data.time;

      if (!data || Object.keys(data).length === 0) {
        setPhoneNumberError('Account does not exist with this mobile number.');
        // console.log('Account does not exist with this mobile number.');
        setIsLoading(false);
        return;
      }

      if (data.password !== password) {
        setPasswordError('Password does not match');
        //  console.log('Password does not match');
        setIsLoading(false);
        return;
      }
      localStorage.removeItem('forgetphoneNumber');
      localStorage.removeItem('ForgetPassword');
      localStorage.setItem('phoneNumber', phoneNumber);
      localStorage.setItem('password', password); // Corrected the typo here
      navigate("/verifyphone");

    } catch (error) {

      /// console.log('Something went wrong: ', error);
      setIsDialogOpen(true);
      setModalMessage(`Response not received from server. Please retry. ${error}`);
      setIsLoading(false);
    }
  };


  const handlePhoneNumberChange = (e) => {
    setError('');
    setPhoneNumberError('');
    let input = e.target.value.replace(/\D/, '').slice(0, 10); // Remove non-digit characters and limit length to 10 digits

    if (input.length > 0 && input.charAt(0) === '0') {
      input = input.slice(1);
    }
    if (input === '0') {

      setError('Phone number cannot be 0');

    } else {
      setPhoneNumber(input);
      setError(null);
    }
  };
  const handlechangePassword = (input) => {
    setError('');
    setPasswordError('');
    setPassword(input);
  }

  const handleForgotPassword = async () => {
    setError('');
    setPasswordError('');

    if (phoneNumber.trim() !== '') {
      try {
        const auth = getAuth();
        const email = `${phoneNumber}@gmail.com`;
        // Check if the user exists for the provided email (constructed from phone number)
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        if (signInMethods.length > 0) {
          localStorage.setItem('forgetphoneNumber', phoneNumber);
          // User exists, navigate to the forgetpassword page
          navigate("/forgetpassword", { state: { phoneNumber } });
        } else {
          // User does not exist, show error message
          setError('Account does not exist with this mobile number.');
        }
      } catch (error) {
        //  alert('Error checking user existence. Please try again later.');
        ///  console.error('Error checking user existence:', error);
      }
    } else {
      //alert('Please enter your phone number');

      setPhoneNumberError('Please enter phone number');
    }
  };



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');


  const closeDialog = () => {
    setIsDialogOpen(false);
  };



  const [responseDailog, setIsResponseDialogOpen] = useState(false);
  const [modalResponseMessage, setResponseModalMessage] = useState('');

  const closeResponseDialog = () => {
    setIsDialogOpen(false);
    window.location.reload(); // Reload the page

  };


  return (
    <>
      <div>
        <Mainhomepage />
      </div>
      <div>

        {isLoading ? (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999'
          }}>
            <div className="spinner-border text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
      </div>

      <div className="containerpage" id="container">
        <div className="form-container log-in-container">
          <form onSubmit={handleSubmit}>
            <h2>Login Account </h2>
            {/* Phone Number input */}
            <div style={{ margin: '15px' }}>
              <p className='paragrap' htmlFor="phoneNumber">Phone Number</p>
              <div className="input-container1">
                <input
                  type="text"
                  value={phoneNumber}
                  className='form-control'
                  onChange={handlePhoneNumberChange}
                  maxLength={10}
                  placeholder="Phone Number"
                  style={{ paddingLeft: '30px' }}
                />
                <i className="fas fa-phone"></i>
              </div>
              <span style={{ color: 'red' }}>{phoneNumberError}</span>
            </div>

            {/* Password input */}
            <div style={{ margin: '15px' }}>
              <p className='paragrappassword' htmlFor="password">Password</p>
              <div className="input-container1">
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  className='form-control'
                  onChange={(e) => handlechangePassword(e.target.value)}
                  maxLength={18}
                  placeholder="Password"
                  style={{ paddingLeft: '30px' }}
                  autoComplete="current-password"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="password-toggle-button"
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className='password-toggle-button-eye-icon'
                  />
                </button>
                <i className="fas fa-lock password-icon"></i>
              </div>
              <span style={{ color: 'red' }}>{passwordError}</span>
            </div>

            {/* Error message */}
            <div>
              <p style={{ color: 'red' }}>{error}</p>
            </div>

            {/* Sign In button */}
            <div className="">
              <button className='btn btn-primary button' type='submit'>Sign In</button>
            </div>

            {/* Forgot Password link */}
            <p style={{ cursor: 'pointer', color: 'blue', margin: '10px' }} onClick={handleForgotPassword}>
              Forgot Password ?
            </p>

            <div className="mobile-only">
              <p style={{ margin: '-1px' }}>Don't have an account ?</p>
              <NavLink to="/createaccount">
                <button className='createbutton'>CREATE ACCOUNT</button>
              </NavLink>
            </div>

          </form>
        </div>

        {/* Create Account section (visible only on mobile) */}
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-right">
              <div className="flex-container">
                <div className="welcome-text">
                  <h3>Welcome To Maxwell</h3>
                  <h5>Prepaid Energy Meter System</h5>
                </div>
                <div className="button-container">
                  <p style={{ margin: '-1px' }}>Don't have an account ?</p>
                  <NavLink to="/createaccount">
                    <button className='createbutton'>CREATE ACCOUNT</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>






      {/* 
      <div className="containerpage" id="container">
        <div className="form-container log-in-container">
          <form onSubmit={handleSubmit}>
            <h2>Login Account </h2>
       
            <div style={{ margin: '15px' }}>
              <p className='paragrap' htmlFor="phoneNumber">Phone Number</p>
              <div className="input-container1">
                <input
                  type="text"
                  value={phoneNumber}
                  className='form-control'
                  onChange={handlePhoneNumberChange}
                  maxLength={10}
                  placeholder=" Phone Number"
                  style={{ paddingLeft: '30px' }}
                />
                <i class="fas fa-phone"></i>

              </div>
              <span style={{ color: 'red' }}>{phoneNumberError}</span>
            </div>

            <div style={{ margin: '15px' }}>
              <p className='paragrappassword' htmlFor="password">Password</p>
              <div class="input-container1">
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  className='form-control'
                  onChange={(e) => handlechangePassword(e.target.value)}
                  maxLength={18}
                  placeholder="Password"
                  style={{ paddingLeft: '30px' }}
                  autocomplete="current-password" // Add this line
                />

                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="password-toggle-button"
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className='password-toggle-button-eye-icon'
              
                  />
                </button>
                <i className="fas fa-lock password-icon"></i>
              </div>

              <span style={{ color: 'red' }}>{passwordError}</span>
            </div>
            <div>
              <p style={{ color: 'red' }}>{error}</p>
            </div>
            <div className="">
              <button className='btn btn-primary button' type='submit'>Sign In</button>
            </div>

            <p style={{ cursor: 'pointer', color: 'blue', margin: '10px' }} onClick={handleForgotPassword}>
              Forgot Password ?
            </p>

          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-right">
              <div className="flex-container">
                <div className="welcome-text">
                  <h3>Welcome To Maxwell</h3>
                  <h5>Prepaid Energy Meter System</h5>
                </div>
                <div className="button-container">
                  <p style={{ margin: '-1px' }}>Don't have an account ?</p>
                  <NavLink to="/createaccount">
                    <button className='createbutton'>CREATE ACCOUNT</button>
                  </NavLink>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}



      <Modal show={isDialogOpen} onHide={closeDialog} backdrop="static" >
        <Modal.Body>
          <p style={{ color: 'red' }}> {modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={responseDailog} onHide={closeResponseDialog} backdrop="static" >
        <Modal.Body>
          <p style={{ color: 'red' }}> {modalResponseMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeResponseDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



    </>
  );
};

export default Login;
