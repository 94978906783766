import React from 'react'

function Wel() {
  return (
    <>
    
    
       <h1>Welcome </h1>
    </>
  )
}

export default Wel