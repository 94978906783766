import React, { useState } from 'react';
import '../meterdetailonserver/meterdetail.css';
import Meterdetail from '../meterdetailonserver/meterdetail';
import Groupdetails from '../meterdetailonserver/groupdetails';

function Consumerdetails() {
  const [inputValue, setInputValue] = useState('');
  const [view, setView] = useState('both'); // State to manage the view
  const [highlightedSerial, setHighlightedSerial] = useState(null); // State to manage the highlighted serial number
  const [errorMessage, setErrorMessage] = useState(''); // State to manage the error message

  const handleInputChange = (e) => {
    setHighlightedSerial(null);
    const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    setInputValue(value);
  };

  const handleButtonClick = () => {

  //  console.log('Number is ', inputValue);
    if (inputValue.trim() === '') {
      setErrorMessage('Please enter a valid number');
      setHighlightedSerial(null);
    } else {
      setHighlightedSerial(inputValue.trim());
      setErrorMessage(''); // Clear any previous error messages
    }
  };

  
  const showMeterDetail = () => {
    setView('meterdetail');
  };

  const showGroupDetails = () => {
    setView('groupdetails');
  };

  return (
    <>

      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <div style={{ position: 'fixed', top: 0, width: '100%', backgroundColor: 'white', zIndex: 1 }}>
          <div style={{ marginTop: '7%', marginLeft: '16%' }}>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control w-25"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Enter number"
                maxLength={6}
              />
              <button className="btn btn-primary ms-4" onClick={handleButtonClick}>Search</button>
            </div>
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          </div>

          <div style={{ display: 'flex', marginLeft: '16%', height: '60px' }}>
            <h3 className='EditGRoupname' style={{ width: '25%', marginRight: '10px', alignItems: 'center' }} onClick={showMeterDetail}>Ungroup data</h3>
            <h3 className='EditGRoupname' style={{ width: '25%', alignItems: 'center' }} onClick={showGroupDetails}>Group Data</h3>
          </div>
          
        </div>
        <div style={{ marginTop: '15%', overflowY: 'auto', flex: 1 }}>
          {view === 'both' && (
            <>
              <Meterdetail highlightedSerial={highlightedSerial} />
              <Groupdetails highlightedSerial={highlightedSerial} />
            </>
          )}
          {view === 'meterdetail' && <Meterdetail highlightedSerial={highlightedSerial} />}
          {view === 'groupdetails' && <Groupdetails highlightedSerial={highlightedSerial} />}
        </div>
      </div>

    </>
  );
}

export default Consumerdetails;
